<template>
  <div class="arrangeOptFormBlock">
    <div class="dates">
      <div class="dates-head">
        <span>排班日期</span>
      </div>
      <div class="dates-content">
        <div v-for="(item, index) of gradeDates" class="dates-content-lab">
          <div>{{ item }}</div>
          <el-icon class="dates-content-close">
            <el-icon-circle-close />
          </el-icon>
        </div>
      </div>
    </div>
    <div class="people">
      <div class="people-head">
        <span>添加员工</span>

        <el-icon><el-icon-circle-plus-outline /></el-icon>
        <i class="iconfont iconshanchudefuben" @click="delUsers" />
      </div>

      <div class="people-content">
        <div
          v-for="(item, index) of users"
          :class="
            !item.checked
              ? 'people-content-lab'
              : 'people-content-lab user-checked'
          "
          @click="userSelect(item, index)"
        >
          {{ item.staffName }}
        </div>
      </div>
    </div>
    <div class="grade">
      <div class="grade-head">
        <span>添加班次</span>
      </div>

      <div class="grade-content">
        <el-select
          v-model="arrestId"
          class="grade-content-select"
          style="width: 145px"
          filterable
          placeholder="请选择"
          @change="arrestChange"
        >
          <el-option
            v-for="item in arrestOptions"
            :key="item.arrestId"
            :label="item.arrestName"
            :value="item.arrestId"
          />
        </el-select>
        <el-select
          v-if="arrestId != null"
          v-model="postId"
          class="grade-content-select"
          filterable
          placeholder="请选择"
          @change="postChange"
        >
          <el-option
            v-for="item in postList"
            :key="item.postId"
            :label="item.postName"
            :value="item.postId"
          />
        </el-select>
        <el-select
          v-if="postId != null"
          v-model="classesId"
          class="grade-content-select"
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="item in classesList"
            :key="item.classesId"
            :label="item.classesName"
            :value="item.classesId"
          />
        </el-select>
      </div>
    </div>
    <arrangePeopleSelectDialog
      v-model:visible="peopleDialogVisible"
      @confirm="peopleConfirm"
    />
  </div>
</template>

<script>
import {
  CircleClose as ElIconCircleClose,
  CirclePlus as ElIconCirclePlusOutline,
} from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../../../utils/gogocodeTransfer'
import arrangePeopleSelectDialog from '@/components/aloneComponents/pg/duty/dialog/arrangePeopleSelectDialog'
import { getArrest, getClasses, getPost } from '@/requet/api/ba/scheduleApi'

export default {
  name: 'ArrangeOptForm',
  components: {
    arrangePeopleSelectDialog,
    ElIconCircleClose,
    ElIconCirclePlusOutline
  },
  props: {
    gradeDates: {
      type: Array,
      default: []
    },
    arrestList: {
      type: Array | Object
    }
  },
  emits: ['removeDate'],
  data() {
    return {
      peopleDialogVisible: false,
      users: [],
      arrestOptions: [],
      arrestId: null,
      postList: [],
      postId: null,
      classesList: [],
      classesId: null
    }
  },
  created() {
    // this.arrestList.forEach(x => {
    //   this.arrestIds.push(x.id)
    // })
  },
  mounted() {
    this.getArrest()
  },
  methods: {
    async getArrest() {
      let res = await getArrest()
      this.arrestOptions = res
      await this.getPost()
    },
    async getPost() {
      let res = await getPost({
        arrestIdList: [this.arrestId]
      })
      this.postList = res
      if (this.posList && this.posList.length > 1) {
        this.postId = this.posList[0].postId
      }
    },
    async arrestChange() {
      this.postId = null
      this.postList = []
      this.classesList = []
      this.classesId = null
      await this.getPost()
    },
    postChange() {
      this.classesList = []
      this.classesId = null
      this.getClasses()
    },
    async getClasses() {
      let res = await getClasses({
        postId: this.postId
      })
      this.classesList = res
      if (this.classesList && this.classesList.length > 1) {
        this.classesId = this.classesList[0].classesId
      }
    },
    removeDate(index) {
      $emit(this, 'removeDate', index)
    },
    openPeopleDialog() {
      this.peopleDialogVisible = true
      console.log(this.peopleDialogVisible)
    },
    userSelect(user, index) {
      this.users[index].checked = !this.users[index].checked
    },
    delUsers() {
      this.users = this.users.filter(x => !x.checked)
    },
    peopleConfirm(users) {
      for (const user of users) {
        if (!this.users.find(x => x.staffId === users.staffId)) {
          this.users.push({
            ...user,
            checked: false
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'css/duty';
.arrangeOptFormBlock {
  display: flex;
  flex-wrap: wrap;
  .dates {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &-head {
      width: 100%;

      span {
        font-size: 14px;
        color: #b6bbc8;
      }
    }

    &-content {
      width: 100%;
      min-height: 50px;
      padding: 5px 0 5px 0;
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;

      &-lab {
        width: 85px;
        height: 30px;
        //position: relative;
        line-height: 30px;
        margin-right: 5px;
        margin-bottom: 3px;
        text-align: center;
        background-color: #eff1f3;
        border-radius: 4px;
        font-size: 13px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }

      &-close {
        font-size: 12px;
        cursor: pointer;
        //color: #f38b8b;
        //position: absolute;
        //right: 2px;
        //top: 9px
      }
    }
  }

  .people {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-head {
      width: 100%;

      span {
        font-size: 14px;
        color: #b6bbc8;
        margin-right: 10px;
      }

      i:nth-of-type(1) {
        margin-right: 10px;
        color: #5892e8;
        font-size: 14px;
      }

      i:nth-of-type(2) {
        margin-right: 10px;
        color: #f38b8b;
        font-size: 14px;
      }
    }

    &-content {
      width: 100%;
      min-height: 50px;
      padding: 5px 0 5px 0;
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;

      &-lab {
        width: 65px;
        height: 30px;
        line-height: 30px;
        margin-right: 5px;
        margin-bottom: 5px;
        text-align: center;
        background-color: #eff1f3;
        border-radius: 4px;
        font-size: 13px;
        cursor: pointer;
        text-overflow: ellipsis;
        //font-weight: bold;
      }

      .user-checked {
        background-color: #5892e8;
        color: white;
      }
    }
  }

  .grade {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .grade-head {
      width: 100%;

      span {
        font-size: 14px;
        color: #b6bbc8;
        margin-right: 10px;
      }

      i:nth-of-type(1) {
        margin-right: 10px;
        color: #5892e8;
        font-size: 14px;
      }

      i:nth-of-type(2) {
        margin-right: 10px;
        color: #f38b8b;
        font-size: 14px;
      }
    }

    &-content {
      width: 100%;
      min-height: 50px;
      padding: 5px 0 5px 0;

      &-select {
        width: 105px;
        margin-right: 5px;
      }

      &-lab {
        width: 60px;
        height: 30px;
        line-height: 30px;
        margin-right: 5px;
        text-align: center;
        background-color: $dayBg;
        border-radius: 4px;
        font-size: 13px;
        cursor: pointer;
        text-overflow: ellipsis;
        font-weight: bold;
      }
    }
  }
}
:deep(.el-select__tags-text) {
  max-width: 30px;
}
</style>
