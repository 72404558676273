import {
  post,
  fetch,
  patch,
  put,
  postJson,
  fetch_blob,
  postBlob,
} from '../../http'
export default {
  //在途列表
  shipHD(params) {
    return fetch('/rextec/api/shipHD', params)
  },
  // 在途明细查询
  shipHDId(id) {
    return fetch('/rextec/api/shipHD/' + id, {})
  },
  // 中转到货按钮
  transitArrival(params) {
    return put('/rextec/api/shipHD/transitArrival', params)
  },
  // 到货按钮
  arrival(params) {
    return put('/rextec/api/shipHD/arrival', params)
  },
  // 上报异常
  abnormalWay(params) {
    return postJson('/rextec/api/abnormalWay', params)
  },
  // 配载中转查询
  shipDT(params) {
    return fetch('/rextec/api/shipDT/getStowageTransfer', params)
  },

  // 配载中转明细详情
  getStowageTransfer(id, params) {
    return fetch('/rextec/api/getStowageTransfer/' + id, params)
  },
  // 配置中转按钮
  stowageTransfer(params) {
    return postJson('/rextec/api/shipHD/stowageTransfer', params)
  },

  // 待派送查询
  shipDTWaitDeliver(params) {
    return fetch('/rextec/api/shipDT/waitDeliver', params)
  },

  //预约派送、签收明细查询
  getDeliverySign(params) {
    return fetch('/rextec/api/shipDT/getDeliverySign', params)
  },

  // 在途异常查询
  abnormalWayList(params) {
    return fetch('/rextec/api/abnormalWay', params)
  },
  // 在途异常详情
  abnormalWayId(id) {
    return fetch('/rextec/api/abnormalWay/' + id)
  },
  // 在途异常编辑
  abnormalWayUpdte(id, params) {
    return put('/rextec/api/abnormalWay/' + id, params)
  },

  // 待签收列表
  deliveryAppointmentHD(params) {
    return fetch('/rextec/api/deliveryAppointmentHD', params)
  },

  // 已签收列表
  arrivalSignHD(param) {
    return fetch('/rextec/api/arrivalSignHD', param)
  },
  // 获取已签收详情：http
  arrivalSignHDDetail(id) {
    return fetch('/rextec/api/SignDetailDT/list/' + id)
  },
  // 签收异常列表
  getSignAbnormalList(params) {
    return fetch('/rextec/api/arrivalSignHD/getSignAbnormalList', params)
  },
  // 签收异常详情
  getSignAbnormalDetail(id) {
    return fetch('/rextec/api/arrivalSignHD/getSignAbnormalDetail/' + id)
  },
  // 签收异常处理
  editSignAbnormal(id, data) {
    return put('/rextec/api/arrivalSignHD/editSignAbnormal/' + id, data)
  },
  // 新建预约派送
  deliveryAppointmentHDUpdate(params) {
    return postJson('/rextec/api/deliveryAppointmentHD', params)
  },
  //上传附件
  upload(data) {
    return postBlob('/rextec/api/minio/upload', data)
  },

  // 待签收 详情
  getAppointmentDetail(id) {
    return fetch('/rextec/api/deliveryAppointmentHD/getAppointmentDetail/' + id)
  },

  //待签收 批量签收详情
  getAllAppointmentDetail(params) {
    return fetch(
      '/rextec/api/deliveryAppointmentHD/getAppointmentDetailAll',
      params
    )
  },

  //批量部分签收
  arrivalSignHDUpdate(params) {
    return postJson('/rextec/api/arrivalSignHD', params)
  },

  //批量全部签收
  arrivalSignHDAllUpdate(params) {
    return postJson('/rextec/api/arrivalSignHD/confirm', params)
  },
}
