export function encode64(input) {
  var output = encodeSub64(input)
  if (output != '') {
    var vNum = randomNum(0, 25) + 65
    var vChar = String.fromCharCode(vNum)
    var vMidNum = Math.floor(output.length / 2)
    output =
      output.substring(0, vMidNum) +
      vChar +
      output.substring(vMidNum, output.length)
    vNum = randomNum(0, 25) + 65
    vChar = String.fromCharCode(vNum)
    output = vChar + output
    vNum = randomNum(0, 25) + 65
    vChar = String.fromCharCode(vNum)
    output = output + vChar
    output = output.split('').reverse().join('')
    output = reverseCharUpperOrLower(output)
    output = encodeSub64(output)
    output = randomString(7) + output + randomString(7)
  }
  //var output1 = decode64(output);
  return output
}

export function decode64(input) {
  var output = ''
  if (input != '' && input.length > 14) {
    output = input
    output = output.substring(7, output.length - 7)
    output = decodeSub64(output)
    output = reverseCharUpperOrLower(output)
    output = output.split('').reverse().join('')
    output = output.substring(1, output.length - 1)
    var vMidNum = Math.floor((output.length - 1) / 2)
    output =
      output.substring(0, vMidNum) +
      output.substring(vMidNum + 1, output.length)
    output = decodeSub64(output)
  }
  return output
}
function encodeSub64(input) {
  var output = ''
  var base = new Base64()
  var output = base.encode(input)
  return output
}

function decodeSub64(input) {
  var output = ''
  var base = new Base64()
  var output = base.decode(input)
  return output
}

function Base64() {
  // private property
  var _keyStr =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  // public method for encoding
  this.encode = function (input) {
    var output = ''
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4
    var i = 0
    input = _utf8_encode(input)
    while (i < input.length) {
      chr1 = input.charCodeAt(i++)
      chr2 = input.charCodeAt(i++)
      chr3 = input.charCodeAt(i++)
      enc1 = chr1 >> 2
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
      enc4 = chr3 & 63
      if (isNaN(chr2)) {
        enc3 = enc4 = 64
      } else if (isNaN(chr3)) {
        enc4 = 64
      }
      output =
        output +
        _keyStr.charAt(enc1) +
        _keyStr.charAt(enc2) +
        _keyStr.charAt(enc3) +
        _keyStr.charAt(enc4)
    }
    return output
  }

  // public method for decoding
  this.decode = function (input) {
    var output = ''
    var chr1, chr2, chr3
    var enc1, enc2, enc3, enc4
    var i = 0
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '')
    while (i < input.length) {
      enc1 = _keyStr.indexOf(input.charAt(i++))
      enc2 = _keyStr.indexOf(input.charAt(i++))
      enc3 = _keyStr.indexOf(input.charAt(i++))
      enc4 = _keyStr.indexOf(input.charAt(i++))
      chr1 = (enc1 << 2) | (enc2 >> 4)
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2)
      chr3 = ((enc3 & 3) << 6) | enc4
      output = output + String.fromCharCode(chr1)
      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2)
      }
      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3)
      }
    }
    output = _utf8_decode(output)
    return output
  }

  // private method for UTF-8 encoding
  var _utf8_encode = function (string) {
    string = string.replace(/\r\n/g, '\n')
    var utftext = ''
    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n)
      if (c < 128) {
        utftext += String.fromCharCode(c)
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192)
        utftext += String.fromCharCode((c & 63) | 128)
      } else {
        utftext += String.fromCharCode((c >> 12) | 224)
        utftext += String.fromCharCode(((c >> 6) & 63) | 128)
        utftext += String.fromCharCode((c & 63) | 128)
      }
    }
    return utftext
  }

  // private method for UTF-8 decoding
  var _utf8_decode = function (utftext) {
    var string = ''
    var i = 0
    var c = (c1 = c2 = 0)
    while (i < utftext.length) {
      c = utftext.charCodeAt(i)
      if (c < 128) {
        string += String.fromCharCode(c)
        i++
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1)
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63))
        i += 2
      } else {
        c2 = utftext.charCodeAt(i + 1)
        c3 = utftext.charCodeAt(i + 2)
        string += String.fromCharCode(
          ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        )
        i += 3
      }
    }
    return string
  }
}

function randomString(e) {
  e = e || 32
  var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
    a = t.length,
    n = ''
  for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
  return n
}

//生成从minNum到maxNum的随机数
function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10)
      break
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
      break
    default:
      return 0
      break
  }
}

function reverseCharUpperOrLower(input) {
  var output = ''
  if (input != '') {
    var arr = input.split('')
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] == arr[i].toLowerCase()) {
        arr[i] = arr[i].toUpperCase()
      } else {
        arr[i] = arr[i].toLowerCase()
      }
    }
    output = arr.join('')
  }
  return output
}
