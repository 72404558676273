<template>
  <div class="dispatchMessageBlock">
    <!--    {{ data }}-->
    <el-tabs v-model="activeName" :stretch="true" @tab-click="handleClick">
      <el-tab-pane
        v-for="(msg, index) in data"
        :id="msg.title"
        :label="msg.title"
        :name="msg.title"
        class="msg-item"
      >
        <template v-if="msg.msgList && msg.msgList.length > 0">
          <div
            v-for="(item, index) in msg.msgList"
            :key="index"
            class="right-top-whole-list"
          >
            <div class="right-top-whole-list-fonts">
              <div
                style="
                  width: 100%;
                  height: 50%;
                  display: flex;
                  flex-direction: row;
                "
              >
                <div style="width: 20%; height: 20%; text-align: center">
                  <img
                    v-if="item.urgency == '001'"
                    :src="data[1].icon"
                    style="width: 1.5vw; margin-right: 10px"
                  />
                  <img
                    v-if="item.urgency == '002'"
                    :src="data[2].icon"
                    style="width: 1.5vw; margin-right: 10px"
                  />
                  <img
                    v-if="item.urgency == '003'"
                    :src="data[3].icon"
                    style="width: 1.5vw; margin-right: 10px"
                  />
                  <img
                    v-if="item.urgency == '004'"
                    :src="data[4].icon"
                    style="width: 1.5vw; margin-right: 10px"
                  />
                </div>
                <div style="width: 80%; height: 100%">
                  <span>{{ item.messageName }}</span>
                </div>
              </div>
              <div style="width: 90%; height: 50%; margin-top: 1px">
                <!-- <el-tooltip :content=item.messageInfo class="item" effect="light" placement="top-start">
                              <pre style="overflow:hidden;text-overflow:ellipsis;">{{ item.messageInfo }}</pre>
                            </el-tooltip> -->
              </div>
            </div>
            <div class="right-top-whole-list-btn">
              <span
                class="spancolor"
                style="cursor: pointer"
                @click="
                  gotohandle(
                    item.messageType,
                    item.messageId,
                    item,
                    item.targetInfo.key
                  )
                "
                >去处理</span
              >
              <el-icon class="btni">
                <el-icon-arrow-right />
              </el-icon>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            style="
              width: 100%;
              margin-top: 20px;
              font-size: 16px;
              color: #999;
              text-align: center;
            "
          >
            暂无消息
          </div>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { ArrowRight as ElIconArrowRight } from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../../../utils/gogocodeTransfer'
import { updateMessage } from '@/requet/api/ba/dispatchingCenter'

export default {
  name: 'DispatchMessage',
  components: {
    ElIconArrowRight
  },
  props: ['data', 'db'],
  emits: ['scrolldown', 'tabindex', 'nopost', 'getMessageRefresh'],
  data() {
    return {
      activeName: '全部',
      dbl: this.db
    }
  },
  watch: {
    // data(val){
    //   console.log(val[0].msgList);
    // }
  },
  created() {
    this.dbl = this.db
  },
  mounted() {
    console.log(this.data)
    this.$nextTick(() => {
      this.data.forEach(element => {
        this.scroll(element.title)
      })
    })
  },
  methods: {
    scroll(title) {
      const dom = document.getElementById(title)
      dom.addEventListener('scroll', () => {
        const clientHeight = dom.clientHeight
        const scrollTop = dom.scrollTop
        const scrollHeight = dom.scrollHeight
        // console.log('clientHeight:'+ clientHeight,'scrollTop:'+ scrollTop,'scrollHeight:'+ scrollHeight)
        if (clientHeight + scrollTop > scrollHeight - 1) {
          this.data.forEach(item => {
            if (item.title == this.activeName) {
              if (item.msgList.length <= 20) {
                this.dbl = 0
              }
            }
          })
          if (scrollTop > this.dbl + 1) {
            // console.log('clientHeight:'+ clientHeight,'scrollTop:'+ scrollTop,'scrollHeight:'+ scrollHeight)
            // console.log('竖向滚动条已经滚动到底部')
            $emit(this, 'scrolldown', true)
            this.dbl = scrollTop
          }

          // this.pageNumber++;
          // this.getMessageagain();
        }
      })
    },
    handleClick(tab, event) {
      // console.log(tab.index);
      $emit(this, 'tabindex', tab.index)
    },
    //消息列表 去处理 按钮
    gotohandle(messageType, messageId, item, id) {
      this.updateMessage(messageId)
      console.log('handle:', item)
      if (messageType == '004') {
        this.$router.push({
          name: 'remainProcess',
          params: {
            approvalInfo: {
              ...item?.targetInfo?.ApprovalInfo,
              key: item?.targetInfo?.key
            }
          },
          query: {
            menuid: 'LM1312',
            title: '待办工作',
            configtype: '003',
            groupid: '',
            filtergroup: '',
            filtergroupshowsign: 'Y'
          }
        })
      } else if (messageType == '005') {
        $emit(this, 'nopost', id)
      } else {
        this.$message.warning('该消息类型未设置操作')
      }
      console.log(this.data)
    },
    //更新为已读
    async updateMessage(id) {
      let res = updateMessage({ messageIdList: [id] })
      res.then(res => {
        console.log(res)
        $emit(this, 'getMessageRefresh', true)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dispatchMessageBlock {
  width: 96%;
  height: 100%;
  margin-left: 2%;
  .msg-item {
    width: 100%;
    height: 70vh;
    // display: flex;
    // align-items: center;
    flex-wrap: wrap;
    overflow: auto;
  }

  .right-top-whole-list {
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .right-top-whole-list-font {
    width: 100%;
    height: 100%;
    font-size: 0.9vw;
  }

  .right-top-whole-list-fonts {
    width: 75%;
    height: 100%;
    font-size: 0.9vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .right-top-whole-list-btn {
    width: 20%;
    height: 100%;
    font-size: 0.9vw;
  }

  .btni {
    padding-top: 2%;
  }
}
</style>
