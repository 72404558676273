<template>
  <el-dialog
    :title="type"
    :model-value="dialogVisible"
    width="45%"
    :append-to-body="true"
    @close="handleClose"
  >
    <div v-for="arr in dataArrMsg">
      <div v-if="arr.levelItem.itemName == type && type == '动态评分'">
        <div v-for="(todo, index) in arr.nodeItems" :key="index">
          <div v-if="index == 0" class="box1">
            <p class="pTitle">
              {{ todo.levelItem.itemName }}
            </p>
            <div class="div1">
              <div v-for="(text, index) in todo.nodeItems" class="div1-1">
                <el-tooltip
                  placement="bottom"
                  effect="light"
                  style="border: none"
                >
                  <template #content>
                    <div style="width: 420px">
                      <div>
                        <div style="display: flex; align-items: baseline">
                          <div
                            style="
                              font-size: 1rem;
                              font-weight: 600;
                              margin-bottom: 1rem;
                              margin-left: 1rem;
                            "
                          >
                            {{ text.levelItem.itemName }}
                          </div>
                          <div style="display: flex">
                            <p
                              style="
                                font-size: 0.7rem;
                                color: #3674f4;
                                margin-left: 0.5rem;
                              "
                            >
                              (权重
                            </p>
                            <p style="font-size: 0.7rem; color: #3674f4">
                              {{ text.levelItem.itemWeight }})
                            </p>
                          </div>
                        </div>
                        <div style="display: flex; flex-wrap: wrap">
                          <div
                            v-for="(item, index) in text.nodeItems"
                            style="
                              margin-bottom: 2rem;
                              margin-left: 1rem;
                              background-color: #f9fbfe;
                            "
                          >
                            <div
                              style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 0.5rem;
                                width: 120px;
                              "
                            >
                              <div
                                style="
                                  background-color: #3674f4;
                                  width: 10px;
                                  height: 10px;
                                  border-radius: 50%;
                                "
                              />
                              <div
                                style="
                                  font-size: 0.5rem;
                                  color: #808181;
                                  margin-left: 0.5rem;
                                "
                              >
                                {{ item.levelItem.itemName }}
                              </div>
                              <div
                                style="
                                  font-size: 0.5rem;
                                  color: #808181;
                                  margin-left: 1rem;
                                "
                              >
                                {{ item.levelItem.rank }}
                              </div>
                            </div>
                            <div
                              style="
                                display: flex;
                                margin-left: calc(0.5rem + 10px);
                                align-items: baseline;
                              "
                            >
                              <div style="font-size: 1rem; font-weight: 600">
                                {{
                                  item.levelItem.itemScore
                                    ? item.levelItem.itemScore
                                    : 0

                                }}分
                              </div>
                              <div style="font-size: 0.5rem; color: #3674f4">
                                ({{
                                  item.levelItem.trueScore
                                    ? item.levelItem.trueScore.toFixed(2)
                                    : 0

                                }})
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div style="display: flex; align-items: center">
                    <div style="margin-right: 1rem">
                      <img :src="messageArr1[index].icon" class="img1" />
                    </div>
                    <div>
                      <p class="p1">
                        {{ text.levelItem.itemName }}
                      </p>
                      <span style="display: flex">
                        <p class="p2">{{ text.levelItem.itemScore }}分</p>
                        <p
                          style="
                            color: #3674f4;
                            display: flex;
                            align-items: flex-end;
                            font-size: 0.5rem;
                          "
                        >
                          ({{ text.levelItem.trueScore.toFixed(2) }})
                        </p>
                      </span>
                    </div>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div v-if="index == 1" class="box1">
            <p class="pTitle">
              {{ todo.levelItem.itemName }}
            </p>
            <div class="div1">
              <div v-for="(text, index) in todo.nodeItems" class="div1-1">
                <el-tooltip placement="bottom" effect="light">
                  <template #content>
                    <div style="width: 420px">
                      <div>
                        <div style="display: flex; align-items: baseline">
                          <div
                            style="
                              font-size: 1rem;
                              font-weight: 600;
                              margin-bottom: 0.7rem;
                              margin-left: 1rem;
                            "
                          >
                            {{ text.levelItem.itemName }}
                          </div>
                          <div style="display: flex">
                            <p
                              style="
                                font-size: 0.7rem;
                                color: #3674f4;
                                margin-left: 0.5rem;
                              "
                            >
                              (权重
                            </p>
                            <p style="font-size: 0.7rem; color: #3674f4">
                              {{ text.levelItem.itemWeight }})
                            </p>
                          </div>
                        </div>
                        <div style="display: flex; flex-wrap: wrap">
                          <div
                            v-for="(item, index) in text.nodeItems"
                            style="
                              margin-bottom: 2rem;
                              margin-left: 1rem;
                              background-color: #f9fbfe;
                            "
                          >
                            <div
                              style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 0.5rem;
                              "
                            >
                              <div
                                style="
                                  background-color: #3674f4;
                                  width: 10px;
                                  height: 10px;
                                  border-radius: 50%;
                                "
                              />
                              <div
                                style="
                                  font-size: 0.5rem;
                                  color: #808181;
                                  margin-left: 0.5rem;
                                "
                              >
                                {{ item.levelItem.itemName }}
                              </div>
                              <div
                                style="
                                  font-size: 0.5rem;
                                  color: #808181;
                                  margin-left: 1rem;
                                "
                              >
                                {{ item.levelItem.rank }}
                              </div>
                            </div>
                            <div
                              style="
                                display: flex;
                                margin-left: calc(0.5rem + 10px);
                                align-items: baseline;
                              "
                            >
                              <div style="font-size: 1rem; font-weight: 600">
                                {{
                                  item.levelItem.itemScore
                                    ? item.levelItem.itemScore
                                    : 0

                                }}分
                              </div>
                              <div style="font-size: 0.5rem; color: #3674f4">
                                ({{
                                  item.levelItem.trueScore
                                    ? item.levelItem.trueScore.toFixed(2)
                                    : 0

                                }})
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div style="display: flex; align-items: center">
                    <div style="margin-right: 1rem">
                      <img :src="messageArr2[index].icon" class="img1" />
                    </div>
                    <div>
                      <p class="p1">
                        {{ text.levelItem.itemName }}
                      </p>
                      <span style="display: flex"
                        ><p class="p2">
                          {{
                                                      text.levelItem.itemScore
                                                        ? text.levelItem.itemScore
                                                        : 0

                          }}分
                        </p>
                        <p
                          style="
                            color: #3674f4;
                            display: flex;
                            align-items: flex-end;
                            font-size: 0.5rem;
                          "
                        >
                          ({{
                            text.levelItem.trueScore
                              ? text.levelItem.trueScore.toFixed(2)
                              : 0

                          }})
                        </p>
                      </span>
                    </div>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div v-if="index == 2" class="box1">
            <p class="pTitle">
              {{ todo.levelItem.itemName }}
            </p>
            <div class="div1">
              <div v-for="(text, index) in todo.nodeItems" class="div1-1">
                <el-tooltip placement="bottom" effect="light">
                  <template #content>
                    <div style="width: 420px">
                      <div>
                        <div style="display: flex; align-items: baseline">
                          <div
                            style="
                              font-size: 1rem;
                              font-weight: 600;
                              margin-bottom: 1rem;
                              margin-left: 1rem;
                            "
                          >
                            {{ text.levelItem.itemName }}
                          </div>
                          <div style="display: flex">
                            <p
                              style="
                                font-size: 0.7rem;
                                color: #3674f4;
                                margin-left: 0.5rem;
                              "
                            >
                              (权重
                            </p>
                            <p style="font-size: 0.7rem; color: #3674f4">
                              {{ text.levelItem.itemWeight }})
                            </p>
                          </div>
                        </div>
                        <div style="display: flex; flex-wrap: wrap">
                          <div
                            v-for="(item, index) in text.nodeItems"
                            style="
                              margin-bottom: 2rem;
                              margin-left: 1rem;
                              background-color: #f9fbfe;
                            "
                          >
                            <div
                              style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 0.5rem;
                              "
                            >
                              <div
                                style="
                                  background-color: #3674f4;
                                  width: 10px;
                                  height: 10px;
                                  border-radius: 50%;
                                "
                              />
                              <div
                                style="
                                  font-size: 0.5rem;
                                  color: #808181;
                                  margin-left: 0.5rem;
                                "
                              >
                                {{ item.levelItem.itemName }}
                              </div>
                              <div
                                style="
                                  font-size: 0.5rem;
                                  color: #808181;
                                  margin-left: 1rem;
                                "
                              >
                                {{ item.levelItem.rank }}
                              </div>
                            </div>
                            <div
                              style="
                                display: flex;
                                margin-left: calc(0.5rem + 10px);
                                align-items: baseline;
                              "
                            >
                              <div style="font-size: 1rem; font-weight: 600">
                                {{
                                  item.levelItem.itemScore
                                    ? item.levelItem.itemScore
                                    : 0

                                }}分
                              </div>
                              <div style="font-size: 0.5rem; color: #3674f4">
                                ({{
                                  item.levelItem.trueScore
                                    ? item.levelItem.trueScore.toFixed(2)
                                    : 0

                                }})
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div style="display: flex; align-items: center">
                    <div style="margin-right: 1rem">
                      <img :src="messageArr3[index].icon" class="img1" />
                    </div>
                    <div>
                      <p class="p1">
                        {{ text.levelItem.itemName }}
                      </p>
                      <span style="display: flex"
                        ><p class="p2">
                          {{
                                                      text.levelItem.itemScore
                                                        ? text.levelItem.itemScore
                                                        : 0

                          }}分
                        </p>
                        <p
                          style="
                            color: #3674f4;
                            display: flex;
                            align-items: flex-end;
                            font-size: 0.5rem;
                          "
                        >
                          ({{
                            text.levelItem.trueScore
                              ? text.levelItem.trueScore
                              : 0

                          }})
                        </p>
                      </span>
                    </div>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="arr in dataArrMsg">
      <div v-if="arr.levelItem.itemName == type && type == '静态评分'">
        <div v-for="todo in arr.nodeItems">
          <div class="box1">
            <p class="pTitle">
              {{ todo.levelItem.itemName }}
            </p>
            <div class="div1" style="flex-wrap: wrap">
              <div
                v-for="(text, index) in todo.nodeItems"
                class="div1-1"
                style="margin-bottom: 1.5rem"
              >
                <el-tooltip placement="bottom" effect="light">
                  <template #content>
                    <div style="width: 420px">
                      <div>
                        <div style="display: flex; align-items: baseline">
                          <div
                            style="
                              font-size: 1rem;
                              font-weight: 600;
                              margin-bottom: 1rem;
                              margin-left: 1rem;
                            "
                          >
                            {{ text.levelItem.itemName }}
                          </div>
                          <div style="display: flex">
                            <p
                              style="
                                font-size: 0.7rem;
                                color: #3674f4;
                                margin-left: 0.5rem;
                              "
                            >
                              (权重
                            </p>
                            <p style="font-size: 0.7rem; color: #3674f4">
                              {{ text.levelItem.itemWeight }})
                            </p>
                          </div>
                        </div>
                        <div style="display: flex; flex-wrap: wrap">
                          <div
                            style="
                              margin-bottom: 2rem;
                              margin-left: 1rem;
                              background-color: #f9fbfe;
                            "
                          >
                            <div
                              style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 0.5rem;
                              "
                            >
                              <div
                                style="
                                  background-color: #3674f4;
                                  width: 10px;
                                  height: 10px;
                                  border-radius: 50%;
                                "
                              />
                              <div
                                style="
                                  font-size: 0.5rem;
                                  color: #808181;
                                  margin-left: 0.5rem;
                                "
                              >
                                {{ text.levelItem.itemName }}
                              </div>
                              <div
                                style="
                                  font-size: 0.5rem;
                                  color: #808181;
                                  margin-left: 1rem;
                                "
                              >
                                {{ text.levelItem.rank }}
                              </div>
                            </div>
                            <div
                              style="
                                display: flex;
                                margin-left: calc(0.5rem + 10px);
                                align-items: baseline;
                              "
                            >
                              <div style="font-size: 1rem; font-weight: 600">
                                {{
                                  text.levelItem.itemScore
                                    ? text.levelItem.itemScore
                                    : 0

                                }}分
                              </div>
                              <div style="font-size: 0.5rem; color: #3674f4">
                                ({{
                                  text.levelItem.trueScore
                                    ? text.levelItem.trueScore.toFixed(2)
                                    : 0

                                }})
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div style="display: flex; align-items: center">
                    <div style="margin-right: 1rem">
                      <img :src="imgArr1[index].icon" class="img1" />
                    </div>
                    <div>
                      <p class="p1">
                        {{ text.levelItem.itemName }}
                      </p>
                      <span style="display: flex">
                        <p class="p2">
                          {{
                            text.levelItem.itemScore
                              ? text.levelItem.itemScore
                              : 0

                          }}分
                        </p>
                        <p
                          style="
                            color: #3674f4;
                            display: flex;
                            align-items: flex-end;
                            font-size: 0.5rem;
                          "
                        >
                          ({{
                            text.levelItem.trueScore
                              ? text.levelItem.trueScore.toFixed(2)
                              : 0

                          }})
                        </p>
                      </span>
                    </div>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="arr in dataArrMsg">
      <div v-if="arr.levelItem.itemName == type && type == '奖惩评分'">
        <div v-for="todo in arr.nodeItems">
          <div class="box1">
            <p class="pTitle">
              {{ todo.levelItem.itemName }}
            </p>
            <div class="div1" style="flex-wrap: wrap">
              <div
                v-for="(text, index) in todo.nodeItems"
                class="div1-1"
                style="margin-bottom: 1.5rem"
              >
                <el-tooltip placement="bottom" effect="light">
                  <template #content>
                    <div style="width: 420px">
                      <div>
                        <div style="display: flex; align-items: baseline">
                          <div
                            style="
                              font-size: 1rem;
                              font-weight: 600;
                              margin-bottom: 1rem;
                              margin-left: 1rem;
                            "
                          >
                            {{ text.levelItem.itemName }}
                          </div>
                          <div style="display: flex">
                            <p
                              style="
                                font-size: 0.7rem;
                                color: #3674f4;
                                margin-left: 0.5rem;
                              "
                            >
                              (权重
                            </p>
                            <p style="font-size: 0.7rem; color: #3674f4">
                              {{ text.levelItem.itemWeight }})
                            </p>
                          </div>
                        </div>
                        <div style="display: flex; flex-wrap: wrap">
                          <div
                            v-for="(item, index) in text.nodeItems"
                            style="
                              margin-bottom: 2rem;
                              margin-left: 1rem;
                              background-color: #f9fbfe;
                            "
                          >
                            <div
                              style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 0.5rem;
                                width: 120px;
                              "
                            >
                              <div
                                style="
                                  background-color: #3674f4;
                                  width: 10px;
                                  height: 10px;
                                  border-radius: 50%;
                                "
                              />
                              <div
                                style="
                                  font-size: 0.5rem;
                                  color: #808181;
                                  margin-left: 0.5rem;
                                "
                              >
                                {{ item.levelItem.itemName }}
                              </div>
                              <div
                                style="
                                  font-size: 0.5rem;
                                  color: #808181;
                                  margin-left: 1rem;
                                "
                              >
                                {{ item.levelItem.rank }}
                              </div>
                            </div>
                            <div
                              style="
                                display: flex;
                                margin-left: calc(0.5rem + 10px);
                                align-items: baseline;
                              "
                            >
                              <div style="font-size: 1rem; font-weight: 600">
                                {{
                                  item.levelItem.itemScore
                                    ? item.levelItem.itemScore
                                    : 0

                                }}分
                              </div>
                              <div style="font-size: 0.5rem; color: #3674f4">
                                ({{
                                  item.levelItem.trueScore
                                    ? item.levelItem.trueScore.toFixed(2)
                                    : '0'

                                }})
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div style="display: flex; align-items: center">
                    <div style="margin-right: 1rem">
                      <img :src="messageArr4[index].icon" class="img1" />
                    </div>
                    <div>
                      <p class="p1">
                        {{ text.levelItem.itemName }}
                      </p>
                      <span style="display: flex">
                        <p class="p2">{{ text.levelItem.itemScore }}分</p>
                        <p
                          style="
                            color: #3674f4;
                            display: flex;
                            align-items: flex-end;
                            font-size: 0.5rem;
                          "
                        >
                          ({{ text.levelItem.trueScore.toFixed(2) }})
                        </p>
                      </span>
                    </div>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props: ['type', 'dataArrMsg'],
  data() {
    return {
      dialogVisible: false,
      dataArr: [],
      // type: "静态评分",
      messageArr1: [{
          icon: require('@/assets/images/perPortrait/tc_gztd.png'),
          text1: '工作态度',
        },
        {
          icon: require('@/assets/images/perPortrait/tc_gznl.png'),
          text1: '工作能力',
        },
        {
          icon: require('@/assets/images/perPortrait/tc_gzjx.png'),
          text1: '工作绩效',
        },],
      messageArr2: [{
          icon: require('@/assets/images/perPortrait/tc_kqdk.png'),
          text1: '考勤打卡',
        },
        {
          icon: require('@/assets/images/perPortrait/tc_bsj.png'),
          text1: '病事假',
        },

        {
          icon: require('@/assets/images/perPortrait/tc_jbyy.png'),
          text1: '加班意愿',
        },],
      messageArr3: [{
          icon: require('@/assets/images/perPortrait/tc_sjpj.png'),
          text1: '上级评价',
        },
        {
          icon: require('@/assets/images/perPortrait/tc_khpj.png'),
          text1: '客户评价',
        },
        {
          icon: require('@/assets/images/perPortrait/tc_tspj.png'),
          text1: '同事评价',
        },],
      messageArr4: [{
          icon: require('@/assets/images/perPortrait/tc_jl.png'),
          text1: '奖励',
        },
        {
          icon: require('@/assets/images/perPortrait/tc_cf.png'),
          text1: '处罚',
        },],
      imgArr1: [
        { icon: require('@/assets/images/perPortrait/bmi.png') },
        { icon: require('@/assets/images/perPortrait/fby.png') },
        { icon: require('@/assets/images/perPortrait/qtzs.png') },
        { icon: require('@/assets/images/perPortrait/bazs.png') },
        { icon: require('@/assets/images/perPortrait/xfzs.png') },
        { icon: require('@/assets/images/perPortrait/zzmm.png') },
        { icon: require('@/assets/images/perPortrait/whcd.png') },
      ],
      imgArr2: [{ icon: require('@/assets/images/perPortrait/pxkh.png') }],
    }
  },

  watch: {
    dataArrMsg: {
      deep: true,

      handler: function (newVal, oldVal) {
        this.dataArr = newVal
        console.log(this.dataArr)
      },
    },
  },
  methods: {
    show() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
      console.log('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.img1 {
  max-width: 80%;
  background-size: cover;
}
.box1 {
  width: 100%;
  height: 6rem;
}
.pTitle {
  font-size: 1rem;
  color: #333333;
  margin-bottom: 5px;
}
.div1 {
  display: flex;
  flex-direction: row;
  background-color: #f9fbfe;
  .div1-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30%;
    margin-right: 1rem;
    .p1 {
      font-size: 0.5rem;
      color: #808181;
    }
    .p2 {
      font-weight: 700;
      font-size: 1rem;
      color: #333333;
    }
  }
}
:deep(.el-dialog) {
  border-radius: 5px;
}
:deep(.el-dialog__header) {
  padding: 0.7rem 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f7fa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
:deep(.el-dialog__title) {
  font-size: 0.9rem;
  font-weight: bold;
}
:deep(.el-dialog__headerbtn) {
  position: relative;
  top: unset;
  right: unset;
}
:deep(.el-dialog__body) {
  padding: 15px 20px 5px 20px;
  overflow-y: auto;
  height: 23rem;
}
</style>
