<template>
  <el-dialog
    v-bind="$attrs"
    :append-to-body="true"
    title="新增工单-选择推送对象"
    width="80%"
    @close="_close"
  >
    <div class="dialogBlock">
      <div style="width: 100%">
        <div style="width: 100%; display: flex; height: 400px; margin-top: 0px">
          <div style="width: 100%; height: 100%">
            <filterCriteria
              :is-show="isShow"
              @search="searchpeople"
              @selectButton="staffsearch"
            />
            <div
              style="
                height: 30px;
                border-top: 1px solid #e4e5e8;
                padding-top: 10px;
                display: flex;
                flex-direction: row;
              "
            >
              <div style="width: 85%">
                <span v-if="type == 'Single'" style="margin-left: 0%"
                  >驻点：{{ val.arrestName }}</span
                >
                <span v-if="type == 'Single'" style="margin-left: 2%"
                  >岗位：{{ val.postName }}</span
                >
                <span v-if="type == 'Single'" style="margin-left: 2%"
                  >班次：{{ val.classesName }}</span
                >
                <span v-if="type == 'Single'" style="margin-left: 1%"
                  >开始时间：{{ val.startDate }}</span
                >
                <span v-if="type == 'Single'" style="margin-left: 1%"
                  >结束时间：{{ val.endDate }}</span
                >
              </div>
              <div
                style="
                  width: 15%;
                  height: 30px;
                  margin-right: 10px;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-end;
                "
              >
                <el-button plain type="primary" @click="confirmAll">
                  <i class="iconfont icondaochuexcel" />全部推送
                </el-button>
              </div>
            </div>

            <el-table
              ref="multipleTable"
              :data="dirData"
              height="60%"
              style="width: 100%; margin-top: 10px"
              @selection-change="wkOrderPeopleSelect"
              @sort-change="sortChange"
            >
              <el-table-column type="selection" width="55" />
              <el-table-column label="序号" type="index" width="55" />
              <el-table-column label="大队" prop="depName" />
              <el-table-column label="驻点" prop="arrestName" />
              <el-table-column label="工号" prop="oldStaffId" />
              <el-table-column label="姓名" prop="staffName" />
              <el-table-column label="联系方式" prop="relationTele" />
              <el-table-column
                label="星级"
                prop="levelName"
                show-overflow-tooltip
                sortable="custom"
              >
                <template #default="scope">
                  <div v-if="scope.row.levelName" class="levelName-btn">
                    {{ scope.row.levelName }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          style="
            width: 100%;
            height: 20px;
            margin-top: 60px;
            padding-top: 30px;
            padding-bottom: 30px;
            display: flex;
            justify-content: flex-end;
            border-bottom: 1px solid #e4e5e8;
          "
        >
          <el-pagination
            v-model:current-page="workerOrderPage"
            v-model:page-size="workerOrderpagesize"
            :page-sizes="[100, 200, 300, 400]"
            :total="workerOrdernums"
            background
            layout="total,sizes,prev, pager, next,  jumper"
            style="margin-right: 30px; margin-top: 5px"
          />
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button @click="_close"> 取消 </el-button>
      <el-button type="primary" @click="confirm"> 确定 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../../../utils/gogocodeTransfer'
import treemenu2 from '@/views/configurationForm/treeMenu2'
import {
  getSatffWithTree,
  getNeedStaff
} from '@/requet/api/ba/dispatchingCenter'
import { diffDatesec } from '@/utils/dateTime'
import cascader from '@/components/aloneComponents/pg/duty/dispatchCenter/cascader'
import filterCriteria from '@/components/aloneComponents/pg/duty/dispatchCenter/filterCriteria'

export default {
  name: 'AddOrderPushObjDialog',
  components: { treemenu2, cascader, filterCriteria },
  props: {
    type: String,
    val: Object,
    Data: Array,
    isShow: Boolean
  },
  emits: ['update:visible', 'confirm', 'confirmAll'],
  data() {
    return {
      SheetAgainAddstarttime: null,
      dirData: [],
      dirData2: null,
      dirProps: {
        label: 'label',
        children: 'children',
        value: 'value'
      },
      starname: '',
      depname: '',
      arrestName: '',
      starvalue: [],
      workerOrderPage: 1,
      workerOrderpagesize: 100,
      workerOrdernums: 0,
      staroptions: ['一星', '二星', '三星', '四星', '五星'],
      openIs: false,
      xfzValue2: '',
      fbyValue2: '',
      tgjlValue2: '',
      degree: '006',
      degreeArr: [
        {
          value: '006',
          label: '初中以上'
        },
        {
          value: '005',
          label: '高中/职高/中专以上'
        },
        {
          value: '002',
          label: '大专以上'
        },
        {
          value: '001',
          label: '本科以上'
        }
      ],
      sexARrr: [
        {
          value: '全部',
          label: '性别'
        },
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      order: 0,

      startDate: '',
      endDate: '',

      isFireNo: false,
      isService: false,
      arrestId: '',
      params: {
        fireNo: null,
        service: null

        // height: null,
        // smallAge: null,
        // bigAge: null,
        // sex: null,
      },
      height: '',
      ageMin: '',
      ageMax: '',
      sex: '',

      needKind: '002',
      kongXian: false, //空闲
      sendschedulingId: false, //同审批人
      sendsecurityStaff: false, //保安部
      sendExpatriate: false, //外派人员
      arrestIdList: [], //驻点id列表
      selectRows: [],
      depIdList: [], //大队id列表
      schedulingIdList: [], //排班id
      experience: false //  同岗经历
      // isShow:true,
    }
  },
  watch: {
    starvalue(val) {
      this.getNeedStaff()
    },
    async workerOrderPage(newval) {
      // this.getNeedStaff(),
      this.getNeedStaff2()
    }
  },
  created() {},
  mounted() {
    this.getSatffWithTree()
    console.log(this.type)

    //Single 为单；Multiple为多
    if (this.type == 'Single') {
      console.log(this.val)
      this.startDate = this.val.startDate
      this.endDate = this.val.endDate
      this.arrestIdList = []
      this.depIdList = []
      this.schedulingIdList = []
      this.arrestIdList.push(this.val.arrestId)
      this.depIdList.push(this.val.depId)
      this.schedulingIdList.push(this.val.schedulingId)
      this.getNeedStaff()
    } else if (this.type == 'Multiple') {
      console.log(this.Data)
      this.arrestIdList = []
      this.depIdList = []
      this.schedulingIdList = []
      if (this.Data[0]) {
        this.startDate = this.Data[0].startDate
        this.endDate = this.Data[0].endDate
        for (let i = 1; i < this.Data.length; i++) {
          this.arrestIdList.push(this.Data[i].arrestId)
          this.depIdList.push(this.Data[i].depId)
          this.schedulingIdList.push(this.Data[i].schedulingId)
          if (this.timeFrame(this.startDate, this.Data[i].startDate) > 0) {
            this.startDate = this.Data[i].startDate
          }
          if (this.timeFrame(this.endDate, this.Data[i].endDate) < 0) {
            this.endDate = this.Data[i].endDate
          }
        }
        this.getNeedStaff()
      }
    }
    console.log(this.arrestIdList, this.depIdList)
  },
  methods: {
    //取消按钮
    _close() {
      $emit(this, 'update:visible', false)
    },

    //确认按钮
    confirm() {
      let data = {
        selectRows: this.selectRows
      }
      if (this.selectRows?.length > 0) {
        $emit(this, 'confirm', data)
      } else {
        this.$message.warning('请选择人员')
      }
    },
    confirmAll() {
      let data = {
        startDate: this.startDate,
        endDate: this.endDate,
        staff: this.starname,
        starList: this.starvalue,
        starOrder: this.order,
        depName: this.depname,
        arrestName: this.arrestName,
        // approvalList:this.sendschedulingId?this.schedulingIdList:[],
        // securityStaff:this.sendsecurityStaff?'001':null,
        // arrestIdList:this.needNoStaffArrestId?this.arrestIdList:[],
        // depIdList:this.needNoStaffDepId?this.depIdList:[],
        // ...this.params,
        // fireNo: this.isFireNo ? 'Y' : null,
        // service: this.isService ? '001' : null,
        approvalList:
          this.sendschedulingId == true ? this.schedulingIdList : null,
        kind: this.kongXian == true ? '001' : this.needKind,
        securityStaff: this.sendsecurityStaff == true ? '001' : null,
        expatriate: this.sendExpatriate ? '002' : null,
        education: this.degree ? this.degree : null,
        arrestIdList:
          this.needNoStaffArrestId == true ? this.arrestIdList : null,
        depIdList: this.needNoStaffDepId == true ? this.depIdList : null,
        ...this.params,
        fireNo: this.isFireNo == true ? 'Y' : null,
        service: this.isService == true ? '001' : null,
        sex: this.sex,
        smallAge: this.ageMin ? this.ageMin : null,
        bigAge: this.ageMax ? this.ageMax : null,
        height: this.height ? this.height : null,
        experience: this.experience == true ? this.schedulingIdList[0] : null
      }
      $emit(this, 'confirmAll', data)
    },

    //获取树形菜单数据
    async getSatffWithTree() {
      let res = await getSatffWithTree()
      this.dirData2 = res
      console.log(res)
      this.dirData2.forEach(e => {
        if (e.children != null) {
          e.children.forEach(res => {
            res.children = null
          })
        }
      })
    },

    // //树形控件点击选中的数据
    // getactClickdata(val) {
    //   console.log(val);
    // },

    // //刷新
    // getactClickdataReset() {
    //   this.getactClickdata({
    //     value: null,
    //   });
    // },

    // //展开、收缩更多条件
    // more() {
    //   this.openIs = !this.openIs;
    // },

    //判断获取最大时间范围
    timeFrame(dateA, dataB) {
      return diffDatesec(dateA, dataB)
    },

    //获取table数据
    async getNeedStaff() {
      let res = await getNeedStaff(
        this.workerOrderPage,
        this.workerOrderpagesize,
        {
          startDate: this.startDate,
          endDate: this.endDate,
          staff: this.starname,
          starList: this.starvalue,
          starOrder: this.order,
          // arrestId:this.arrestId,
          depName: this.depname,
          arrestName: this.arrestName,
          approvalList:
            this.sendschedulingId == true ? this.schedulingIdList : null,
          kind: this.kongXian == true ? '001' : this.needKind,
          securityStaff: this.sendsecurityStaff == true ? '001' : null,
          expatriate: this.sendExpatriate ? '002' : null,
          education: this.degree ? this.degree : null,
          arrestIdList:
            this.needNoStaffArrestId == true ? this.arrestIdList : null,
          depIdList: this.needNoStaffDepId == true ? this.depIdList : null,
          ...this.params,
          fireNo: this.isFireNo == true ? 'Y' : null,
          service: this.isService == true ? '001' : null,
          sex: this.sex,
          smallAge: this.ageMin ? this.ageMin : null,
          bigAge: this.ageMax ? this.ageMax : null,
          height: this.height ? this.height : null,
          experience: this.experience == true ? this.schedulingIdList[0] : null
        }
      )
      console.log(res)
      if (res) {
        this.dirData = res.pageInfo.list
        this.workerOrdernums = res.pageInfo.total
      } else {
        this.dirData = []
        this.workerOrdernums = 0
      }
    },
    async getNeedStaff2() {
      let res = await getNeedStaff(
        this.workerOrderPage,
        this.workerOrderpagesize,
        {
          startDate: this.startDate,
          endDate: this.endDate,
          staff: this.starname,
          starList: this.starvalue,
          starOrder: this.order,
          // arrestId:this.arrestId,
          depName: this.depname,
          arrestName: this.arrestName,
          approvalList:
            this.sendschedulingId == true ? this.schedulingIdList : null,
          kind: this.kongXian == true ? '001' : this.needKind,
          securityStaff: this.sendsecurityStaff == true ? '001' : null,
          expatriate: this.sendExpatriate ? '002' : null,
          education: this.degree ? this.degree : null,
          arrestIdList:
            this.needNoStaffArrestId == true ? this.arrestIdList : null,
          depIdList: this.needNoStaffDepId == true ? this.depIdList : null,
          ...this.params,
          fireNo: this.isFireNo == true ? 'Y' : null,
          service: this.isService == true ? '001' : null,
          sex: this.sex,
          smallAge: this.ageMin ? this.ageMin : null,
          bigAge: this.ageMax ? this.ageMax : null,
          height: this.height ? this.height : null,
          experience: this.experience == true ? this.schedulingIdList[0] : null
        }
      )
      console.log(res)
      if (res) {
        this.dirData = res.pageInfo.list
        this.workerOrdernums = res.pageInfo.total
      } else {
        this.dirData = []
        this.workerOrdernums = 0
      }
    },

    //查询按钮
    searchpeople(
      degree,
      needKind,
      starname,
      starvalue,
      order,
      depname,
      arrestName,
      sendschedulingId,
      sendsecurityStaff,
      needNoStaffArrestId,
      needNoStaffDepId,
      isFireNo,
      isService,
      height,
      ageMin,
      ageMax
    ) {
      this.height = height
      this.ageMin = ageMin
      this.ageMax = ageMax
      this.starname = starname
      this.depname = depname
      this.order = order
      this.arrestName = arrestName
      this.workerOrderPage = 1
      this.getNeedStaff2()
    },

    //选择驻点id查询
    getcheckedId(id) {
      // console.log(id);
      this.arrestId = id
      this.getNeedStaff()
    },

    //星级升降
    sortChange(column) {
      console.log(column)
      if (column.order == 'ascending') {
        this.order = 0
      } else {
        this.order = 1
      }
      this.getNeedStaff()
    },

    //查询 排班不冲突人员
    staffsearch(
      needKind,
      degree,
      kongXian,
      sendsecurityStaff,
      sendExpatriate,
      isFireNo,
      isService,
      sex,
      sendschedulingId,
      experience,
      starvalue,
      needNoStaffArrestId,
      needNoStaffDepId,
      starname,
      depname,
      arrestName,
      height,
      ageMin,
      ageMax
    ) {
      ;(this.starname = starname),
        (this.depname = depname),
        (this.arrestName = arrestName),
        (this.height = height),
        (this.ageMin = ageMin),
        (this.ageMax = ageMax),
        (this.isFireNo = isFireNo)
      this.isService = isService
      this.sendExpatriate = sendExpatriate
      this.needKind = needKind
      this.kongXian = kongXian
      this.sendsecurityStaff = sendsecurityStaff
      this.degree = degree
      this.sex = sex
      this.sendschedulingId = sendschedulingId
      this.experience = experience
      this.starvalue = starvalue
      this.needNoStaffArrestId = needNoStaffArrestId
      this.needNoStaffDepId = needNoStaffDepId

      this.workerOrderPage = 1
      //保安部
      if (needKind == '004') {
        this.sendsecurityStaff = true
      } else {
        this.sendsecurityStaff = false
      }
      //同审批人
      if (needKind == '003') {
        this.sendschedulingId = true
      } else {
        this.sendschedulingId = false
      }
      //本大队
      if (needKind == '006') {
        this.needNoStaffDepId = true
      } else {
        this.needNoStaffDepId = false
      }
      //本驻点
      if (needKind == '007') {
        this.needNoStaffArrestId = true
      } else {
        this.needNoStaffArrestId = false
      }
      this.getNeedStaff()
      // this.getStaff(this.needendDate, this.needstartDate, "");
    },
    wkOrderPeopleSelect(val) {
      this.selectRows = val
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.dialogBlock {
  display: flex;
  flex-wrap: wrap;
}
.footer {
  margin-top: 20px;
  text-align: right;
}
.filterItem {
  margin-bottom: 10px;
}
:deep(.el-dialog__body) {
  padding: 10px 20px 30px 20px;
}
.levelName-btn {
  width: 50px;
  height: 20px;
  color: #3a75ff;
  border: solid 1px #bfd3ff;
  background-color: #eef3ff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.el-dialog) {
  border-radius: 5px;
}
:deep(.el-dialog__header) {
  padding: 10px 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f7fa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
:deep(.el-dialog__title) {
  font-size: 16px;
  font-weight: bold;
}
:deep(.el-dialog__headerbtn) {
  position: relative;
  top: unset;
  right: unset;
}
</style>
