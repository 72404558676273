<template>
  <div
    style="
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
    "
  >
    <!--    <transition name="el-fade-in-linear">-->
    <div v-show="treeShow" class="all">
      <div class="ei_icon flexrowbetween">
        <span style="font-size: 14px">{{ title }}</span>
        <div>
          <el-icon><el-icon-refresh /></el-icon>
        </div>
      </div>
      <div class="input">
        <el-input
          v-model="input"
          class="input-with-select"
          placeholder="请输入内容"
          @keyup.enter="search()"
        >
          <template #append>
            <el-button :icon="ElIconSearch" @click="search()" />
          </template>
        </el-input>
      </div>
      <div class="ei_tree tree-container">
        <!--        show-checkbox-->
        <el-tree
          ref="tree"
          v-loading="treeload"
          :data="menuList"
          :props="props"
          :current-node-key="checkeddata"
          node-key="id"
          highlight-current
          check-on-click-node
          :expand-on-click-node="true"
          :filter-node-method="filterNode"
          @node-click="handleNodeClick"
        >
          <template #default="{ node }">
            <span class="custom-tree-node">
              <span class="wordStyle">
                <div
                  v-if="node.level == 3"
                  :class="node.data.kind == 'Y' ? 'circle1' : 'circle2'"
                />
                <el-icon><el-icon-place /></el-icon>
                <el-icon><el-icon-location-outline /></el-icon>
                <el-icon><el-icon-office-building /></el-icon>
                <span v-if="node.level == 3">{{ node.data.oldArrestId }}.</span
                >{{ node.data.label }}
              </span>
            </span>
          </template>
        </el-tree>
      </div>
      <!--      <div class="btn">-->
      <!--        <el-button @click="jumpto()">前去排班</el-button>-->
      <!--      </div>-->
    </div>
    <!--    </transition>-->
    <div style="display: flex; align-items: center">
      <i
        :class="treeShow ? 'el-icon-caret-left' : 'el-icon-caret-right'"
        @click="treeShow = !treeShow"
      />
    </div>
    <div :style="!treeShow ? 'width: 100%' : ''" class="manage">
      <arrangeGrade
        :prop-arrest-list="propArrestList"
        :idkey="key"
        :date="date"
        @getdutyDate="getdutyDate"
      />
    </div>
  </div>
</template>

<script>
import {
  Refresh as ElIconRefresh,
  Place as ElIconPlace,
  Location as ElIconLocationOutline,
  OfficeBuilding as ElIconOfficeBuilding,
  Search as ElIconSearch
} from '@element-plus/icons-vue'
import arrangeGrade from '@/views/aloneViews/pg/scheduling/arrangeGrade'
import { getArrestTree } from '@/requet/api/ba/scheduleApi'
import { shallowRef } from 'vue'
export default {
  name: 'ChooseJump',
  components: {
    arrangeGrade,
    ElIconRefresh,
    ElIconPlace,
    ElIconLocationOutline,
    ElIconOfficeBuilding
  },
  props: ['params', 'itemdetail'],
  data() {
    return {
      input: '',
      props: {
        label: 'label',
        children: 'children'
      },
      treeShow: true,
      menuList: [],
      checkedidlist: [],
      datalist: [],
      mainform: '',
      faId: '',
      item: {},
      //树形加载loading
      treeload: false,
      actClick: {},
      title: '部门-驻点',
      moduleid: 'TREE220001',
      menuid: 'LM1364',
      propArrestList: {
        id: 0,
        name: ''
      },
      reslist: [],
      checkeddata: '',
      typelist: [],
      types: [],
      year: '',
      month: '',
      key: '',
      date: {},
      ElIconSearch: shallowRef(ElIconSearch)
    }
  },
  mounted() {
    // console.log(Number(this.itemdetail.date.slice(4,6)))
    if (this.itemdetail) {
      this.key = this.itemdetail.key
      this.date = {
        year: Number(this.itemdetail.date.slice(0, 4)),
        month: Number(this.itemdetail.date.slice(4, 6))
      }
    }
    this.addcheckeddata()
  },
  beforeUnmount() {},
  methods: {
    async getdutyDate(dutyDate) {
      console.log(dutyDate)
      if (this.year != dutyDate.year || this.month != dutyDate.month) {
        this.treeload = true
        let res = await getArrestTree({
          month: dutyDate.month,
          year: dutyDate.year
        })
        this.treeload = false
        console.log(res)
        this.menuList = [res]
        this.typelist.push(this.menuList[0].value)
        this.year = dutyDate.year
        this.month = dutyDate.month
      }
    },

    search() {
      this.$refs.tree.filter(this.input)
    },
    addcheckeddata() {
      this.checkeddata = ''
      if (JSON.parse(sessionStorage.getItem('propArrestList')) != '') {
        this.propArrestList = JSON.parse(
          sessionStorage.getItem('propArrestList')
        )
        if (this.propArrestList != null) {
          this.checkeddata = this.propArrestList.depId
          console.log(this.propArrestList)
          this.types.push(this.propArrestList.depId)
          this.handleNodeClick(this.propArrestList)
          this.$nextTick(function () {
            this.$refs['tree'].setCurrentKey(this.checkeddata)
          })

          console.log(this.checkeddata)
        }
      }
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    handleNodeClick(data) {
      if (data.children == null) {
        console.log('handleNodeClick', data)
        let obj = {
          id: data.value,
          name: data.label
        }
        this.actClick = obj
        this.propArrestList = obj
        sessionStorage.setItem('propArrestList', JSON.stringify(this.actClick))
      }
    },
    async changeTree() {
      this.treeload = true
      let res = await getArrestTree({ month: this.month, year: this.year })
      this.treeload = false
      this.menuList = [res]
      this.typelist.push(this.menuList[0].value)
    }
  }
}
</script>

<style lang="scss" scoped>
.all {
  width: 250px;
  height: 100%;
  float: left;
  background-color: white;
  padding: 0 5px 0 5px;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}
.all-hidden {
  transform: translate(-100%, 0);
}
.manage {
  width: calc(100% - 250px);
}
.circle1 {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background-color: rgb(107, 244, 107);
}
.circle2 {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background-color: rgb(243, 137, 137);
}
.input {
  width: 90%;
  margin-left: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
}
.ei_icon {
  padding: 2% 4%;
  box-sizing: border-box;
  i {
    margin-right: 10px;
  }
}
.el-input-group {
  width: 100%;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.ei_tree {
  width: 100%;
  height: 80%;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 0 auto;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4px;
  padding-right: 8px;
}
.wordStyle {
  font-size: 1.4px;
}
.btn {
  width: 100%;
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tree-container :deep(.el-tree-node__expand-icon.expanded) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.tree-container :deep(.el-icon-caret-right:before) {
  content: '\e791';
  font-size: 1.4px;
}
.tree-container :deep(.el-tree-node__expand-icon) {
  margin-left: 2%;
  padding: 0px;
}
.tree-container :deep(.el-tree-node__expand-icon.is-leaf) {
  margin-left: 0px;
}
.tree-container :deep(.el-tree-node) {
  position: relative;
  padding-left: 6%;
}
.tree-container :deep(.el-tree-node__children) {
  padding-left: 4%;
}
.tree-container :deep(.el-tree-node:after) {
  border-top: 1px solid #e6e6e6;
  height: 2vh;
  top: 2vh;
  width: 7%;
}
@media screen and (min-width: 0px) {
  .tree-container :deep(.el-tree-node__children) {
    padding-left: 3%;
  }
  .tree-container :deep(.el-tree-node) {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep(.el-tree-node:after) {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 10%;
  }
}
@media screen and (min-width: 230px) {
  .tree-container :deep(.el-tree-node__children) {
    padding-left: 2%;
  }
  .tree-container :deep(.el-tree-node) {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep(.el-tree-node:after) {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 8.5%;
  }
}
@media screen and (min-width: 350px) {
  .tree-container :deep(.el-tree-node__children) {
    padding-left: 1.5%;
  }
  .tree-container :deep(.el-tree-node) {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep(.el-tree-node:after) {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 5%;
  }
}
@media screen and (min-width: 500px) {
  .tree-container :deep(.el-tree-node__children) {
    padding-left: 1%;
  }
  .tree-container :deep(.el-tree-node) {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep(.el-tree-node:after) {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 4%;
  }
}
@media screen and (min-width: 700px) {
  .tree-container :deep(.el-tree-node__children) {
    padding-left: 0.6%;
  }
  .tree-container :deep(.el-tree-node) {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep(.el-tree-node:after) {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 8%;
  }
}
.tree-container :deep(.el-tree > .el-tree-node:before) {
  border-left: none;
}
.tree-container :deep(.el-tree > .el-tree-node:after) {
  border-top: none;
}
.tree-container :deep(.el-tree > .el-tree-node:before) {
  border-left: none;
}
.tree-container :deep(.el-tree > .el-tree-node:after) {
  border-top: none;
}
.tree-container :deep(.el-tree-node:before) {
  content: '';
  left: 2%;
  position: absolute;
  right: auto;
  border-width: 1px;
}
.tree-container :deep(.el-tree-node:after) {
  content: '';
  left: 2%;
  position: absolute;
  right: auto;
  border-width: 1px;
}
.tree-container :deep(.el-tree-node:before) {
  border-left: 1px solid #e6e6e6;
  bottom: 0px;
  height: 100%;
  top: -1vh;
  width: 1px;
}
.el-tree-node :last-child:before {
  height: 5vh;
}
.tree-container {
  margin: 0px;
}
.tree-container :deep(.el-tree .el-tree-node) {
  position: relative;
}
:deep(.el-tree-node__content) {
  background-color: white;
  margin: 0px 0px;
  padding: 0px 0px;
  padding-left: 0px;
}
.tree-container :deep(.el-tree-node .el-tree-node__content) {
  height: 4vh;
  padding-left: 0 !important;
}
.tree-container :deep(.el-tree-node .el-tree-node__content::before) {
  border-left: 1px solid #e6e6e6;
  height: 100%;
  top: 0;
  width: 1px;
  margin-left: 1px;
  margin-top: 0px;
  z-index: 8;
}
.tree-container
  :deep(.el-tree-node .el-tree-node__children .el-tree-node__content::before) {
  border-left: 0px solid #e6e6e6;
  height: 100%;
  top: 0;
  width: 1px;
  margin-left: 1px;
  margin-top: 0px;
  z-index: 8;
}
.tree-container :deep(.el-tree-node .el-tree-node__content::after) {
  border-top: 1px solid #e6e6e6;
  height: 1px;
  top: 2vh;
  width: 1.5%;
  margin-left: 1px;
  z-index: 8;
}
.tree-container
  :deep(.el-tree-node .el-tree-node__children .el-tree-node__content::after) {
  border-top: 0px solid #e6e6e6;
}
.tree-container .el-tree-node .el-tree-node__content::before,
.tree-container .el-tree-node .el-tree-node__content::after {
  content: '';
  position: absolute;
  right: auto;
}
:deep(.el-tree-node__content > label.el-checkbox) {
  margin-left: 2px;
  margin-right: 2px;
}
</style>
