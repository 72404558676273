import { createI18n } from "vue-i18n";
import {cnLanguageMap}from './CN.js'
import {enLanguageMap}from './EN.js'

const messages = {
    EN: {
      msg: {
        ...enLanguageMap
      },
    },
    CN: {
      msg: {
        ...cnLanguageMap
      },
    },
  }

//   const locale = "CN";
  const locale = localStorage.getItem('language') ?localStorage.getItem('language')  : 'CN'
  const i18n = createI18n({
    // 使用 Composition API 模式，则需要将其设置为false
    legacy: false,
    // 全局注入 $t 函数
    globalInjection: true,
    locale,
    messages,
  })

  export default i18n