<template>
  <div class="dutyTypesBlock" style="position: relative">
    <div class="block-left">
      <div class="block-left-icon">
        <i v-if="showIcon" class="iconfont icongongnengleixing1" />
      </div>
      <div class="block-left-title">
        {{ title }}
      </div>
    </div>

    <div class="block-right">
      <template v-for="(item, index) of shiftTypes">
        <dutyTypeCard
          v-if="index < 3 || show == true"
          class="card"
          :item="item"
        />
      </template>
    </div>
    <div v-if="show == false" style="position: absolute; top: 0; right: 0">
      <el-icon><el-icon-arrow-down /></el-icon>
    </div>
    <div v-if="show == true" style="position: absolute; top: 0; right: 0">
      <el-icon><el-icon-arrow-up /></el-icon>
    </div>
  </div>
</template>

<script>
import {
  ArrowDown as ElIconArrowDown,
  ArrowUp as ElIconArrowUp
} from '@element-plus/icons-vue'
import dutyTypeCard from '@/components/aloneComponents/pg/duty/dutyTypeCard'

export default {
  name: 'DutyTypes',
  components: {
    dutyTypeCard,
    ElIconArrowDown,
    ElIconArrowUp
  },
  props: {
    shiftTypes: {
      type: Array
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '班次类型'
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    change() {
      if (this.show == false) {
        this.show = true
      } else {
        this.show = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'css/duty';
.dutyTypesBlock {
  display: flex;
  width: 97%;
  .block-left {
    display: flex;
    width: 100px;
    justify-content: flex-end;

    &-icon {
      display: flex;
      justify-content: right;
      padding-right: 5px;
      width: 35%;
      i {
        color: #b2b8c6;
      }
    }

    &-title {
      font-size: 14px;
      color: #b2b8c6;
      width: 65%;
      padding: 2px 0 0 0;
      text-align: right;
    }
  }

  .block-right {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 100px);

    .card {
      margin-left: 15px;
      margin-bottom: 10px;
    }
  }
}
</style>
