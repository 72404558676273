<template>
  <div
    v-show="visible"
    class="classPopoverBlock"
    :style="{ left: left + 'px', top: top + 'px' }"
  >
    <div>
      <li @click="_close()">关闭</li>
    </div>
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../../../utils/gogocodeTransfer'
export default {
  name: 'ClassPopover',
  components: {},
  props: {
    left: [String, Number],
    top: [String, Number],
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible'],
  data() {
    return {}
  },
  watch: {},
  mounted() {},
  methods: {
    confirm() {
      $emit(this, 'update:visible', false)
    },
    _close() {
      $emit(this, 'update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../css/duty';
.classPopoverBlock {
  width: 150px;
  min-height: 200px;
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
</style>
