<template>
  <div class="mianCust">
    <div v-loading="loadingpic" class="picshow">
      <img v-if="picShow" class="picImg" :src="picUrl" />
      <span v-else class="lrtip">暂未录入!</span>
    </div>
    <div class="picup">
      <el-upload
        ref="upload"
        class="upload-demo btnc"
        action="/Custom/pictureInfo/uploadStaffPicture"
        :headers="myHeaders"
        accept=".png,.jpeg,jpg"
        :data="{ staffid: staffid }"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
      >
        <el-button type="primary">
          {{ btnText }}
        </el-button>
      </el-upload>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadFace',
  components: {},
  props: ['btnLink'],
  data() {
    return {
      staffid: 'ZH92666',
      srcList: [],
      picUrl: '',
      pic: '',
      myHeaders: { AuthToken: sessionStorage.getItem('mytoken') },
      btnText: '上传图片',
      picShow: true
    }
  },
  watch: {
    picUrl: {
      handler(val) {
        console.log(val)
      },
      deep: true
    }
  },
  mounted() {
    //this.$store.state.loginInfo.id
    console.log(this.btnLink.query.pkval)
    this.staffid = this.btnLink.query.pkval
    this.getStaffPicture()
  },
  methods: {
    getStaffPicture() {
      let params = {
        staffid: this.staffid
      }
      this.$doFetch('/Custom/pictureInfo/getStaffPicture', params).then(res => {
        // console.log(res)
        if (res.length > 0) {
          this.picShow = true
          this.pic = res[0].authContent
          this.picUrl = 'data:image/png;base64,' + res[0].authContent
          if (res[0].authContent != '') {
            this.btnText = '修改图片'
          }
        } else {
          this.picShow = false
        }
      })
    },
    handleAvatarSuccess() {
      this.getStaffPicture()
    }
  }
}
</script>

<style lang="scss" scoped>
.mianCust {
  width: 100%;
  height: 100%;
  .picshow {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    .picImg {
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
    .lrtip {
      font-size: 1.5rem;
    }
  }
  .picup {
    width: 100%;
    height: 20%;
    .btnc {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
