<template>
  <div style="width: 100%; height: 50%">
    <div style="width: 100%; display: flex">
      <div style="width: 60%; display: flex; height: 30px">
        <div
          :class="
            needKind === '002' && stafftypeclick === false
              ? 'selected'
              : 'noSelected'
          "
          size="small"
          @click="staffsearch('002')"
        >
          全部
        </div>
        <div
          v-if="isShow == true"
          :class="
            needKind === '003' && stafftypeclick === false
              ? 'selected'
              : 'noSelected'
          "
          size="small"
          @click="staffsearch('003')"
        >
          同审批人
        </div>
        <div
          :class="
            needKind === '004' && stafftypeclick === false
              ? 'selected'
              : 'noSelected'
          "
          size="small"
          @click="staffsearch('004')"
        >
          保安部
        </div>
        <div
          v-if="isShow == true"
          :class="
            needKind === '006' && stafftypeclick === false
              ? 'selected'
              : 'noSelected'
          "
          size="small"
          @click="staffsearch('006')"
        >
          本大队
        </div>
        <div
          v-if="isShow == true"
          :class="
            needKind === '007' && stafftypeclick === false
              ? 'selected'
              : 'noSelected'
          "
          size="small"
          @click="staffsearch('007')"
        >
          本驻点
        </div>
        <div
          v-if="isShow == false && stafftype == true"
          :class="stafftypeclick === true ? 'selected' : 'noSelected'"
          size="small"
          @click="getStaffType()"
        >
          抢单人员
        </div>
      </div>
      <div style="width: 40%; display: flex">
        <el-input
          v-model="starname"
          placeholder="请输入工号或姓名进行搜索"
          style="display: inline-block; width: 100%; margin-right: 10px"
        />
        <el-button
          style="margin-left: 15px; width: 6rem; height: 25px"
          type="primary"
          @click="searchpeople2()"
        >
          查询
        </el-button>
      </div>
    </div>
    <hr class="hrStyle" />
    <div
      v-if="stafftypeclick == false"
      style="width: 100%; display: flex; margin-top: 10px"
    >
      <div style="width: 34%">
        <el-input
          v-model="depname"
          placeholder="请搜索或选择大队名称"
          style="display: inline-block; width: 99%; margin-right: 10px"
        />
      </div>
      <div style="width: 34%; margin-left: 1%">
        <el-input
          v-model="arrestName"
          placeholder="请搜索或选择驻点名称"
          style="display: inline-block; width: 99%; margin-right: 10px"
        />
      </div>
      <div
        style="
          display: inline-block;
          width: 34%;
          margin-left: 1%;
          border: 1px solid #e4e5e8;
          background-color: #f7f8fa;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <el-checkbox-group
          v-model="starvalue"
          style="margin-left: 1%"
          @change="staffsearch(needKind, starvalue)"
        >
          <el-checkbox
            v-for="star in staroptions"
            :key="star"
            :label="star"
            style="margin-right: 10px"
          >
            {{ star }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div
      v-if="stafftypeclick == false"
      style="margin-top: 1%; width: 100%; display: flex; flex-wrap: wrap"
    >
      <div
        style="
          width: 32%;
          border: 1px solid #e4e5e8;
          border-radius: 5px;
          background-color: #f7f8fa;
          display: flex;
          align-items: center;
        "
      >
        <el-checkbox
          v-model="kongXian"
          style="font-size: 0.8rem; margin-right: 0"
          @change="staffsearch(needKind)"
        >
          空闲
        </el-checkbox>
        <el-checkbox
          v-model="sendExpatriate"
          style="font-size: 0.8rem; margin-left: 8px; margin-right: 0"
          @change="staffsearch(needKind)"
        >
          外派人员
        </el-checkbox>
        <el-checkbox
          v-model="isFireNo"
          class="filterItem"
          style="font-size: 0.8rem; margin-left: 8px; margin-right: 0"
          @change="staffsearch(needKind)"
        >
          消防证
        </el-checkbox>
        <el-checkbox
          v-model="isService"
          class="filterItem"
          style="font-size: 0.8rem; margin-left: 8px; margin-right: 0"
          @change="staffsearch(needKind)"
        >
          服兵役
        </el-checkbox>
        <el-checkbox
          v-if="isShow == true"
          v-model="experience"
          style="font-size: 0.8rem; margin-left: 8px; margin-right: 0"
          @change="staffsearch(needKind)"
        >
          同岗经历
        </el-checkbox>
      </div>

      <!-- <el-select class="filterItem"
                    v-model="sex"
                    placeholder="性别"
                    size="mini"
                    style="width: 30%; margin-left: 2%"
                    @change="staffsearch(needKind)"
                  >
                    <el-option
                      v-for="item in sexARrr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      size="mini"
                    >
                    </el-option>
                  </el-select>  -->
      <div
        style="
          width: 32%;
          margin-left: 1.5%;
          border: 1px solid #e4e5e8;
          background-color: #f7f8fa;
          display: flex;
          align-items: center;
          border-radius: 4px;
        "
      >
        <el-checkbox
          v-model="man"
          style="font-size: 0.8rem; margin-left: 8px; margin-right: 0"
          @change="staffsearch(needKind)"
        >
          男
        </el-checkbox>
        <el-checkbox
          v-model="woman"
          style="font-size: 0.8rem; margin-left: 8px; margin-right: 0"
          @change="staffsearch(needKind)"
        >
          女
        </el-checkbox>
      </div>
      <el-select
        v-model="degree"
        class="filterItem"
        placeholder="学历"
        size="mini"
        style="width: 32%; margin-left: 1.5%"
        @change="staffsearch(needKind)"
      >
        <el-option
          v-for="item in degreeArr"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          size="mini"
        />
      </el-select>

      <el-input
        v-model="height"
        class="filterItem"
        max="200"
        min="120"
        placeholder="请输入身高下限"
        size="mini"
        style="width: 32%; margin-top: 1%"
        type="number"
      />

      <el-input
        v-model="ageMin"
        max="120"
        min="0"
        placeholder="请输入最小年龄"
        size="mini"
        style="width: 15.5%; margin-top: 1%; margin-left: 1.5%"
        type="number"
      />
      <span style="margin: 1.25% 4px 0 4px">至</span>
      <el-input
        v-model="ageMax"
        max="120"
        min="0"
        placeholder="请输入最大年龄"
        size="mini"
        style="width: 15.5%; margin-top: 1%"
        type="number"
      />
    </div>
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../../../utils/gogocodeTransfer'
import cascader from '@/components/aloneComponents/pg/duty/dispatchCenter/cascader'

export default {
  components: {
    cascader
  },
  props: ['isShow', 'stafftype'],
  emits: ['getStaffType', 'selectButton', 'search'],
  data() {
    return {
      SheetAgainAddstarttime: null,
      dirData: [],
      dirData2: null,
      dirProps: {
        label: 'label',
        children: 'children',
        value: 'value'
      },
      starname: '',
      depname: '',
      arrestName: '',
      starvalue: [],
      workerOrderPage: 1,
      workerOrderpagesize: 100,
      workerOrdernums: 0,
      staroptions: ['一星', '二星', '三星', '四星', '五星'],
      openIs: false,
      xfzValue2: '',
      fbyValue2: '',
      tgjlValue2: '',
      degree: '006',
      degreeArr: [
        {
          value: '006',
          label: '初中以上'
        },
        {
          value: '005',
          label: '高中/职高/中专以上'
        },
        {
          value: '002',
          label: '大专以上'
        },
        {
          value: '001',
          label: '本科以上'
        }
      ],
      sexARrr: [
        {
          value: '全部',
          label: '性别'
        },
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      order: 0,

      startDate: '',
      endDate: '',

      isFireNo: false,
      isService: false,
      arrestId: '',
      params: {
        fireNo: null,
        service: null
      },
      height: '',
      ageMin: '',
      ageMax: '',
      sex: '全部',

      needKind: '002',
      kongXian: false, //空闲
      sendschedulingId: false, //同审批人
      sendsecurityStaff: false, //保安部
      sendExpatriate: false, //外派人员
      arrestIdList: [], //驻点id列表
      selectRows: [],
      depIdList: [], //大队id列表
      schedulingIdList: [], //排班id
      service: false,
      sendFireNo: false,
      experience: false, //  同岗经历
      man: false,
      woman: false,
      sexType: '',
      stafftypeclick: false //抢单人员选中情况
    }
  },
  watch: {
    isShow(newval) {
      this.needKind = '002'
      this.stafftypeclick = false
    }
  },
  mounted() {},
  methods: {
    staffsearch(needKind, starvalue) {
      this.needKind = needKind
      this.starvalue = starvalue ? starvalue : this.starvalue
      if (
        needKind == '002' ||
        needKind == '003' ||
        needKind == '004' ||
        needKind == '006' ||
        needKind == '007'
      ) {
        this.stafftypeclick = false
        $emit(this, 'getStaffType', false)
      }
      //保安部
      if (needKind == '004') {
        this.sendsecurityStaff = true
      } else {
        this.sendsecurityStaff = false
      }
      //同审批人
      if (needKind == '003') {
        this.sendschedulingId = true
      } else {
        this.sendschedulingId = false
      }
      //本大队
      if (needKind == '006') {
        this.needNoStaffDepId = true
      } else {
        this.needNoStaffDepId = false
      }
      //本驻点
      if (needKind == '007') {
        this.needNoStaffArrestId = true
      } else {
        this.needNoStaffArrestId = false
      }
      if (this.man == true && this.woman == false) {
        this.sex = '男'
      }
      if (this.man == false && this.woman == true) {
        this.sex = '女'
      }
      if (this.man == true && this.woman == true) {
        this.sex = '全部'
      }
      if (this.man == false && this.woman == false) {
        this.sex = '全部'
      }
      $emit(
        this,
        'selectButton',
        needKind,
        this.degree,
        this.kongXian,
        this.sendsecurityStaff,
        this.sendExpatriate,
        this.isFireNo,
        this.isService,
        this.sex,
        this.sendschedulingId,
        this.experience,
        this.starvalue,
        this.needNoStaffArrestId,
        this.needNoStaffDepId,
        this.starname,
        this.depname,
        this.arrestName,
        this.height,
        this.ageMin,
        this.ageMax
      )
    },
    searchpeople2() {
      $emit(
        this,
        'search',
        this.degree,
        this.needKind,
        this.starname,
        this.starvalue,
        this.order,
        this.depname,
        this.arrestName,
        this.sendschedulingId,
        this.sendsecurityStaff,
        this.needNoStaffArrestId,
        this.needNoStaffDepId,
        this.isFireNo,
        this.isService,
        this.height,
        this.ageMin,
        this.ageMax
      )
    },

    //抢单人员被选中
    getStaffType() {
      this.needKind = null
      this.stafftypeclick = true
      $emit(this, 'getStaffType', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.selected {
  font-size: 0.8rem;
  margin-right: 2rem;
  color: blue;
  border-bottom: 1px solid blue;
}
.noSelected {
  font-size: 0.8rem;
  margin-right: 2rem;
}
.hrStyle {
  border-top: 1px solid #e4e5e8;
}
:deep(.el-checkbox__label) {
  display: inline-block;
  padding-left: 4px;
  line-height: 19px;
  font-size: 10px;
}
</style>
