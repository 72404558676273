<template>
  <div class="dutyCondationBlock">
    <div class="date">
      <div class="date-right">
        <!--        filterable multiple clearable-->
        <!--        <el-select v-model="selectArrestIds" disabled collapse-tags multiple-->
        <!--                   placeholder="请筛选驻点"-->
        <!--                   style="width: 180px;margin-right: 10px;">-->
        <!--          <el-option v-for="(item,index) in arrestOptions"-->
        <!--                     :key="index" :label="item.name" :value="item.id">-->
        <!--          </el-option>-->
        <!--        </el-select>-->
        <el-input
          v-model.trim="condation.staff"
          placeholder="请输入姓名/工号"
          style="width: 140px; margin-right: 10px"
          @keyup.enter.n="search"
        />
        <el-input
          v-model.trim="condation.postName"
          placeholder="请输入岗位"
          style="width: 140px; margin-right: 10px"
          @keyup.enter.n="search"
        />
        <el-input
          v-model.trim="condation.classesName"
          placeholder="请输入班次"
          style="width: 140px; margin-right: 10px"
          @keyup.enter.n="search"
        />

        <el-button style="margin-right: 10px" type="primary" @click="search">
          查询
        </el-button>

        <el-upload
          ref="upload"
          :action="action"
          :auto-upload="false"
          :before-upload="beforeUpload"
          :file-list="fileList"
          :headers="myHeaders"
          :on-change="handleChange"
          :on-error="uploadFail"
          :on-progress="onProgress"
          :on-success="uploadSuccess"
          :show-file-list="false"
          accept=".xlsx,.xls"
          class="upload-demo"
          style="margin-right: 10px"
        >
          <el-button :disabled="frozen">
            <i class="iconfont icondaoru" />导入
          </el-button>
        </el-upload>
        <el-button @click="download()">
          <i class="iconfont icon24px"
            >导出
            <el-button :disabled="frozen" @click="openArrange"
              ><i class="iconfont iconpiliangbianji"
                >批量排班
                <el-button
                  v-if="mode === 'view'"
                  :disabled="frozen"
                  type="primary"
                  @click="edit"
                  >排班</el-button
                >
                <el-button
                  v-if="mode === 'view'"
                  type="danger"
                  :disabled="frozen"
                  @click="resetDuty"
                  >全部删除</el-button
                >
                <el-button
                  v-if="mode === 'edit'"
                  type="success"
                  :loading="saveLoading"
                  @click="save"
                  >保存</el-button
                >
                <el-button v-if="mode === 'edit'" @click="cancel"
                  >取消</el-button
                >

                <arrangeDialog
                  v-if="showArrangeDialog"
                  v-model:visible="showArrangeDialog"
                  :arrest-list="arrestList"
                  :prop-duty-date="dutyDate"
                  @confirm="search"
                />
                <arrangeDeleteDialog
                  v-if="shoDeleteDialog"
                  v-model:visible="shoDeleteDialog"
                  :year="year"
                  :month="month"
                  :date-range="dateRange"
                  :arrest-options-all="arrestOptionsAll"
                  :staff-options="staffOptions"
                  @confirm="search"
                /> </i></el-button
          ></i>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../../utils/gogocodeTransfer'
import arrangeDialog from '@/components/aloneComponents/pg/duty/dialog/arrangeDialog'
import {
  dateEndOf,
  dateStartOf,
  formatDate,
  plusMonth,
  str2Date
} from '@/utils/dateTime'
import monthSelectPop from '@/components/aloneComponents/pg/duty/monthSelectPop'
import {
  deleteAllDuty,
  download,
  getUploadCount
} from '@/requet/api/ba/scheduleApi'
import { downloadFileBlob } from '@/utils/file-utils'
import arrangeDeleteDialog from '@/components/aloneComponents/pg/duty/dialog/arrangeDeleteDialog'

export default {
  name: 'DutyCondation',
  components: {
    arrangeDialog,
    arrangeDeleteDialog,
    monthSelectPop
  },
  props: {
    mode: {
      type: String //view、edit
    },
    dutyDate2: {},
    shiftColorsObj: Object,
    arrestList: Array | Object,
    peopleDutys: Array,
    month: String,
    year: String,
    frozen: Boolean,
    idkey: String
  },
  emits: ['update:mode', 'search', 'edit', 'save', 'cancel'],
  data() {
    return {
      showArrangeDialog: false,
      dutyDate: null,
      condation: {
        postName: null,
        classesName: null,
        staff: null,
        arrestList: []
      },
      condation1: {
        postName: null,
        classesName: null,
        staff: null,
        arrestList: []
      },
      ids: [],
      fileList: [],
      file: [],
      myHeaders: { AuthToken: sessionStorage.getItem('mytoken') },
      // selectArrestIds: [],
      arrestOptions: [],
      arrestOptionsAll: [],
      action: '/Custom/schedule/upload/',
      tip: '是否确认导入XXXX年XX月的排班记录?',
      shoDeleteDialog: false,
      dateRange: [],
      staffOptions: [],
      saveLoading: false
    }
  },
  watch: {
    idkey(val) {
      this.condation.staff = val
      console.log(val)
      this.search()
    },
    arrestList(val) {
      console.log('val', val)
      if (val && val.length > 0) {
        // val.forEach(x => {
        //   this.selectArrestIds.push(
        //       x.id
        //   )
        // })
        this.arrestOptions = JSON.parse(JSON.stringify(val))
      }
    },
    fileList(newval) {
      console.log(this.fileList)
      if (this.fileList != []) {
        console.log(this.file)
        console.log(this.$refs.upload.uploadFiles)
        // this.getUploadCount(this.$refs.upload.uploadFiles);
        this.$confirm(this.tip, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // let res=upload({file:this.fileList,month:this.month,year:this.year});
            // res.then(res=>{
            //   console.log(res);
            // })
            this.$refs.upload.submit()
            this.$refs.upload.clearFiles()
          })
          .catch(() => {
            this.$refs.upload.clearFiles()
          })
      }
    },
    // btnchange(){
    // if(this.btnchange==true){
    //   this.fileListbtn=true;
    //   this.btnchange=false;
    //   }
    // },

    month(newval) {
      this.tip =
        '是否确认导入' + this.year + '年' + this.month + '月的排班记录?'
      this.action = '/Custom/schedule/upload/' + this.year + '/' + this.month
    }
  },
  mounted() {
    // if (this.arrestList) {
    //   this.arrestList.forEach(x => {
    //     this.selectArrestIds.push(
    //         x.id
    //     )
    //   })
    // }
    this.dutyDate = new Date()
    console.log(this.dutyDate.getFullYear(), this.dutyDate.getMonth() + 1)
    this.tip =
      '是否确认导入' +
      this.dutyDate.getFullYear() +
      '年' +
      (this.dutyDate.getMonth() + 1) +
      '月的排班记录?'
    this.action =
      '/Custom/schedule/upload/' +
      this.dutyDate.getFullYear() +
      '/' +
      (this.dutyDate.getMonth() + 1)
    this.condation = {
      dutyDate: this.dutyDate,
      ...this.condation
    }
    // this.getArrest()
    // this.$emit('search', this.condation)
  },
  methods: {
    monthFormat_filter(val) {
      return formatDate(val, 'YYYY年MM月')
    },
    // async getArrest() {
    //   let res = await getArrest()
    //   res.forEach(x => {
    //     x.arrestId = x.arrestId + ''
    //   })
    //   this.arrestOptions = res
    // },
    openArrange() {
      this.showArrangeDialog = true
    },
    resetDuty() {
      // this.$emit('resetDuty')
      console.log(this.peopleDutys, this.year, this.month)
      this.staffOptions = []
      this.arrestOptionsAll = []

      for (const people of this.peopleDutys) {
        if (this.arrestOptionsAll == []) {
          this.arrestOptionsAll.push(people)
        } else {
          if (
            !this.arrestOptionsAll.find(
              res => res.arrestName === people.arrestName
            )
          ) {
            this.arrestOptionsAll.push({
              arrestName: people.arrestName,
              arrestId: people.arrestId
            })
          }
        }

        if (!this.staffOptions.find(x => x.staffId === people.staffId)) {
          this.staffOptions.push(people)
        }
      }
      let date = str2Date(this.year + '-' + this.month + '-' + '01')

      this.dateRange = [
        this.year + '-' + this.month + '-' + '01',
        formatDate(dateEndOf(date))
      ]
      this.shoDeleteDialog = true
    },
    search() {
      console.log('search asd')
      this.condation.dutyDate = this.dutyDate
      // this.condation.arrestList = this.selectArrestIds
      $emit(this, 'search')
    },
    preMonth(months = -1) {
      this.dutyDate = plusMonth(this.dutyDate, months)
      this.search()
    },
    nextMonth(months = 1) {
      this.dutyDate = plusMonth(this.dutyDate, months)
      this.search()
    },
    edit() {
      // this.$emit("search");
      // this.$emit("update:mode", "edit");
      $emit(this, 'edit')
    },
    save() {
      if (this.saveLoading) {
        this.$message.warning('正在保存，请稍后')
        return
      }
      this.saveLoading = true
      $emit(this, 'save')
    },
    saveEnd() {
      this.saveLoading = false
    },
    cancel() {
      $emit(this, 'cancel')
      $emit(this, 'update:mode', 'view')
    },
    async download() {
      this.ids = []
      if (!this.arrestList || this.arrestList.length == 0) {
        this.$message.warning('请选择驻点')
        return
      }
      console.log(this.arrestList)
      if (this.arrestList.length == 1) {
        this.ids.push(this.arrestList[0].id)
      } else {
        this.ids = null
      }

      if (this.condation.staff != null && this.condation.staff != '') {
        this.condation1.postName = null
        this.condation1.classesName = null
        this.condation1.arrestList = null
        this.condation1.staff = this.condation.staff
        this.ids = null
      } else {
        this.condation1.postName = this.condation.postName
        this.condation1.classesName = this.condation.classesName
        this.condation1.arrestList = this.condation.arrestList
        this.condation1.staff = null
      }
      let params = {
        year: formatDate(this.dutyDate2, 'YYYY'),
        month: formatDate(this.dutyDate2, 'MM') + '',
        ...this.condation1,
        arrestList: this.ids
      }
      let down = await download(params)
      console.log('down', down)
      await downloadFileBlob(
        down,
        'xlsx',
        formatDate(this.dutyDate2, 'YYYY-MM') + '排班记录.xlsx'
      )
      console.log(down)
    },
    handleChange(file, fileList) {
      this.file = file.raw
      console.log(file, fileList)
      this.fileList = []
      this.fileList = fileList.slice(-3)
      console.log(this.fileList)
    },
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['xlsx', 'xls']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$notify({
          title: '上传文件只能是 xlsx,xls格式!',
          type: 'error'
        })
        return false
      }
      return true
    },
    onProgress(event, file, fileList) {
      // this.btnchange=false;
      console.log(event)
      console.log(file)
      console.log(fileList)
      console.log(this.fileList)
      this.$router.push({
        name: 'configurationForm',
        params: {
          menuid: 'LM1391'
        },
        query: {
          menuid: 'LM1391',
          title: '排班导入记录管理',
          configtype: '006',
          groupid: 'M11492',
          filtergroup: '',
          filtergroupshowsign: 'Y',
          // id:'success',
          totitle: '排班导入'
        }
      })
    },
    uploadSuccess(response) {
      console.log(response)
      // this.btnchange=true;
      this.$refs.upload.clearFiles()
    },
    uploadFail() {
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles()
      }
    },
    async getUploadCount(file) {
      let res = getUploadCount(this.year, this.month, file)
      res.then(res => {
        console.log(res)
      })
    },
    clearStaff() {
      this.condation.staff = null
    }
  }
}
</script>

<style lang="scss" scoped>
.dutyCondationBlock {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 97%;

  .date {
    width: 100%;
    display: flex;
    align-content: space-between;

    &-left {
      width: 30%;
      display: flex;
    }

    &-right {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    &-icon {
      color: #367ce3;
      font-size: 22px;
      margin-right: 10px;
    }

    &-lab {
      margin-right: 10px;
    }

    &-step {
      margin-right: 5px;

      i {
        color: #367ce3;
        font-size: 16px;
        margin-right: 5px;
        cursor: pointer;
      }

      i:hover {
        color: blue;
        font-weight: bold;
      }
    }
  }

  .filterRight {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    &-search {
      width: 160px;
      margin-right: 10px;
      //:deep(.el-input__inner) {
      //  background-color: #acadaf;
      //  color: #80838d;
      //}
    }
  }

  .filter-opt {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
}

:deep(.el-input__icon) {
  color: #367ce3;
  font-weight: 600;
}

//:deep(.el-select .el-tag:first-child) {
//  display: inline-block;
//  width: 75px;
//  white-space: nowrap;
//  overflow: hidden;
//  text-overflow: ellipsis;
//}
//
//:deep(.el-select .el-tag) {
//  display: inline-block;
//  width: 30px;
//}
</style>
