<template>
  <div class="cascader">
    <el-cascader
      v-model="value"
      :options="options"
      clearable
      @change="handleChange"
    />
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../../../utils/gogocodeTransfer'
export default {
  name: 'Cascader',
  props: {
    options: Array
  },
  emits: ['checkedId'],
  data() {
    return {
      value: ''
    }
  },
  mounted() {},
  methods: {
    handleChange(value) {
      // if(value!=[]){
      //     console.log(value[1]);
      $emit(this, 'checkedId', value ? value[1] : null)
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.cascader {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}
</style>
