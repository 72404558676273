import { postJson, post } from '../../http'
export default {
  //提交审批
  orderAduit(params) {
    return post('/ConfigureReports/tms/orderAduit', params)
  },
  //通用查询
  commonCombox(params) {
    return post('/ConfigureReports/tms/commonCombox', params)
  },
  //承运商报价
  trancompanyQuote(params) {
    return post('/ConfigureReports/tms/trancompanyQuote', params)
  },
  //订单批量更新
  batchEditTmsOrderHd(params) {
    return postJson('/ConfigureReports/tms/batchEditTmsOrderHd', params)
  },
  //承运商列表
  getTranCompanyList() {
    return post('/ConfigureReports/tms/getTranCompanyList')
  },
  //客户列表
  getCustomerList() {
    return post('/ConfigureReports/tms/getCustomerList')
  },
  //收发货列表
  getConinfoList(params) {
    return post('/ConfigureReports/tms/getConinfoList', params)
  },
  //到货及时率
  dhjsl(params) {
    return post('/ConfigureReports/other1/dhjsl', params)
  },
  //提货效率
  thxl(params) {
    return post('/ConfigureReports/other1/thxl', params)
  },
  //提货准时率
  zsth(params) {
    return post('/ConfigureReports/other1/zsth', params)
  },
  //订单详情
  tmsOrderHd(params) {
    return post('/ConfigureReports/other/tmsOrderHd', params)
  },
  //订单货物明细
  tmsOrderGoods(params) {
    return post('/ConfigureReports/other/tmsOrderGoods', params)
  },
  //订单日志
  tmsOrderLog(params) {
    return post('/ConfigureReports/other/tmsOrderLog', params)
  },
  //订单在途信息
  tmsOrderShip(params) {
    return post('/ConfigureReports/other/tmsOrderShip', params)
  },
  //订单异常
  tmsOrderError(params) {
    return post('/ConfigureReports/other/tmsOrderError', params)
  },
  //订单应付
  tmsOrderPayable(params) {
    return post('/ConfigureReports/other/tmsOrderPayable', params)
  },
  //订单应收
  tmsOrderRecevable(params) {
    return post('/ConfigureReports/other/tmsOrderRecevable', params)
  },
  //订单审核
  tmsOrderAudit(params) {
    return post('/ConfigureReports/other/tmsOrderAudit', params)
  },
  //订单箱号
  tmsOrderBox(params) {
    return post('/ConfigureReports/other/tmsOrderBox', params)
  },
  //发货方
  fhf(params) {
    return post('/ConfigureReports/other1/fhf', params)
  },
  //运输公司
  ysgs(params) {
    return post('/ConfigureReports/other1/ysgs', params)
  },
  //对账
  dz(params) {
    return post('/ConfigureReports/other1/dz', params)
  },
}
