<template>
  <el-dialog
    v-bind="$attrs"
    :append-to-body="true"
    :close-on-click-modal="false"
    title="工单信息"
    width="60%"
    @close="_close"
  >
    <div class="dialogBlock">
      <div style="width: 100%">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          class="form-body"
          label-width="80px"
        >
          <el-form-item label="截单时间" prop="stopTime">
            <el-date-picker
              v-model="form.stopTime"
              placeholder="选择日期时间"
              type="datetime"
              value-format="YYYY-MM-DD HH:mm:ss"
            />
          </el-form-item>
          <el-form-item label="工单名称" prop="sheetName">
            <el-input v-model="form.sheetName" class="form-input" />
          </el-form-item>
          <el-form-item label="工单内容">
            <el-input v-model="form.sheetContent" class="form-input" />
          </el-form-item>
          <el-form-item label="需求人数" prop="num">
            <el-input
              v-if="type != '01'"
              v-model.number="form.num"
              class="form-input"
              @input="change"
            />
            <span v-else>{{ form.num }}</span>
          </el-form-item>
          <!--          <el-form-item label="完成后是否推送红包">-->

          <!--          </el-form-item>-->
          <el-form-item label="工单数">
            <!--            <el-input v-model="form.name" class="form-input"></el-input>-->
            <span>{{ type == '01' ? Data.length : 1 }}</span>
          </el-form-item>

          <!--          <el-checkbox label="完成后是否推送红包" name="type" style="padding-left: 30px"></el-checkbox>-->
        </el-form>
      </div>

      <div class="footer">
        <el-button @click="_close"> 取消 </el-button>
        <el-button type="primary" @click="confirm"> 确定 </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../../../utils/gogocodeTransfer'
import { saveAndApproval } from '@/requet/api/pg/perPortrait'
import { formatDate, plusDays, str2Date } from '@/utils/dateTime'

export default {
  name: 'BatchOrderInfoDialog',
  components: {},
  props: [
    'batchOrderInfo',
    'Data',
    'type',
    'currentClass',
    'distanceRow',
    'Addaddress',
    'Addstarttime1',
    'actClick',
    'Addstarttime2',
    'lon1',
    'lat1',
    'billType',
    'schedulingId'
  ],
  emits: ['update:visible', 'close'],
  data() {
    return {
      form: {
        // SheetAgainAddstarttime: '',
        // orderName: '',

        sheetName: null,
        stopTime: null,
        startDate: null,
        endDate: null,
        sheetContent: null,
        num: null,
        receiveIds: null, //可接单人
        arrestId: null, //驻点
        postId: null, //岗位
        classesId: null, //班次
        workPlace: null, //工作地点
        longitude: null,
        latitude: null
      },
      rules: {
        stopTime: [
          { required: true, message: '请选择截单时间', trigger: 'blur' }
        ],
        sheetName: [
          { required: true, message: '工单名称不可为空', trigger: 'blur' }
        ],
        num: [{ required: true, message: '需求人数不可为空', trigger: 'blur' }]
      }
    }
  },
  watch: {
    batchOrderInfo: {
      handler(val) {
        console.log('watch', val)
        // this.form = {
        //   ...this.form,
        //   ...val
        // }
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    // this.form = this.batchOrderInfo
    if (this.type === '01') {
      this.form.num = 1
    }
  },
  methods: {
    _close() {
      $emit(this, 'update:visible', false)
    },
    async confirm() {
      console.log('btype:', this.type)
      if (this.form.stopTime == null) {
        this.$message.warning('请选择截单时间!')
        return
      }

      if (this.form.sheetName == null) {
        this.$message.warning('工单名称不可为空!')
        return
      }
      if (this.form.num == null) {
        this.$message.warning('人数不能为空!')
        return
      }
      if (this.form.num != null && this.form.num < 1) {
        this.$message.warning('人数不能少于1!')
        return
      }

      let arr = []

      if (this.type === '01') {
        let receiveIdList = []
        for (const tempa of this.batchOrderInfo?.selectRows) {
          receiveIdList.push(tempa.staffId)
        }

        for (const item of this.Data) {
          let temp = {
            schedulingId: item.schedulingId,
            sheetName: this.form.sheetName,
            stopTime: this.form.stopTime,
            startDate: item.startDate,
            endDate: item.endDate,
            sheetContent: this.form.sheetContent,
            num: this.form.num,
            receiveIds: receiveIdList.join(','), //可接单人
            arrestId: item.arrestId, //驻点
            postId: item.postId, //岗位
            classesId: item.classesId, //班次
            workPlace: null, //工作地点
            longitude: null,
            latitude: null,
            billType: this.billType //新增工单类型
          }
          arr.push(temp)
        }
      }

      if (this.type === '02') {
        // console.log('022', this.currentClass)
        let receiveIdList = []
        for (const tempa of this.distanceRow) {
          receiveIdList.push(tempa.staffId)
        }

        let endDateTime = null
        let dd = plusDays(
          str2Date(this.Addstarttime2),
          this.currentClass?.classesDay ? this.currentClass?.classesDay : 0
        )
        endDateTime = formatDate(dd) + ' ' + this.currentClass?.endTime

        arr.push({
          schedulingId: this.schedulingId,
          sheetName: this.form.sheetName,
          stopTime: this.form.stopTime,
          startDate: this.Addstarttime2 + ' ' + this.currentClass.startTime,
          endDate: endDateTime,
          sheetContent: this.form.sheetContent,
          num: this.form.num,
          receiveIds: receiveIdList.join(','), //可接单人
          arrestId: this.actClick, //驻点
          postId: this.currentClass.postId, //岗位
          classesId: this.currentClass.classesId, //班次
          workPlace: null, //工作地点
          longitude: this.currentClass.longitude,
          latitude: this.currentClass.latitude,
          billType: this.billType //新增工单类型
        })
      }
      if (this.type === '03') {
        let receiveIdList = []
        for (const tempa of this.distanceRow) {
          receiveIdList.push(tempa.staffId)
        }

        console.log('03', this.Addstarttime1)
        console.log('03', this.Addstarttime1[0])
        let dd = plusDays(
          str2Date(this.Addstarttime1[1].split(' ')[0]),
          this.currentClass?.classesDay ? this.currentClass?.classesDay : 0
        )
        let endDateTime = formatDate(dd) + ' 00:00:00'
        console.log(
          'endDate:',
          this.Addstarttime1,
          this.Addstarttime1[1].split(' ')[0],
          endDateTime
        )
        arr.push({
          sheetName: this.form.sheetName,
          stopTime: this.form.stopTime,
          startDate: this.Addstarttime1[0],
          endDate: endDateTime,
          sheetContent: this.form.sheetContent,
          num: this.form.num,
          receiveIds: receiveIdList.join(','), //可接单人
          arrestId: null, //驻点
          postId: null, //岗位
          classesId: null, //班次
          workPlace: this.Addaddress, //工作地点
          longitude: this.lon1,
          latitude: this.lat1,
          billType: this.billType //新增工单类型
        })
      }

      let res = await saveAndApproval({ sheetList: arr })
      this.$message.success('操作成功')
      this._close()
      $emit(this, 'close')
      console.log(res)
    },
    change(val) {
      this.form.num = val.replace(/[^0-9.]/g, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialogBlock {
  display: flex;
  flex-wrap: wrap;
  .footer {
    width: 100%;
    float: right;
    display: flex;
    justify-content: right;
  }

  .form-body {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .form-input {
    width: 100%;
  }
}
</style>
