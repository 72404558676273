<template>
  <div class="dutyCalendarHeadBlock">
    <!--    <div class="block-left">-->
    <!--      <div class="zhudian">驻点</div>-->
    <!--      <div class="post">岗位</div>-->
    <!--      <div class="people">人员</div>-->
    <!--    </div>-->
    <div class="block-right">
      <div v-for="item in showDates" class="item">
        <div class="item-week" :style="'color:' + weekMap[item.week].color">
          {{ weekMap[item.week].lab }}
        </div>
        <div :class="item.isBeforeDay ? 'item-day item-before' : 'item-day'">
          {{ item.dayNo }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DutyCalendarHead',
  props: {
    dates: {
      type: Array
    }
  },
  data() {
    return {
      weekMap: {
        0: {
          lab: '日',
          color: '#f17575'
        },
        1: {
          lab: '一',
          color: '#c5cad4'
        },
        2: {
          lab: '二',
          color: '#c5cad4'
        },
        3: {
          lab: '三',
          color: '#c5cad4'
        },
        4: {
          lab: '四',
          color: '#c5cad4'
        },
        5: {
          lab: '五',
          color: '#c5cad4'
        },
        6: {
          lab: '六',
          color: '#f17575'
        }
      }
    }
  },
  computed: {
    showDates() {
      let arr = this.dates
      for (const item of arr) {
        item.week = this.$moment(item.date).day()
        item.isBeforeDay = this.$moment(item.date).isBefore(new Date())
      }
      return arr
    }
  }
}
</script>

<style scoped lang="scss">
@import 'css/duty';

.dutyCalendarHeadBlock {
  display: flex;
  background-color: #f6f7fa;
  height: 35px;
  padding: 10px 0 10px 0;
  //box-sizing: border-box;

  .block-left {
    width: 12%;
    display: flex;

    .people {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: 13px;
      font-weight: 500;
      color: #b3b9c6;
      align-items: flex-end;
    }

    .zhudian {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: 13px;
      font-weight: 500;
      color: #b3b9c6;
      align-items: flex-end;
    }

    .post {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: 13px;
      font-weight: 500;
      color: #b3b9c6;
      align-items: flex-end;
    }
  }

  .block-right {
    width: 100%;
    display: flex;

    .item {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &-week {
        width: 100%;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-day {
        width: 100%;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-before {
        color: #b2b8c6;
      }
    }
  }
}
</style>
