<template>
  <div class="perschedulingall">
    <div class="schedulingwhole">
      <div class="schedulingwholeleft">
        <div class="schedulingperson">
          <div class="personimg">
            <!-- <img src="@/assets/images/perScheduling/tx.png"> -->
            <img :src="'/_MINIO' + perImg" />
          </div>
          <div class="personwhole">
            <div class="personwhole1">
              <span>{{ staff.staffName }}</span>
            </div>
            <div class="personwhole2">
              <span>职位：{{ postname }}({{ depName }})</span>
            </div>
          </div>
        </div>
        <div class="schedulingcalendar">
          <el-calendar v-model="value">
            <template #dateCell="{ date, data }">
              <div class="dateCellcenter" @click="allcalendar(data)">
                <span style="width: 40px; text-align: center">
                  {{ data.day.split('-').slice(2).join('-') }}
                </span>
                <div v-for="(item, index) in calendarList" :key="index">
                  <span
                    v-if="item.date == data.day.split('-').slice(1).join('-')"
                  >
                    <div v-if="item.tips.length == 1">
                      <span
                        v-if="item.tips[0] == '假'"
                        style="color: #f4745e; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '班' && item.colorStar !== 'Y'"
                        style="color: #367ce3; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '班' && item.colorStar == 'Y'"
                        style="color: red; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '出'"
                        style="color: #46d0a7; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '加' && item.colorStar !== 'Y'"
                        style="color: #fac275; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '加' && item.colorStar == 'Y'"
                        style="color: red; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '休'"
                        style="color: #b5bbc9; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                    </div>
                    <div v-if="item.tips.length == 2">
                      <span
                        v-if="item.tips[0] == '假'"
                        style="color: #f4745e; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '班' && item.colorStar !== 'Y'"
                        style="color: #367ce3; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '班' && item.colorStar == 'Y'"
                        style="color: red; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '出'"
                        style="color: #46d0a7; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '加' && item.colorStar !== 'Y'"
                        style="color: #fac275; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '加' && item.colorStar == 'Y'"
                        style="color: red; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '休'"
                        style="color: #b5bbc9; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span>/</span>
                      <span
                        v-if="item.tips[1] == '假'"
                        style="color: #f4745e; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                      <span
                        v-if="item.tips[1] == '班' && item.colorStar !== 'Y'"
                        style="color: #367ce3; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                      <span
                        v-if="item.tips[1] == '班' && item.colorStar == 'Y'"
                        style="color: red; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                      <span
                        v-if="item.tips[1] == '出'"
                        style="color: #46d0a7; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                      <span
                        v-if="item.tips[1] == '加' && item.colorStar !== 'Y'"
                        style="color: #fac275; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                      <span
                        v-if="item.tips[1] == '加' && item.colorStar == 'Y'"
                        style="color: red; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                      <span
                        v-if="item.tips[1] == '休'"
                        style="color: #b5bbc9; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                    </div>
                    <div v-if="item.tips.length == 3">
                      <span
                        v-if="item.tips[0] == '假'"
                        style="color: #f4745e; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '班' && item.colorStar !== 'Y'"
                        style="color: #367ce3; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '班' && item.colorStar == 'Y'"
                        style="color: red; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '出'"
                        style="color: #46d0a7; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '加' && item.colorStar !== 'Y'"
                        style="color: #fac275; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '加' && item.colorStar == 'Y'"
                        style="color: red; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span
                        v-if="item.tips[0] == '休'"
                        style="color: #b5bbc9; font-size: 4px"
                        >{{ item.tips[0] }}</span
                      >
                      <span>/</span>
                      <span
                        v-if="item.tips[1] == '假'"
                        style="color: #f4745e; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                      <span
                        v-if="item.tips[1] == '班' && item.colorStar !== 'Y'"
                        style="color: #367ce3; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                      <span
                        v-if="item.tips[1] == '班' && item.colorStar == 'Y'"
                        style="color: red; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                      <span
                        v-if="item.tips[1] == '出'"
                        style="color: #46d0a7; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                      <span
                        v-if="item.tips[1] == '加' && item.colorStar !== 'Y'"
                        style="color: #fac275; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                      <span
                        v-if="item.tips[1] == '加' && item.colorStar == 'Y'"
                        style="color: red; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                      <span
                        v-if="item.tips[1] == '休'"
                        style="color: #b5bbc9; font-size: 4px"
                        >{{ item.tips[1] }}</span
                      >
                      <span>/</span>
                      <span
                        v-if="item.tips[2] == '假'"
                        style="color: #f4745e; font-size: 4px"
                        >{{ item.tips[2] }}</span
                      >
                      <span
                        v-if="item.tips[2] == '班' && item.colorStar !== 'Y'"
                        style="color: #367ce3; font-size: 4px"
                        >{{ item.tips[2] }}</span
                      >
                      <span
                        v-if="item.tips[2] == '班' && item.colorStar == 'Y'"
                        style="color: red; font-size: 4px"
                        >{{ item.tips[2] }}</span
                      >
                      <span
                        v-if="item.tips[2] == '出'"
                        style="color: #46d0a7; font-size: 4px"
                        >{{ item.tips[2] }}</span
                      >
                      <span
                        v-if="item.tips[2] == '加' && item.colorStar !== 'Y'"
                        style="color: #fac275; font-size: 4px"
                        >{{ item.tips[2] }}</span
                      >
                      <span
                        v-if="item.tips[2] == '加' && item.colorStar == 'Y'"
                        style="color: red; font-size: 4px"
                        >{{ item.tips[2] }}</span
                      >
                      <span
                        v-if="item.tips[2] == '休'"
                        style="color: #b5bbc9; font-size: 4px"
                        >{{ item.tips[2] }}</span
                      >
                    </div>
                  </span>
                </div>
              </div>
            </template>
          </el-calendar>
          <div class="schedulingcalendar-button">
            <span @click="skip('pre')"
              ><el-icon><el-icon-caret-left /></el-icon
            ></span>
            <span @click="skip('post')"
              ><el-icon><el-icon-caret-right /></el-icon
            ></span>
          </div>
        </div>
        <div class="schedulingbtn">
          <div class="schedulingbtnwhole">
            <div class="schedulingbtnwholelist listcolor1">
              <div class="listone">
                <img src="@/assets/images/perScheduling/cq.png" />
              </div>
              <div class="listtwo">出勤</div>
              <div class="listthree">
                {{ StaffCalendarCount.alreadyAttendance }}/{{
                  StaffCalendarCount.attendance
                }}
              </div>
            </div>
            <div
              class="schedulingbtnwholelist listcolor2"
              @click="totab('请假')"
            >
              <div class="listone">
                <img src="@/assets/images/perScheduling/qj.png" />
              </div>
              <div class="listtwo">请假</div>
              <div class="listthree">
                {{ StaffCalendarCount.holiday }}
              </div>
            </div>
            <div
              class="schedulingbtnwholelist listcolor3"
              @click="totab('加班')"
            >
              <div class="listone">
                <img src="@/assets/images/perScheduling/jb.png" />
              </div>
              <div class="listtwo">加班</div>
              <div class="listthree">
                {{ StaffCalendarCount.overtime }}
              </div>
            </div>
            <div
              class="schedulingbtnwholelist listcolor4"
              @click="totab('出差')"
            >
              <div class="listone">
                <img src="@/assets/images/perScheduling/cc.png" />
              </div>
              <div class="listtwo">出差</div>
              <div class="listthree">
                {{ StaffCalendarCount.travel }}
              </div>
            </div>
          </div>
          <!-- <div class="schedulingbtnimg">
                                    <div class="schedulingbtnimg-add">
                                        <el-button size="mini" round class="add" @click="add()">添加排班</el-button>
                                    </div>
                                    <div class="schedulingbtnimg-img">
                                        <img src="@/assets/images/perScheduling/tj.png">
                                    </div>
                                </div> -->
        </div>
      </div>
      <div class="schedulingwholeright">
        <div class="schedulingwholerightimg">
          <img src="@/assets/images/perScheduling/grpb.png" />
          <span>个人排班明细</span>

          <el-button style="margin-left: 3rem" @click="returnList">
            本月考勤记录
          </el-button>
        </div>
        <div class="table">
          <div class="tableall">
            <el-table :data="list" style="width: 100%">
              <el-table-column type="expand">
                <template #default="props">
                  <el-form
                    v-for="(item, index) in props.row.list"
                    label-position="left"
                    inline
                    class="demo-table-expand"
                  >
                    <el-form-item v-if="item.kind == 'Y'" label="">
                      <div style="display: flex">
                        <div style="margin-left: 7rem; font-size: 12px">
                          班次开始时间：{{
                            format_filter(item.standardClockTime)
                          }}
                        </div>
                        <div style="margin-left: 7rem; font-size: 12px">
                          实际打卡时间：{{ format_filter(item.clockTime) }}
                        </div>
                        <div style="margin-left: 7rem; font-size: 12px">
                          打卡状态：{{
                            item.clockResult !== null ? item.clockResult : ''
                          }}
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item v-if="item.kind == 'N'" label="">
                      <div style="display: flex">
                        <div style="margin-left: 7rem; font-size: 12px">
                          班次结束时间：{{
                            format_filter(item.standardClockTime)
                          }}
                        </div>
                        <div style="margin-left: 7rem; font-size: 12px">
                          实际打卡时间：{{ format_filter(item.clockTime) }}
                        </div>
                        <div style="margin-left: 7rem; font-size: 12px">
                          打卡状态：{{
                            item.clockResult !== null ? item.clockResult : ''
                          }}
                        </div>
                      </div>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
              <el-table-column label="序号" type="index" :index="indexMethod" />
              <el-table-column label="驻点" prop="arrestName" />
              <el-table-column label="岗位" prop="postName" />
              <el-table-column label="班次" prop="classesName" />
              <el-table-column label="排班日期" prop="attendanceDate" />
              <el-table-column label="操作人" prop="upStaff" />
              <el-table-column label="发布时间" prop="upTime" />
              <el-table-column label="状态" prop="">
                <template #default="props">
                  <!--      <span  class="td1" @click="dialogVisible=true,schedulingId=props.row.list[0].schedulingId">调班</span>-->
                  <!--      <span class="td2" @click="deleteStaff(props.row.list[0].schedulingId)">删除</span>-->
                  <span :class="props.row.status === '正常' ? 'td1' : 'td2'">{{
                    props.row.status
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
            <!-- <table style="width:100%;padding:10px;border-spacing: 0;table-layout:fixed;">
                                    <tr class="tableth">
                                        <th style="width:5%">序号</th>
                                        <th>驻点</th>
                                        <th>岗位</th>
                                        <th>班次</th>
                                        <th>排班日期</th>
                                        <th>操作人</th>
                                        <th>发布时间</th>
                                        <th>操作</th>
                                    </tr>
                                    <tr v-for="(item,index) in list" :key="index" :class="(item.attendanceResult!=null&&item.attendanceResult!='正常')?'Resultcolor':'tabletd'" @click="toggle = !toggle,clickIndex(index)">
                                        <td style="padding-left: 5px;">{{index+1}}</td>
                                        <td>{{item.arrestName}}</td>
                                        <td>{{item.postName}}</td>
                                        <td>{{item.classesName}}</td>
                                        <td>{{item.attendanceDate}}</td>
                                        <td>{{item.upStaff}}</td>
                                        <td>{{item.upTime}}</td>
                                        <td>
                                            <span class="td1" @click="dialogVisible=true,schedulingId=item.schedulingId">调度</span>
                                             <span class="td2" @click="deleteStaff(item.schedulingId)">取消</span>
                                        </td>
                                        <div  v-if="toggle==true && indexCheck==index">1111</div>
                                    </tr>
                                    </table> -->
          </div>
          <div class="listbtn">
            <div v-if="isClose === false" class="block pagination">
              <el-pagination
                v-model:current-page="pageId"
                background
                layout="prev, pager, next, total,jumper"
                :total="total"
              />
            </div>
          </div>
        </div>
        <el-dialog
          v-model:value="dialogVisible"
          title="人员选择"
          width="60%"
          :close-on-click-modal="false"
          :before-close="handleClose"
        >
          <div class="dialogall">
            <div class="left">
              <treemenu2
                :data="dirData"
                :props="dirProps"
                @getactClickdata="getactClickdata"
              />
            </div>
            <div class="right">
              <el-table
                :data="data"
                height="320"
                style="width: 100%"
                highlight-current-row
                @current-change="handleCurrentChange"
              >
                <el-table-column label="姓名" prop="label" />
                <!-- <el-table-column label="出勤时间" prop="attendanceDate"></el-table-column> -->
              </el-table>
            </div>
          </div>
          <div
            style="
              width: 100%;
              margin-top: 10px;
              display: flex;
              justify-content: flex-end;
            "
          >
            <el-button
              @click="
                ;(dialogVisible = false),
                  (otherStaffId = ''),
                  (otherSchedulingId = '')
              "
            >
              取消
            </el-button>
            <el-button type="primary" @click="doDispatch()"> 确认 </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CaretLeft as ElIconCaretLeft,
  CaretRight as ElIconCaretRight
} from '@element-plus/icons-vue'
import { formatDate } from '@/utils/dateTime'
import treemenu2 from '@/views/configurationForm/treeMenu2.vue'
import {
  getStaff,
  getStaffCalendar,
  getStaffAll,
  deleteStaff,
  getStaffCalendarCount,
  getDispatchInfo,
  doDispatch,
  getSchedulingClockInfo
} from '@/requet/api/ba/perScheduleApi.js'
export default {
  name: 'PerScheduling',
  components: {
    treemenu2,
    ElIconCaretLeft,
    ElIconCaretRight
  },
  props: ['params'],
  data() {
    return {
      toggle: false,
      value: new Date(),
      list: [],
      calendarList: [],
      calendardate: [],
      calendartips: [],
      StaffCalendarCount: [],
      staff: {},
      postname: '',
      depName: '',
      pages: 1,
      total: 0,
      pageId: 1,
      pageLimit: 10,
      staffId: '',
      otherStaffId: '',
      schedulingId: '',
      otherSchedulingId: '',
      arrestId: '',
      dialogVisible: false,
      dirData: [],
      dirData2: [],
      data: [],
      dirProps: {
        label: 'label',
        children: 'children',
        value: 'value'
      },
      children: [],
      indexCheck: '',
      clickYear: '',
      clickMonth: '',
      clickDay: '',
      color: [],
      perImg: '',
      isClose: false
    }
  },
  watch: {
    pageId(newval) {
      console.log(this.pageId)
      this.getStaffAll()
    }
  },
  mounted() {
    // this.staffId=this.$route.query.staffId;
    // console.log(this.staffId);
    console.log(this.params)
    this.staffId = this.params
    if (this.$route.query.staffId) {
      this.staffId = this.$route.query.staffId
    }
    if (this.staffId !== '') {
      // this.getDispatchInfo();
      this.getStaff()
      this.getStaffCalendar()
      this.getStaffAll()
      this.getStaffCalendarCount()
    }
  },
  methods: {
    format_filter(value) {
      if (value !== null) {
        let time1 = formatDate(value, 'YYYY-MM-DD HH:mm:ss')
        return time1
      } else {
        let time1 = '——'
        return time1
      }
    },
    indexMethod(index) {
      return index + 1
    },
    allcalendar(data) {
      let time = data.day
      this.clickYear = formatDate(time, 'YYYY')
      this.clickMonth = formatDate(time, 'MM')
      this.clickDay = formatDate(time, 'DD')
      this.getSchedulingClockInfo()
    },
    clickIndex(value) {
      this.indexCheck = value
    },
    async getSchedulingClockInfo() {
      this.isClose = true
      let res = await getSchedulingClockInfo({
        staffId: this.staffId,
        year: this.clickYear,
        month: this.clickMonth,
        day: this.clickDay
      })
      this.list = []
      console.log(res)
      this.list = res
      this.list.forEach(item => {
        if (item.attendanceDate) {
          let end = item.attendanceDate.indexOf(' ')
          item.attendanceDate = item.attendanceDate.slice(0, end)
        }
        if (item.upTime) {
          let end = item.upTime.indexOf(' ')
          item.upTime = item.upTime.slice(0, end)
        }
      })
    },
    skip(val) {
      if (val == 'pre') {
        let date = new Date(this.value)
        date.setMonth(date.getMonth() - 1)
        this.value = new Date(date)
      } else if (val == 'post') {
        let date = new Date(this.value)
        date.setMonth(date.getMonth() + 1)
        this.value = new Date(date)
      }
      this.getStaffCalendar()
      this.getStaffAll()
      this.getStaffCalendarCount()
    },
    //查询员工基本信息（按员工编号）
    async getStaff() {
      let a = await getStaff({ staffId: this.staffId })
      this.staff = a
      this.perImg = a.staffPicture
      this.arrestId = a.arrestId
      this.postname = a.postName
      this.depName = a.depName
      console.log(this.postname, this.depName)
    },
    //查询员工排班日历信息（按员工编号，年，月）
    async getStaffCalendar() {
      this.calendarList = []
      let date = new Date(this.value)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let time = ''
      // console.log(year,month);
      let a = await getStaffCalendar({
        staffId: this.staffId,
        year: year,
        month: month
      })
      console.log(a)
      a.forEach(res => {
        this.color.push(res.color)
      })
      for (var i = 0; i < a.length; i++) {
        //获取时间
        if (month / 10 < 1) {
          time = '0' + month.toString()
        } else {
          time = month.toString()
        }
        if (a[i].date / 10 < 1) {
          time = time + '-0' + a[i].date.toString()
          this.calendardate[i] = time
          //    console.log(time);
        } else {
          time = time + '-' + a[i].date.toString()
          this.calendardate[i] = time
          //    console.log(time);
        }
        //获取类型
        let k = 0
        this.calendartips[i] = []
        if (a[i].list[0].isAbsent == 'Y') {
          this.calendartips[i][k] = '假'
          // console.log(this.calendartips[i][k]);
          k++
        }
        if (a[i].list[0].isAttendance == 'Y') {
          this.calendartips[i][k] = '班'
          // console.log(this.calendartips[i][k]);
          k++
        }
        if (a[i].list[0].isAway == 'Y') {
          this.calendartips[i][k] = '出'
          // console.log(this.calendartips[i][k]);
          k++
        }
        if (a[i].list[0].isOvertime == 'Y') {
          this.calendartips[i][k] = '加'
          // console.log(this.calendartips[i][k]);
          k++
        }
        if (k == 0) {
          this.calendartips[i][k] = '休'
          // console.log(this.calendartips[i][k]);
        }
      }
      // console.log(this.calendardate);
      // console.log(this.calendartips);
      let list = []
      for (var i = 0; i < this.calendardate.length; i++) {
        let obj = {
          date: this.calendardate[i],
          tips: this.calendartips[i],
          colorStar: this.color[i]
        }
        list[i] = obj
      }
      this.calendarList = list
    },
    //查询排班信息（按人员）
    async getStaffAll() {
      let date = new Date(this.value)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let a = await getStaffAll(this.pageId, this.pageLimit, {
        arrestList: [],
        classesName: '',
        month: month,
        postId: '',
        postName: '',
        staffId: this.staffId,
        year: year
      })
      this.pages = a.pages
      this.total = a.total
      this.list = a.list
      this.list.forEach(item => {
        if (item.attendanceDate) {
          let end = item.attendanceDate.indexOf(' ')
          item.attendanceDate = item.attendanceDate.slice(0, end)
        }
        if (item.upTime) {
          let end = item.upTime.indexOf(' ')
          item.upTime = item.upTime.slice(0, end)
        }
      })
      console.log(this.list)
    },
    //显示所有考勤记录数据
    returnList() {
      this.isClose = false
      this.getStaffAll()
    },
    //物理删除排班信息
    async deleteStaff(schedulingId) {
      this.$confirm('是否确定取消该排班?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          let a = deleteStaff([schedulingId]).then(() => {
            console.log(a)
            this.getStaffAll()
            this.getStaffCalendar()
            this.getStaffCalendarCount()
            this.$message({
              type: 'success',
              message: '取消成功!'
            })
          })
        })
        .catch(() => {
          // this.$message({
          //     type: 'info',
          //     message: '已取消删除'
          // });
        })
    },
    //查询员工日历数量统计信息（按员工编号，年，月）
    async getStaffCalendarCount() {
      let date = new Date(this.value)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let a = getStaffCalendarCount({
        month: month,
        year: year,
        staffId: this.staffId
      })
      a.then(res => {
        this.StaffCalendarCount = res
      })
    },
    //添加排班，跳转到排班页面
    add() {
      let date = new Date(this.value)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let time = ''
      if (month / 10 < 1) {
        time = year.toString() + '-' + '0' + month.toString()
      } else {
        time = year.toString() + '-' + month.toString()
      }
      this.$router.push({
        name: 'chooseJump',
        // name: 'dispatchingCenter',
        query: {
          date: time,
          staffId: this.staffId,
          arrestList: JSON.stringify([{ id: this.arrestId }])
        }
      })
    },
    //跳转到请假，出差，加班
    totab(type) {
      let date = new Date(this.value)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let d = new Date(year, month, 0).getDate()
      let endDate2 =
        year +
        1 +
        '-' +
        (month < 10 ? '0' + month : month) +
        '-' +
        (d < 10 ? '0' + d : d) +
        ' 23:59:59'
      let endDate1 =
        year + '-' + (month < 10 ? '0' + month : month) + '-01 00:00:00'
      let startDate2 =
        year +
        '-' +
        (month < 10 ? '0' + month : month) +
        '-' +
        (d < 10 ? '0' + d : d) +
        ' 23:59:59'
      let startDate1 =
        year - 1 + '-' + (month < 10 ? '0' + month : month) + '-01 00:00:00'
      if (type == '请假') {
        sessionStorage.setItem('pgSysSign', '个人排班-请假申请')
        this.$router.push({
          name: 'addProcess',
          params: {
            wfid: '1538809084525019136'
          },
          query: {
            moduleid: 'M11468',
            wfid: '1538809084525019136',
            frombillid: '12',
            wfRange: '1',
            menuid: 'LM1365',
            title: '请假申请',
            configtype: '003',
            groupid: 'M11468',
            filtergroup: '',
            filtergroupshowsign: 'Y',
            staffName: this.staff.staffName,
            startDate1: startDate1,
            startDate2: startDate2,
            endDate1: endDate1,
            endDate2: endDate2,
            depName: this.depName
          }
        })
      }
      if (type == '出差') {
        sessionStorage.setItem('pgSysSign', '个人排班-出差申请')
        this.$router.push({
          name: 'addProcess',
          params: {
            wfid: '1536580718707212288'
          },
          query: {
            moduleid: 'M11470',
            wfid: '1536580718707212288',
            frombillid: '14',
            wfRange: '1',
            menuid: 'LM1367',
            title: '出差申请',
            configtype: '003',
            groupid: 'M11470',
            filtergroup: '',
            filtergroupshowsign: 'Y',
            staffName: this.staff.staffName,
            startDate1: startDate1,
            startDate2: startDate2,
            endDate1: endDate1,
            endDate2: endDate2,
            depName: this.depName
          }
        })
      }
      // if(type=='加班'){
      //     sessionStorage.setItem('pgSysSign','个人排班-加班申请');
      //     this.$router.push({
      //         name:'addProcess',
      //         params:{
      //             wfid:'1536580689032511488',
      //         },
      //         query:{
      //             moduleid:'M11469',
      //             wfid:'1536580689032511488',
      //             frombillid:'13',
      //             wfRange:'1',
      //             menuid:'LM1368',
      //             title:'加班申请',
      //             configtype:'003',
      //             groupid:'M11469',
      //             filtergroup:'',
      //             filtergroupshowsign:'Y',
      //             staffName:this.staff.staffName,
      //             startDate1:startDate1,
      //             startDate2:startDate2,
      //             depName:this.depName,
      //         }
      //     })
      // }
    },
    //调度弹窗
    handleClose(done) {
      done()
    },
    getactClickdata(val) {
      console.log(val)
      console.log(this.dirData2)
      this.dirData2.forEach(item => {
        if (item.children != null) {
          item.children.forEach(res => {
            if (res.value == val.value && res.label == val.label) {
              this.data = res.children
              // console.log(this.data);
            }
          })
        }
      })
    },
    //调度弹窗内部数据
    async getDispatchInfo() {
      let res = await getDispatchInfo({
        staffId: this.staffId
      })
      res.forEach(item => {
        this.dirData2.push(item)
      })
      console.log(this.dirData2)
      res.forEach(item => {
        this.children = []
        item.children.forEach(res => {
          let obj = {
            depId: res.depId,
            label: res.label,
            value: res.value,
            children: null
          }
          this.children.push(obj)
        })
        let obj = {
          value: item.value,
          label: item.label,
          children: this.children
        }
        this.dirData.push(obj)
      })
    },
    handleCurrentChange(val) {
      console.log(val)
      if (val) {
        this.otherStaffId = val.value
        this.otherSchedulingId = val.schedulingId
      }
    },
    //换班
    async doDispatch() {
      let res = await doDispatch({
        otherSchedulingId: this.otherSchedulingId,
        otherStaffId: this.otherStaffId,
        schedulingId: this.schedulingId,
        staffId: this.staffId
      })
      console.log(res)
      this.dialogVisible = false
      this.getStaffAll()
      this.getStaffCalendar()
      this.getStaffCalendarCount()
      this.otherStaffId = ''
      this.otherSchedulingId = ''
      this.$message({
        message: '调度成功！',
        type: 'success'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.perschedulingall {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.schedulingwhole {
  width: 100%;
  height: 98%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.schedulingwholeleft {
  width: 29%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
}
.schedulingperson {
  width: 100%;
  height: 12%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.personimg {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.personimg > img {
  width: 3.8vw;
  height: 3.8vw;
  border-radius: 50%;
  overflow: hidden;
}
.personwhole {
  width: 66%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.personwhole1 {
  width: 100%;
  height: 45%;
  font-size: 1.2vw;
  font-weight: bold;
  color: #333333;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.personwhole2 {
  width: 100%;
  height: 50%;
  font-size: 0.8vw;
  color: #b0b0b0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.schedulingcalendar {
  width: 100%;
  height: 46%;
  overflow: auto;
  border-top: 2px solid #f0f2f5;
  position: relative;
}
.dateCellcenter {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.schedulingcalendar-button {
  font-size: 1.2vw;
  cursor: pointer;
  color: #b0b0b0;
  position: absolute;
  top: 1%;
  right: 5%;
}
:deep(.el-calendar__header) {
  padding: 3px 15px;
  font-size: 1vw;
}
:deep(.el-calendar__body) {
  padding: 0px;
  font-size: 1vw;
}
.el-calendar-table__row {
  padding: 5px;
}
//当日样式
// :deep(.el-calendar-table td.is-today){
//    color: black;
//    background-color: white;
// }
:deep(.el-calendar-table td.is-selected) {
  background-color: skyblue;
}
:deep(.el-calendar-table thead th) {
  padding: 1px;
  color: #b0b0b0;
  background-color: #f6f7fa;
}
:deep(.el-calendar-table .el-calendar-day) {
  height: auto;
  padding: 0px;
  display: flex;
  // margin-top: 1px;
  // margin-bottom: 1px;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
:deep(.el-calendar-table .el-calendar-day:hover) {
  background-color: skyblue;
  cursor: auto;
}
//去掉原按钮
:deep(.el-button-group) {
  display: none;
}
//去掉原外框
:deep(.el-calendar-table tr:first-child td) {
  border: none;
}
:deep(.el-calendar-table tr td:first-child) {
  border: none;
}
:deep(.el-calendar-table td) {
  border: none;
}
//日历只显示五行
// :deep(.el-calendar-table tbody tr:last-child){
//     // display: none !important;
// }
.schedulingbtn {
  width: 100%;
  height: 42%;
  border-top: 2px solid #f0f2f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.schedulingbtnwhole {
  width: 95%;
  height: 88%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.schedulingbtnwholelist {
  width: 95%;
  height: 23%;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}
.listcolor1 {
  background-color: #f5f8fe;
}
.listcolor2 {
  background-color: #fef8f7;
}
.listcolor3 {
  background-color: #fffcf8;
}
.listcolor4 {
  background-color: #f6fdfb;
}
.listone {
  width: 12%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.listone > img {
  width: 1.5vw;
}
.listtwo {
  width: 68%;
  height: 100%;
  font-size: 1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.listthree {
  width: 20%;
  height: 100%;
  font-size: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.schedulingbtnimg {
  width: 90%;
  height: 50%;
  border-radius: 4px;
  background-image: url('~@/assets/images/perScheduling/tjpb.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.schedulingbtnimg-add {
  width: 90%;
  height: 45%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.add {
  padding: 5px 12px;
  font-size: 1vw;
  background-color: #367ce3;
  color: white;
}
.schedulingbtnimg-img {
  width: 80%;
  height: 45%;
}
.schedulingbtnimg-img > img {
  width: 1.2vw;
}
.schedulingwholeright {
  width: 69%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.schedulingwholerightimg {
  width: 100%;
  height: 8%;
  background-image: url('~@/assets/images/perScheduling/titlebg.png');
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.schedulingwholerightimg > img {
  width: 1.4vw;
  margin-left: 1vw;
  margin-right: 1vw;
}
.table {
  width: 100%;
  height: 92%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tableall {
  width: 100%;
  height: 80%;
  text-align: left;
  font-size: 1vw;
  overflow: auto;
}
.tableth {
  color: #b0b0b0;
}
.tabletd > td {
  height: 4vh;
  border-bottom: 2px solid #f0f2f5;
}
.Resultcolor > td {
  height: 4vh;
  border-bottom: 2px solid #f0f2f5;
}
.Resultcolor {
  background-color: rgba(255, 0, 0, 0.21);
}
.td1 {
  color: #367ce3;
  cursor: pointer;
}
.td2 {
  color: #f47451;
  cursor: pointer;
  //margin-left: 10px;
}
.listbtn {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.pagination {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogall {
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: row;

  .left {
    width: 40%;
    height: 100%;
    border: 1px solid #ebeef5;
    overflow-y: auto;
  }

  .right {
    width: 60%;
    height: 100%;
    border: 1px solid #ebeef5;
    border-left: none;
    border-bottom: none;
  }
}
td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
:deep(.el-dialog__body) {
  padding: 7px 10px;
  padding-bottom: 20px;
}
:deep(.el-table td),
:deep(.el-table th.is-leaf),
:deep(.el-table--border),
:deep(.el-table--group) {
  border-color: #ebeef5 !important;
}
:deep(.el-table--border::after),
:deep(.el-table--group::after),
:deep(.el-table::before) {
  background-color: #ebeef5;
}
</style>
