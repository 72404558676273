<template>
  <el-dialog
    v-bind="$attrs"
    :append-to-body="true"
    title="批量排班"
    width="700px"
    @close="_close"
  >
    <div class="dialogBlock">
      <div class="left">
        <div class="left-block">
          <div class="left-block-condation">
            <div class="left-block-condation-month">
              {{ monthFormat_filter(dutyDate) }}
            </div>
            <div class="left-block-condation-icon">
              <el-icon><el-icon-arrow-left /></el-icon>
              <el-icon><el-icon-arrow-right /></el-icon>
            </div>
            <div class="left-block-condation-all">
              <el-checkbox v-model="checked" @change="wholeMonthClick">
                全选
              </el-checkbox>
            </div>
          </div>
          <!--          :range="range"-->
          <el-calendar
            v-model="calendarValue"
            :show-next-prev-month="true"
            :first-day-of-week="7"
          >
            <template #dateCell="{ date, data }">
              <div
                :class="
                  gradeDates.indexOf(data.day) === -1
                    ? 'date-block'
                    : 'date-block selected'
                "
                @click="chooseDate(date, data)"
              >
                <div>{{ data.day.split('-').slice(2).join('-') }}</div>
                <!--                <div v-if="gradeDates.indexOf(data.day) === -1" style="display: flex;justify-content: center">-->
                <!--                  <div class="date-point"></div>-->
                <!--                </div>-->
              </div>
            </template>
          </el-calendar>
        </div>
      </div>
      <div class="right">
        <div class="right-block">
          <arrangeOptForm
            ref="arrangeOptForm"
            :arrest-list="arrestList"
            :grade-dates="gradeDates"
            @removeDate="removeDate"
          />
        </div>
      </div>

      <div class="footer">
        <el-button type="danger" @click="_close"> 取消 </el-button>
        <el-button type="primary" @click="confirm"> 确定 </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  ArrowLeft as ElIconArrowLeft,
  ArrowRight as ElIconArrowRight
} from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../../../../utils/gogocodeTransfer'
import arrangeOptForm from '@/components/aloneComponents/pg/duty/arrangeOptForm'
import { daysInMonth, formatDate, plusMonth } from '@/utils/dateTime'
import {
  scheduleUpsert,
  scheduleUpsert1,
  scheduleUpsert2
} from '@/requet/api/ba/scheduleApi'

export default {
  name: 'ArrangeDialog',
  components: {
    arrangeOptForm,
    ElIconArrowLeft,
    ElIconArrowRight
  },
  props: {
    arrestList: Array | Object,
    propDutyDate: Date | Object
  },
  emits: ['update:visible', 'confirm'],
  data() {
    return {
      checked: false,
      calendarValue: null,
      gradeDates: [],
      dutyDate: null,
      range: [],
      cmLoading: false
    }
  },
  watch: {
    calendarValue: function (val) {
      if (formatDate(val, 'YYYYMM') != formatDate(this.dutyDate, 'YYYYMM')) {
        this.dutyDate = this.calendarValue
        // this.bindClick()
      }
    }
  },
  created() {},
  mounted() {
    this.calendarValue = this.$moment(this.propDutyDate).toDate()
    this.dutyDate = this.$moment(this.propDutyDate).toDate()
    // this.bindClick()
  },
  methods: {
    monthFormat_filter(val) {
      return formatDate(val, 'YYYY年MM月')
    },
    bindClick() {
      // console.log('bindClick')
      this.$nextTick(() => {
        let dayBtns = document.querySelectorAll('.current .el-calendar-day')
        let prevBtns = document.querySelectorAll('.prev .el-calendar-day')
        let nextBtns = document.querySelectorAll('.next .el-calendar-day')

        //绑定
        for (let btn of dayBtns) {
          addEventListener(btn, 0)
        }
        for (let btn of prevBtns) {
          addEventListener(btn, -1)
        }
        for (let btn of nextBtns) {
          addEventListener(btn, 1)
        }
        let _this = this

        function addEventListener(btn, month) {
          console.log('btn:', btn, month)
          btn.addEventListener(
            'click',
            () => {
              let dom = btn.querySelector(
                '.el-calendar-day .date-block div:nth-child(1)'
              )
              // console.log('dom', _this.calendarValue)
              let date =
                formatDate(plusMonth(_this.calendarValue, month), 'YYYY-MM') +
                '-' +
                dom.innerHTML
              _this.judgeDate(date)
            },
            false
          )
        }

        // function addEventListener (btn, month) {
        //   console.log('btn:',btn, month)
        //   btn.addEventListener("click",() => this.bindFunc, false)
        // }
      })
    },
    unBindClick() {
      // console.log('bindClick')
      this.$nextTick(() => {
        let addBtns = document.querySelectorAll('.el-calendar-day')
        let dayBtns = document.querySelectorAll('.current .el-calendar-day')
        let prevBtns = document.querySelectorAll('.prev .el-calendar-day')
        let nextBtns = document.querySelectorAll('.next .el-calendar-day')
        // //解绑
        for (let btn of addBtns) {
          removeEvent(btn, 0)
        }
        // for (let btn of dayBtns) {
        //   removeEvent(btn, 0)
        // }
        // for (let btn of prevBtns) {
        //   removeEvent(btn, -1)
        // }
        // for (let btn of nextBtns) {
        //   removeEvent(btn, 1)
        // }
        let _this = this

        function removeEvent(btn, month) {
          console.log('btn2:', btn, month)
          btn.removeEventListener('click', () => {
            let dom = btn.querySelector(
              '.el-calendar-day .date-block div:nth-child(1)'
            )
            let date =
              formatDate(plusMonth(_this.calendarValue, month), 'YYYY-MM') +
              '-' +
              dom.innerHTML
            _this.judgeDate(date)
          })
        }
      })
    },
    bindFunc(btn, month) {
      let dom = btn.querySelector(
        '.el-calendar-day .date-block div:nth-child(1)'
      )
      // console.log('dom', _this.calendarValue)
      let date =
        formatDate(plusMonth(this.calendarValue, month), 'YYYY-MM') +
        '-' +
        dom.innerHTML
      this.judgeDate(date)
    },
    chooseDate(date, data) {
      console.log('chooseDate', formatDate(date), data)
      this.judgeDate(formatDate(date))
    },
    judgeDate(val) {
      // console.log('jj', value)
      if (val) {
        // let dateStr = formatDate(this.calendarValue, 'YYYY-MM-DD')
        let index = this.gradeDates.indexOf(val)
        // console.log(index, this.gradeDates, val)
        if (index === -1) {
          this.gradeDates.push(val)
        } else {
          this.gradeDates.splice(index, 1)
        }
        // console.log(this.gradeDates)
      }
    },
    removeDate(index) {
      this.gradeDates.splice(index, 1)
    },
    preMonth() {
      this.dutyDate = plusMonth(this.dutyDate, -1)
      this.checked = false
      this.buildCalRange(-1)
      // this.unBindClick()
      // this.bindClick()
    },
    nextMonth() {
      this.dutyDate = plusMonth(this.dutyDate, 1)
      this.checked = false
      this.buildCalRange(1)
      // this.unBindClick()
      // this.bindClick()
    },
    buildCalRange(m = 0) {
      this.calendarValue = plusMonth(this.calendarValue, m)
    },
    wholeMonthClick() {
      console.log(this.checked)
      const date = this.dutyDate
      let currMonthDays = []
      let firstDay = this.$moment(date).startOf('month')
      for (let i = 0; i < daysInMonth(date); i++) {
        currMonthDays.push(formatDate(this.$moment(firstDay).add(i, 'days')))
      }

      if (this.checked) {
        for (const day of currMonthDays) {
          if (this.gradeDates.indexOf(day) === -1) {
            this.gradeDates.push(day)
          }
        }
      } else {
        for (const day of currMonthDays) {
          let index = this.gradeDates.indexOf(day)
          if (index > -1) {
            this.gradeDates.splice(index, 1)
          }
        }
      }
    },
    async confirm() {
      // console.log('confirm')
      // console.log(this.$refs.arrangeOptForm)
      if (this.cmLoading) {
        this.$message.warning('处理中，请稍等')
        return
      }
      let optForm = this.$refs.arrangeOptForm
      if (this.gradeDates.length === 0) {
        this.$message.warning('请添加日期')
        return
      }
      if (optForm.users.length === 0) {
        this.$message.warning('请添加人员')
        return
      }
      if (optForm.classesId === null) {
        this.$message.warning('请选择班次')
        return
      }
      let startDate = this.gradeDates.reduce(this.getMinDate)
      let endDate = this.gradeDates.reduce(this.getMaxDate)
      console.log(startDate, endDate)

      this.cmLoading = true
      let params = []
      let classes = optForm.classesList.find(
        x => x.classesId === optForm.classesId
      )
      for (const date of this.gradeDates) {
        for (const user of optForm.users) {
          params.push({
            schedulingId: null,
            attendanteDate: date,
            classesId: optForm.classesId,
            staffId: user.staffId,
            startTime: classes.startTime,
            endTime: classes.endTime,
            postId: optForm.postId,
            staffName: user.staffName,
            classesName: optForm.classesList.find(
              x => x.classesId === optForm.classesId
            ).classesName,
            classesDay:
              classes.classesDay != null && classes.classesDay != undefined
                ? classes.classesDay
                : null
          })
        }
      }
      scheduleUpsert(params, startDate, endDate)
        .then(res => {
          if (res?.kind == 'Y') {
            this.$confirm('是否填补岗位空缺？', '排班新增提示', {
              confirmButtonText: '是',
              cancelButtonText: '否',
              showClose: false,
              type: 'warning'
            })
              .then(async () => {
                await scheduleUpsert1(params, startDate, endDate)
                // this.$notify.success({message: '保存成功'})
                this.$message.success('保存成功')
                this._close()
                this.cmLoading = false
                $emit(this, 'confirm')
              })
              .catch(async () => {
                await scheduleUpsert2(params, startDate, endDate)
                // this.$notify.success({message: '保存成功'})
                this.$message.success('保存成功2')
                this._close()
                this.cmLoading = false
                $emit(this, 'confirm')
              })
          } else {
            // this.$notify.success({message: '保存成功'})
            this.$message.success('保存成功')
            this._close()
            this.cmLoading = false
            $emit(this, 'confirm')
          }
        })
        .catch(err => {
          this.cmLoading = false
        })
      // this.cmLoading = false
    },
    getMinDate(x, y) {
      return this.$moment(x).isBefore(y) ? x : y
    },
    getMaxDate(x, y) {
      return this.$moment(x).isAfter(y) ? x : y
    },
    _close() {
      $emit(this, 'update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../css/duty';

.input_width_ .el-input {
  width: 220px;
}

.dialogBlock {
  display: flex;
  flex-wrap: wrap;

  .left {
    width: 40%;
    height: 100%;

    .left-block {
      border: 1px solid #ddd;
      border-radius: 4px;
      padding: 4px 8px 5px 8px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &-condation {
        display: flex;
        align-items: center;
        height: 30px;
        width: 90%;

        &-month {
          width: 35%;
          cursor: pointer;
        }

        &-icon {
          width: 10%;
          display: flex;

          i {
            color: #367ce3;
            font-size: 13px;
            cursor: pointer;
          }
        }

        &-all {
          width: 55%;
          display: flex;
          justify-content: flex-end;

          :deep(.el-checkbox__label) {
            font-size: 13px;
          }
        }
      }

      .date-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        //padding: 4px;
      }

      .selected {
        background-color: #4391e9;
        border-radius: 4px;
        color: white;
        //padding: 8px;
        //margin: 1px;
      }

      .date-point {
        width: 4px;
        height: 4px;
        border-radius: 80px;
        background-color: #367ce3;
      }

      //.date-block-selected {
      //  display: flex;
      //  justify-content: center;
      //  background-color: #4391e9;
      //  color: white;
      //}
    }
  }

  .right {
    width: 60%;
    display: flex;
    justify-content: center;

    &-block {
      width: 92%;
      margin-left: 8%;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  :deep(.van-divider) {
    color: #bfbfbf;
    border-color: #bfbfbf;
    margin-top: 2px;
    margin-bottom: 5px;
  }

  :deep(.el-calendar) {
    height: 254px;
    width: 90%;
    font-size: 13px;
  }

  :deep(.el-calendar__header) {
    display: none;
  }

  :deep(.el-calendar__body) {
    padding: 0;
  }

  :deep(.el-calendar-day) {
    height: 32px;
    //background-color:#f3f1f1;
    border-radius: 6px;
    font-size: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 2px;
  }

  :deep(.el-calendar-table td) {
    border: 0;
  }

  :deep(.el-calendar-table tr td:first-child) {
    border: 0;
  }

  :deep(.el-calendar-table tr:first-child td) {
    border: 0;
  }

  :deep(.el-calendar-table .el-calendar-day:hover) {
    background-color: #4391e9;
    color: white;
  }

  :deep(.el-calendar-table .el-calendar-day) {
    padding: 0px;
  }

  :deep(.el-calendar-table thead th) {
    padding: 6px 0 4px 0;
  }
}

:deep(.el-dialog) {
  border-radius: 6px;
}

:deep(.el-dialog__body) {
  padding: 20px 10px;
}

:deep(.el-dialog__header) {
  background-color: #f5f7fa;
  border-bottom: solid 1px #eaeced;
  padding: 10px 20px 10px 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  //display: flex;
  //align-items: center;
}

:deep(.el-dialog__title) {
  font-size: 15px;
}

//:deep(.el-dialog__headerbtn i){
//  margin-bottom: 4px;
//}
</style>
