// 守押全局组件
import floorIframe from '@/views/threeD/floorIframe'
import floor from '@/views/threeD/floor'
// 戎美全局组件
import sampleDetail from '@/views/sampleDetail'
// 检测中心全局组件
import dialogSearchJL from '@/views/aloneViews/jczx/dialogSearchJL'
// 养老
import getLocation from '@/components/aloneComponents/yl/mapDialog'


import arrangeGrade from '@/views/aloneViews/pg/scheduling/arrangeGrade'
import perScheduling from '@/views/aloneViews/pg/scheduling/perScheduling'
import chooseJump from '@/views/aloneViews/pg/chooseJump/chooseJump'
import dispatchingCenter from '@/views/aloneViews/pg/dispatchingCenter/index'
import perPortrait from '@/views/aloneViews/pg/perPortrait/index'

import uploadFace from '@/views/customMade/uploadFace'
import dialogMap from '@/components/aloneComponents/pg/duty/dialogMap'
import batchUpdateStaffDialog from '@/components/aloneComponents/pg/staff/batchUpdateStaffDialog'
import batchChangeShiftTypeDialog from '@/components/aloneComponents/pg/staff/batchChangeShiftTypeDialog'
import batchUpdateEmployeeStatus from '@/components/aloneComponents/pg/staff/batchUpdateEmployeeStatus'
import batchUpdateEmployeeApprovalPermissions from '@/components/aloneComponents/pg/staff/batchUpdateEmployeeApprovalPermissions'
import batchUpdateClockDistance from "@/components/aloneComponents/pg/staff/batchUpdateClockDistance";
import splitBill from "@/components/aloneComponents/pg/staff/splitBill";
import caseNo from "@/views/threeD/caseNo"

export default {
  //btnLink.vue
  uploadFace,
  dialogMap,
  batchUpdateStaffDialog,
  batchChangeShiftTypeDialog,
  batchUpdateEmployeeStatus,
  batchUpdateEmployeeApprovalPermissions,
  batchUpdateClockDistance,
  splitBill,

  // 守押全局组件
  floorIframe,
  floor,
  caseNo,

  // 戎美全局组件
  sampleDetail,

  // 检测中心全局组件
  dialogSearchJL,

  // 养老全局组件
  getLocation,

  //===保安===
  arrangeGrade,
  perScheduling,
  chooseJump,
  dispatchingCenter,
  perPortrait

}
