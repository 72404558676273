<template>
  <div
    class="gradeTypeOptCardBlock"
    :style="
      'background-color:' +
      item.shiftCatColor +
      ';border: solid 1px' +
      (!item.checked
        ? item.shiftCatColor
          ? item.shiftCatColor
          : '#fff'
        : '#367ce3') +
      (!item.checked ? ';' : ';color: #367ce3;')
    "
    @click="change"
  >
    <el-icon class="checked-icon">
      <el-icon-success />
    </el-icon>
    <div class="name">
      {{ item.classesName }}
      <!--      {{' (' + item.startTime + '-' + item.endTime + ')'}}-->
    </div>
  </div>
</template>

<script>
import { SuccessFilled as ElIconSuccess } from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../../../utils/gogocodeTransfer'
export default {
  name: 'GradeTypeOptCard',
  components: {
    ElIconSuccess
  },
  props: {
    item: {
      type: Object
    },
    showCkeckIcon: {
      type: Boolean,
      default: true
    },
    index: Number | String,
    postation: Array
  },
  emits: ['mutilSelect'],
  data() {
    return {}
  },
  methods: {
    change() {
      console.log('change', this.item.checked, this.index)

      if (
        (this.index != null && this.index != undefined) ||
        (this.postation && this.postation.length > 0)
      ) {
        $emit(this, 'mutilSelect', {
          index: this.index,
          postation: this.postation,
          checked: !this.item.checked
        })
      } else {
        this.item.checked = !this.item.checked
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'css/duty';
.gradeTypeOptCardBlock {
  display: flex;

  width: 100%;
  //height: 25px;
  background-color: $noonNightBg;
  border-radius: 5px;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  border: $noonNightBg solid 1px;
  padding: 3px;

  .name {
    text-align: center;
    text-overflow: ellipsis;
  }
}
.checked {
  color: #367ce3;
  border: #367ce3 solid 1px;
}
.checked-icon {
  color: #367ce3;
  position: absolute;
  right: -1px;
  top: -1px;
  font-size: 10px;
}
</style>
