import * as Vue from 'vue'
import App from './App.vue'
import '@babel/polyfill'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { routerData, routerDataRM, routerDataJCZX, routerDataSY, routerDataJJ,routerDataPXXT, routerDataSWJ } from './router/routerData.js'
// import router from './router'
// import detaultRouter from './router'
// import rmRouter from './router/index-rm.js'
// import syRouter from './router/index-sy.js'
// import jczxRouter from './router/index-jczx.js'


import store from './store'
import ElementUI, { ElNotification as Notification } from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import './assets/style/common.css'
import './assets/style/bicommon.css'
import './assets/style/configure.scss'
import './assets/style/iconfont.css'
import './assets/style/iconfont.js'
import 'swiper/css'
import '../node_modules/echarts/map/js/china.js'
import moment from 'moment'
import BaiduMap from 'vue-baidu-map-3x'
// 引入echarts
import echarts from 'echarts'
// 载入全局组件
import components from './global-components'
import 'echarts-gl'
// // 可选择导入默认样式
import axios, {
  doFetch,
  doPost,
  doPostJson,
  doPut,
  fetch,
  patch,
  post,
  postJson,
  put,
  puts
} from './http'
import api from './requet/apiUrl'
import contentmenu from 'v-contextmenu'
import 'v-contextmenu/dist/themes/default.css'
//打印
import Print from 'vue3-print-nb'
import { Button, Field, Icon, Pagination, Popup } from 'vant'
import VUI from 'vite-test'
import '../node_modules/vite-test/dist/style.css'

import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'

import { Boot } from '@wangeditor/editor'
import attachmentModule from '@wangeditor/plugin-upload-attachment'
import {debounce} from "lodash";
import bus from "./Bus/bus.js";
// window.$vueApp.use(ColFitPlugin)
import Plugin from 'v-fit-columns'
import * as VueRouter from "vue-router";
// import '../public/js/autoUpdate.js'
import i18n from "./i18n/index.js";

import md5 from 'js-md5'

import vue3TreeOrg from 'vue3-tree-org'
import 'vue3-tree-org/lib/vue3-tree-org.css'

window.$vueApp = Vue.createApp(App)
window.$vueApp.config.globalProperties.$md5 = md5;
window.$vueApp.use(VUI)
window.$vueApp.use(vue3TreeOrg)
// 注册。要在创建编辑器之前注册，且只能注册一次，不可重复注册。 //wangeditor富文本注册
Boot.registerModule(attachmentModule)

// window.$vueApp.use(ElementUI, {locale: zhCn, size: 'small'})
// window.$vueApp.use(ElementUI, { locale: zhCn })
// window.$vueApp.config.globalProperties.$language = localStorage.getItem("language")
// window.$vueApp.use(ElementUI, { locale: window.$vueApp.config.globalProperties.$language == 'CN' ? zhCn : en })
window.$vueApp.use(ElementUI)
window.$vueApp.use(Plugin)





const titleMap = {
  default: 'rmscm',
  rm: 'rmscm',
  jczx: '常熟市检验检测中心',
  sy: '常熟涉案财物管理中心',
  jj: '交通设施智能化管理系统',
  yl: '智慧养老管理服务平台',
  cxzx:'促销中心'
}




window.$vueApp.config.globalProperties.$moment = moment
window.$vueApp.use(BaiduMap, {
  ak: '1XjLLEhZhQNUzd93EjU5nOGQ'
})
window.$vueApp.config.globalProperties.$notify = Notification

window.$vueApp.config.globalProperties.$echarts = echarts

for (let k in components) {
  window.$vueApp.component(k, components[k])
}

;(
  window.$vueApp.config.globalProperties.$filters ||
  (window.$vueApp.config.globalProperties.$filters = {})
).dateformat = function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
}
window.$vueApp.use(ElementUI, { size: 'mini', zIndex: 3000 })

window.$vueApp.config.globalProperties.fwqImgPath = ''
//定义全局变量
window.$vueApp.config.globalProperties.$post = post
window.$vueApp.config.globalProperties.$fetch = fetch
window.$vueApp.config.globalProperties.$patch = patch
window.$vueApp.config.globalProperties.$put = put
window.$vueApp.config.globalProperties.$postJson = postJson
window.$vueApp.config.globalProperties.$puts = puts

window.$vueApp.config.globalProperties.$doPost = doPost
window.$vueApp.config.globalProperties.$doPostJson = doPostJson
window.$vueApp.config.globalProperties.$doFetch = doFetch
window.$vueApp.config.globalProperties.$doPut = doPut

window.$vueApp.config.globalProperties.$axios = axios
window.$vueApp.config.globalProperties.$api = api
window.$vueApp.config.globalProperties.$store = store
window.$vueApp.config.globalProperties.$bus = bus

//设置主题色
// localStorage.setItem('themeName',JSON.stringify({name: 'white-green'}))
window.$vueApp.config.globalProperties.themeName = JSON.parse(localStorage.getItem("themeName"));


fetch('json/xxlUrl.json?random=' + new Date().getTime()).then(res => {
  // console.log('xxlUrlmain',res)
  window.$vueApp.config.globalProperties.sysSign = res.sysSign
  window.$vueApp.config.globalProperties.QRCodeUrl = res.QRCodeUrl
  window.$vueApp.config.globalProperties.showTranslateButton = res.showTranslateButton
  window.$vueApp.config.globalProperties.$sysTitle = titleMap[res.sysSign]
  window.$vueApp.config.globalProperties.isShowHomeSettingBtn = res.isShowHomeSettingBtn
  window.$vueApp.config.globalProperties.guideFile = res.guideFile
  window.$vueApp.config.globalProperties.ImgbaseURL = res.ImgbaseURL
  window.$vueApp.config.globalProperties.kkFilePreviewUrl = res.kkFilePreviewUrl
  window.$vueApp.config.globalProperties.kkFileDownLoadUrl = res.kkFileDownLoadUrl
  window.$vueApp.config.globalProperties.ImgPath =
    res.ImgbaseURL + res.bucketName //process.env.BASE_URL
  // 水印类型 userName:用户名；staffId：用户id；other：用户名+id换行显示
  window.$vueApp.config.globalProperties.watermarkType = res.watermarkType
  //戎美售后权限开关
  window.$vueApp.config.globalProperties.rmshBtn = res.rmshBtn
  window.$vueApp.config.globalProperties.caseguide = res.caseguide
  window.$vueApp.config.globalProperties.enterPage = res.enterPage
  window.$vueApp.config.globalProperties.wsAddress = res.wsAddress
  window.$vueApp.config.globalProperties.signUrl = res.signUrl
  window.$vueApp.config.globalProperties.iconRemove = res.iconRemove
  window.$vueApp.config.globalProperties.tableHeaderColor = res.tableHeaderColor
  window.$vueApp.config.globalProperties.aiCustomerSn = res.aiCustomerSn
  window.$vueApp.config.globalProperties.UploadType = res.UploadType

  if (res.technicalSupport != '' && res.technicalSupport != undefined) {
    window.$vueApp.config.globalProperties.technicalSupport =
      res.technicalSupport
  } else {
    window.$vueApp.config.globalProperties.technicalSupport =
      '魔元术(苏州)信息科技有限公司'
  }
  //获取机器码
  if (res.isMachine != '' && res.isMachine != undefined) {
    window.$vueApp.config.globalProperties.isMachine = res.isMachine
  } else {
    window.$vueApp.config.globalProperties.isMachine = 'N'
  }
  //注册、忘记密码等按钮
  if (res.isRegister != '' && res.isRegister != undefined) {
    window.$vueApp.config.globalProperties.isRegister = res.isRegister
  } else {
    window.$vueApp.config.globalProperties.isRegister = 'N'
  }
  //全局加密开关
  if (res.isEncryption != '' && res.isEncryption != undefined) {
    window.$vueApp.config.globalProperties.isEncryption = res.isEncryption
  } else {
    window.$vueApp.config.globalProperties.isEncryption = 'N'
  }
  //主题颜色  blue-蓝色 green-绿色 black-黑色 white-白色
  if (res.topicColor != '' && res.topicColor != undefined) {
    window.$vueApp.config.globalProperties.topicColor = res.topicColor
  } else {
    window.$vueApp.config.globalProperties.topicColor = 'black'
  }
  //首页入口
  if (res.homePath != '' && res.homePath != undefined) {
    window.$vueApp.config.globalProperties.homePath = res.homePath
  } else {
    window.$vueApp.config.globalProperties.homePath = ''
  }

  window.$vueApp.config.globalProperties.isFollowTheme = res.isFollowTheme
  window.$vueApp.config.globalProperties.isShowMenu = res.isShowMenu
  // console.log('Vue.prototype.res.wsAddress',res.wsAddress)
  // console.log('Vue.prototype.wsAddress',Vue.prototype.wsAddress)

  autoUpdateProject()

  initVue()
})

if (localStorage.getItem('mytoken')) {
  sessionStorage.setItem('mytoken', localStorage.getItem('mytoken'))
  localStorage.removeItem('mytoken')
}

function initVue() {
  window.$vueApp.use(contentmenu)

  window.$vueApp.use(Print) //注册
  var w = document.documentElement.clientWidth / 80
  window.$vueApp.config.globalProperties.w = w
  window.$vueApp.provide('globalProperties', window.$vueApp.config.globalProperties)

  window.$vueApp.use(Pagination)
  window.$vueApp.use(Icon)
  window.$vueApp.use(Field)
  window.$vueApp.use(Button)
  window.$vueApp.use(Popup)

// 定义全局时间戳过滤器 datefmt('YYYY-MM-DD HH:mm:ss')
  ;(
      window.$vueApp.config.globalProperties.$filters ||
      (window.$vueApp.config.globalProperties.$filters = {})
  ).datefmt = function (input, fmtstring) {
    //当input为时间戳，需转为Number类型
    if (input) {
      return moment(input).format(fmtstring)
    } else {
      return ''
    }
  }

// 阻⽌type="number"⿏标滚动改变数值
  window.$vueApp.directive('stopNumberMousewheel', {
    mounted: function (el) {
      const ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
      ele.addEventListener('mousewheel', () => {
        ele.blur()
      })
    }
  })
  window.$vueApp.config.errorHandler = (err, vm, info) => {
    console.error(err)
  }
  const routeMap = {
    'default': routerData,
    'rm': routerDataRM,
    'sy': routerDataSY,
    'jczx': routerDataJCZX,
    'jj': routerDataJJ,
    'yl': routerData,
    'pxxt': routerDataPXXT,
    'swj': routerDataSWJ
  }
  console.log('routeSysSign:', window?.$vueApp?.config?.globalProperties?.sysSign)
  const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(process.env.BASE_URL),
    routes: routeMap[window?.$vueApp?.config?.globalProperties?.sysSign] ?? routerData
  })
  window.$vueApp.config.globalProperties.$router = router

  window.$vueApp.use(store)
  window.$vueApp.use(router)
  window.$vueApp.use(i18n)
  window.$vueApp.mount('#app')
  window.$vueApp.config.globalProperties.routerAppend = (path, pathToAppend) => {
    return path + (path.endsWith('/') ? '' : '/') + pathToAppend
  }
}

/**
 * 自动判断更新项目
 */
function autoUpdateProject() {
  console.log('autoUpdateProject')
  axios.get('version.json?random=' + new Date().getTime()).then(res => {
    let version = res.data.version
    let lastBuildTime = res.data.lastBuildTime
    let oldVerStr = localStorage.getItem('mysBIVersionInfo')
    if (oldVerStr) {
      let oldInfo = JSON.parse(oldVerStr)
      if (
          oldInfo.version != version ||
          oldInfo.lastBuildTime != lastBuildTime
      ) {
        if (window.$vueApp.config.globalProperties.isShowMenu == 'N') {
          reloadPage(true, res.data)
        } else {
          const result = confirm('页面有更新，请点击确定刷新页面')
          reloadPage(result, res.data)
        }
      }
    } else {
      reloadPage(true, res.data)
    }
  })
}

function updateLoaclVersion(params) {
  localStorage.setItem('mysBIVersionInfo', JSON.stringify(params))
}

function reloadPage(confirm, params) {
  if (confirm) {
    updateLoaclVersion(params)
    window.location.reload(true)
  }
}