<template>
  <div v-bind="$attrs" class="dutyCalendarBlock">
    <div class="table-left" :style="'width:' + (ifExpand ? '560px' : '160px')">
      <!--      <i v-if="ifExpand" class="iconfont iconzhankai zhankai" @click="expandChange()"></i>-->
      <!--      <i v-else class="iconfont iconzhankai1 zhankai" @click="expandChange()"></i>-->
      <div
        class="table-left-head"
        :style="`position:sticky;top: 0;left: 0;z-index: 999;${
          ifExpand ? 'min-width:380px' : 'min-width:150px'
        }`"
        title="点击展开/隐藏列"
        @click="expandChange()"
      >
        <div v-if="ifExpand" class="zhudian">驻点</div>
        <div v-if="ifExpand" class="post">岗位</div>
        <div class="jobnumber">工号</div>
        <div class="people">人员</div>
      </div>
      <div
        class="table-left-data"
        style="position: sticky; top: 0; left: 0; z-index: 99"
      >
        <div
          v-for="(item, index) in localPpeopleDutys"
          :key="index"
          class="table-left-data-line"
        >
          <template v-if="ifExpand">
            <el-tooltip
              v-if="item.arrestName && item.arrestName.length > 6"
              class="zhudian"
              effect="dark"
              :content="item.arrestName"
              placement="top-start"
            >
              <div class="zhudian">
                {{ item.arrestName }}
              </div>
            </el-tooltip>
            <div v-else class="zhudian">
              {{ item.arrestName }}
            </div>
          </template>

          <template v-if="ifExpand">
            <el-tooltip
              v-if="item.postName && item.postName.length > 6"
              class="post"
              effect="dark"
              :content="item.postName"
              placement="top-start"
            >
              <div class="post">
                {{ item.postName }}
              </div>
            </el-tooltip>
            <div v-else class="post">
              {{ item.postName }}
            </div>
          </template>

          <el-tooltip
            v-if="item.oldStaffId && item.oldStaffId.length > 6"
            class="post"
            effect="dark"
            :content="item.oldStaffId"
            placement="top-start"
          >
            <div class="jobnumber">
              {{ item.oldStaffId }}
            </div>
          </el-tooltip>
          <div v-else class="jobnumber">
            {{ item.oldStaffId }}
          </div>

          <el-tooltip
            v-if="item.staffName && item.staffName.length > 4"
            class="people"
            effect="dark"
            :content="item.staffName"
            placement="top-start"
          >
            <div class="people">{{ item.staffName }}{{ item.staffName }}</div>
          </el-tooltip>
          <div v-else class="people">
            {{ item.staffName }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="table-right"
      style="position: sticky; top: 0; left: 0; overflow: visible"
    >
      <div class="table-right-block" style="position: sticky; top: 0; left: 0">
        <dutyCalendarHead
          style="width: 1600px; position: sticky; top: 0; left: 0; z-index: 999"
          :dates="currMonthDays"
        />
        <dutyCalendarTable
          ref="dutyCalendarTable"
          :mode="mode"
          style="width: 1600px"
          :people="localPpeopleDutys"
          :dates="currMonthDays"
          :shift-colors-obj="shiftColorsObj"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dutyCalendarHead from '@/components/aloneComponents/pg/duty/dutyCalendarHead'
import dutyCalendarTable from '@/components/aloneComponents/pg/duty/dutyCalendarTable'
import { daysInMonth } from '@/utils/dateTime'

export default {
  name: 'DutyCalendar',
  components: {
    dutyCalendarHead,
    dutyCalendarTable
  },
  props: {
    peopleDutys: {
      type: Array
    },
    mode: {
      type: String
    },
    dutyDate: {},
    shiftColorsObj: Object
  },
  data() {
    return {
      currMonthDays: [],
      localPpeopleDutys: [],
      localTd: {},
      ifExpand: true
    }
  },
  watch: {
    dutyDate: {
      handler(val) {
        this.calCurrMonthDays(val)
      },
      deep: true,
      immediate: true
    },
    peopleDutys: {
      handler(val) {
        console.log('peopleDutys', val)
        this.localPpeopleDutys = val ? val : []
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // this.calCurrMonthDays()
  },
  methods: {
    calCurrMonthDays(val) {
      const date = val
      this.currMonthDays = []
      let firstDay = this.$moment(date).startOf('month')
      for (let i = 0; i < daysInMonth(date); i++) {
        this.currMonthDays.push({
          dayNo: i + 1,
          date: this.$moment(firstDay).add(i, 'days')
        })
      }
    },
    /** 鼠标按下 */
    mouseDown(e) {
      // 记录单元格
      if (!(e && e.target)) return
      console.log('mouseDown', e, e.target.offsetWidth)
      // this.localTd = e.target;
      // if (e.offsetX > this.localTd.offsetWidth - 10) {
      //   this.localTd.mouseDown = true;
      //   this.localTd.oldX = e.x;
      //   this.localTd.oldWidth = this.localTd.offsetWidth;
      // }
    },
    /** 鼠标移动 */
    mouseMove(e) {
      if (!(e && e.target)) return
      console.log('mouseMove', e, e.target.offsetWidth)
      // const vm = e.target;
      // // 更改鼠标样式
      // if (e.offsetX > vm.offsetWidth - 10) {
      //   vm.style.cursor = "col-resize";
      // } else {
      //   vm.style.cursor = "default";
      // }
      // // 取出暂存的Table Cell
      // if (!this.localTd) this.localTd = vm;
      // if (this.localTd.mouseDown !== null && this.localTd.mouseDown === true) {
      //   this.localTd.style.cursor = "default";
      //   if (this.localTd.oldWidth + (e.x - this.localTd.oldX) > 0) {
      //     this.localTd.width =
      //         this.localTd.oldWidth + (e.x - this.localTd.oldX);
      //   }
      //   // 调整列宽
      //   this.localTd.style.width = this.localTd.width;
      //   this.localTd.style.cursor = "col-resize";
      //   // 调整该列中的每个Cell
      //   let table = this.localTd;
      //   while (table.tagName !== "TABLE") table = table.parentElement;
      //   for (let j = 0; j < table.rows.length; j++) {
      //     table.rows[j].cells[
      //         this.localTd.cellIndex
      //         ].width = this.localTd.width;
      //   }
      // }
    },
    /** 鼠标抬起 */
    mouseUp(e) {
      // 结束宽度调整
      if (!(e && e.target)) return
      console.log('mouseUp', e, e.target.offsetWidth)
      // if (!this.localTd) this.localTd = e.target;
      // this.localTd.mouseDown = false;
      // this.localTd.style.cursor = "default";
    },
    expandChange() {
      this.ifExpand = !this.ifExpand
    }
  }
}
</script>

<style scoped lang="scss">
@import 'css/duty';
.text-hidden {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dutyCalendarBlock {
  display: flex;
  //flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  height: 500px;
  overflow: auto;

  .table-left {
    width: 560px;
    display: flex;
    flex-wrap: wrap;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: white;

    .zhankai {
      font-size: 5px;
      position: absolute;
      top: 5px;
      left: 10px;
    }
    &-head {
      width: 100%;
      display: flex;
      align-items: flex-end;
      height: 35px;
      background-color: #f6f7fa;
      padding: 10px 0 10px 0;

      .people {
        @extend .text-hidden;
        flex: 1;
        display: flex;
        justify-content: center;
        font-size: 13px;
        align-items: center;
        color: #b3b9c6;
      }

      .zhudian {
        @extend .text-hidden;
        flex: 2;
        display: flex;
        justify-content: center;
        font-size: 13px;
        align-items: center;
        color: #b3b9c6;
        position: relative;
      }

      .post {
        @extend .text-hidden;
        flex: 2;
        display: flex;
        justify-content: center;
        font-size: 13px;
        align-items: center;
        color: #b3b9c6;
        position: relative;
      }
      .jobnumber {
        @extend .text-hidden;
        flex: 1;
        display: flex;
        justify-content: center;
        font-size: 13px;
        align-items: center;
        color: #b3b9c6;
        position: relative;
      }
    }

    &-data {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 8px 0 8px 0;
      background-color: white;

      &-line {
        width: 100%;
        display: flex;
        height: 28px;
        line-height: 28px;
        .people {
          @extend .text-hidden;
          flex: 1;
          font-size: 13px;
          color: #3f3f3f;
          text-align: center;
        }

        .zhudian {
          @extend .text-hidden;
          flex: 2;
          //display: flex;
          //justify-content: center;
          font-size: 13px;
          //align-items: center;
          color: #3f3f3f;
          text-align: center;
        }

        .post {
          @extend .text-hidden;
          flex: 2;
          font-size: 13px;
          color: #3f3f3f;
          text-align: center;
        }
        .jobnumber {
          @extend .text-hidden;
          flex: 1;
          font-size: 13px;
          color: #3f3f3f;
          text-align: center;
        }
      }
    }
  }

  .table-right {
    //min-width: 1200px;
    width: 1600px;
    //overflow-x: scroll;
    overflow: visible;
    &-block {
      width: 100%;
    }
  }
}
</style>
