<template>
  <div class="ett font12">
    <el-table
      :data="datalist"
      empty-text="没有数据"
      stripe
      :height="tableHeight"
      :header-cell-style="{
        background: '#5093E8',
        color: '#ffffff',
        padding: 0
      }"
      style="width: 120%; font-size: 12px; borderradius: 15px 15px 0 0"
      :row-class-name="rowclass"
      :cell-class-name="cellclass"
      :row-style="{ height: '40px' }"
      :header-row-style="{ height: '36px' }"
      :cell-style="{ padding: 0 }"
      @row-click="rowclick"
      @cell-click="cellclick"
    >
      <el-table-column
        v-for="(item, index) in tableTitles"
        :key="index"
        :fixed="item.fixed"
        :prop="item.prop"
        :align="item.align"
        :label="item.label"
        :min-width="item.width"
      />
    </el-table>
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
export default {
  props: [
    'tableHeight',
    'datalist',
    'tableTitles',
    'rowclassname',
    'cellclassname',
    'currentpage',
    'pageCount',
    'rowclickName',
    'cellclickName'
  ],
  emits: ['nextpage', 'fatherMethod'],
  data() {
    return {
      childcurrentpage: 1
    }
  },
  watch: {
    currentpage(newval, oldval) {
      this.childcurrentpage = newval
    }
  },
  mounted() {
    //this.childcurrentpage=this.currentpage
  },
  methods: {
    nextpage() {
      console.log('currentpage', this.currentpage)
      console.log('childcurrentpage', this.childcurrentpage)
      $emit(this, 'nextpage', this.childcurrentpage)
    },
    rowclass({ row, rowIndex }) {
      // console.log('rowclassname',this.rowclassname)
      if (this.rowclassname == 'tabRowClassName') {
        return this.tabRowClassName(row, rowIndex)
      }
    },
    cellclass({ row, column, rowIndex, columnIndex }) {
      if (this.cellclassname == 'getRedCell') {
        return this.getRedCell(row, column, rowIndex, columnIndex)
      } else if (this.cellclassname == 'cellStyle') {
        return this.cellStyle(row, column, rowIndex, columnIndex)
      } else if (this.cellclassname == 'bensell') {
        return this.bensell(row, column, rowIndex, columnIndex)
      } else if (this.cellclassname == 'storsell') {
        return this.storsell(row, column, rowIndex, columnIndex)
      } else if (this.cellclassname == 'strdetal') {
        return this.strdetal(row, column, rowIndex, columnIndex)
      } else if (this.cellclassname == 'centCellClassName') {
        return this.daysell(row, column, rowIndex, columnIndex)
      } else if (this.cellclassname == 'salesell') {
        return this.salesell(row, column, rowIndex, columnIndex)
      } else if (this.cellclassname == 'fontweight') {
        return this.fontweight(row, column, rowIndex, columnIndex)
      }
    },
    rowclick(row) {
      if (this.rowclickName == 'Saleclass_tonext') {
        this.Saleclass_tonext(row)
      }
      if (this.rowclickName == 'showICUmx') {
        $emit(this, 'fatherMethod', row)
      }
    },
    cellclick(row, column) {
      if (this.cellclickName == 'showBrandName') {
        $emit(this, 'fatherMethod', row, column)
      }
    },
    Saleclass_tonext(row) {
      $emit(this, 'fatherMethod', row)
    },
    tabRowClassName(row, rowIndex) {
      let index = rowIndex + 1
      if (index % 2 == 0) {
        return 'warning-row'
      }
    },
    getRedCell(row, column, rowIndex, columnIndex) {
      let index = columnIndex + 1
      console.log('getRedCell', columnIndex)
      if (index == 12) {
        return 'red-row'
      }
      if (index != 1 && index != 12) {
        return 'blue-word'
      }
    },
    salesell(row, column, rowIndex, columnIndex) {
      let index = columnIndex + 1
      if (index == 6) {
        return 'blue-word'
      }
      if (index == 1) {
        return 'blue-word-line'
      }
    },
    cellStyle(row, column, rowIndex, columnIndex) {
      let index = rowIndex + 1
      if (index <= 6) {
        // return "color:#e34a36";
        return 'row-cell1'
      } else {
        // return "color:#ffb434";
        return 'row-cell2'
      }
    },
    bensell(row, column, rowIndex, columnIndex) {
      if (columnIndex != 0) {
        // return "color:#e34a36";
        return 'row-cell1'
      }
    },
    storsell(row, column, rowIndex, columnIndex) {
      let index = columnIndex + 1
      // console.log('getRedCell',columnIndex)
      for (var i = 0; i < this.datalist.length; i++) {
        if (this.datalist[i].rwwc == '未完成') {
          if (index == 3 && rowIndex == i) {
            return 'red-word'
          }
        } else if (this.datalist[i].rwwc == '完成') {
          if (index == 3 && rowIndex == i) {
            return 'blue-word'
          }
        }
      }
    },
    daysell(row, column, rowIndex, columnIndex) {
      let index = columnIndex + 1
      if (index == 2) {
        return 'blue-word'
      }
      for (var i = 0; i < this.datalist.length; i++) {
        if (this.datalist[i].today == i) {
          if (index == 1 && rowIndex == i) {
            return 'blue-word'
          }
        } else if (
          this.datalist[i].day == '星期六' ||
          this.datalist[i].day == '星期日'
        ) {
          if (index == 1 && rowIndex == i) {
            return 'red-word'
          }
        }
      }
    },
    strdetal(row, column, rowIndex, columnIndex) {
      let index = columnIndex + 1
      //console.log(rowIndex);
      //console.log(this.tableData);
      for (var i = 0; i < this.datalist.length; i++) {
        if (this.datalist[i].xstb < 0) {
          if (index == 4 && rowIndex == i) {
            return 'red-word'
          }
        } else if (this.datalist[i].xstb >= 0) {
          if (index == 4 && rowIndex == i) {
            return 'blue-word'
          }
        }
        if (this.datalist[i].zktb < 0) {
          if (index == 6 && rowIndex == i) {
            return 'red-word'
          }
        } else if (this.datalist[i].zktb >= 0) {
          if (index == 6 && rowIndex == i) {
            return 'blue-word'
          }
        }
      }
    },
    fontweight(row, column, rowIndex, columnIndex) {
      let index = columnIndex + 1
      // console.log(columnIndex);
      //console.log(this.tableData);
      // for (var i = 0; i < this.datalist.length; i++) {
      //   if(this.tableTitles[0].label=="批次"){
      //   if (index == 5 && rowIndex == i) {
      //       return "bgcolor1";
      //     }else if (index == 1 && rowIndex == i) {
      //       return "fontweight";
      //     }
      //     }else{
      //       if (index == 9 && rowIndex == i) {
      //       return "bgcolor3";
      //     }else if (index == 8 && rowIndex == i) {
      //       return "bgcolor2";
      //     }else if (index == 2 && rowIndex == 0) {
      //       return "fontweight";
      //     }
      //     }
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.el-table .fontweight) {
  font-weight: bold;
}
:deep(.el-table .bgcolor1) {
  background: rgba(255, 213, 122, 0.2) !important;
  font-weight: bold;
}
:deep(.el-table .bgcolor2) {
  background: rgba(98, 192, 255, 0.2) !important;
  font-weight: bold;
}
:deep(.el-table .bgcolor3) {
  background: rgba(255, 176, 145, 0.2) !important;
  font-weight: bold;
}
:deep(.el-table .fontweight) {
  font-weight: bold;
}
:deep(.el-table th .cell) {
  text-overflow: unset;
  max-height: 46px;
  padding-left: 2px;
  padding-right: 2px;
  white-space: pre-line;
}
:deep(.el-table .cell) {
  text-overflow: unset;
  padding-left: 2px;
  padding-right: 2px;
}
.ett {
  border-radius: 15px;
}
:deep(.el-table .warning-row) {
  background: #f3f9ff;
}
:deep(.el-table .red-row) {
  background: #f00 !important;
  color: rgb(51, 196, 32);
}
:deep(.el-table .blue-word) {
  color: #367ce3;
}
:deep(.el-table .row-cell1) {
  color: #e34a36;
}
:deep(.el-table .row-cell2) {
  color: #ffb434;
}
:deep(.el-table .red-word) {
  color: #f4745e;
}
:deep(.el-table .blue-word-line) {
  color: #367ce3;
  text-decoration: underline;
}
</style>
