import { doFetch, doPostJson } from '@/http'

/**
 * 应打卡未打卡  已过排班时间（上班或下班）但尚未打卡
 * @param params
 *
 */
export function getClockShould(pageId, pageLimit, params) {
  return doPostJson(
    `/Custom/clock/getClockShould/${pageId}/${pageLimit}`,
    params
  )
}

/**
 * 逻辑删除岗位空缺信息
 * @param params
 *
 */
export function deletePost(params) {
  return doPostJson('/Custom/post/delete', params)
}

/**
 * 变为待分配
 * @param params
 *
 */
export function updateSheetToStop(params) {
  return doPostJson('/Custom/sheet/updateSheetToStop', params)
}

/**
 * 再发布工单确认
 * @param params
 *
 */
export function updateSheetToWork(params) {
  return doPostJson('/Custom/sheet/updateSheetToWork', params)
}

/**
 * 再发布工单
 * @param params
 *
 */
export function sendSheetAgain(params) {
  return doPostJson('/Custom/sheet/sendSheetAgain', params)
}

/**
 * 获取工单类型
 * @param params
 *
 */
export function getSheetType(params) {
  return doFetch('/Custom/sheet/getSheetType', params)
}

/**
 * 根据驻点id获取班次信息
 * @param params
 *
 */
export function getClassesByArrest(params) {
  return doPostJson('/Custom/classes/getClassesByArrest2', params)
}

/**
 * 更新为已读
 * @param params
 *
 */
export function updateMessage(params) {
  return doPostJson('/Custom/message/updateMessage', params)
}

/**
 * 已打卡
 * @param params
 *
 */
export function getClockNormal(pageId, pageLimit, params) {
  return doFetch(`/Custom/clock/getClockNormal/${pageId}/${pageLimit}`, params)
}

/**
 * 迟到
 * @param params
 *
 */
export function getClockLate(pageId, pageLimit, params) {
  return doFetch(`/Custom/clock/getClockLate/${pageId}/${pageLimit}`, params)
}

/**
 * 缺卡
 * @param params
 *
 */
export function getClockLack(pageId, pageLimit, params) {
  return doFetch(`/Custom/clock/getClockLack/${pageId}/${pageLimit}`, params)
}

/**
 * 早退
 * @param params
 *
 */
export function getClockEarly(pageId, pageLimit, params) {
  return doFetch(`/Custom/clock/getClockEarly/${pageId}/${pageLimit}`, params)
}
/**
 * 范围外打卡
 * @param params
 *
 */
export function getClockOut(pageId, pageLimit, params) {
  return doFetch(`/Custom/clock/getClockOut/${pageId}/${pageLimit}`, params)
}
/**
 * 未排班打卡
 * @param params
 *
 */
export function getClockExtra(pageId, pageLimit, params) {
  return doFetch(`/Custom/clock/getClockExtra/${pageId}/${pageLimit}`, params)
}

/**
 * 应打卡
 * @param params
 *
 */
export function getClockAll(pageId, pageLimit, params) {
  return doFetch(`/Custom/clock/getClockAll/${pageId}/${pageLimit}`, params)
}

/**
 * 脱岗
 * @param params
 *
 */
export function getOutPost(pageId, pageLimit, params) {
  return doFetch(`/Custom/schedule/getOutPost/${pageId}/${pageLimit}`, params)
}

/**
 * 在岗
 * @param params
 *
 */
export function getOnPost(pageId, pageLimit, params) {
  return doFetch(`/Custom/schedule/getOnPost/${pageId}/${pageLimit}`, params)
}

/**
 * 总岗位
 * @param params
 *
 */
export function getAllPost(pageId, pageLimit, params) {
  return doFetch(`/Custom/schedule/getAllPost/${pageId}/${pageLimit}`, params)
}

/**
 * 人工查岗
 * @param params
 *
 */
export function checkPost(params) {
  return doPostJson('/Custom/schedule/checkPost', params)
}

/**
 * 查岗数量统计
 * @param params
 *
 */
export function getCheckPostNumber(params) {
  return doFetch('/Custom/schedule/getCheckPostNumber', params)
}

/**
 * 获取完成的工单信息
 * @param params
 *
 */
export function getSheetFinish(pageId, pageLimit, params) {
  return doPostJson(
    `/Custom/sheet/getSheetFinish/${pageId}/${pageLimit}?billType=${params.billType}`
  )
}

/**
 * 工单生效
 * @param params
 *
 */
export function sheetWork(params) {
  return doPostJson('/Custom/sheet/sheetWork', params)
}

/**
 * 工单废弃
 * @param params
 *
 */
export function sheetAbandon(params) {
  return doPostJson('/Custom/sheet/sheetAbandon', params)
}

/**
 * 获取员工信息（树形结构）
 * @param params
 *
 */
export function getSatffWithTree(params) {
  return doFetch('/Custom/staff/getSatffWithTree', params)
}
export function getPostStaff(params) {
  return doPostJson('/Custom/schedule/getPostStaff', params)
}

/**
 * 获取打卡异常详细信息
 * @param params
 *
 */
export function getClockDetail(params) {
  return doFetch('/Custom/clock/getClockDetail', params)
}

/**
 * 获取打卡统计信息
 * @param params
 *
 */
export function getClockCount(params) {
  return doFetch('/Custom/clock/getClockCount', params)
}

/**
 * 查询班次（按岗位编号）
 * @param params
 *
 */
export function getClasses(params) {
  return doPostJson('/Custom/classes/getClasses', params)
}

/**
 * 查询岗位（按驻点编号集合）
 * @param params
 *
 */
export function getPost(params) {
  return doPostJson('/Custom/post/getPost', params)
}

/**
 * 获取驻点信息
 * @param params
 *
 */
export function getArrest(params) {
  return doFetch('/Custom/arrest/getArrest', params)
}

/**
 * 更新排班信息
 * @param params
 *
 */
export function updateScheduling(params) {
  return doPostJson('/Custom/schedule/updateScheduling', params)
}

/**
 * 获取排班不冲突人员
 * @param params
 *
 */
export function getStaff(pageId, pageLimit, params) {
  return doPostJson(`/Custom/schedule/getStaff/${pageId}/${pageLimit}`, params)
}

/**
 * 查询岗位空缺信息
 * @param params
 *
 */
export function getPostNoStaff(pageId, pageLimit, params) {
  return doPostJson(
    `/Custom/post/getPostNoStaff/${pageId}/${pageLimit}`,
    params
  )
}

/**
 * 查询审批信息
 * @param params
 *
 */
export function getApproval(params) {
  return doFetch('/Custom/approval/getApproval', params)
}

/**
 * 查询工单信息
 * @param params
 *
 */
export function getSheet(params) {
  return doFetch('/Custom/sheet/getSheet', params)
}

/**
 * 查询全部消息
 * @param params
 *
 */
export function getMessage(pageId, pageLimit, params) {
  return doPostJson(
    `/Custom/message/getMessage/pc/${pageId}/${pageLimit}`,
    params
  )
}

/**
 * 驳回
 * @param params
 *
 */
export function noApproval(params) {
  return doPostJson('/Custom/clock/noApproval', params)
}

/**
 * 处理为正常
 * @param params
 *
 */
export function handleNormal(params) {
  return doPostJson('/Custom/clock/handleNormal', params)
}

/**
 * 获取考勤异常类型
 * @param params
 *
 */
export function getExceptionType(params) {
  return doFetch('/Custom/clock/getExceptionType', params)
}

export function getNeedStaff(pageId, pageLimit, params) {
  return doPostJson(`/Custom/sheet/getNeedStaff/${pageId}/${pageLimit}`, params)
}

/**
 * 获取考勤异常信息
 * @param params
 *
 */
export function getClockException(pageId, pageLimit, params) {
  return doPostJson(
    `/Custom/clock/getClockException/${pageId}/${pageLimit}`,
    params
  )
}
export function updateMessageAllRead(pageId, pageLimit, params) {
  return doPostJson(`/Custom/message/updateMessageAllRead`, params)
}
export function confirmChecked(params) {
  return doPostJson('/Custom/post/confirm', params)
} //空岗指派确认
