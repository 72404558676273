<template>
  <el-dialog
    v-bind="$attrs"
    :append-to-body="true"
    title="删除排班"
    width="70%"
    @close="_close"
  >
    <div class="arrangeDeleteDialogBlock">
      <div style="margin-bottom: 20px">
        <el-checkbox-group v-model="checkList" @change="arrestchange">
          <el-checkbox
            v-for="(item, index) in arrestOptionsAll"
            :key="index"
            :label="item.arrestName"
          />
        </el-checkbox-group>
        <br />
        <el-date-picker
          v-model="range"
          :shortcuts="pickerOptions && pickerOptions.shortcuts"
          :disabled-date="pickerOptions && pickerOptions.disabledDate"
          :cell-class-name="pickerOptions && pickerOptions.cellClassName"
          :clearable="false"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
        />
        <br />
        <!-- <el-select v-model="staffIds" multiple
                               placeholder="请选择驻点"
                               style="width: 280px;margin-top: 10px">
                      <el-option v-for="(item,index) in arrestOptionsAll"
                                 :key="index" :label="item.arrestName" :value="item.arrestName">
                      </el-option>
                    </el-select>
                    <br> -->
        <el-select
          v-model="staffIds"
          multiple
          filterable
          collapse-tags
          placeholder="请选择人员"
          style="width: 280px; margin-top: 10px"
          @change="ChangeSelect"
        >
          <el-checkbox v-model="checked" class="SelectAll" @change="SelectAll">
            全选
          </el-checkbox>
          <el-option
            v-for="(item, index) in staffOptions"
            :key="index"
            :label="getStaffLab(item)"
            :value="item.staffId"
          />
        </el-select>
      </div>

      <div class="footer">
        <el-button type="danger" @click="_close"> 取消 </el-button>
        <el-button type="primary" @click="open"> 确定 </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../../../utils/gogocodeTransfer'
import { deleteAllDuty } from '@/requet/api/ba/scheduleApi'
import { formatDate } from '@/utils/dateTime'

export default {
  name: 'ArrangeDeleteDialog',
  components: {},
  props: {
    dateRange: Array,
    staffOptions: Array,
    arrestOptionsAll: Array,
    month: String,
    year: String
  },
  emits: ['update:visible', 'confirm'],
  data() {
    return {
      checked: false,
      staffIds: [],
      range: [],
      checkList: [],
      checkArrestId: [],
      kind: '',
      pickerOptions: {
        //设置可选择的日期范围
        disabledDate: time => {
          // console.log(this.year,Number(this.month));
          // const now=new Date();
          const maxTime = new Date(
            this.year,
            Number(this.month) - 1,
            1
          ).getTime()
          const minTime = new Date(this.year, Number(this.month), 0).getTime()
          return time.getTime() < maxTime || time.getTime() > minTime
        }
      }
    }
  },
  watch: {
    // calendarValue: function (val) {
    // }
  },
  created() {},
  mounted() {
    this.range = this.dateRange
    this.checkList = []
    this.checkArrestId = []
    for (const arrest of this.arrestOptionsAll) {
      this.checkList.push(arrest.arrestName)
    }
    for (const arrest of this.checkList) {
      this.arrestOptionsAll.forEach(res => {
        if (res.arrestName === arrest) {
          this.checkArrestId.push(res.arrestId)
        }
      })
    }
    console.log(this.checkArrestId)
  },
  methods: {
    getStaffLab(item) {
      return (
        (item.depName ? item.depName + '-' : '') +
        (item.oldStaffId ? item.oldStaffId + '-' : '') +
        item.staffName
      )
    },
    open() {
      this.$confirm('是否产生岗位空缺？', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        showClose: false,
        type: 'warning'
      })
        .then(() => {
          this.kind = 'Y'
          this.confirm()
        })
        .catch(() => {
          this.kind = 'N'
          this.confirm()
        })
    },
    async confirm() {
      if (this.staffIds.length === 0) {
        this.$message.warning('请添加人员')
        return
      }
      if (this.checkArrestId.length === 0) {
        console.log(this.checkArrestId)
        this.$message.warning('请添加驻点')
        return
      }
      // this.$confirm(`确定删除${formatDate(this.range[0])}至${formatDate(this.range[1])}的数据吗?`, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(async () => {
      let res = await deleteAllDuty({
        start: formatDate(this.range[0]),
        end: formatDate(this.range[1]),
        staffIdList: this.staffIds,
        kind: this.kind,
        arrestList: this.checkArrestId
      })
      if (res === 'Y') {
        this.$confirm('存在排班已打卡，是否删除这些排班？', '排班删除提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          showClose: false,
          type: 'warning'
        })
          .then(async () => {
            await this.deleteDutyFunConfirm('Y')
          })
          .catch(async () => {
            await this.deleteDutyFunConfirm('N')
          })
      } else {
        this.$message.success('删除成功')
        $emit(this, 'confirm')
        this._close()
      }
      // this.$message.success("删除成功");
      // this.$emit("confirm");
      // this._close();
      // })
    },
    async deleteDutyFunConfirm(clock) {
      await deleteAllDuty({
        start: formatDate(this.range[0]),
        end: formatDate(this.range[1]),
        staffIdList: this.staffIds,
        kind: this.kind,
        arrestList: this.checkArrestId,
        clock: clock
      })
      this.$message.success('删除成功')
      $emit(this, 'confirm')
      this._close()
    },
    _close() {
      $emit(this, 'update:visible', false)
    },
    arrestchange(value) {
      console.log(value)
      console.log(this.arrestOptionsAll)
      this.checkArrestId = []
      for (const arrest of value) {
        this.arrestOptionsAll.forEach(res => {
          if (res.arrestName === arrest) {
            this.checkArrestId.push(res.arrestId)
          }
        })
      }
      console.log(this.checkArrestId)
    },
    //全选
    SelectAll() {
      if (this.checked) {
        let arr = []
        this.staffOptions.forEach(res => {
          // console.log(res.staffId);
          arr.push(res.staffId)
        })
        this.staffIds = arr
      } else {
        this.staffIds = []
      }
    },
    //el-select关联 全选按钮
    ChangeSelect(val) {
      console.log(val)
      if (val.length == this.staffOptions.length) {
        this.checked = true
      } else {
        this.checked = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.arrangeDeleteDialogBlock {
  display: flex;
  flex-wrap: wrap;
  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
.SelectAll {
  text-align: right;
  width: 98%;
}
</style>
