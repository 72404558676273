<template>
  <div class="dutyCalendarTableBlock" v-bind="$attrs">
    <div v-for="(item, index) in showLocalPeople" :key="index" class="block">
      <div class="block-right">
        <template>
          <template v-for="(temp, index2) in item.dateList">
            <div v-if="temp.show" :style="'width:' + temp.cellWidth + '%;'">
              <!--            查看状态和编辑状态下的popover-->
              <div
                v-if="
                  mode === 'view' &&
                  ((temp.shiftCatNameAbrv == null && mode == 'edit') ||
                    ((temp.classesDay2
                      ? Number(temp.classesDay2) > 0
                      : Number(temp.classesDay) > 0) &&
                      mode == 'view') ||
                    (((temp.classesDay2
                      ? Number(temp.classesDay2) === 0
                      : Number(temp.classesDay) === 0) ||
                      index2 + 1 === item.dateList.length) &&
                      temp.shiftCatNameAbrv != null))
                "
              >
                <!--              v-model="localPeople[index].dateList[index2].openPop"-->
                <el-popover
                  v-model="localPeople[index].dateList[index2].openPop"
                  :placement="mode === 'edit' ? 'right-start' : 'bottom'"
                  trigger="click"
                >
                  <template>
                    <viewGradeTypePop
                      v-show="mode === 'view'"
                      :date="temp.date"
                      :date-list="item.dateList"
                      :list="temp.shiftList"
                      :postation="[index, index2]"
                      @close="typeClose"
                    />
                  </template>
                  <template #reference>
                    <div v-if="temp.shiftCatNameAbrv != null" class="item">
                      <div class="item-day">
                        <div
                          :style="
                            'background-color:' + temp.shiftCatColor + ';'
                          "
                          class="item-day-txt"
                        >
                          {{ temp.shiftCatNameAbrv }}
                          <!--                      <i v-if="temp.frozen === 'Y'" class="el-icon-lock lock-icon"></i>-->
                        </div>
                      </div>

                      <el-icon class="more-icon">
                        <el-icon-caret-right />
                      </el-icon>
                    </div>

                    <div
                      v-if="temp.shiftCatNameAbrv == null && mode === 'edit'"
                      class="item-xiu"
                    >
                      <div class="item-xiu-day">
                        <div v-if="!temp.isHoliday" class="item-xiu-day-txt">
                          休
                        </div>
                        <div v-else class="item-xiu-holiday-txt">假</div>
                      </div>
                    </div>
                  </template>
                </el-popover>
              </div>

              <div
                v-if="
                  mode === 'edit' &&
                  ((temp.shiftCatNameAbrv == null && mode == 'edit') ||
                    ((temp.classesDay2
                      ? Number(temp.classesDay2) > 0
                      : Number(temp.classesDay) > 0) &&
                      mode == 'view') ||
                    (((temp.classesDay2
                      ? Number(temp.classesDay2) === 0
                      : Number(temp.classesDay) === 0) ||
                      index2 + 1 === item.dateList.length) &&
                      temp.shiftCatNameAbrv != null))
                "
              >
                <div>
                  <div @click="openEditDialog(index, index2, temp, item)">
                    <div
                      v-if="temp.shiftCatNameAbrv != null"
                      slot="reference"
                      class="item"
                    >
                      <div class="item-day">
                        <div
                          :style="
                            'background-color:' + temp.shiftCatColor + ';'
                          "
                          class="item-day-txt"
                        >
                          {{ temp.shiftCatNameAbrv }}
                          <!--                        <i v-if="temp.frozen === 'Y'" class="el-icon-lock lock-icon"></i>-->
                        </div>
                      </div>

                      <el-icon class="more-icon">
                        <el-icon-caret-right />
                      </el-icon>
                    </div>
                    <div
                      v-if="temp.shiftCatNameAbrv == null && mode === 'edit'"
                      slot="reference"
                      class="item-xiu"
                    >
                      <div class="item-xiu-day">
                        <div v-if="!temp.isHoliday" class="item-xiu-day-txt">
                          休
                        </div>
                        <div v-else class="item-xiu-holiday-txt">假</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--            跨日-->
              <div
                v-if="
                  (temp.classesDay2
                    ? Number(temp.classesDay2) > 0
                    : Number(temp.classesDay) > 0) &&
                  mode != 'view' &&
                  index2 + 1 !== item.dateList.length
                "
              >
                <div>
                  <div
                    v-if="temp.shiftCatNameAbrv != null"
                    slot="reference"
                    class="item"
                    @click="
                      openMutilEdit(
                        item,
                        temp,
                        item.dateList,
                        [index, index2],
                        temp.frozen
                      )
                    "
                  >
                    <div class="item-day">
                      <div
                        :style="'background-color:' + temp.shiftCatColor + ';'"
                        class="item-day-txt"
                      >
                        {{ temp.shiftCatNameAbrv }}
                        <!--                      <i v-if="temp.frozen === 'Y'" class="el-icon-lock lock-icon"></i>-->
                      </div>
                    </div>

                    <el-icon class="more-icon">
                      <el-icon-caret-right />
                    </el-icon>
                  </div>
                  <div
                    v-if="temp.shiftCatNameAbrv == null"
                    slot="reference"
                    class="item-xiu"
                  >
                    <div class="item-xiu-day">
                      <div v-if="!temp.isHoliday" class="item-xiu-day-txt">
                        休
                      </div>
                      <div v-else class="item-xiu-holiday-txt">假</div>
                    </div>
                  </div>
                </div>
              </div>

              <!--            查看状态下 休-->
              <!--            @click.prevent="openClassPop($event)"-->
              <div v-if="temp.shiftCatNameAbrv == null && mode === 'view'">
                <div slot="reference" class="item-xiu">
                  <div class="item-xiu-day">
                    <div v-if="!temp.isHoliday" class="item-xiu-day-txt">
                      休
                    </div>
                    <div v-else class="item-xiu-holiday-txt">假</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <classPopover :left="popLeft" :top="popTop" :visible="classPopVisible" />
    <mutilDayEditDialog
      v-if="mutilEditDialogVisble"
      v-model:visible="mutilEditDialogVisble"
      :dates="mutilDates"
      :post-classes="postClasses"
      @confirm="mutilDayConfirm"
    />
    <normalDayEditDialog
      v-model:visible="normalEditDialogVisible"
      :normal-holiday-list="normalHolidayList"
      :normal-list="normalList"
      @confirm="normalDialogConfirm"
    />
  </div>
</template>

<script>
import { CaretRight as ElIconCaretRight } from '@element-plus/icons-vue'
import editGradeTypePop from '@/components/aloneComponents/pg/duty/editGradeTypePop'
import viewGradeTypePop from '@/components/aloneComponents/pg/duty/viewGradeTypePop'
import { getClasses } from '@/requet/api/ba/scheduleApi'
import mutilDayEditDialog from '@/components/aloneComponents/pg/duty/dialog/mutilDayEditDialog'
import classPopover from '@/components/aloneComponents/pg/duty/dialog/classPopover'
import normalDayEditDialog from '@/components/aloneComponents/pg/duty/dialog/normalDayEditDialog'

export default {
  name: 'DutyCalendarTable',
  components: {
    editGradeTypePop,
    viewGradeTypePop,
    mutilDayEditDialog,
    normalDayEditDialog,
    classPopover,
    ElIconCaretRight
  },
  // props: ['mode','people','dates','shiftColorsObj'],
  props: {
    mode: {
      type: String
    },
    people: {
      type: Array
    },
    dates: {
      type: Array
    },
    shiftColorsObj: Object
  },
  data() {
    return {
      // random: 1
      mutilDayType: 8,
      postClasses: [], //岗位下的班次,
      postClassesMap: {},
      localPeople: [],
      mutilEditDialogVisble: false,
      mutilDates: [],
      classPopVisible: false,
      normalEditDialogVisible: false,
      normalList: [],
      normalHolidayList: [],
      normalShiftList: [],
      normalPostation: [],
      popTop: 10,
      popLeft: 50
    }
  },
  computed: {
    showLocalPeople() {
      console.log('showLocalPeople:')
      let list = JSON.parse(JSON.stringify(this.localPeople))
      for (const item of list) {
        for (const temp of item.dateList) {
          // console.log('date:', temp.date)
          temp.isMore = this.calDayClassesNum(temp.shiftList, item.dateList)
          // 计算当前排班是否请假
          this.calHoliday(temp, item.holidayList)
        }
      }
      // console.log(list)
      //return JSON.parse(JSON.stringify(this.localPeople))
      return list
      // return this.localPeople
    }
  },
  watch: {
    people: {
      handler(val) {
        // this.localPeople = JSON.parse(JSON.stringify(val))
        this.localPeople = val
      },
      deep: true,
      immediate: true
    },
    classPopVisible(value) {
      console.log('watch classPopVisible')
      if (this.classPopVisible) {
        document.body.addEventListener('click', this.closeClassPop)
      } else {
        document.body.removeEventListener('click', this.closeClassPop)
      }
    }
  },
  mounted() {
    // console.log('mode', this.mode)
    // this.random = Math.floor((Math.random() * 7) + 1)
  },
  methods: {
    calDayClassesNum(list, dateList) {
      console.log('calDayClassesNum', list, dateList)
      //判断跨日 classesDay > 0 或 attendanceDate != date
      if (list && list.length > 0) {
        let mutilDay = list?.find(
          x => Number(x.classesDay) > 0 || x.date != x.attendanceDate
        )
        let arr = []
        if (mutilDay) {
          let mutilDayIndex = dateList.findIndex(
            x =>
              x.date == mutilDay.attendanceDate ||
              this.$moment(mutilDay.attendanceDate).isBefore(x.date)
          ) //上月日期处理
          for (let i = 0; i <= Number(mutilDay.classesDay); i++) {
            if (dateList[mutilDayIndex + i]?.shiftList) {
              arr.push(dateList[mutilDayIndex + i])
            }
          }
        } else {
          arr = list
        }
        // console.log('num:', arr, arr.filter(a => a?.shiftList?.length > 0)?.length > 1, arr.find(a => a.shiftList?.length > 1))
        return (
          arr.filter(a => a?.shiftList?.length > 0)?.length > 1 ||
          !!arr.find(a => a?.shiftList?.length > 1)
        )
        // return arr?.length > 1 || !!arr.find(a => a?.shiftList?.length > 1)
      } else {
        // console.log('num2', list)
        return false
      }
    },
    // 计算当前排班是否请假
    calHoliday(item, holidayList) {
      item.isHoliday = false
      for (const holiday of holidayList) {
        if (item.date == holiday.attendanceDate) {
          item.holidays = holiday.items
          item.isHoliday = !holiday.isExtra
          return
        }
      }
    },
    typeConfirm(postation, ckList) {
      // this.
      console.log('typeConfirm', ckList)

      this.localPeople[postation[0]].dateList[postation[1]].shiftList = ckList
      this.localPeople[postation[0]].dateList[postation[1]].openPop = false

      if (ckList.length > 0) {
        this.localPeople[postation[0]].dateList[postation[1]].shiftCatNameAbrv =
          ckList[0].shiftCatNameAbrv
        this.localPeople[postation[0]].dateList[postation[1]].shiftCatColor =
          ckList[0].shiftCatColor

        //最长跨日班次
        let maxMutilDay = ckList.reduce((x, y) => {
          return Number(x.classesDay) > Number(y.classesDay) ? x : y
        })
        console.log('maxMutilDay', maxMutilDay)
        this.localPeople[postation[0]].dateList[postation[1]].classesDay =
          maxMutilDay.classesDay
      } else {
        let dateItem = this.localPeople[postation[0]].dateList[postation[1]]
        this.localPeople[postation[0]].dateList[postation[1]] = {
          date: dateItem.date,
          dayNo: dateItem.dayNo,
          openPop: dateItem.openPop,
          show: dateItem.show
        }
      }
      // console.log(this.localPeople[postation[0]].dateList[postation[1]])
      // this.localPeople = JSON.parse(JSON.stringify(this.localPeople))
      this.dealDateItemShow(this.localPeople)
    },
    typeClose(postation) {
      this.localPeople[postation[0]].dateList[postation[1]].openPop = false
    },
    async loadClasses(item) {
      console.log('loadClasses', this.postClassesMap, item.postId)
      let list = []
      if (!this.postClassesMap[item.postId]) {
        list = await getClasses({
          postId: item.postId
        })
        list.forEach(x => {
          x.shiftCatColor = this.shiftColorsObj[x.classesId]
          x.postId = item.postId
        })
        this.postClassesMap[item.postId] = list
      }
      this.postClasses = this.postClassesMap[item.postId]
    },
    openClassPop(e) {
      console.log('eee', e)
      e.preventDefault()
      e.stopPropagation()
      this.classPopVisible = true
      const menuMinWidth = 200
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary

      console.log(offsetLeft, offsetWidth, maxLeft)
      // const left = e.clientX - offsetLeft + 480; // 15: margin right
      //
      // if (left > maxLeft) {
      //   this.popLeft = maxLeft;
      // } else {
      //   this.popLeft = e.clientX
      // }
      this.popLeft = e.clientX
      this.popTop = e.clientY - 12
      console.log(this.popTop, this.popLeft)
    },
    closeClassPop() {
      console.log('closeClassPop')
      this.classPopVisible = false
    },
    openMutilEdit(item, mutilDay, dateList, postation, frozen) {
      console.log(frozen)
      let classesDay = Number(mutilDay.classesDay)
      let datesTemp = []
      for (let i = 0; i < classesDay + 1; i++) {
        if (dateList[postation[1] + i]) {
          //处理边界问题：月末跨月的快日班次
          // console.log('datelist',dateList[postation[1] + i], dateList)
          let temp = {
            ...dateList[postation[1] + i],
            staffId: item.staffId,
            postId: item.postId
          }
          console.log('dateTemp:', item)
          datesTemp.push(temp)
        }
      }
      // console.log('datesTemp', datesTemp)
      for (const item of datesTemp) {
        if (item.shiftList) {
          for (const shift of item.shiftList) {
            shift.checked = false
          }
        } else {
          item.shiftList = []
        }
      }
      this.mutilDates = datesTemp
      this.loadClasses(item)
      this.mutilEditDialogVisble = true
    },
    mutilDayConfirm(data) {
      console.log('mutilDayConfirm', data.list, this.localPeople)
      for (const [index1, people] of this.localPeople.entries()) {
        for (const item of data.list) {
          // console.log('mutilDayConfirm2', item, people, item.staffId === people.staffId && item.postId === people.postId)
          console.log(
            'mutilDayConfirm3',
            item.staffId,
            people.staffId,
            item.postId,
            people.postId
          )
          if (
            item.staffId === people.staffId &&
            item.postId === people.postId
          ) {
            console.log('item.dataList', item, people)
            for (const [index2, day] of people.dateList.entries()) {
              if (day.date === item.date) {
                console.log('tt2', day.date, item.date)
                this.localPeople[index1].dateList[index2] = item
                console.log(
                  'mutil',
                  day.date,
                  this.localPeople[index1].dateList[index2],
                  item
                )
                // break
              }
            }
            // break
          }
        }
      }
      this.dealDateItemShow(this.localPeople)
    },
    //判断班次重叠显示问题 计算显隐和日期宽度
    dealDateItemShow(list) {
      for (const item of list) {
        for (const temp of item.dateList) {
          temp.show = true
        }
        let dateIndex = 0
        for (const temp of item.dateList) {
          let cellWidthNum = 1
          let maxClassDay = 0
          let maxClass = null
          console.log('max:', temp.shiftList)
          if (temp.shiftList && temp.shiftList.length > 0) {
            maxClass =
              temp.shiftList.length > 1
                ? temp.shiftList.reduce((x, y) => {
                    return Number(x.classesDay) > Number(y.classesDay) ? x : y
                  })
                : temp.shiftList[0]
            maxClassDay = maxClass?.classesDay
          }

          console.log('tt:', item, temp, maxClassDay)
          if (maxClassDay > 0 && temp.show) {
            for (let i = 0; i < maxClassDay; i++) {
              if (item.dateList[dateIndex + i + 1]) {
                item.dateList[dateIndex + i + 1].show = false
                cellWidthNum++
              }
            }
          }
          dateIndex++
          if (maxClass?.shiftCatNameAbrv) {
            temp.shiftCatNameAbrv = maxClass.shiftCatNameAbrv
          }
          temp.cellWidth = (cellWidthNum / item.dateList.length) * 100
        }
      }
      console.log('local', list)
      this.localPeople = JSON.parse(JSON.stringify(list))
    },
    async openEditDialog(index, index2, temp, item) {
      console.log(temp.frozen)
      let list = []
      if (!this.postClassesMap[item.postId]) {
        list = await getClasses({
          postId: item.postId
        })
        list.forEach(x => {
          x.shiftCatColor = this.shiftColorsObj[x.classesId]
          x.postId = item.postId
        })
        this.postClassesMap[item.postId] = list
      }
      this.postClasses = this.postClassesMap[item.postId]
      this.normalPostation = [index, index2]
      this.normalShiftList = temp.shiftList

      let arr = []
      for (const item of this.postClasses) {
        let obj = this.normalShiftList?.find(
          x => x.classesId === item.classesId
        )
        let flag = this.normalShiftList && obj ? true : false
        // schedulingId
        arr.push({
          ...item,
          schedulingId: flag ? obj.schedulingId : null,
          checked: flag
        })
      }
      this.normalList = arr
      this.normalHolidayList = temp.holidays
      this.normalEditDialogVisible = true
    },
    normalDialogConfirm(ckList) {
      if (ckList) {
        ckList.forEach(res => {
          res.attendanceDate =
            this.localPeople[this.normalPostation[0]].dateList[
              this.normalPostation[1]
            ].date
        })
      }
      this.localPeople[this.normalPostation[0]].dateList[
        this.normalPostation[1]
      ].shiftList = ckList
      this.localPeople[this.normalPostation[0]].dateList[
        this.normalPostation[1]
      ].openPop = false

      if (ckList.length > 0) {
        this.localPeople[this.normalPostation[0]].dateList[
          this.normalPostation[1]
        ].shiftCatNameAbrv = ckList[0].shiftCatNameAbrv
        this.localPeople[this.normalPostation[0]].dateList[
          this.normalPostation[1]
        ].shiftCatColor = ckList[0].shiftCatColor

        //最长跨日班次
        let maxMutilDay = ckList.reduce((x, y) => {
          return Number(x.classesDay) > Number(y.classesDay) ? x : y
        })
        this.localPeople[this.normalPostation[0]].dateList[
          this.normalPostation[1]
        ].classesDay = maxMutilDay.classesDay
      } else {
        let dateItem =
          this.localPeople[this.normalPostation[0]].dateList[
            this.normalPostation[1]
          ]
        this.localPeople[this.normalPostation[0]].dateList[
          this.normalPostation[1]
        ] = {
          date: dateItem.date,
          dayNo: dateItem.dayNo,
          openPop: dateItem.openPop,
          show: dateItem.show
        }
      }
      this.dealDateItemShow(this.localPeople)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'css/duty';

.dutyCalendarTableBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 1px solid #e6e8ec;
  padding: 8px 0 8px 0;

  .block {
    width: 100%;
    display: flex;
    //margin: 5px 0 5px 0;
    height: 28px;
    line-height: 28px;
  }

  .block-left {
    width: 12%;
    display: flex;

    .people {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: 13px;
      //font-weight: 550;
      align-items: center;
      color: #3f3f3f;
      overflow: hidden;
      //white-space: nowrap;
      text-overflow: ellipsis;
    }

    .zhudain {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: 13px;
      //font-weight: 550;
      align-items: center;
      color: #3f3f3f;
      overflow: hidden;
      //white-space: nowrap;
      text-overflow: ellipsis;
    }

    .post {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: 13px;
      //font-weight: 550;
      align-items: center;
      color: #3f3f3f;
      overflow: hidden;
      //white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .block-right {
    width: 100%;
    display: flex;
    //margin-bottom: 2px;
    height: 28px;
    line-height: 28px;
    //&-popver {
    //  width: 100px;
    //}

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      //background-color: $dayBg;
      //border-radius: 4px;
      //padding: 3px 0 3px 0;
      margin: 3px 4px 0 4px;
      cursor: pointer;
      position: relative;

      .more-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 10px;
        transform: rotate(45deg);
        color: red;
      }

      .lock-icon {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
      }

      &-day {
        width: 100%;
        font-size: 12px;
        display: flex;
        justify-content: center;
        //padding: 3px 4px 3px 4px;
        //background-color: $dayBg;
        //border-radius: 4px;

        &-txt {
          width: 100%;
          //display: flex;
          //justify-content: center;
          //padding: 3px 0 3px 0;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          height: 24px;
          line-height: 24px;
          border-radius: 4px;
          //margin-top: 2px;
        }
      }
    }

    .item-xiu {
      display: flex;
      justify-content: center;
      flex: 1;
      border-radius: 4px;
      //padding: 3px 0 3px 0;
      //margin: 0 4px 0 4px;
      cursor: pointer;

      &-day {
        width: 100%;
        font-size: 12px;
        display: flex;
        justify-content: center;

        &-txt {
          width: 100%;
          display: flex;
          justify-content: center;
          color: #b5bbc9;
        }
      }

      &-holiday-txt {
        width: 100%;
        display: flex;
        justify-content: center;
        color: #f56c6c;
      }
    }
  }
}
</style>
