<template>
  <div class="all">
    <div class="input">
      <el-input
          id="suggestId"
          v-model="Addaddress"
          style="width: 30%; height: auto; margin-bottom: 2%"
          placeholder="请输入地址"
      />
      <div>
        <span v-if="show" style="margin-right: 3%; font-weight: bold"
        ><span>{{ newtext }}</span
        >：经度：{{ lon1 }}，纬度：{{ lat1 }}</span
        >
      </div>
      <el-button class="btn" :disabled="!show" @click="btnchange()">
        经纬度更新
      </el-button>
    </div>
    <div :id="id" class="map" />
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../utils/gogocodeTransfer'
import { BMPGL } from '@/assets/js/bmpgl.js'
import { updateFamilyAddress } from '@/requet/api/dialogMap.js'
import {doPostJson} from "@/http";

export default {
  name: "mapDialog",
  props: {
    btnLink: Object
  },
  emits: ['btnLinkClose'],
  data() {
    return {
      familyId:'',
      ak: '1XjLLEhZhQNUzd93EjU5nOGQ', // 百度的地图密钥
      lon: 120.59241222959322, //经度,苏州市中心
      lonnew: null,
      lon1: null,
      lat: 31.303564074441766, //纬度,苏州市中心
      latnew: null,
      lat1: null,
      Addaddress: '',
      show: false,
      newtext: '家庭地址',
      id: '',
      obj: {
        color: 'red',
        border: '0px',
        backgroundColor: '0.000000000001',
        fontSize: '12px',
        fontFamily: '微软雅黑'
      },
      obj1: {
        color: 'blue',
        border: '0px',
        backgroundColor: '0.000000000001',
        fontSize: '12px',
        fontFamily: '微软雅黑'
      },
      map: null
    }
  },
  watch: {
    btnLink(newval) {
      this.show = false;
      this.familyId = this.btnLink.query.familyId;
      this.id = this.btnLink.query.familyId;
      this.getPosition(this.lon, this.lat);
    },
  },
  beforeUnmount() {
    this.map.destroy()
    this.map = null
  },
  mounted() {
    console.log(this.btnLink)
    this.familyId = this.btnLink.query.familyId;
    this.id = this.btnLink.query.familyId
    console.log(this.lon, this.lat)
    this.getPosition(this.lon, this.lat)
  },
  methods: {
    //地图显示
    getPosition(a, b) {
      // 传入密钥获取地图回调。
      let _this = this
      console.log(_this.id)
      BMPGL(_this.ak).then(BMapGL => {
        // 创建地图实例
        _this.map = new BMapGL.Map(_this.id)
        // 创建点坐标 axios => res 获取的初始化定位坐标
        let point = new BMapGL.Point(a, b)
        // 初始化地图，设置中心点坐标和地图级别
        _this.map.centerAndZoom(point, 19)
        //开启鼠标滚轮缩放
        _this.map.enableScrollWheelZoom(true)

        //按地址查询
        var ac = new BMapGL.Autocomplete({
          //建立一个自动完成的对象
          input: 'suggestId',
          location: _this.map
        })
        ac.addEventListener('onhighlight', function (e) {
          //鼠标放在下拉列表上的事件
          var str = ''
          var _value = e.fromitem.value
          var value = ''
          if (e.fromitem.index > -1) {
            value =
                _value.province +
                _value.city +
                _value.district +
                _value.street +
                _value.business
          }
          str =
              'FromItem<br />index = ' +
              e.fromitem.index +
              '<br />value = ' +
              value

          value = ''
          if (e.toitem.index > -1) {
            _value = e.toitem.value
            value =
                _value.province +
                _value.city +
                _value.district +
                _value.street +
                _value.business
          }
          str +=
              '<br />ToItem<br />index = ' +
              e.toitem.index +
              '<br />value = ' +
              value
          // document.getElementById("searchResultPanel").innerHTML = str;
        })
        var myValue
        ac.addEventListener('onconfirm', function (e) {
          //鼠标点击下拉列表后的事件
          // console.log(e);
          var _value = e.item.value
          myValue =
              _value.province +
              _value.city +
              _value.district +
              _value.street +
              _value.business
          // document.getElementById("searchResultPanel").innerHTML ="onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
          setPlace()
        })

        _this.map.addEventListener('click', function (e) {
          console.log(e)
          _this.newtext = '家庭地址'
          _this.lon1 = e.latlng.lng
          _this.lat1 = e.latlng.lat
          _this.show = true
          console.log('点击位置经纬度：' + e.latlng.lng + ',' + e.latlng.lat)
          _this.map.clearOverlays()

          // //创建地址解析器实例
          // var myGeoLocation = new BMapGL.Geocoder()
          // //获取选中地址的经纬度
          // myGeoLocation.getLocation(new BMapGL.Point(_this.lon1, _this.lat1), result => {
          //   console.log(result);
          // })

          var myIcon = new BMapGL.Icon(
              require('@/assets/markers.png'),
              new BMapGL.Size(23, 25),
              {
                offset: new BMapGL.Size(10, 25), // 指定定位位置
                imageOffset: new BMapGL.Size(0, 263.5) // 设置图片偏移
              }
          )
          var markernew = new BMapGL.Marker(
              new BMapGL.Point(_this.lon1, _this.lat1),
              { icon: myIcon }
          )
          _this.map.addOverlay(markernew)
          var opts2 = {
            position: new BMapGL.Point(_this.lon1, _this.lat1), // 指定文本标注所在的地理位置
            offset: new BMapGL.Size(-25, 0)
          }
          var label3 = new BMapGL.Label('家庭地址', opts2)
          label3.setStyle(_this.obj)
          _this.map.addOverlay(label3)
          // alert('点击位置经纬度：' + e.latlng.lng + ',' + e.latlng.lat);
        })

        function setPlace() {
          _this.map.clearOverlays() //清除地图上所有覆盖物
          function myFun() {
            var pp = local.getResults().getPoi(0).point //获取第一个智能搜索的结果
            _this.map.centerAndZoom(pp, 18)
            // _this.map.addOverlay(new BMapGL.Marker(pp)); //添加标注

          }

          var local = new BMapGL.LocalSearch(_this.map, {
            //智能搜索
            onSearchComplete: myFun
          })
          // console.log(myValue);

          //创建地址解析器实例
          var myGeo = new BMapGL.Geocoder()
          //获取选中地址的经纬度
          myGeo.getPoint(myValue, point => {
            // console.log("point", point, myValue);
            _this.lonnew = point.lng
            _this.latnew = point.lat
            _this.mapAddress = myValue
          })
          local.search(myValue)
        }
      })
    },
    btnchange() {
      this.$confirm('是否确认更新当前经纬度?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            this.change()
          })
          .catch(() => {})
    },
    async change() {
      let param = {
        latitude: this.lat1,
        longitude: this.lon1,
        familyId: this.familyId,
      }
      this.$doPostJson('/Custom/family/updateFamilyAddress', param).then((res)=>{
        this.$message({
          type: 'success',
          message: '更新成功!'
        })
        $emit(this, 'btnLinkClose')
      }).catch((err) => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.all {
  width: 100%;
  height: 100%;
  .input {
    width: 100%;
    height: 90px;
    position: relative;

    .btn {
      position: absolute;
      top: 0px;
      right: 5%;
    }
  }

  .map {
    width: 100%;
    height: calc(100% - 90px);
  }
}
:deep(.BMap_cpyCtrl) {
  display: none;
}
:deep(.anchorBL) {
  display: none;
}
</style>
