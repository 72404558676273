<template>
  <div class="main">
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item :label="form.name">
        <el-input v-model="form.billid" placeholder="请输入案件号"></el-input>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" @click="syncCase">数据抽取</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaseNo",
  components: {},
  props: [],
  data() {
    return {
      form:{
        name:"案件编号：",
        billid:""
      }
    };
  },
  mounted() {},
  methods: {
    syncCase(){
      // console.log("数据抽取！")
      let params={
        caseid:this.form.billid
      }
       this.$doPost("/Custom/sync/case",params).then((res) => {
         Notification.success({
          message: "抽取成功！",
        });
      });
    }
  },
};
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  height: 100%;
.btn{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
}
</style>
