const button = {
  '新增': '新增',
}

const languageMap = {
  ...button,
 
}

module.exports = {
  cnLanguageMap: languageMap
}
