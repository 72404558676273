<template>
  <el-dialog
    v-bind="$attrs"
    :append-to-body="true"
    title="更改班次"
    width="480px"
    @close="_close"
  >
    <div class="normalDayEditDialogBlock" style="margin-top: -20px">
      <div v-for="holiday in holidayList" class="holiday-item">
        {{ holiday.holidaytypeName }}:{{ holiday.holidayBeginDate }}~{{
          holiday.holidayEndDate
        }}
      </div>
      <div class="types">
        <div v-for="item in list" class="types-item">
          <gradeTypeOptCard :item="item" />
        </div>
      </div>
      <div class="footer">
        <el-button type="danger" @click="_close"> 取消 </el-button>
        <el-button type="primary" @click="confirm"> 确定 </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../../../utils/gogocodeTransfer'
import gradeTypeOptCard from '@/components/aloneComponents/pg/duty/gradeTypeOptCard'

export default {
  name: 'NormalDayEditDialog',
  components: {
    gradeTypeOptCard
  },
  props: {
    normalList: Array,
    normalHolidayList: Array
  },
  emits: ['confirm', 'update:visible'],
  data() {
    return {
      list: [],
      holidayList: {}
    }
  },
  watch: {
    normalList: {
      handler(val, oldVal) {
        // if (val && this.list.length == 0) {
        this.list = this.normalList
        // }
      },
      deep: true,
      immediate: true
    },
    normalHolidayList: {
      handler(val, oldVal) {
        this.holidayList = this.normalHolidayList
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {},
  methods: {
    confirm() {
      $emit(
        this,
        'confirm',
        this.list.filter(x => x.checked)
      )
      this._close()
    },
    _close() {
      $emit(this, 'update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../css/duty';
.input_width_ .el-input {
  width: 320px;
}
.normalDayEditDialogBlock {
  display: flex;
  flex-wrap: wrap;
  .types {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-height: 400px;
    overflow-y: auto;

    &-item {
      width: 45%;
      margin-right: 5%;
      margin-bottom: 15px;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .holiday-item {
    width: 100%;
    font-size: 13px;
    text-align: center;
    text-overflow: ellipsis;
    background-color: #f56c6c;
    color: #ffffff;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}
</style>
