import { doFetch, doPostJson } from '@/http'
/**
 *
 * 【计量所】计量器具历史记录查询
 * @param params
 * @constructor
 */
export function getJLHistoryItem(params) {
  return doPostJson('/cmct/CheckOrder/getJLHistoryItem', params)
}
