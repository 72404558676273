<template>
  <div class="arrangeBlock">
    <div class="head" style="width: 100%; display: flex">
      <div style="display: flex; align-items: center">排班管理</div>
      <div class="date" style="">
        <i class="iconfont iconriqi1 date-icon" />
        <monthSelectPop
          v-model:dutyDate="dutyDate"
          @change="condationChange"
          @nextMonth="nextMonth"
          @preMonth="preMonth"
        >
          <template>
            <div class="date-lab">
              {{ monthFormat_filter(dutyDate) }}
            </div>
          </template>
        </monthSelectPop>
        <div class="date-step">
          <el-icon><el-icon-arrow-left /></el-icon>
          <el-icon><el-icon-arrow-right /></el-icon>
        </div>
      </div>
    </div>

    <div class="content">
      <!--      :arrestList="arrestList"-->
      <dutyCondation
        ref="dutyCondation"
        v-model:mode="mode"
        :idkey="idkey"
        :arrest-list="arrestList"
        :duty-date2="dutyDate"
        :frozen="frozen"
        :month="params.month"
        :people-dutys="peopleDutys"
        :year="params.year"
        style="margin-bottom: 15px"
        @cancel="calCancel"
        @edit="openEdit"
        @save="calSave"
        @search="condSearch"
      />
      <!--      <dutyTypes :shiftTypes="shiftTypes"></dutyTypes>-->
      <dutyTypes :shift-types="classesList" title="班次" />
      <div v-loading="loading" style="width: 100%; min-height: 400px">
        <dutyCalendar
          ref="dutyCalendar"
          :duty-date="dutyDate"
          :mode="mode"
          :people-dutys="peopleDutys"
          :shift-colors-obj="shiftColorsObj"
        />
        <div class="paginationBlock">
          <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :page-sizes="[10, 20, 30, 60]"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ArrowLeft as ElIconArrowLeft,
  ArrowRight as ElIconArrowRight
} from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../../../utils/gogocodeTransfer'
import dutyCondation from '@/components/aloneComponents/pg/duty/dutyCondation'
import dutyTypes from '@/components/aloneComponents/pg/duty/dutyTypes'
import dutyCalendar from '@/components/aloneComponents/pg/duty/dutyCalendar'
import {
  deleteDuty,
  getClassesByArrest,
  getClassesByStaff,
  getClassesType,
  getShiftColor,
  retrievePost,
  scheduleUpsert,
  scheduleUpsert1,
  scheduleUpsert2
} from '@/requet/api/ba/scheduleApi'
import {
  dateEndOf,
  dateStartOf,
  daysInMonth,
  diffDate,
  formatDate,
  plusMonth,
  str2Date
} from '@/utils/dateTime'
import editGradeTypePop from '@/components/aloneComponents/pg/duty/editGradeTypePop'
import monthSelectPop from '@/components/aloneComponents/pg/duty/monthSelectPop'

export default {
  name: 'ArrangeGrade',
  components: {
    dutyCondation,
    dutyTypes,
    dutyCalendar,
    editGradeTypePop,
    monthSelectPop,
    ElIconArrowLeft,
    ElIconArrowRight
  },
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      if (this.mode === 'edit') {
        next(false)
        this.$confirm('修改的信息尚未保存，是否确认离开?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          next()
        })
      } else {
        next() //必加
      }
    }, 200)
  },
  props: {
    propArrestList: Object,
    idkey: String,
    date: Object
  },
  emits: ['getdutyDate'],
  data() {
    return {
      showArrangeDialog: false,
      mode: 'view',
      dutyDate: null,
      shiftTypes: [],
      classesList: [],
      postClassesList: [],
      peopleDutys: [],
      peopleDutysCopy: [],
      currMonthDays: [],
      arrestList: [], //驻点
      defaultArrestList: [],
      routeQuery: {},
      loading: false,
      params: {
        month: null,
        postId: null,
        retrieveParam: null,
        staffId: null,
        year: null,
        arrestList: []
        // pageNum: 1,
        // pageSize: 20
      },
      pagination: {
        pageNo: 1,
        pageSize: 60
      },
      frozen: false,
      total: 0,
      shiftColorList: [],
      shiftColorsObj: {},
      kind: '',
      selectDate: ''
    }
  },
  watch: {
    date(val) {
      this.dutyDate = str2Date(this.date.year + '-' + this.date.month + '-01')
    },
    propArrestList: function (val, oldVal) {
      console.log('watch:', val)
      if (val && val.id) {
        this.arrestList = [val]
        this.defaultArrestList = [val]
        this.params.staffId = null
        this.routeQuery = {}
        this.$refs.dutyCondation.clearStaff()
        this.pagination.pageNo = 1
        this.condationChange()
      }
    },
    dutyDate: {
      deep: true,

      handler(newVal, oldVal) {
        let selectDate =
          formatDate(newVal, 'YYYY') + '/' + formatDate(newVal, 'MM')
        let selectDate2 =
          formatDate(oldVal, 'YYYY') + '/' + formatDate(oldVal, 'MM')
        if (selectDate !== selectDate2) {
          this.disabled()
        }
      }
    }
  },
  created() {},
  mounted() {
    // this.disabled()

    console.log(
      'load:',
      this.$route.query.arrestList,
      this.propArrestList,
      !!this.$route.query.arrestList
    )
    this.routeQuery = this.$route.query
    this.params.staffId = this.routeQuery.staffId
    this.dutyDate =
      this.routeQuery.date != undefined
        ? str2Date(this.routeQuery.date + '-01')
        : new Date()

    $emit(this, 'getdutyDate', {
      year: formatDate(this.dutyDate, 'YYYY'),
      month: formatDate(this.dutyDate, 'MM') + ''
    })
    //todo elseZD22063188测试用
    // {
    //   id: 'ZD22063188',
    //       name: '昆承湖'
    // }

    //如果路由没参数 取prop里的驻点
    this.arrestList = !!this.routeQuery.arrestList
      ? JSON.parse(decodeURI(this.routeQuery.arrestList))
      : !!this.propArrestList
      ? this.propArrestList
      : []
    this.defaultArrestList = JSON.parse(JSON.stringify(this.arrestList))

    // this.condationChange()
  },
  methods: {
    monthFormat_filter(val) {
      return formatDate(val, 'YYYY年MM月')
    },
    async disabled() {
      let creatYear = formatDate(new Date(), 'YYYY')
      let creatMonth = formatDate(new Date(), 'MM') + ''
      this.params.year = this.dutyDate
        ? formatDate(this.dutyDate, 'YYYY')
        : creatYear
      this.params.month = this.dutyDate
        ? formatDate(this.dutyDate, 'MM') + ''
        : creatMonth
      let query = {
        ...this.params,
        ...this.pagination
      }
      let dutyListData = await retrievePost(query)
      // console.log('dutyListData', dutyListData?.list)
      this.frozen = dutyListData?.frozen === 'Y'
    },
    condSearch() {
      this.pagination.pageNo = 1
      this.condationChange()
    },
    async openEdit() {
      console.log('openEdit:')
      await this.condationChange()
      this.mode = 'edit'
    },
    condationChange() {
      let condationData = this.$refs.dutyCondation

      this.params.year = formatDate(this.dutyDate, 'YYYY')
      this.params.month = formatDate(this.dutyDate, 'MM') + ''
      // this.$emit("getdutyDate", {year: this.params.year, month: this.params.month});
      let arrlist = []
      if (this.arrestList[0] != null) {
        arrlist.push(this.arrestList[0].id)
      }

      this.params = {
        year: this.params.year,
        month: this.params.month,
        arrestList: arrlist,
        postName: condationData.condation.postName,
        classesName: condationData.condation.classesName,
        staff: condationData.condation.staff,
        staffId: this.params.staffId
      }

      //不查整月数据
      if (
        this.params.arrestList.length > 0 ||
        this.params.classesName != null ||
        this.params.staff != null
      ) {
        this.init()
      }
    },
    async init() {
      this.shiftColorList = await getShiftColor()
      console.log('init', this.shiftColorList)
      await this.getClassesByArrest()

      let message = JSON.stringify(this.params)
      console.log(message)
      let shiftTypes = await getClassesType()

      let tempTyps = []
      for (const [index, item] of shiftTypes.entries()) {
        // shiftTypes.forEach(x => {
        //   tempTyps.push({
        //     name: x.shiftCatName,
        //     color: x.shiftCatColor
        //   })
        // })
        tempTyps.push({
          name: item.shiftCatName,
          color: this.shiftColorList[index % this.shiftColorList.length]
        })
      }
      this.shiftTypes = tempTyps
      // let dutyListData = this.params.arrestList.length > 0 ? await retrievePost(this.params) : []
      let query = {
        ...this.params,
        ...this.pagination
      }
      if (this.params.staff) {
        query.arrestList = null
        // query.postName = null
        // query.classesName = null
        query.staffId = null
        await this.getClassesByStaff()
      }
      this.loading = true
      let dutyListData = await retrievePost(query)
      this.loading = false
      // console.log('dutyListData', dutyListData?.list)
      this.frozen = dutyListData?.frozen === 'Y'
      this.total = dutyListData.list.total
      this.calCurrMonthDays(
        dutyListData?.list?.list ? dutyListData.list.list : []
      )
    },
    async getClassesByStaff() {
      console.log('getClassesByStaff')
      let condationData = this.$refs.dutyCondation

      this.params.year = formatDate(this.dutyDate, 'YYYY')
      this.params.month = formatDate(this.dutyDate, 'MM') + ''
      // this.$emit("getdutyDate", {year: this.params.year, month: this.params.month});
      // let arrlist = [];
      // if (this.arrestList[0] != null) {
      //   arrlist.push(this.arrestList[0].id);
      // }
      let res = await getClassesByStaff({
        year: this.params.year,
        month: this.params.month,
        // arrestList: arrlist,
        postName: condationData.condation.postName,
        classesName: condationData.condation.classesName,
        staff: condationData.condation.staff,
        staffId: this.params.staffId
      })
      let tempList = []
      if (res) {
        for (const [index, item] of res.entries()) {
          tempList.push({
            time: item.startTime + '-' + item.endTime,
            classesId: item.classesId,
            name: item.classesName,
            color: this.shiftColorList[index % this.shiftColorList.length]
          })
          // console.log(this.shiftColorList[index % this.shiftColorList.length]);
        }
      }
      this.classesList = tempList
      this.classesList.forEach(x => {
        this.shiftColorsObj[x.classesId] = x.color
      })
      console.log('aa', this.shiftColorsObj)
    },
    async getClassesByArrest() {
      console.log('getClassesByArrest')
      let arrlist = []
      if (!(this.arrestList.length > 0)) {
        return
      }
      this.arrestList.forEach(x => {
        if (x != null) {
          arrlist.push(x.id)
        }
      })
      let res = await getClassesByArrest(arrlist)
      let tempList = []
      if (res) {
        for (const [index, item] of res.entries()) {
          tempList.push({
            time: item.startTime + '-' + item.endTime,
            classesId: item.classesId,
            name: item.classesName,
            color: this.shiftColorList[index % this.shiftColorList.length]
          })
          // console.log(this.shiftColorList[index % this.shiftColorList.length]);
        }
      }
      this.classesList = tempList
      this.classesList.forEach(x => {
        this.shiftColorsObj[x.classesId] = x.color
      })
      console.log('bb', this.shiftColorsObj)
    },
    calCurrMonthDays(dutyListData) {
      const date = this.dutyDate
      this.currMonthDays = []
      let firstDay = this.$moment(date).startOf('month')
      for (let i = 0; i < daysInMonth(date); i++) {
        this.currMonthDays.push({
          dayNo: i + 1,
          date: this.$moment(firstDay).add(i, 'days')
        })
      }
      this.buildPeopleDuty(dutyListData)
    },
    buildPeopleDuty(data) {
      const date = this.dutyDate
      let currMonthDays = []
      let firstDay = this.$moment(date).startOf('month')
      for (let i = 0; i < daysInMonth(date); i++) {
        currMonthDays.push({
          dayNo: i + 1,
          date: formatDate(this.$moment(firstDay).add(i, 'days'))
        })
      }

      let pageAllList = []
      // console.log('pageList', this.dutyList)
      if (data) {
        for (const item of data) {
          let people = {
            ...item,
            postClassesList: this.postClassesList,
            dateList: []
          }

          let dateItemList = []
          let preMonthDay = item.dateList.find(x =>
            this.$moment(x.attendanceDate).isBefore(dateStartOf(this.dutyDate))
          )
          // console.log('preMonthDay', preMonthDay,formatDate(dateStartOf(this.dutyDate)))

          for (const [index, day] of currMonthDays.entries()) {
            // console.log('dateList', day)
            let findDay = item.dateList.find(x => x.attendanceDate === day.date)

            //处理上月日期 添加到1号
            if (preMonthDay && index === 0) {
              let diffDays = diffDate(day.date, preMonthDay.attendanceDate)
              // console.log('diffDays', diffDays)
              preMonthDay.shiftList.forEach(x => {
                x.classesDay2 = x.classesDay
                x.classesDay = Number(x.classesDay) - diffDays
              })
              let tempDay = findDay
              // console.log('findDay', findDay, tempDay)
              if (findDay) {
                findDay = preMonthDay
                findDay.shiftList.push(...tempDay.shiftList)
              } else {
                findDay = preMonthDay
              }
            }

            let dateItem = {}

            if (findDay) {
              let findClass = findDay.shiftList
                ? findDay.shiftList.find(x => x.classesId === findDay.classesId)
                : null
              console.log(
                'shiftCatColor::',
                this.shiftColorsObj,
                findClass.classesId,
                this.shiftColorsObj[findClass.classesId]
              )
              findClass.shiftCatColor = this.shiftColorsObj[findClass.classesId]
              // console.log('testa1', this.shiftColorsObj[findClass.classesId],findClass.classesId, this.shiftColorsObj.BC220900008329)
              // console.log('testa' + findClass.classesId + 'b')
              if (findDay.shiftList) {
                findDay.shiftList.forEach(x => {
                  x.shiftCatColor = this.shiftColorsObj[x.classesId]
                })
              }

              if (findClass) {
                dateItem = {
                  openPop: false,
                  show: true, //是否显示
                  ...findClass,
                  ...day,
                  ...findDay
                }
              } else {
                dateItem = {
                  openPop: false,
                  show: true,
                  ...day,
                  ...findDay
                }
              }
            } else {
              dateItem = {
                openPop: false,
                show: true,
                ...day
              }
            }

            // for (const shiftDay of item.dateList) {
            // }

            dateItemList.push(dateItem)
          }
          people.dateList = dateItemList
          pageAllList.push(people)
        }
      }

      this.dealDateItemShow(pageAllList)
    },
    //判断班次重叠显示问题
    dealDateItemShow(list) {
      for (const item of list) {
        let dateIndex = 0
        for (const temp of item.dateList) {
          // console.log('dateList', item.dateList, Number(temp.classesDay))
          let cellWidthNum = 1
          if (Number(temp.classesDay) > 0 && temp.show) {
            for (let i = 0; i < Number(temp.classesDay); i++) {
              // console.log('item', item, dateIndex + i + 1)
              // if((dateIndex + i + 1) != item.dateList.length){
              // console.log('show',temp.classesDay,dateIndex + i + 1, item.dateList[dateIndex + i + 1])
              if (item.dateList[dateIndex + i + 1]) {
                item.dateList[dateIndex + i + 1].show = false
                cellWidthNum++
              }
            }
          }
          dateIndex++
          temp.cellWidth = (cellWidthNum / item.dateList.length) * 100
        }
      }
      this.peopleDutys = list
      this.peopleDutysCopy = JSON.parse(JSON.stringify(list))
      // console.log('peopleDutys', this.peopleDutys)
    },
    handleSizeChange(val) {
      if (this.mode === 'edit') {
        this.$confirm(`修改的信息尚未保存，是否确认翻页?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.pagination.pageSize = val
          this.init()
        })
      } else {
        this.pagination.pageSize = val
        this.init()
      }
    },
    handleCurrentChange(val) {
      if (this.mode === 'edit') {
        this.$confirm(`修改的信息尚未保存，是否确认翻页?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.pagination.pageNo = val
          this.init()
        })
      } else {
        this.pagination.pageNo = val
        this.init()
      }
    },
    async calSave() {
      // console.log('calSave', this.$refs.dutyCalendar.$refs.dutyCalendarTable.localPeople)
      let params = []
      let startDate = formatDate(dateStartOf(this.dutyDate))
      let endDate = formatDate(dateEndOf(this.dutyDate))

      for (const item of this.$refs.dutyCalendar.$refs.dutyCalendarTable
        .localPeople) {
        for (const temp of item.dateList) {
          if (temp.shiftList) {
            for (const shift of temp.shiftList) {
              //处理上月跨日
              // console.log('addd', shift.attendanceDate === undefined)
              // console.log(shift.attendanceDate && !this.$moment(shift.attendance).isBefore(temp.date))
              let isPreMonthDay =
                shift.attendanceDate &&
                this.$moment(shift.attendanceDate).isBefore(temp.date)
              // console.log('lines:', shift.attendanceDate,
              //     this.$moment(shift.attendanceDate).isBefore(temp.date))
              // console.log('test:', shift.attendanceDate, temp.date, shift.schedulingId, temp.schedulingId)
              if (!isPreMonthDay) {
                let flag = this.isUpdateOrAddLine(
                  item.postId,
                  item.staffId,
                  temp.date,
                  shift.schedulingId ? shift.schedulingId : null
                )
                if (flag) {
                  console.log(
                    'isUpdateOrSendLine:',
                    flag,
                    item.staffId,
                    temp.date,
                    shift.schedulingId,
                    shift.classesId
                  )
                }
                // if (!isPreMonthDay || shift.attendanceDate === undefined) {
                let peopleLine = {
                  schedulingId: shift.schedulingId ? shift.schedulingId : null,
                  attendanteDate: temp.date,
                  classesId: shift.classesId,
                  staffId: item.staffId,
                  startTime: shift.startTime,
                  endTime: shift.endTime,
                  postId: item.postId,
                  staffName: item.staffName,
                  classesName: shift.classesName,
                  uptime: shift?.uptime ? shift.uptime : null,
                  classesDay:
                    temp.classesDay != null && temp.classesDay != undefined
                      ? temp.classesDay
                      : null
                }
                flag && params.push(peopleLine)
              }
            }
          }
        }
      }
      let delIds = this.getDelScheduleIds()
      this.params.year = formatDate(this.dutyDate, 'YYYY')
      this.params.month = formatDate(this.dutyDate, 'MM') + ''
      // console.log('delids:', delIds, params)
      if (delIds.length > 0) {
        this.$confirm('是否产生岗位空缺？', '排班删除提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          showClose: false,
          type: 'warning'
        })
          .then(() => {
            this.kind = 'Y'
            console.log('122', this.kind)
            this.deleteDutyFun(delIds, params, startDate, endDate)
            // await this.scheduleUpsert(params, startDate, endDate);
          })
          .catch(() => {
            this.kind = 'N'
            console.log('121', this.kind)
            this.deleteDutyFun(delIds, params, startDate, endDate)
            // await this.scheduleUpsert(params, startDate, endDate);
          })
      } else {
        await this.scheduleUpsert(params, startDate, endDate)
      }
      // console.log('delIds', delIds)
      // this.$notify({message: "保存成功", type: "success"});
    },
    //构建
    isUpdateOrAddLine(postId, staffId, date, schedulingId) {
      for (const people of this.peopleDutysCopy) {
        // console.log('peo0:', people.postId === postId, people.staffId === staffId, people)
        if (
          people.postId === postId &&
          people.staffId === staffId &&
          people.dateList
        ) {
          // console.log('peo:', people, date, schedulingId)
          for (const day of people.dateList) {
            if (day.shiftList) {
              for (const shift of day.shiftList) {
                if (
                  shift.attendanceDate === date &&
                  shift.schedulingId === schedulingId
                ) {
                  return false
                }
              }
            }
          }
        }
      }
      return true
    },
    async deleteDutyFun(delIds, params, startDate, endDate) {
      let res = await deleteDuty({
        kind: this.kind,
        list: delIds
      })
      console.log('deleteDutyFun:', res)
      if (res === 'Y') {
        this.$confirm('存在排班已打卡，是否删除这些排班？', '排班删除提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          showClose: false,
          type: 'warning'
        })
          .then(async () => {
            await this.deleteDutyFunConfirm(
              delIds,
              'Y',
              params,
              startDate,
              endDate
            )
          })
          .catch(async () => {
            await this.deleteDutyFunConfirm(
              delIds,
              'N',
              params,
              startDate,
              endDate
            )
          })
      } else {
        console.log('123')
        await this.deleteDutyFunConfirm(
          delIds,
          null,
          params,
          startDate,
          endDate
        )
      }
    },
    async deleteDutyFunConfirm(delIds, clock, params, startDate, endDate) {
      console.log('deleteDutyFunConfirm')
      if (clock != null) {
        await deleteDuty({
          kind: this.kind,
          list: delIds,
          clock: clock
        })
      }
      await this.scheduleUpsert(params, startDate, endDate)
    },
    async scheduleUpsert(params, startDate, endDate) {
      if (params?.length > 0) {
        scheduleUpsert(params, startDate, endDate)
          .then(res => {
            if (res?.kind == 'Y') {
              this.$confirm('是否填补岗位空缺？', '排班新增提示', {
                confirmButtonText: '是',
                cancelButtonText: '否',
                showClose: false,
                type: 'warning'
              })
                .then(async () => {
                  await scheduleUpsert1(params, startDate, endDate)
                  this.$message.success('保存成功')
                  this.$refs.dutyCondation.saveEnd()
                  await this.init()
                  this.mode = 'view'
                })
                .catch(async () => {
                  await scheduleUpsert2(params, startDate, endDate)
                  this.$message.success('保存成功')
                  this.$refs.dutyCondation.saveEnd()
                  await this.init()
                  this.mode = 'view'
                })
            } else {
              this.$message.success('保存成功')
              this.$refs.dutyCondation.saveEnd()
              this.init()
              this.mode = 'view'
            }
          })
          .catch(err => {
            this.$refs.dutyCondation.saveEnd()
            // this.init();
            // this.mode = "view";
          })
      } else {
        this.$message.success('保存成功')
        this.$refs.dutyCondation.saveEnd()
        this.init()
        this.mode = 'view'
      }
    },
    getDelScheduleIds() {
      let ids = []
      let editPeople =
        this.$refs.dutyCalendar.$refs.dutyCalendarTable.localPeople
      for (const [index1, person] of this.peopleDutysCopy.entries()) {
        for (const [index2, day] of person.dateList.entries()) {
          // console.log('shiftList', day.shiftList, day.date)
          if (day.shiftList) {
            let targetShifts = editPeople[index1].dateList[index2]?.shiftList
            // console.log('targetShifts', targetShifts)
            if (targetShifts) {
              for (const shift of day.shiftList) {
                //处理上月跨日
                if (
                  !shift.attendanceDate ||
                  !this.$moment(shift.attendanceDate).isBefore(day.date)
                ) {
                  let targetShift = targetShifts.find(
                    s => s.schedulingId === shift.schedulingId
                  )
                  // console.log("targetShift", targetShift);
                  if (targetShift == null) {
                    ids.push(shift.schedulingId)
                  }
                }
              }
            } else {
              day.shiftList.forEach(x => {
                ids.push(x.schedulingId)
              })
            }
          }
        }
      }
      return ids
    },
    getDelScheduleIds2() {
      var schedulingIdArr = []
      var schedulingIdArr2 = []
      let ids = []
      let editPeople =
        this.$refs.dutyCalendar.$refs.dutyCalendarTable.localPeople
      editPeople.forEach(e => {
        let dateList = e.dateList
        dateList.forEach(e => {
          if (e.shiftList) {
            let shiftList = e.shiftList
            shiftList.forEach(e => {
              console.log(e.schedulingId)
              schedulingIdArr.push(e.schedulingId)
            })
          }
        })
      })
      console.log(schedulingIdArr)
      this.peopleDutysCopy.forEach(e => {
        let dateList = e.dateList
        dateList.forEach(e => {
          if (e.shiftList) {
            let shiftList = e.shiftList
            shiftList.forEach(e => {
              schedulingIdArr2.push(e.schedulingId)
            })
          }
        })
      })
      console.log(schedulingIdArr2)
      this.isOpen = schedulingIdArr.toString() == schedulingIdArr2.toString()
      console.log(this.isOpen)
      for (const [index1, person] of this.peopleDutysCopy.entries()) {
        for (const [index2, day] of person.dateList.entries()) {
          // console.log('shiftList', day.shiftList, day.date)
          if (day.shiftList) {
            let targetShifts = editPeople[index1].dateList[index2]?.shiftList
            // console.log('targetShifts', targetShifts)
            if (targetShifts) {
              for (const shift of day.shiftList) {
                let schedulingId1 = []
                schedulingId1.push(shift.schedulingId)
                //处理上月跨日
                if (
                  !shift.attendanceDate ||
                  !this.$moment(shift.attendanceDate).isBefore(day.date)
                ) {
                  let targetShift = targetShifts.find(
                    s => s.schedulingId === shift.schedulingId
                  )
                  // console.log("targetShift", targetShift);
                  if (targetShift == null) {
                    ids.push(shift.schedulingId)
                  }
                }
              }
            } else {
              day.shiftList.forEach(x => {
                ids.push(x.schedulingId)
              })
            }
          }
        }
      }
      return ids
    },
    calCancel() {
      this.params.year = formatDate(this.dutyDate, 'YYYY')
      this.params.month = formatDate(this.dutyDate, 'MM') + ''
      this.init()
    },
    resetDuty() {
      for (const [index, item] of this.peopleDutys.entries()) {
        for (let [index2, day] of item.dateList.entries()) {
          if (day.attendanceDate == day.date || !day.attendanceDate) {
            this.peopleDutys[index].dateList[index2] = {
              cellWidth: (1 / item.dateList.length) * 100,
              date: day.date,
              dayNo: day.dayNo,
              openPop: day.openPop,
              show: true
            }
          } else {
            //上月跨日处理
            this.peopleDutys[index].dateList[index2].shiftList =
              this.peopleDutys[index].dateList[index2].shiftList.filter(x =>
                this.$moment(x.attendanceDate).isBefore(day.date)
              )
          }
        }
      }
      this.peopleDutys = JSON.parse(JSON.stringify(this.peopleDutys))
      // console.log(this.peopleDutys)
    },
    preMonth(months = -1) {
      if (this.mode != 'edit') {
        this.dutyDate = plusMonth(this.dutyDate, months)
        // console.log(formatDate(this.dutyDate))
        $emit(this, 'getdutyDate', {
          year: formatDate(this.dutyDate, 'YYYY'),
          month: formatDate(this.dutyDate, 'MM') + ''
        })
        this.condationChange()
      } else {
        this.$message({
          message: '排班时不可切换月份！',
          type: 'warning'
        })
      }
    },
    nextMonth(months = 1) {
      if (this.mode != 'edit') {
        this.dutyDate = plusMonth(this.dutyDate, months)
        $emit(this, 'getdutyDate', {
          year: formatDate(this.dutyDate, 'YYYY'),
          month: formatDate(this.dutyDate, 'MM') + ''
        })
        this.condationChange()
      } else {
        this.$message({
          message: '排班时不可切换月份！',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.arrangeBlock {
  padding: 10px;
  min-width: 1100px;
  background-color: white;
  .head {
    font-size: 18px;
    padding: 5px 0 5px 8px;

    .date {
      width: 50%;
      display: flex;
      align-items: center;
      padding-left: 20px;

      &-left {
        width: 30%;
        display: flex;
      }

      &-right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      &-icon {
        color: #367ce3;
        font-size: 22px;
        margin-right: 10px;
      }

      &-lab {
        margin-right: 10px;
        cursor: pointer;
      }

      &-step {
        margin-right: 5px;

        i {
          color: #367ce3;
          font-size: 16px;
          margin-right: 5px;
          cursor: pointer;
        }

        i:hover {
          color: blue;
          font-weight: bold;
        }
      }
    }
  }

  .content {
    height: 100%;
    border: 1px #eaeaeb solid;
    border-radius: 10px;
    min-height: 200px;
    padding: 20px 0 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }

  .pagination-block {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .paginationBlock {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 5px;
  }
}
</style>
