import { post } from '../../http'
export default {
  //编辑前进后退
  getPageEditLog(params) {
    return post('/ConfigureReports/MobileReportConfig/getPageEditLog', params)
  },
  //模块锁定和解锁
  setModuleLock(params) {
    return post('/ConfigureReports/MobileReportConfig/setModuleLock', params)
  },
  //解锁报表
  closeMobileReportConfig(params) {
    return post(
      '/ConfigureReports/BigScreenConfig/closeBigscreenConfig',
      params
    )
  },
  //设置报表人员权限
  setStaffPageAuthority(params) {
    return post(
      '/ConfigureReports/BigScreenConfig/setStaffPageAuthority',
      params
    )
  },
  //取报表所有组件sql
  getMobileReportConfigSql(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/getMobileReportConfigSql',
      params
    )
  },
  //报表预览数据接口
  getMobileReportRelease(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/getMobileReportRelease',
      params
    )
  },
  //配置报表环境列表
  getMobileReportList(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/getMobileReportList',
      params
    )
  },
  //新建报表
  creatMobileReporCfg(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/creatMobileReporCfg',
      params
    )
  },
  //删除报表
  removeMobileReporCfg(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/removeMobileReporCfg',
      params
    )
  },
  //配置环境报表数据接口
  getMobileReportCfg(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/getMobileReportCfg',
      params
    )
  },
  //添加模块
  submitAddMobileModule(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/submitAddMobileModule',
      params
    )
  },
  //删除模块
  removeMobileModule(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/removeMobileModule',
      params
    )
  },
  //更新模块配置
  setMobileModuleConfig(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/setMobileModuleConfig',
      params
    )
  },
  //更新报表配置
  setMobileMainConfig(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/setMobileMainConfig',
      params
    )
  },
  //删除组件
  removeMobileEchart(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/removeMobileEchart',
      params
    )
  },
  //添加组件
  submitAddMobileChart(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/submitAddMobileChart',
      params
    )
  },
  //更新组件配置
  setMobileEchartConfig(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/setMobileEchartConfig',
      params
    )
  },
  //复制模块
  setCopyMobileModule(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/setCopyMobileModule',
      params
    )
  },
  //移动模块位置
  changeModuleLocation(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/changeModuleLocation',
      params
    )
  },
  //更新报表预览图
  updateMbPreviewImg(params) {
    return post(
      '/ConfigureReports/BigScreenConfig/updateBigScreenPreviewImg',
      params
    )
  },
  //复制报表
  setCopyMobileReport(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/setCopyMobileReport',
      params
    )
  },
  //数据推送远程信息
  getBigScreenProjectServes() {
    return post('/ConfigureReports/BigScreenConfig/getBigScreenProjectServes')
  },
  //单报表数据推送执行
  setReleaseMobileReport(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/setReleaseMobileReport',
      params
    )
  },
  //单报表数据抽取
  setReleaseReverseEdition(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/setReleaseReverseEdition',
      params
    )
  },
  //组件实时数据
  getRealTimeDataForChart(params, name, sqlData) {
    if (sqlData && sqlData[3] == '') {
      if (sqlData[0] == 'B') {
        return post(sqlData[1], JSON.parse(sqlData[2]))
      } else {
        return post(
          '/ConfigureReports/MobileReportConfig/getRealTimeDataForChart',
          params
        )
      }
    } else if (name == 'mob')
      return post(
        '/ConfigureReports/MobileReportConfig/getRealTimeDataForChart',
        params
      )
    else
      return post(
        '/ConfigureReports/BigScreenConfig/getRealTimeDataForChart',
        params
      )
  },
  //组件sql查询校验
  CheckSqlSafety(params) {
    return post('/ConfigureReports/BigScreenConfig/CheckSqlSafety', params)
  },

  /**
   * 需要修改接口名的方法
   */
  //获取报表图片素材
  getReportPicSource(params) {
    return post('/ConfigureReports/BigScreenConfig/getScreenPicSource', params)
  },
  //获取组件分类
  getCommonChartType() {
    return post('/ConfigureReports/BigScreenConfig/getCommonChartType')
  },
  //获取组件库
  getAddScreenChartList(params) {
    return post(
      '/ConfigureReports/BigScreenConfig/getAddScreenChartList',
      params
    )
  },
  //取报表项目文件中所有图片
  getMyBigScreenAllImages(params) {
    return post(
      '/ConfigureReports/BigScreenConfig/getMyBigScreenAllImages',
      params
    )
  },
  //报表图片推送
  setReleaseScreenImages(params) {
    return post(
      '/ConfigureReports/BigScreenConfig/setReleaseScreenImages',
      params
    )
  },
  //组件库列表
  getCommonChartList(params) {
    return post('/ConfigureReports/BigScreenConfig/getCommonChartList', params)
  },
  //报表素材库
  getScreenPicSource(params) {
    return post('/ConfigureReports/BigScreenConfig/getScreenPicSource', params)
  },
  //组件sql批量操作
  setEchartBaseSql(params) {
    return post('/ConfigureReports/BigScreenConfig/setEchartBaseSql', params)
  },
  //删除素材
  removeMaterial(params) {
    return post('/ConfigureReports/BigScreenConfig/removeMaterial', params)
  },
  //下钻组件数据
  getHierarchyData(params) {
    return post('/ConfigureReports/BigScreenConfig/getHierarchyData', params)
  },
  //筛选更新数据
  getRetrievalData(params) {
    return post('/ConfigureReports/BigScreenConfig/getRetrievalData', params)
  },
  //手机端筛选更新数据
  getRetrievalDataMobile(params) {
    return post('/ConfigureReports/MobileReportConfig/getRetrievalData', params)
  },
  //筛选更新数据
  getHierarchyGroupList(params) {
    return post(
      '/ConfigureReports/BigScreenConfig/getHierarchyGroupList',
      params
    )
  },
  //大屏转手机端
  bigScreenToMobileReport(params) {
    return post(
      '/ConfigureReports/MobileReportConfig/bigScreenToMobileReport',
      params
    )
  },
}
