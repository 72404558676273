<template>
  <div>
    <div class="ei_icon flexrowbetween">
      <span style="font-size: 14px">{{ title }}</span>
      <div v-if="title != '岗位选取'">
        <el-icon v-if="updown" @click="expandAll(true);updown=false">
          <el-icon-arrow-down />
        </el-icon>
        <el-icon v-else @click="expandAll(false);updown=true">
          <el-icon-arrow-up />
        </el-icon>
        <el-icon @click="getInitTree">
          <el-icon-refresh />
        </el-icon>
      </div>
    </div>
    <div class="input">
      <el-input
        v-model="input"
        class="input-with-select"
        placeholder="请输入内容"
        @keydown.enter="search"
      >
        <template #append>
          <el-button :icon="ElIconSearch" @click="search()" />
        </template>
      </el-input>
    </div>
    <div class="ei_tree tree-container">
      <el-tree
        ref="tree"
        v-loading="treeload"
        :data="menuList"
        :default-expanded-keys="['40']"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        :props="props"
        node-key="id"
        @node-click="handleNodeClick"
      >
        <template #default="{ node }">
          <span class="custom-tree-node">
            <span class="wordStyle">
              <el-icon v-if="node.level==1"><el-icon-place /></el-icon>
              <el-icon v-if="node.level==2"><el-icon-location /></el-icon>
              <el-icon v-if="node.level==3"
                ><el-icon-office-building
              /></el-icon>
              {{ node.label }}
            </span>
          </span>
        </template>
      </el-tree>
    </div>
  </div>
</template>

<script>
import {
  ArrowDown as ElIconArrowDown,
  ArrowUp as ElIconArrowUp,
  Refresh as ElIconRefresh,
  Place as ElIconPlace,
  Location as ElIconLocation,
  OfficeBuilding as ElIconOfficeBuilding,
  Search as ElIconSearch,
} from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
import { mapMutations } from 'vuex'
import { shallowRef } from "vue";

export default {
  components: {
    ElIconArrowDown,
    ElIconArrowUp,
    ElIconRefresh,
    ElIconPlace,
    ElIconLocation,
    ElIconOfficeBuilding,
  },
  props: {
    moduleid: String,
    props: Object,
    title: String,
    faId: String,
    allshow: String,
  },
  data() {
    return {
      input: '',
      menuList: [],
      mainform: '',
      // faId:'',
      item: {},
      //树形加载loading
      treeload: false,
      //树形展开收缩标记
      updown: false,
      actClick: {},
      typelist: [],
      ElIconSearch: shallowRef(ElIconSearch),
    }
  },
  watch: {
    moduleid(newnew, oldold) {
      console.log('moduleid' + this.moduleid)
      this.getInitTree()
    },
  },
  mounted() {
    if (this.allshow == 'true') {
      this.getInitTreeall()
    }
    // this.getInitTree();
  },
  methods: {
    ...mapMutations(['setEmployInfo']),
    search() {
      this.$refs.tree.filter(this.input)
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    expandAll(bool) {
      console.log(this.$refs.tree.store)
      var nodes = this.$refs.tree.store.nodesMap
      for (var i in nodes) {
        if (nodes[i].level == 1) nodes[i].expanded = bool
      }
    },
    handleNodeClick(data) {
      console.log(data)
      this.actClick = data
      let obj = {}
      if (data.children == null) {
        obj = {
          id: data.id,
          name: data.name,
          faId: this.faId,
          operateType: 'equal',
        }
      } else {
        //获取点击层级的所有子级信息，并进行拼接
        let idList = [data.id],
            nameList = [data.name]
        let res = this.reList(data.children)
        obj = {
          id: idList.concat(res[0]).join(','),
          name: idList.concat(res[1]).join(','),
          faId: this.faId,
          operateType: 'exist',
        }
      }
      this.setEmployInfo(obj)
      //给调度中心页面弹窗传驻点id
      if (data.children == null) {
        $emit(this, 'getactClickdataid', data.id)
      }
      $emit(this, 'getactClickdata', this.actClick)
    },
    //根据菜单子级数组 获取子级所有的id和name
    reList(list) {
      let list1 = [],
          list2 = []
      for (let i = 0; i < list.length; i++) {
        list1.push(list[i].id)
        list2.push(list[i].name)
        if (list[i].children != null) {
          let res = this.reList(list[i].children)
          list1 = list1.concat(res[0])
          list2 = list2.concat(res[1])
        }
      }
      return [list1, list2]
    },
    //只显示到驻点
    getInitTree() {
      let param = {
        moduleid: this.moduleid,
      }
      this.treeload = true
      this.$postJson('/ConfigureReports/Tree/getTree', param).then((res) => {
        this.treeload = false
        if (res.data) {
          this.mainform = res.data.mainform //主表单模块Id
          // this.faId = res.data.subformlinkfield //筛选关联Id
          console.log(res.data.treeList)
          this.menuList = res.data.treeList
          //去除多余的数据，只显示到驻点数据
          this.typelist = []
          this.typelist.push(this.menuList[0].name)
          this.menuList[0].children.forEach((element) => {
            console.log('element:', element)
            this.typelist.push(element.name)
            // element.children.forEach((m) => {
            //   m.children = null
            // })
          })
          // console.log('typelist',this.typelist)
          $emit(this, 'gettypelistdata', this.typelist)
          //打开不可关闭的表单主页
          this.item = {
            menuid: this.$route.query.menuid,
            type: 'table',
            title: res.data.modulename,
            moduleid: res.data.subform,
            isTt: true,
            noClose: true,
          }
          this.flag = true
        } else {
          this.$notify.error({
            title: res.errMsg,
            message: res.errMsgDetail,
            duration: 0,
          })
        }
      })
    },
    //显示到岗位
    getInitTreeall() {
      let param = {
        moduleid: this.moduleid,
      }
      this.treeload = true
      this.$postJson('/ConfigureReports/Tree/getTree', param).then((res) => {
        this.treeload = false
        if (res.data) {
          this.mainform = res.data.mainform //主表单模块Id
          console.log(res.data.treeList)
          this.menuList = res.data.treeList
          //去除多余的数据，只显示到驻点数据
          this.typelist = []
          this.typelist.push(this.menuList[0].name)
          this.menuList[0].children.forEach((element) => {
            this.typelist.push(element.name)
            element.children.forEach((m) => {
              // m.children = null;
              // console.log(m);
            })
          })
          // console.log('typelist',this.typelist)
          $emit(this, 'gettypelistdata', this.typelist)
          //打开不可关闭的表单主页
          this.item = {
            menuid: this.$route.query.menuid,
            type: 'table',
            title: res.data.modulename,
            moduleid: res.data.subform,
            isTt: true,
            noClose: true,
          }
          this.flag = true
        } else {
          this.$notify.error({
            title: res.errMsg,
            message: res.errMsgDetail,
            duration: 0,
          })
        }
      })
    },
  },
  emits: ['getactClickdataid', 'getactClickdata', 'gettypelistdata'],
}
</script>

<style lang="scss" scoped>
.input {
  width: 90%;
  margin-left: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.ei_icon {
  padding: 2% 4%;
  box-sizing: border-box;

  i {
    margin-right: 10px;
  }
}

.el-input-group {
  width: 100%;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.ei_tree {
  width: 100%;
  height: 80%;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 0 auto;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4px;
  padding-right: 8px;
}

.wordStyle {
  font-size: 1.4px;
}

.tree-container :deep .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.tree-container :deep .el-icon-caret-right:before {
  content: '\e791';
  font-size: 1.4px;
}

.tree-container :deep .el-tree-node__expand-icon {
  margin-left: 2%;
  padding: 0px;
}

.tree-container :deep .el-tree-node__expand-icon.is-leaf {
  margin-left: 0px;
}

.tree-container :deep .el-tree-node {
  position: relative;
  padding-left: 6%;
}

.tree-container :deep .el-tree-node__children {
  padding-left: 4%;
}

.tree-container :deep .el-tree-node:after {
  border-top: 1px solid #e6e6e6;
  height: 2vh;
  top: 2vh;
  width: 7%;
}

@media screen and (min-width: 0px) {
  .tree-container :deep .el-tree-node__children {
    padding-left: 3%;
  }
  .tree-container :deep .el-tree-node {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep .el-tree-node:after {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 10%;
  }
}

@media screen and (min-width: 230px) {
  .tree-container :deep .el-tree-node__children {
    padding-left: 2%;
  }
  .tree-container :deep .el-tree-node {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep .el-tree-node:after {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 8.5%;
  }
}

@media screen and (min-width: 350px) {
  .tree-container :deep .el-tree-node__children {
    padding-left: 1.5%;
  }
  .tree-container :deep .el-tree-node {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep .el-tree-node:after {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 5%;
  }
}

@media screen and (min-width: 500px) {
  .tree-container :deep .el-tree-node__children {
    padding-left: 1%;
  }
  .tree-container :deep .el-tree-node {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep .el-tree-node:after {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 4%;
  }
}

@media screen and (min-width: 700px) {
  .tree-container :deep .el-tree-node__children {
    padding-left: 0.6%;
  }
  .tree-container :deep .el-tree-node {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep .el-tree-node:after {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 8%;
  }
}

.tree-container :deep .el-tree > .el-tree-node:before {
  border-left: none;
}

.tree-container :deep .el-tree > .el-tree-node:after {
  border-top: none;
}

.tree-container :deep .el-tree > .el-tree-node:before {
  border-left: none;
}

.tree-container :deep .el-tree > .el-tree-node:after {
  border-top: none;
}

.tree-container :deep .el-tree-node:before {
  content: '';
  left: 2%;
  position: absolute;
  right: auto;
  border-width: 1px;
}

.tree-container :deep .el-tree-node:after {
  content: '';
  left: 2%;
  position: absolute;
  right: auto;
  border-width: 1px;
}

.tree-container :deep .el-tree-node:before {
  border-left: 1px solid #e6e6e6;
  bottom: 0px;
  height: 100%;
  top: -1vh;
  width: 1px;
}

.el-tree-node :last-child:before {
  height: 5vh;
}

.tree-container {
  margin: 0px;
}

.tree-container :deep .el-tree .el-tree-node {
  position: relative;
}

:deep .el-tree-node__content {
  background-color: rgba(0, 0, 0, 0);
  margin: 0px 0px;
  padding: 0px 0px;
}

.tree-container :deep .el-tree-node .el-tree-node__content {
  height: 4vh;
  padding-left: 0 !important;
}

.tree-container :deep .el-tree-node .el-tree-node__content::before {
  border-left: 1px solid #e6e6e6;
  height: 100%;
  top: 0;
  width: 1px;
  margin-left: 1px;
  margin-top: 0px;
  z-index: 8;
}

.tree-container
:deep
.el-tree-node
.el-tree-node__children
.el-tree-node__content::before {
  border-left: 0px solid #e6e6e6;
  height: 100%;
  top: 0;
  width: 1px;
  margin-left: 1px;
  margin-top: 0px;
  z-index: 8;
}

.tree-container :deep .el-tree-node .el-tree-node__content::after {
  border-top: 1px solid #e6e6e6;
  height: 1px;
  top: 2vh;
  width: 1.5%;
  margin-left: 1px;
  z-index: 8;
}

.tree-container
:deep
.el-tree-node
.el-tree-node__children
.el-tree-node__content::after {
  border-top: 0px solid #e6e6e6;
}

.tree-container .el-tree-node .el-tree-node__content::before,
.tree-container .el-tree-node .el-tree-node__content::after {
  content: '';
  position: absolute;
  right: auto;
}
</style>
