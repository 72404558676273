<template>
  <div class="scoreDemo">
    <div
      v-for="(item, index) in scoreArr"
      :key="index"
      class="scoringDetailslist"
    >
      <div class="img">
        <div>
          <img :src="item.icon" style="width: 3.2vw" />
        </div>
        <div>
          <el-icon><el-icon-more-outline /></el-icon>
        </div>
      </div>
      <div class="type">
        <span>{{ item.text }}</span>
      </div>
      <div v-if="item.type !== 'a3'" class="progress">
        <el-progress
          :show-text="false"
          :text-inside="true"
          :stroke-width="16"
          :percentage="item.percentage"
          :class="item.type"
        />
      </div>
      <div v-if="item.type !== 'a3'" class="score">
        <span class="flexCenter" style="font-size: 10px">得分</span>
        <div class="flexCenter">
          <p
            style="font-weight: 700; font-size: 20px"
            :style="item.number < 0 ? 'color:red' : 'color:black'"
          >
            {{ item.number }}
          </p>
          <p style="font-size: 15px">分</p>
        </div>
      </div>
      <div
        v-if="item.type == 'a3'"
        style="width: 70%; height: 20%; display: flex; flex-direction: column"
      >
        <div style="display: flex; justify-content: space-between">
          <div class="flexCenter" style="font-size: 10px">
            <span
              style="
                background-color: #3c80f7;
                width: 10px;
                height: 10px;
                border-radius: 50%;
              "
            />
            <span>加分</span>
          </div>
          <div class="flexCenter">
            <p
              style="font-weight: 700; font-size: 20px"
              :style="item.reward < 0 ? 'color:red' : 'color:black'"
            >
              {{ item.reward }}
            </p>
            <p style="font-size: 15px">分</p>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div class="flexCenter" style="font-size: 10px">
            <span
              style="
                background-color: #f47862;
                width: 10px;
                height: 10px;
                border-radius: 50%;
              "
            />
            <span>扣分</span>
          </div>
          <div class="flexCenter">
            <p
              style="font-weight: 700; font-size: 20px"
              :style="item.punish < 0 ? 'color:red' : 'color:black'"
            >
              {{ item.punish }}
            </p>
            <p style="font-size: 15px">分</p>
          </div>
        </div>
      </div>
    </div>
    <scoreCard ref="dialog" :type="type" :data-arr-msg="dataArrMsg" />
  </div>
</template>

<script>
import { More as ElIconMore } from '@element-plus/icons-vue'
import scoreCard from './scoreCard.vue'

export default {
  name: 'ScoringDetailslist',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    scoreCard,
    ElIconMore
  },
  props: ['item', 'scoreArr', 'dataArr'],
  data() {
    return {
      type: '',
      dataArrMsg: ''
    }
  },
  watch: {
    dataArr: {
      deep: true,

      handler: function (newVal, oldVal) {
        this.dataArrMsg = newVal
      }
    }
  },
  methods: {
    openDialog(text) {
      this.$refs.dialog.show()
      this.type = text
      console.log(this.type)
    }
  }
}
</script>

<style lang="scss" scoped>
.scoreDemo {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
}
.scoringDetailslist {
  width: 100%;
  border-radius: 8px;
  border-right: 1px solid rgb(240, 240, 240);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img {
  width: 70%;
  height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.type {
  width: 70%;
  height: 30%;
  display: flex;
  //flex-direction: flex;
  align-items: center;
}
.progress {
  width: 70%;
  height: 20%;
}
.score {
  width: 70%;
  height: 20%;
  display: flex;
  justify-content: space-between;
}
.flexCenter {
  display: flex;
  align-items: center;
}
:deep(.a1) {
  .el-progress-bar__inner {
    background-image: linear-gradient(to right, #78cdf7, #5996eb);
  }
}
:deep(.a2) {
  .el-progress-bar__inner {
    background-image: linear-gradient(to right, #fdda8f, #fabb5d);
  }
}
:deep(.a3) {
  .el-progress-bar__inner {
    background-image: linear-gradient(to right, #fd948f, #ee726e);
  }
}
</style>
