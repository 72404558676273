import { getJson, postBlob, postJson } from '../../../http'

import { Notify } from 'vant'

function checkResult(res) {
  res.then((r) => {
    if (r.errCode != null && r.errCode.length > 0) {
      Notify(r.errMsg + ':' + r.errMsgDetail ? r.errMsgDetail : '')
    }
  })
  return res
}

export function uploadFile(file,filePath) {
  let formData = new FormData()
  formData.append('file', file)
  formData.append('filePath', filePath)
  console.log('formData', formData, file)
  return checkResult(postBlob('/file/minio/uploadFile', formData))
}
