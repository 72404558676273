import { post, fetch, patch, put, postJson } from '../../http'
export default {
  //查询首页预览
  getFPConfigViewList() {
    return post('/ConfigureReports/homepage/getFPConfigViewList')
  },
  //获取首页组件列表
  getFPItemList() {
    return post('/ConfigureReports/homepage/getFPItemList')
  },
  //保存首页配置
  saveFPConfig() {
    return postJson('/ConfigureReports/homepage/saveFPConfig')
  },
  //保存首页预览
  saveFPConfigView(params) {
    return postJson('/ConfigureReports/homepage/saveFPConfigView', params)
  },
  //保存快速菜单
  saveFPQuickMenuGroup(params) {
    return postJson('/ConfigureReports/homepage/saveFPQuickMenuGroup', params)
  },
  //查询快速菜单
  getFPQuickMenuGroup(params) {
    return post('/ConfigureReports/homepage/getFPQuickMenuGroup', params)
  },
  //删除快速菜单
  delFPQuickMenuGroup(params) {
    return post('/ConfigureReports/homepage/delFPQuickMenuGroup', params)
  },
  //查询首页配置
  getFPConfig() {
    return post('/ConfigureReports/homepage/getFPConfig')
  },

  //查询统计数据
  getFPDTInfoLList(params) {
    return post('/ConfigureReports/homepage/getFPDTInfoLList', params)
  },
}
