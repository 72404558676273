<template>
  <div class="faceIndexBlock">
    <!--    <img :src="img" alt="">-->
    <div class="filesBlock">
      <div
        v-for="(file, index) in fileList"
        class="filesBlock-item"
        @mouseenter="menter(index)"
        @mouseleave="mleave(index)"
      >
        <img
          :src="
            file.url
              ? file.url
              : ImgbaseURL + '/' + file.bucketName + '/' + file.fileName
          "
          alt=""
          class="filesBlock-item-img"
        />
        <div v-show="file.ck" class="filesBlock-del">
          <el-icon class="filesBlock-delicon" @click="del(index)">
            <el-icon-delete />
          </el-icon>
        </div>
      </div>
    </div>
    <!--    <el-button @click="startPhoto">拍照</el-button>-->
    <div class="photoBtn" @click="startPhoto">
      <el-icon class="photoBtn-icon">
        <el-icon-camera />
      </el-icon>
    </div>
    <el-dialog
      v-model="dialogVisible"
      append-to-body
      width="400px"
      @closed="videoClose"
      @opened="initVideo"
    >
      <!--      style="height: 600px"-->
      <div>
        <el-button
          :type="mode === 'camera' ? 'primary' : ''"
          @click="changeMode('camera')"
        >
          高拍仪<el-icon class="el-icon--right">
            <el-icon-video-camera />
          </el-icon>
        </el-button>
        <el-button
          :type="mode === 'file' ? 'primary' : ''"
          @click="changeMode('file')"
        >
          上传<el-icon class="el-icon--right">
            <el-icon-upload />
          </el-icon>
        </el-button>
        <div v-show="mode === 'camera'">
          <div class="titleTxt">
            <p class="titleTxt-val" v-html="data.info" />
          </div>
          <div style="text-align: center">
            <div class="videoBlock" style="">
              <!--        border-radius: 50%-->
              <video
                id="videoCamera"
                :ref="refName"
                autoplay
                class="videoCamera"
                muted
                playsinline
                style="
                  width: 300px;
                  height: 100%;
                  border-radius: 5px;
                  z-index: 9999;
                "
                webkit-playsinline
              />
            </div>

            <div style="position: absolute; z-index: -1; display: none">
              <canvas id="overlay" style="width: 640px; height: auto" />
            </div>
          </div>
        </div>
        <div v-show="mode === 'file'" style="margin-top: 20px">
          <el-upload
            :http-request="handleUpload"
            accept=".png,.jpeg,.jpg"
            action="#"
            class="upload-demo"
            drag
            multiple
          >
            <el-icon><el-icon-upload /></el-icon>
            <div class="el-upload__text">
              将图片拖到此处，或<em>点击上传</em>
            </div>
            <template #tip>
              <div class="el-upload__tip">只能上传jpg/jpeg/png图片</div>
            </template>
          </el-upload>
        </div>
      </div>
      <template #footer>
        <span v-show="mode === 'camera'" class="dialog-footer" type="primary">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="photo">拍 照</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  Delete as ElIconDelete,
  Camera as ElIconCamera,
  VideoCamera as ElIconVideoCamera,
  Upload as ElIconUpload
} from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
// import * as faceapi from 'face-api.js'
// import "babel-polyfill"

import { base64toFile } from '@/utils/file-utils'
import { uploadFile } from '@/requet/api/common/fileApi'
import * as faceapi from 'face-api.js'
import * as imageConversion from 'image-conversion'

export default {
  name: 'JsCamera',
  components: {
    ElIconDelete,
    ElIconCamera,
    ElIconVideoCamera,
    ElIconUpload
  },
  beforeRouteLeave(to, from, next) {
    this.videoClose()
    next()
  },
  props: ['showFileList'],
  emits: ['change'],
  data() {
    return {
      refName: 'videoCamera',
      mode: 'camera', //file camera
      // displaySize: {width: 240, height: 380},
      displaySize: { width: 1000, height: 1000 },
      timer: null,
      video: '',
      nowStep: 1,
      data: {
        // 提示语
        info: '请在取景框内'
      },
      img: '',
      dialogVisible: false,
      fileList: []
    }
  },
  watch: {
    showFileList: {
      handler(val) {
        let arr = []
        for (const item of val) {
          arr.push({
            ...item,
            ck: false
          })
        }
        this.fileList = arr
        console.log(this.fileList, 'this.fileList=++++')
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    let time = new Date()
    this.refName = this.refName + time.getTime()
  },
  mounted() {},
  methods: {
    changeMode(type) {
      this.mode = type
      console.log('mode:', this.mode)
      if (type === 'file') {
        this.videoClose()
      } else {
        this.initVideo()
      }
    },
    startPhoto() {
      this.mode = 'camera'

      this.dialogVisible = true
    },
    async handleUpload(file) {
      console.log('files', file)
      let res = await uploadFile(file.file)
      this.fileList.push({
        ...res.data,
        ck: false
      })
      $emit(this, 'change', this.fileList)
      this.dialogVisible = false
    },
    initVideo() {
      // console.log('initVideo', document.getElementById('videoCamera'))
      // console.log('initVideo1', document.getElementsByClassName('videoCamera'))
      // console.log('initVideo2', this.$refs[this.refName])
      // this.video = document.getElementById('videoCamera')
      this.video = this.$refs[this.refName]

      this.startVideo()

      this.video.addEventListener('play', () => {
        let canvas = document.getElementById('overlay')
        faceapi.matchDimensions(canvas, this.displaySize)
        this.timer = setInterval(async () => {
          canvas
            .getContext('2d')
            .clearRect(0, 0, this.displaySize.width, this.displaySize.height)
        }, 500)
      })
    },
    videoClose() {
      clearInterval(this.timer)
      if (this.video?.srcObject?.getTracks()) {
        this.video?.srcObject?.getTracks().forEach(t => t.stop())
      }
    },
    startVideo() {
      let _this = this

      // 老的浏览器可能根本没有实现 mediaDevices，所以我们可以先设置一个空的对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }

      // 一些浏览器部分支持 mediaDevices。我们不能直接给对象设置 getUserMedia
      // 因为这样可能会覆盖已有的属性。这里我们只会在没有 getUserMedia 属性的时候添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先，如果有 getUserMedia 的话，就获得它
          let getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia

          // 一些浏览器根本没实现它 - 那么就返回一个 error 到 promise 的 reject 来保持一个统一的接口
          if (!getUserMedia) {
            return Promise.reject(
              new Error('getUserMedia is not implemented in this browser')
            )
          }

          // 否则，为老的 navigator.getUserMedia 方法包裹一个 Promise
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }

      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            // width: {min: 450, ideal: 1000, max: 3000},
            // height: {min: 450, ideal: 1000, max: 3000}
            width: 1000,
            height: 1000
          }
        })
        .then(stream => {
          _this.video.srcObject = stream
          _this.video.onloadedmetadata = function (e) {
            _this.video.play()
          }
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    menter(index) {
      console.log('entry')
      this.fileList[index].ck = true
      this.fileList = JSON.parse(JSON.stringify(this.fileList))
    },
    mleave(index) {
      console.log('leave')
      this.fileList[index].ck = false
      this.fileList = JSON.parse(JSON.stringify(this.fileList))
    },
    del(index) {
      this.fileList.splice(index, 1)

      $emit(this, 'change', this.fileList)
    },
    async photo() {
      let canvas = document.getElementById('overlay')
      let context = canvas.getContext('2d')
      let video = this.$refs[this.refName]
      context.drawImage(
        video,
        0,
        0,
        this.displaySize.width,
        this.displaySize.height
      )
      // context.drawImage(video, 0, 0, 1000, 1000)
      this.img = canvas.toDataURL('image/jpeg')
      // console.log('base64', base64toFile(this.img))

      let afile = null
      let imgFile = base64toFile(this.img)
      console.log('size:', imgFile.size, imgFile.size / 1024 / 1024 < 0.2)
      // if(imgFile.size > 5000000) {
      let isLt5M = imgFile.size / 1024 / 1024 < 5
      if (!isLt5M) {
        imageConversion.compressAccurately(imgFile, 5000).then(async res => {
          // console.log(res)
          res = new File([res], imgFile.name, {
            type: res.type,
            lastModified: Date.now()
          })
          console.log('压缩后', res)
          afile = res
          //上传图片
          let upRes = await uploadFile(afile)
          this.fileList.push({
            ...upRes.data,
            ck: false
          })
          $emit(this, 'change', this.fileList)
          this.dialogVisible = false
        })
      } else {
        //上传图片
        let upRes = await uploadFile(imgFile)
        this.fileList.push({
          ...upRes.data,
          ck: false
        })
        $emit(this, 'change', this.fileList)
        this.dialogVisible = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.faceIndexBlock {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  overflow-y: hidden;

  .filesBlock {
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    position: relative;

    &-item {
      width: 65px;
      height: 65px;
      border-radius: 5px;
      margin: 0 3px 3px 0;
      position: relative;

      &-img {
        width: 100%;
        height: auto;
        background-size: cover;
      }
    }

    &-del {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: default;
      text-align: center;
      color: #fff;
      opacity: 0.9;
      font-size: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9;
      border-radius: 5px;
    }

    &-delicon {
      //position: absolute;
      //top: 44%;
      //left: 45%;
      color: white;
    }

    //&-item:hover {
    //  background-color: rgba(0,0,0,.5);
    //  transition: opacity .3s;
    //  z-index: 99;
    //}
  }

  .photoBtn {
    border: #999999 dashed 1px;
    border-radius: 5px;
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-icon {
      font-size: 27px;
    }
  }
}

.titleTxt {
  text-align: center;
  height: 50px;
  line-height: 50px;

  &-val {
    //color:red;
    font-size: 20px;
    font-weight: 550;
  }
}

.titleTxt2 {
  text-align: center;
  height: 30px;
  line-height: 30px;

  &-val {
    color: #b6b6b6;
    font-size: 15px;
    color: #b6b6b6;
    margin-top: 10px;
  }
}

.videoBlock {
  height: 300px;
  padding: 20px 0 20px 0;
  //background-image: url("../../../../assets/imgs/pg/clock/videobg.png");
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

#video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari 和 Chrome */
  -moz-transform: rotateY(180deg);
}

:deep(.el-upload-list) {
  display: none;
}

/*#canvas2 {*/
/*  width: 99%;*/
/*  height: 94%;*/
/*  z-index: -100;*/
/*  position: absolute;*/
/*}*/
</style>
