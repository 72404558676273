<template>
  <div class="all">
    <div class="input">
      <el-input
        id="suggestId"
        v-model="Addaddress"
        style="width: 30%; height: auto; margin-bottom: 2%"
        model-value="百度"
        placeholder="请输入地址"
      />
      <div>
        <span style="margin-right: 3%; font-weight: bold"
          ><span>{{ oldtext }}</span
          >：经度：{{ lon }}，纬度：{{ lat }}</span
        >
      </div>
      <div>
        <span v-if="show" style="margin-right: 3%; font-weight: bold"
          ><span>{{ newtext }}</span
          >：经度：{{ lon1 }}，纬度：{{ lat1 }}</span
        >
      </div>
      <el-button class="btn" style="margin-right: 100px" @click="goback()">
        返回
      </el-button>
      <el-button class="btn" :disabled="!show" @click="btnchange()">
        经纬度更新
      </el-button>
    </div>
    <div :id="id" class="map" />
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../../utils/gogocodeTransfer'
import { BMPGL } from '@/assets/js/bmpgl.js'
import { updateLocation } from '@/requet/api/dialogMap.js'

//定义一些常量
var x_PI = (3.14159265358979324 * 3000.0) / 180.0
var PI = 3.1415926535897932384626
var a = 6378245.0
var ee = 0.00669342162296594323

//百度坐标系 (BD-09) 与 火星坐标系 (GCJ-02)的转换
function bd09togcj02(bd_lon, bd_lat) {
  var x_pi = (3.14159265358979324 * 3000.0) / 180.0
  var x = bd_lon - 0.0065
  var y = bd_lat - 0.006
  var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi)
  var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi)
  var gg_lng = z * Math.cos(theta)
  var gg_lat = z * Math.sin(theta)
  return [gg_lng, gg_lat]
}

//判断是否在国内，不在国内则不做偏移
function out_of_china(lng, lat) {
  return (
    lng < 72.004 || lng > 137.8347 || lat < 0.8293 || lat > 55.8271 || false
  )
}

//GCJ02 转换为 WGS84
function gcj02towgs84(lng, lat) {
  if (out_of_china(lng, lat)) {
    return [lng, lat]
  } else {
    var dlat = transformlat(lng - 105.0, lat - 35.0)
    var dlng = transformlng(lng - 105.0, lat - 35.0)
    var radlat = (lat / 180.0) * PI
    var magic = Math.sin(radlat)
    magic = 1 - ee * magic * magic
    var sqrtmagic = Math.sqrt(magic)
    dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI)
    dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI)
    var mglat = lat + dlat
    var mglng = lng + dlng
    return [lng * 2 - mglng, lat * 2 - mglat]
  }
}

//经度转换
function transformlat(lng, lat) {
  var ret =
    -100.0 +
    2.0 * lng +
    3.0 * lat +
    0.2 * lat * lat +
    0.1 * lng * lat +
    0.2 * Math.sqrt(Math.abs(lng))
  ret +=
    ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) *
      2.0) /
    3.0
  ret +=
    ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) /
    3.0
  ret +=
    ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) *
      2.0) /
    3.0
  return ret
}

//纬度转换
function transformlng(lng, lat) {
  var ret =
    300.0 +
    lng +
    2.0 * lat +
    0.1 * lng * lng +
    0.1 * lng * lat +
    0.1 * Math.sqrt(Math.abs(lng))
  ret +=
    ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) *
      2.0) /
    3.0
  ret +=
    ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) /
    3.0
  ret +=
    ((150.0 * Math.sin((lng / 12.0) * PI) +
      300.0 * Math.sin((lng / 30.0) * PI)) *
      2.0) /
    3.0
  return ret
}

//百度坐标系 (BD-09) 与 火星坐标系 (WGS84)的转换
function bd09towgs84(bd_lon, bd_lat) {
  var gcj02 = bd09togcj02(bd_lon, bd_lat)
  return gcj02towgs84(gcj02[0], gcj02[1])
}

export default {
  name: 'DialogMap',
  props: {
    btnLink: Object
  },
  emits: ['btnLinkClose'],
  data() {
    return {
      ak: '1XjLLEhZhQNUzd93EjU5nOGQ', // 百度的地图密钥
      lon: 120.778499, //经度,
      lonnew: null,
      lon1: null,
      lon2: null,
      lat: 31.601076, //纬度,
      latnew: null,
      lat2: null,
      lat1: null,
      Addaddress: '',
      show: false,
      oldtext: '原地址',
      newtext: '新地址',
      id: '',
      type: null,
      obj: {
        color: 'red',
        border: '0px',
        backgroundColor: '0.000000000001',
        fontSize: '12px',
        fontFamily: '微软雅黑'
      },
      obj1: {
        color: 'blue',
        border: '0px',
        backgroundColor: '0.000000000001',
        fontSize: '12px',
        fontFamily: '微软雅黑'
      },
      map: null
    }
  },
  watch: {
    btnLink(newval) {
      this.show = false;
      if (newval.query.type == "001") {
        this.type = this.btnLink.query.type;
        this.id = this.btnLink.query.post_id;
        this.lon = this.btnLink.query.longitude;
        this.lat = this.btnLink.query.latitude;
        this.oldtext = "原地址";
        this.newtext = "新地址";
        this.getPosition(this.lon, this.lat);
      }
      if (newval.query.type == "002") {
        this.show = true;
        this.type = this.btnLink.query.type;
        this.id = this.btnLink.query.clock;
        this.lon = this.btnLink.query.longitude;
        this.lat = this.btnLink.query.latitude;
        this.lon2 = this.btnLink.query.lon;
        this.lat2 = this.btnLink.query.lat;
        this.lon1 = this.btnLink.query.lon;
        this.lat1 = this.btnLink.query.lat;
        this.oldtext = "岗位地址";
        this.newtext = "打卡地址";
        this.getPosition(this.lon, this.lat);
      }
    },
  },
  beforeUnmount() {
    this.map.destroy()
    this.map = null
  },
  mounted() {
    console.log(this.btnLink)
    if (this.btnLink.query.type == '001') {
      this.type = this.btnLink.query.type
      this.id = this.btnLink.query.post_id
      this.lon = this.btnLink.query.longitude
      this.lat = this.btnLink.query.latitude
      this.oldtext = '原地址'
      this.newtext = '新地址'
    }
    if (this.btnLink.query.type == '002') {
      this.show = true
      this.type = this.btnLink.query.type
      this.id = this.btnLink.query.clock
      this.lon = this.btnLink.query.longitude
      this.lat = this.btnLink.query.latitude
      this.lon2 = this.btnLink.query.lon
      this.lat2 = this.btnLink.query.lat
      this.lon1 = this.btnLink.query.lon
      this.lat1 = this.btnLink.query.lat
      this.oldtext = '岗位地址'
      this.newtext = '打卡地址'
    }
    // console.log(bd09towgs84(this.lon,this.lat));
    // this.lon1=bd09towgs84(this.lon,this.lat)[0];
    // this.lat1=bd09towgs84(this.lon,this.lat)[1];
    console.log(this.lon, this.lat)

    //原经纬度为0或者为空字符时，地图显示的经纬度默认为苏州市中心（120.59241222959322，31.303564074441766）
    if (
      (this.lon == '0' && this.lat == '0') ||
      this.lon == '' ||
      this.lat == ''
    ) {
      // let a = this.lon + "1";
      // let b = this.lat + "1";
      let a = '120.76631636106701'
      let b = '31.65622757993034'
      // console.log(a,b);
      this.getPosition(a, b)
    } else {
      this.getPosition(this.lon, this.lat)
    }
  },
  methods: {
    goback() {
      this.map.destroy()
      // this.map = null;

      //原经纬度为0或者为空字符时，地图显示的经纬度默认为苏州市中心（120.59241222959322，31.303564074441766）
      if (
        (this.lon == '0' && this.lat == '0') ||
        this.lon == '' ||
        this.lat == ''
      ) {
        let a = '120.76631636106701'
        let b = '31.65622757993034'
        this.getPosition(a, b)
      } else {
        this.getPosition(this.lon, this.lat)
      }
    },
    //地图显示
    getPosition(a, b) {
      // 传入密钥获取地图回调。
      let _this = this
      console.log(_this.id)
      BMPGL(_this.ak).then(BMapGL => {
        // 创建地图实例
        _this.map = new BMapGL.Map(_this.id)
        // 创建点坐标 axios => res 获取的初始化定位坐标
        let point = new BMapGL.Point(a, b)
        // 初始化地图，设置中心点坐标和地图级别
        _this.map.centerAndZoom(point, 19)
        //开启鼠标滚轮缩放
        _this.map.enableScrollWheelZoom(true)

        //按地址查询
        var ac = new BMapGL.Autocomplete({
          //建立一个自动完成的对象
          input: 'suggestId',
          location: _this.map
        })
        ac.addEventListener('onhighlight', function (e) {
          //鼠标放在下拉列表上的事件
          var str = ''
          var _value = e.fromitem.value
          var value = ''
          if (e.fromitem.index > -1) {
            value =
              _value.province +
              _value.city +
              _value.district +
              _value.street +
              _value.business
          }
          str =
            'FromItem<br />index = ' +
            e.fromitem.index +
            '<br />value = ' +
            value

          value = ''
          if (e.toitem.index > -1) {
            _value = e.toitem.value
            value =
              _value.province +
              _value.city +
              _value.district +
              _value.street +
              _value.business
          }
          str +=
            '<br />ToItem<br />index = ' +
            e.toitem.index +
            '<br />value = ' +
            value
          // document.getElementById("searchResultPanel").innerHTML = str;
        })
        var myValue
        ac.addEventListener('onconfirm', function (e) {
          //鼠标点击下拉列表后的事件
          // console.log(e);
          var _value = e.item.value
          myValue =
            _value.province +
            _value.city +
            _value.district +
            _value.street +
            _value.business
          // document.getElementById("searchResultPanel").innerHTML ="onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
          setPlace()
        })

        if (_this.type == '002') {
          var myIcon1 = new BMapGL.Icon(
            require('@/assets/markers.png'),
            new BMapGL.Size(23, 25),
            {
              offset: new BMapGL.Size(10, 25), // 指定定位位置
              imageOffset: new BMapGL.Size(0, 239.5) // 设置图片偏移
            }
          )

          if (_this.lon2 != '' && _this.lat2 != '') {
            var marker2 = new BMapGL.Marker(
              new BMapGL.Point(_this.lon2, _this.lat2),
              { icon: myIcon1 }
            )
            _this.map.addOverlay(marker2)
            var opts1 = {
              position: new BMapGL.Point(_this.lon2, _this.lat2), // 指定文本标注所在的地理位置
              offset: new BMapGL.Size(20, -20)
            }
            var label2 = new BMapGL.Label('打卡地址', opts1)
            label2.setStyle(_this.obj1)
            _this.map.addOverlay(label2)
          }
        }
        var marker = new BMapGL.Marker(point)
        _this.map.addOverlay(marker)
        var opts = {
          position: new BMapGL.Point(_this.lon, _this.lat), // 指定文本标注所在的地理位置
          offset: new BMapGL.Size(-65, -20)
        }
        var label = new BMapGL.Label(_this.oldtext, opts)
        label.setStyle(_this.obj)
        _this.map.addOverlay(label)

        _this.map.addEventListener('click', function (e) {
          _this.newtext = '新地址'
          _this.lon1 = e.latlng.lng
          _this.lat1 = e.latlng.lat
          _this.show = true
          console.log('点击位置经纬度：' + e.latlng.lng + ',' + e.latlng.lat)
          _this.map.clearOverlays()
          if (_this.type == '002') {
            var myIcon1 = new BMapGL.Icon(
              require('@/assets/markers.png'),
              new BMapGL.Size(23, 25),
              {
                offset: new BMapGL.Size(10, 25), // 指定定位位置
                imageOffset: new BMapGL.Size(0, 239.5) // 设置图片偏移
              }
            )

            if (_this.lon2 != '' && _this.lat2 != '') {
              var marker2 = new BMapGL.Marker(
                new BMapGL.Point(_this.lon2, _this.lat2),
                { icon: myIcon1 }
              )
              _this.map.addOverlay(marker2)
              var opts1 = {
                position: new BMapGL.Point(_this.lon2, _this.lat2), // 指定文本标注所在的地理位置
                offset: new BMapGL.Size(20, -20)
              }
              var label2 = new BMapGL.Label('打卡地址', opts1)
              label2.setStyle(_this.obj1)
              _this.map.addOverlay(label2)
            }
          }
          var marker = new BMapGL.Marker(point)
          _this.map.addOverlay(marker)
          var opts = {
            position: new BMapGL.Point(_this.lon, _this.lat), // 指定文本标注所在的地理位置
            offset: new BMapGL.Size(-65, -20)
          }
          var label = new BMapGL.Label(_this.oldtext, opts)
          label.setStyle(_this.obj)
          _this.map.addOverlay(label)
          var myIcon = new BMapGL.Icon(
            require('@/assets/markers.png'),
            new BMapGL.Size(23, 25),
            {
              offset: new BMapGL.Size(10, 25), // 指定定位位置
              imageOffset: new BMapGL.Size(0, 287.5) // 设置图片偏移
            }
          )
          var markernew = new BMapGL.Marker(
            new BMapGL.Point(_this.lon1, _this.lat1),
            { icon: myIcon }
          )
          _this.map.addOverlay(markernew)
          var opts2 = {
            position: new BMapGL.Point(_this.lon1, _this.lat1), // 指定文本标注所在的地理位置
            offset: new BMapGL.Size(-20, 0)
          }
          var label3 = new BMapGL.Label('新地址', opts2)
          label3.setStyle(_this.obj)
          _this.map.addOverlay(label3)
          // alert('点击位置经纬度：' + e.latlng.lng + ',' + e.latlng.lat);
        })

        function setPlace() {
          _this.map.clearOverlays() //清除地图上所有覆盖物
          function myFun() {
            var pp = local.getResults().getPoi(0).point //获取第一个智能搜索的结果
            _this.map.centerAndZoom(pp, 18)
            // _this.map.addOverlay(new BMapGL.Marker(pp)); //添加标注

            //标注原坐标
            if (_this.type == '002') {
              var myIcon1 = new BMapGL.Icon(
                require('@/assets/markers.png'),
                new BMapGL.Size(23, 25),
                {
                  offset: new BMapGL.Size(10, 25), // 指定定位位置
                  imageOffset: new BMapGL.Size(0, 239.5) // 设置图片偏移
                }
              )

              if (_this.lon2 != '' && _this.lat2 != '') {
                var marker2 = new BMapGL.Marker(
                  new BMapGL.Point(_this.lon2, _this.lat2),
                  { icon: myIcon1 }
                )
                _this.map.addOverlay(marker2)
                var opts1 = {
                  position: new BMapGL.Point(_this.lon2, _this.lat2), // 指定文本标注所在的地理位置
                  offset: new BMapGL.Size(20, -20)
                }
                var label2 = new BMapGL.Label('打卡地址', opts1)
                label2.setStyle(_this.obj1)
                _this.map.addOverlay(label2)
              }
            }
            var marker = new BMapGL.Marker(point)
            _this.map.addOverlay(marker)
            var opts = {
              position: new BMapGL.Point(_this.lon, _this.lat), // 指定文本标注所在的地理位置
              offset: new BMapGL.Size(-65, -20)
            }
            var label = new BMapGL.Label(_this.oldtext, opts)
            label.setStyle(_this.obj)
            _this.map.addOverlay(label)
          }

          var local = new BMapGL.LocalSearch(_this.map, {
            //智能搜索
            onSearchComplete: myFun
          })
          // console.log(myValue);

          //创建地址解析器实例
          var myGeo = new BMapGL.Geocoder()
          //获取选中地址的经纬度
          myGeo.getPoint(myValue, point => {
            // console.log("point", point, myValue);
            _this.lonnew = point.lng
            _this.latnew = point.lat
            _this.mapAddress = myValue
          })
          local.search(myValue)
          // console.log(bd09towgs84(_this.lon,_this.lat));
          // _this.lon1=bd09towgs84(_this.lon,_this.lat)[0];
          // _this.lat1=bd09towgs84(_this.lon,_this.lat)[1];
        }
      })
    },
    btnchange() {
      this.$confirm('是否确认更新当前经纬度?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.change()
          this.$message({
            type: 'success',
            message: '更新成功!'
          })
        })
        .catch(() => {})
    },
    async change() {
      let param = {
        lat: this.lat1,
        lon: this.lon1,
        needid: this.id,
        type: this.type
      }
      // console.log(param);
      let res = await updateLocation(param)
      console.log(res)
      $emit(this, 'btnLinkClose')
    }
  }
}
</script>

<style lang="scss" scoped>
.all {
  width: 100%;
  height: 100%;
  .input {
    width: 100%;
    height: 20%;
    position: relative;

    .btn {
      position: absolute;
      top: 0px;
      right: 5%;
    }
  }

  .map {
    width: 100%;
    height: 80%;
  }
}
:deep(.BMap_cpyCtrl) {
  display: none;
}
:deep(.anchorBL) {
  display: none;
}
</style>
