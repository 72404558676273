import { post, fetch, patch, put, postJson, fetch_blob } from '../../http'
export default {
  //用户查询
  userPermission(params) {
    return fetch('/rextec/api/userPermission', params)
  },
  //获取收发货方信息下拉列表
  queryConInfoAll() {
    return fetch('/rextec/api/userPermission/queryConInfoAll')
  },
  //获取承运商信息下拉列表
  getCarrierAll() {
    return fetch('/rextec/api/userPermission/getCarrierAll')
  },
  //
  undateUserPermission(params) {
    return put('/rextec/api/userPermission', params)
  },
}
