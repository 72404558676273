<template>
  <div class="batchChangeShiftTypeDialog">
    <div class="choose">
      <el-select
        v-model="classType"
        class="grade-content-select"
        filterable
        placeholder="请选择班次类型"
        @change="classTypeChange"
      >
        <el-option
          v-for="item in Arr"
          :key="item.shiftCatId"
          :label="item.shiftCatName"
          :value="item.shiftCatId"
        />
      </el-select>
    </div>
    <div class="footer">
      <el-button @click="close"> 取消 </el-button>
      <el-button type="primary" @click="confirm"> 确认 </el-button>
    </div>
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../../utils/gogocodeTransfer'
import { getClassesType, updateClassesType } from '@/requet/api/ba/scheduleApi'

export default {
  name: 'BatchChangeShiftTypeDialog',
  props: ['queryParams'],
  emits: ['btnLinkClose'],
  data() {
    return {
      Arr: [],
      classType: '',
      classesid: []
    }
  },
  watch: {
    queryParams: {
      deep: true,

      handler(newval) {
        console.log('queryParams', newval)
        this.getclassesid()
      }
    }
  },
  mounted() {
    console.log('queryParams', this.queryParams)
    this.getClassesType()
    this.getclassesid()
  },
  methods: {
    getclassesid() {
      this.classesid = []
      if (this.queryParams.length != 0) {
        this.queryParams?.forEach(res => {
          this.classesid.push(res.F0)
        })
      }
      console.log(this.classesid)
    },
    async getClassesType() {
      this.Arr = []
      let res = await getClassesType()
      this.Arr = res
    },
    close() {
      $emit(this, 'btnLinkClose')
    },
    async confirm() {
      let obj = {
        classesid: this.classesid,
        classType: this.classType
      }
      console.log(obj)
      if (obj) {
        let res = await updateClassesType(obj)
        console.log(res)
      }
      $emit(this, 'btnLinkClose')
    },
    classTypeChange(item) {
      console.log(item)
    }
  }
}
</script>

<style lang="scss" scoped>
.batchChangeShiftTypeDialog {
  width: 100%;
  height: 100%;
  position: relative;
  .choose {
    width: 100%;

    .grade-content-select {
      margin-right: 15px;
    }
  }

  .footer {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
</style>
