<template>
  <div class="perPortraitPerson positionall">
    <div class="top">
      <div class="top-title">
        <div class="name">
          <span
            style="
              font-size: 1vw;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;
            "
            :title="peopMsg.staffName"
            @click="goTotable()"
            >{{ peopMsg.staffName }}</span
          >
          <div v-if="peopMsg.sex == '男'" style="margin-right: 15px">
            <img
              src="@/assets/images/perPortrait/nan.png"
              style="width: 1vw; margin-left: 5px"
            />
          </div>
          <div v-else style="margin-right: 15px">
            <img
              src="@/assets/images/perPortrait/nv.png"
              style="width: 1vw; margin-left: 5px"
            />
          </div>
        </div>
        <div class="btn">
          <div style="margin-right: 5px">
            <img
              src="@/assets/images/perPortrait/wdpb.png"
              style="width: 0.9vw"
            />
          </div>
          <span
            style="
              font-size: 0.9vw;
              margin-left: 5px;
              cursor: pointer;
              margin: auto 0;
            "
            @click="goto"
            >我的排班</span
          >
        </div>
      </div>
    </div>
    <div class="whole">
      <div class="whole-top">
        <div
          v-for="(item, index) in toplist"
          :key="index"
          class="whole-top-list"
        >
          <span>{{ item }}</span>
        </div>
      </div>
      <div class="whole-bottom">
        <div v-for="(item, index) in bottomlist" :key="index" class="list">
          <span style="color: #b2b2b2; width: 44%">{{ item.tip }}</span>
          <span
            style="
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
            :title="item.info"
            >{{ item.info }}</span
          >
        </div>
      </div>
    </div>
    <div class="portrait">
      <!-- <img src="@/assets/images/perPortrait/tx.png" /> -->
      <img :src="'/_MINIO/' + peopMsg.staffPicture" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PerPortraitPerson',
  props: ['peopMsg', 'getintend', 'getStaffId'],
  data() {
    return {
      dataM: '',
      staffId: '',
      intend: '',
      toplist: [],
      bottomlist: [
        { tip: '员工性质', info: '' },
        { tip: '加班意愿', info: '' },
        { tip: '家庭住址', info: '' },
        { tip: '职位', info: '' }
      ]
    }
  },
  watch: {
    peopMsg: {
      deep: true,
      handler: function (newVal, oldVal) {
        this.dataM = newVal
        this.reviseArr()
      }
    },
    getintend: {
      deep: true,
      handler: function (newVal, oldVal) {
        this.intend = newVal
        this.$nextTick(() => {
          this.bottomlist[1][`info`] = this.intend !== null ? this.intend : ''
        })
      }
    },
    getStaffId: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.staffId = newVal
        }
      }
    }
  },
  created() {
    this.staffId = this.$route.query.staffId
  },
  methods: {
    reviseArr() {
      let age = this.dataM?.age !== null ? this.dataM.age + '岁' : ''
      let height = this.dataM?.height !== null ? this.dataM.height + 'cm' : ''
      let weight = this.dataM?.weight !== null ? this.dataM.weight + 'kg' : ''
      let politicalstatus = this.dataM?.politicalstatus //政治面貌
      let militaryService = this.dataM?.militaryService //兵役情况
      let education = this.dataM?.education //文化程度

      this.toplist.push(age, height, weight)
      if (politicalstatus !== null) {
        if (politicalstatus.trim() !== '') this.toplist.push(politicalstatus)
      }
      if (militaryService !== null) {
        if (militaryService !== '')
          this.toplist.push('服兵役' + ':' + militaryService)
      }
      if (education !== null) {
        if (education.trim() !== '') this.toplist.push(education)
      }
      this.bottomlist[0][`info`] =
        this.dataM.staffType !== null ? this.dataM.staffType : ''
      this.bottomlist[2][`info`] =
        this.dataM.familyAddress !== null ? this.dataM.familyAddress : ''
      this.bottomlist[3][`info`] =
        (this.dataM.postName !== null ? this.dataM.postName : '') +
        (this.dataM.depName !== null ? '(' + this.dataM.depName + ')' : '')
    },
    goto() {
      this.$router.push({
        path: '/home/perScheduling',
        query: {
          staffId: this.staffId
        }
      })
    },
    goTotable() {
      this.$router.push({
        path: 'configurationForm/SM0589',
        // params: {
        //   menuid: "SM0589",
        // },
        query: {
          menuid: 'SM0589',
          title: '保安管理',
          configtype: '006',
          groupid: 'M11463',
          filtergroup: '',
          filtergroupshowsign: 'Y',
          pkval: this.staffId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.perPortraitPerson {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.positionall {
  position: relative;
}
.top {
  width: 100%;
  height: 25%;
  background-image: url('~@/assets/images/perPortrait/bg.png');
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.top-title {
  height: 80%;
  width: 70%;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.name {
  width: 6rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}
.btn {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1vw;
}
.whole {
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.whole-top {
  width: 70%;
  height: 32%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.whole-top-list > span {
  font-size: 0.9vw;
  border-radius: 4px;
  margin-top: 1px;
  margin-right: 5px;
  background-color: #e1ecfb;
  color: #3981e5;
  padding: 2px 4px;
}
.whole-bottom {
  width: 100%;
  height: 63%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.list {
  width: 80%;
  height: 20%;
  font-size: 0.9vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.portrait {
  width: 4.8vw;
  height: 4.8vw;
  overflow: hidden;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.portrait > img {
  width: 4.5vw;
}
</style>
