<template>
  <div
    class="dialogAll"
    :style="{
      height: conType === 'carport' && sortlist.length == 0 ? '250px' : '500px',
    }"
  >
    <div class="closeX">
      <span @click="closedialogHg">×</span>
    </div>
    <div class="dialogtitle">
      {{ hgDetail.facilityname }}
    </div>
    <div v-if="conType == 'container'" class="detailbox">
      <div class="detailCon">
        <div class="detailCon_left">
          <div v-if="hgDetail.image == null || hgDetail.image == ' '">
            <el-image
              ref="showViewer"
              style="width: auto; height: 100%"
              :preview-src-list="srcList(imgUrl)"
              :src="imgUrl"
              class="avatar"
              @click.stop="handleClickItem"
            >
              <template #error>
                <div class="image-slot">
                  <el-icon><ElIconPictureOutline /></el-icon>
                </div>
              </template>
            </el-image>
          </div>
          <div v-else>
            <el-image
              ref="showViewer"
              style="width: auto; height: 100%"
              :preview-src-list="srcList(ImgbaseURL + hgDetail.image)"
              :src="ImgbaseURL + hgDetail.image"
              class="avatar"
              @click.stop="handleClickItem"
            >
              <template #error>
                <div class="image-slot">
                  <el-icon><ElIconPictureOutline /></el-icon>
                </div>
              </template>
            </el-image>
          </div>
        </div>
        <div class="detailCon_right">
          <div class="basictitle">
            <span>基本信息</span>
          </div>
          <el-row>
            <el-col :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>ID：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.facilityid != null">{{
                    hgDetail.facilityid
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
            <el-col v-if="hgDetail.style" :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>认证规格：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.style.authorstyleName != null">{{
                    hgDetail.style.authorstyleName
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>设备状态：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.statusName != null">{{
                    hgDetail.statusName
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>设备规格-长宽高：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.style"
                    >{{ hgDetail.style.length }},{{ hgDetail.style.width }},{{
                    hgDetail.style.height
                    }}</span
                  >
                  <!-- <span v-else>--</span> -->
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <el-col v-if="hgDetail.style" :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>设备类型：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.style.stylename != null">{{
                    hgDetail.style.stylename
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>

            <el-col v-if="hgDetail.style" :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>设备环境：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.style.envirName != null">{{
                    hgDetail.style.envirName
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
            <!-- <el-col :span="12">
                        <el-row>
                          <el-col :span="12">
                            <img src="../../assets/images/police/tc_sj.png" alt="" />
                            <span>设备规格-宽：</span>
                          </el-col>
                          <el-col :span="12">
                            <span v-if="hgDetail.width!=null">{{ hgDetail.width }}</span>
                            <span v-else>--</span>
                          </el-col>
                        </el-row></el-col
                      > -->
          </el-row>
          <!-- <el-row>
                      <el-col :span="12">
                        <el-row>
                          <el-col :span="12">
                            <img src="../../assets/images/police/tc_sj.png" alt="" />
                            <span>设备规格-高：</span>
                          </el-col>
                          <el-col :span="12">
                            <span v-if="hgDetail.height!=null">{{ hgDetail.height }}</span>
                            <span v-else>--</span>
                          </el-col>
                        </el-row></el-col
                      >
                    </el-row> -->
          <el-row>
            <el-col v-if="hgDetail.locker" :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>库柜编号：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.locker.lockerid != null">{{
                    hgDetail.locker.lockerid
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
            <el-col v-if="hgDetail.locker" :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>库柜名称：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.locker.lockername != null">{{
                    hgDetail.locker.lockername
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <el-col v-if="hgDetail.locker" :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>库柜状态：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.locker.statusName != null">{{
                    hgDetail.locker.statusName
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
            <el-col v-if="hgDetail.locker" :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>库柜空闲个数：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.locker.emptyNum != null">{{
                    hgDetail.locker.emptyNum
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="detaillock">
        <div class="xqline">
          <div class="title">
            <span>库柜详情</span>
          </div>
          <div class="fpfilter">
            <div>筛选：</div> <el-select style="width:70%" v-model="fpfilter" placeholder="全部" @change="fpfilterFunc">
            <el-option
                v-for="item in filteroptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          </div>
        </div>
        <div class="KWdetail">
          <el-table
            ref="searchTable"
            :data="sortlist"
            :row-style="{
              color: '#fff',
              backgroundColor: 'transparent !important',
              borderBottom: '#fff',
            }"
            :header-cell-style="{
              color: '#fff',
              backgroundColor: '#1B4973 !important',
            }"
            height="210"
            @row-click="hanldeClick"
            @selection-change="handleChange"
            @select-all="selectAll"
            @select="rowSelect"
          >
            <el-table-column
              v-if="type == 'select'"
              type="selection"
              width="55"
            />
            <el-table-column
              v-for="(item, index) in tableTitles"
              :key="index"
              :fixed="item.fixed"
              :prop="item.prop"
              :align="item.align"
              :label="item.label"
              :min-width="item.width"
              sortable
            />
          </el-table>
        </div>
      </div>
    </div>
    <div v-else-if="conType == 'carport'" class="detailbox">
      <div class="detailCon">
        <div class="detailCon_left">
          <div v-if="hgDetail.image == null || hgDetail.image == ' '">
            <el-image
              ref="showViewer"
              style="width: auto; height: 100%"
              :preview-src-list="srcList(imgUrl)"
              :src="imgUrl"
              class="avatar"
              @click.stop="handleClickItem"
            >
              <template #error>
                <div class="image-slot">
                  <el-icon><ElIconPictureOutline /></el-icon>
                </div>
              </template>
            </el-image>
          </div>
          <div v-else>
            <el-image
              ref="showViewer"
              style="width: auto; height: 100%"
              :preview-src-list="srcList(ImgbaseURL + hgDetail.image)"
              :src="ImgbaseURL + hgDetail.image"
              class="avatar"
              @click.stop="handleClickItem"
            >
              <template #error>
                <div class="image-slot">
                  <el-icon><ElIconPictureOutline /></el-icon>
                </div>
              </template>
            </el-image>
          </div>
        </div>
        <div class="detailCon_right">
          <div class="basictitle">
            <span>基本信息</span>
          </div>
          <el-row>
            <el-col :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>ID：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.facilityid != null">{{
                    hgDetail.facilityid
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>车位名：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.facilityname != null">{{
                    hgDetail.facilityname
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <el-col v-if="hgDetail.style" :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>车位类型：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.style.stylename != null">{{
                    hgDetail.style.stylename
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>车位状态：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.statusName != null">{{
                    hgDetail.statusName
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
            <!-- <el-col :span="12">
                        <el-row>
                          <el-col :span="12">
                            <img src="../../assets/images/police/tc_sj.png" alt="" />
                            <span>设备规格-宽：</span>
                          </el-col>
                          <el-col :span="12">
                            <span v-if="hgDetail.width!=null">{{ hgDetail.width }}</span>
                            <span v-else>--</span>
                          </el-col>
                        </el-row></el-col
                      > -->
          </el-row>
          <!-- <el-row>
                      <el-col :span="12">
                        <el-row>
                          <el-col :span="24">
                          </el-col>
                        </el-row></el-col
                      >
                      <el-col :span="12">
                        <el-row>
                          <el-col :span="24">
                            <el-button type="success" round  v-if="hgDetail.statusName == null" @click="changeCar('stop')">停车</el-button>
                            <el-button type="primary" round v-else  @click="changeCar('move')">移车</el-button>
                          </el-col>
                        </el-row></el-col
                      >
                    </el-row> -->
          <el-row>
            <el-col v-if="hgDetail.locker" :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>车位名称：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.locker.lockername != null">{{
                    hgDetail.locker.lockername
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
            <el-col v-if="hgDetail.locker" :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>车位状态：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.locker.statusName != null">{{
                    hgDetail.locker.statusName
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <el-col v-if="hgDetail.locker" :span="12">
              <el-row>
                <el-col :span="12">
                  <img src="../../assets/images/police/tc_sj.png" alt="" />
                  <span>车位空闲个数：</span>
                </el-col>
                <el-col :span="12">
                  <span v-if="hgDetail.locker.emptyNum != null">{{
                    hgDetail.locker.emptyNum
                  }}</span>
                  <span v-else>--</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>

      <div v-if="sortlist.length > 0" class="detaillock">
        <div class="title">
          <span>车位详情</span>
        </div>
        <div class="KWdetail">
          <el-table
            ref="searchTable"
            :data="sortlist"
            :row-style="{
              color: '#fff',
              backgroundColor: 'transparent !important',
              borderBottom: '#fff',
            }"
            :header-cell-style="{
              color: '#fff',
              backgroundColor: '#1B4973 !important',
            }"
            height="210"
            @row-click="hanldeClick"
            @selection-change="handleChange"
            @select-all="selectAll"
            @select="rowSelect"
          >
            <el-table-column
              v-if="type == 'select'"
              type="selection"
              width="55"
            />
            <el-table-column
              v-for="(item, index) in tableTitles"
              :key="index"
              :fixed="item.fixed"
              :prop="item.prop"
              :align="item.align"
              :label="item.label"
              :min-width="item.width"
            />
          </el-table>
        </div>
      </div>
    </div>
    <div v-if="type == 'select'" class="detailbtn flexrowcenter">
      <el-button type="primary" @click="sureInfo"> 确认 </el-button>
      <el-button type="primary" plain @click="closedialogHg"> 取消 </el-button>
    </div>
  </div>
</template>

<script>
import { Picture as ElIconPictureOutline } from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
export default {
  name: 'DialogHg',
  components: {
    ElIconPictureOutline,
  },
  props: ['faclist', 'type', 'storageObj', 'conType'],
  emits: ['closeLinkTc', 'hgClose'],
  data() {
    return {
      fpfilter:"001",
      filteroptions:[{
        value: '001',
          label: '全部'
      },{
        value: '002',
          label: '库位已分配'
      },{
        value: '003',
          label: '库位未分配'
      }],
      multipleSelection: [],
      hgDetail: {},
      xhhgDetail: [],
      labelPosition: 'left',
      imgUrl: require('../../assets/images/threeD/detailimg.png'),
      dialogVisible: false,
      tableTitles: [
        //   {
        //   fixed: false,
        //   prop: "spaceid",
        //   align: "left",
        //   label: "库位ID",
        //   width: 15,
        // },
        {
          fixed: false,
          prop: 'spacename',
          align: 'left',
          label: '库位名称',
          width: 15,
        },
        // {
        //   fixed: false,
        //   prop: "remark",
        //   align: "left",
        //   label: "备注",
        //   width: 20,
        // },
        {
          fixed: false,
          prop: 'propertyname',
          align: 'left',
          label: '财物名称',
          width: 15,
        },
        {
          fixed: false,
          prop: 'caseName',
          align: 'left',
          label: '所属案件',
          width: 15,
        },
        {
          fixed: false,
          prop: 'ownershipName',
          align: 'left',
          label: '权属单位',
          width: 15,
        },
        {
          fixed: false,
          prop: 'bclassName',
          align: 'left',
          label: '大类',
          width: 15,
        },
        // {
        //   fixed: false,
        //   prop: "mclassName",
        //   align: "left",
        //   label: "中类",
        //   width: 15,
        // },
        // {
        //   fixed: false,
        //   prop: "sclassName",
        //   align: "left",
        //   label: "小类",
        //   width: 15,
        // },
      ],
      gridData: [],
      sortlist: [],
      dialogType: 'container',
    }
  },
  watch: {
    faclist: {
      handler(val) {
        // console.log(val);
        this.hgDetail = val
        // console.log('hgDetail',this.hgDetail)
        if (this.hgDetail.style) {
          if (this.hgDetail.style.length == '') {
            this.hgDetail.style.length = '--'
          }
          if (this.hgDetail.style.width == '') {
            this.hgDetail.style.width = '--'
          }
          if (this.hgDetail.style.height == '') {
            this.hgDetail.style.height = '--'
          }
        }
        this.getgridData()
        // console.log(this.tzdatalist);
      },
      deep: true,
      immediate: true,
    },
    conType: {
      handler(val) {
        this.dialogType = val
        //  console.log('dialogType',this. dialogType);
        if (val == 'carport') {
          this.imgUrl = require('../../assets/images/threeD/carportlimg.png')
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    //  console.log(1111,this.conType);
    this.getgridData()
    this.gridData = []
    this.sortlist = []
  },
  methods: {
    fpfilterFunc(e){
      console.log(e)
      console.log(this.alldata)
      if(e=="001"){//全部
        this.sortlist=this.alldata;
        console.log(this.alldata)
      }else if(e=="002"){//库位已分布
        this.sortlist=this.sortdata;
      }else if(e=="003"){//库位未分布
        this.sortlist=this.emptydata;
      }
    },
    //获取库位等信息
    sureInfo() {
      let obj = {
        spaceid: this.multipleSelection[0].spaceid, //库位
        spacename: this.multipleSelection[0].spacename,
        lockerid: this.hgDetail.locker.lockerid, //库柜
        lockername: this.hgDetail.locker.lockername,
        areaid: this.hgDetail.areaid, //库区
        areaname: this.hgDetail.areaname,
      }
      Object.assign(obj, this.storageObj) //仓库
      $emit(this, 'closeLinkTc', obj)
    },
    //禁止全选
    selectAll() {
      this.$refs.searchTable.clearSelection()
    },
    rowSelect(selection, row) {
      this.$refs.searchTable.clearSelection()
      if (selection.length === 0) {
        // 判断selection是否有值存在
        return
      }
      if (row) {
        // this.multipleSelection = row
        this.multipleSelection = [row];
        this.$refs.searchTable.toggleRowSelection(row, true)
      }
    },
    handleChange(val) {
      // if (this.type == 'select') {
      //   this.multipleSelection = val
      // }
    },
    hanldeClick(row, column) {
      if (this.type != 'select') return
      let res = this.multipleSelection.findIndex(
        (e) => e.spacename === row.spacename
      )
      this.$refs.searchTable.clearSelection();
      if (res == -1) {
        // if (this.multipleSelection.length > 0) {
        //   this.$refs.searchTable.toggleRowSelection(
        //     this.multipleSelection[0],
        //     false
        //   )
        //   this.multipleSelection.splice(0, 1)
        // }
        this.multipleSelection = [row]
        this.$refs.searchTable.toggleRowSelection(row, true)
      } else {
        this.$refs.searchTable.toggleRowSelection(row, false)
        // this.multipleSelection.splice(res, 1)
        this.multipleSelection = []
      }
    },
    closedialogHg() {
      $emit(this, 'hgClose')
    },
    srcList(url) {
      return [url]
    },
    handleClickItem() {
      this.$nextTick(() => {
        let domImageMask = document.querySelector('.el-image-viewer__mask') // 获取遮罩层dom
        if (!domImageMask) {
          return
        }
        domImageMask.addEventListener('click', () => {
          this.showPictureUrl = false
          //点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector('.el-image-viewer__close').click()
        })
      })
    },
    showlocker() {
      this.dialogVisible = true
    },
    getgridData() {
      this.gridData = []
      if (this.hgDetail.locker) {
        for (let i = 0; i < this.hgDetail.locker.spaces.length; i++) {
          if (this.hgDetail.locker.spaces[i].properties == '') {
            this.gridData.push({
              spaceid: this.hgDetail.locker.spaces[i].spaceid,
              spacename: this.hgDetail.locker.spaces[i].spacename,
              //remark:this.hgDetail.locker.spaces[i].remark,
              propertyname: '--',
              caseName: '--',
              ownershipName: '--',
              bclassName: '--',
              //mclassName:'',
              //sclassName:'',
            })
          } else {
            for (
              let x = 0;
              x < this.hgDetail.locker.spaces[i].properties.length;
              x++
            ) {
              this.gridData.push({
                spaceid: this.hgDetail.locker.spaces[i].spaceid,
                spacename: this.hgDetail.locker.spaces[i].spacename,
                //remark:this.hgDetail.locker.spaces[i].remark,
                propertyname:
                  this.hgDetail.locker.spaces[i].properties[x].propertyname !=
                  ''
                    ? this.hgDetail.locker.spaces[i].properties[x].propertyname
                    : '--',
                caseName:
                  this.hgDetail.locker.spaces[i].properties[x].caseName != ''
                    ? this.hgDetail.locker.spaces[i].properties[x].caseName
                    : '--',
                ownershipName:
                  this.hgDetail.locker.spaces[i].properties[x].ownershipName !=
                  ''
                    ? this.hgDetail.locker.spaces[i].properties[x].ownershipName
                    : '--',
                bclassName:
                  this.hgDetail.locker.spaces[i].properties[x].bclassName != ''
                    ? this.hgDetail.locker.spaces[i].properties[x].bclassName
                    : '--',
                //mclassName:this.hgDetail.locker.spaces[i].properties[x].mclassName,
                //sclassName:this.hgDetail.locker.spaces[i].properties[x].sclassName,
              })
            }
          }
        }
      }
      //console.log("gridData",this.gridData)
      let sortdata = []
      let emptydata = []
      this.sortlist = []
      this.sortdata = [];
      this.emptydata = [];
      for (let t = 0; t < this.gridData.length; t++) {
        if (this.gridData[t].propertyname != ""&&this.gridData[t].propertyname != "--") {
          sortdata.push(this.gridData[t]);
        } else {
          emptydata.push(this.gridData[t])
        }
      }
      for (let q = 0; q < emptydata.length; q++) {
        this.sortlist.push(emptydata[q]);
        this.emptydata.push(emptydata[q]);
      }
      for (let y = 0; y < sortdata.length; y++) {
        this.sortlist.push(sortdata[y]);
        this.sortdata.push(sortdata[y]);
      }
      this.alldata=this.sortlist;
      console.log("sortlist",this.sortlist)
    },
    // changeCar(type){
    //    let modelid="SM000015";
    //   if(type=="stop"){//停车
    //    this.$emit("changeCar",modelid,type);
    //   }else if(type=="move"){//移车
    //    this.$emit("changeCar",modelid,type);
    //   }
    // },
  },
}
</script>

<style scoped lang="scss">
.closeX {
  width: 99%;
  height: 10%;
  text-align: right;
  color: #fff;
  font-size: 24px;
  span {
    cursor: pointer;
  }
}
.dialogtitle {
  margin-bottom: 12px;
  text-align: center;
  width: 100%;
  font-family: '庞门正道标题体';
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  background-image: linear-gradient(#17e7e0, #3082c8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.detailbox {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  .detailCon {
    color: #fff;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    .detailCon_left {
      padding: 10px;
      width: 150px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      // height: 100%;
    }
    .detailCon_right {
      width: 75%;
      height: 100%;
      .basictitle {
        width: 20%;
        margin-bottom: 7px;
        padding-left: 25px;
        background-image: url('../../assets/images/police/tc_title1.png') !important;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #fff;
      }
      .el-row {
        height: 30px;
      }
    }
  }
  .detaillock {
    color: #fff;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    //padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    .xqline{
      display: flex;
      flex-direction: row;
      align-items: center;
      .fpfilter{
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
          width: 10%;
        }
        :deep(.el-select .el-input__inner,.el-input__wrapper){
        background-color:rgba(0,0,0,0);
        color: #fff;
        }
        :deep(.el-select .el-input__wrapper){
          background-color:rgba(0,0,0,0);
          color: #fff;
        }
        :deep(.el-checkbox__input.is-checked + .el-checkbox__label){
        color:#409EFF
        }
      }
    }
    .title {
      width: 70%;
      //margin-top: 2rem;
      background-image: url('../../assets/images/police/tc_title2.png') !important;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #fff;
      span {
        font-size: 13px;
        margin-left: 2rem;
      }
    }
    // .detaillockbox{
    // color: #fff;
    // font-size: 12px;
    // display: flex;
    // flex-direction: row;
    // padding: 10px;
    // .detailleft{
    //   width: 80%;
    //   .el-row{
    //     height: 30px;
    //   }
    // }
    // .detailright{
    //   width: 20%;
    //   .el-button{
    //     width: 100%;
    //     color: #fff;
    //     background-color: rgb(64,158,255);
    //     border: 0;
    //   }
    // }
    // }
  }
}
// :deep(.el-dialog){
//   background-color: rgba(0, 0, 0, 0);
//   background-image: url("../../assets/images/police/traffic_lights.png") ;
//   background-size: 100% 100%;
//   width: 70%;
// }
// :deep(.el-dialog__header){
//   text-align: center;
// }
// :deep(.el-dialog__title){
//   color: #fff;
// }
// :deep(.el-dialog__headerbtn .el-dialog__close){
//   color: #fff;
// }
// :deep(.el-dialog__body){
//   color: #fff;
// }
// .KGdialog{
//   display: flex;
//   flex-direction: column;
//   .KGdetail{
//   font-size: 13px;
//   .el-row{
//       height: 30px;
//     }
//   }

// }
.KWdetail {
  margin-top: 10px;
}
:deep(.el-table--scrollable-x .el-table__body-wrapper) {
  overflow-x: hidden;
}
:deep(.el-table),
:deep(.el-table__expanded-cell) {
  background-color: transparent !important;
}
:deep(.el-table th),
:deep(.el-table tr) {
  background-color: transparent !important;
  // border: none;
}
:deep(.el-table td),
:deep(.el-table th.is-leaf) {
  border-bottom: 1px solid rgba(27, 73, 115) !important;
}
.el-table::before {
  //去除底部白线
  left: 0;
  bottom: 0;
  //  width: 100%;
  height: 0px;
}
:deep(.el-table tbody tr:hover > td) {
  background-color: transparent !important;
}
:deep(.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar) {
  width: 0;
}
:deep(.el-table th.gutter) {
  background-color: rgb(27, 73, 115) !important;
}
.detailbtn {
  width: 100%;
  padding: 5px;
}
</style>
