<template>
  <div style="all">
    <div>
      <el-button
        style="background-color: red; color: white; width: 10%"
        @click="toggleSelection()"
      >
        全部取消
      </el-button>
      <el-input
        v-model="searchinput"
        class="input-with-select"
        style="margin-left: 5%"
      >
        <template #append>
          <el-button :icon="ElIconSearch" @click="search()"> 查询 </el-button>
        </template>
      </el-input>
    </div>
    <div :style="{height: tabHeight,width: '100%',}">
      <el-table
        ref="multipleTable"
        :data="searchtableData"
        style="width: 100%; height: 100%; overflow: auto; margin-top: 1%"
        @selection-change="handleSelectionChange"
        @row-click="handleRowClick"
        @cell-dblclick="dblclick"
      >
        <el-table-column type="selection" />
        <el-table-column prop="name" label="物品名称" />
        <el-table-column prop="measuringRange" label="测量范围" />
        <el-table-column prop="accuracyLevel" label="准确度等级" />
        <el-table-column prop="id" label="设施编号" />
        <el-table-column prop="serviceModeName" label="服务方式" />
        <el-table-column prop="serviceTypeName" label="报告类型" />
        <el-table-column prop="repairTypeName" label="修理" />
      </el-table>
    </div>

    <el-pagination
      v-model:current-page="page"
      background
      layout="prev, pager, next, total, sizes, jumper"
      :total="total"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="size"
      style="
        margin-top: 1%;
        position: absolute;
        bottom: 8%;
        left: 50%;
        transform: translateX(-50%);
      "
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <div
      style="
        margin-top: 2%;
        position: absolute;
        bottom: 2%;
        left: 50%;
        transform: translateX(-50%);
      "
    >
      <el-button @click="handleClose()"> 取消 </el-button>
      <el-button type="primary" @click="info()"> 确认 </el-button>
    </div>
  </div>
</template>

<script>
import { Search as ElIconSearch } from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../../utils/gogocodeTransfer'
import { getJLHistoryItem } from '@/requet/api/jczx/dialogSearchJL'
import { shallowRef } from 'vue'
export default {
  name: 'DialogSearchJL',
  emits: ['handleClose', 'btnLinkClose'],
  data() {
    return {
      tabHeight: document.body.clientHeight * 0.65 + 'px',
      searchtableData: [],
      searchinput: '',
      total: 0,
      data: [],
      page: 1,
      size: 20,
      ElIconSearch: shallowRef(ElIconSearch)
    }
  },
  mounted() {
    this.data = []
    this.searchtableData = []
    this.search()
  },
  methods: {
    async search() {
      let param = {
        keyWord: this.searchinput,
        pageNum: this.page,
        pageSize: this.size,
        usesPage: true
      }
      let res = await getJLHistoryItem(param)
      this.searchtableData = res.list
      this.total = res.total
      console.log(res)
    },
    toggleSelection() {
      this.$refs.multipleTable.clearSelection()
    },
    handleSelectionChange(val) {
      this.data = val
      console.log(val)
    },
    handleRowClick(row, column, event) {
      // this.$refs.multipleTable.toggleRowSelection(row);
    },
    handleCurrentChange(val) {
      console.log(this.page)
      this.search()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.size = val
      this.search()
    },
    info() {
      let obj = this.data
      if (obj.length == 0) {
        $emit(this, 'handleClose', [])
        this.$refs.multipleTable.clearSelection()
      } else {
        $emit(this, 'btnLinkClose', obj)
        this.$refs.multipleTable.clearSelection()
      }
    },
    dblclick(row) {
      this.$refs.multipleTable.toggleRowSelection(row)
      let obj = this.data
      // console.log(row);
      $emit(this, 'btnLinkClose', obj)
      this.$refs.multipleTable.clearSelection()
    },
    handleClose() {
      $emit(this, 'handleClose')
      this.$refs.multipleTable.clearSelection()
    }
  }
}
</script>

<style lang="scss" scoped>
.all {
  width: 100%;
  height: 100%;
  position: relative;
}
.el-input-group {
  width: 85%;
}
</style>
