<template>
  <div ref="inputContainer" class="custom-input-container">
    <el-input ref="input" v-model="inputValue" v-bind="$attrs" />
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'

export default {
  name: 'CustomHtmlInput',
  props: ['innerHTML', 'modelValue'],
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const inputContainer = ref(null)
    const input = ref(null)
    const inputValue = ref(props.modelValue)

    watch(inputValue, (newValue) => emit('update:modelValue', newValue))

    onMounted(() => {
      if (props.innerHTML) {
        inputContainer.value.innerHTML = props.innerHTML
      }
    })

    return {
      inputContainer,
      input,
      inputValue
    }
  }
}
</script>
