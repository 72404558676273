import login from './api/login'
import configureReports from './api/configureReports'
import homepage from './api/homepage'
import file from './api/file'
import MobileReportConfig from './api/mobileReportConfig'
import Personnel from './api/personnel'
import dic from './api/dic'
import inform from './api/inform'
import bigScreenConfig from './api/bigScreenConfig'
import printSet from './api/printSet'
import tmsManagement from './api/tmsManagement'
import dispatch from './api/dispatch'
import onway from './api/onway'
import permission from './api/permission'
import formconfig from './api/formconfig'
import mapapi from './api/mapapi'

export default {
  login,
  configureReports,
  homepage,
  file,
  MobileReportConfig,
  Personnel,
  dic,
  inform,
  bigScreenConfig,
  printSet,
  tmsManagement,
  dispatch,
  onway,
  permission,
  formconfig,
  mapapi,
}
