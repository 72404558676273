<template>
  <div class="batchUpdateEmployeeApprovalPermissions">
    <div class="table">
      <div class="search">
        <el-input style="width: 150px;margin-right: 15px;" placeholder="请输入姓名" v-model.trim="params.staffName"></el-input>
        <el-button @click="search" type="primary">查询</el-button>
      </div>
      <el-table :data="tableData" highlight-current-row @current-change="chooseChange" style="width: 94%;margin-left: 3%;" height="320px">
        <el-table-column prop="staffId" label="编号" align="center"></el-table-column>
        <el-table-column prop="depName" label="大队" align="center"></el-table-column>
        <el-table-column prop="staffName" label="姓名" align="center"></el-table-column>
      </el-table>
      <div class="pagination-block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.pageNum"
            :page-sizes="[10,20, 40, 100]"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
      <div class="footer">
        <el-button type="danger" @click="close">取消</el-button>
        <el-button type="primary" @click="conform">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {getAllStaff,updateStaffApprovalPermissions} from "@/requet/api/ba/scheduleApi";

export default {
  name:'batchUpdateEmployeeApprovalPermissions',
  props: ['queryParams'],
  data(){
    return{
      params: {
        staffName: null,
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      tableData: [],
      id:[],
      staffId:'',
    }
  },
  watch:{
    queryParams:{
      handler(newval){
        console.log('queryParams',newval);
        this.getid();
      }
    }
  },
  mounted(){
    console.log('queryParams', this.queryParams)
    this.getid();
    this.getList();
  },
  methods:{
    close(){
      this.$emit('btnLinkClose')
    },
    conform(){
      if(this.staffId==''){
        this.$message({
          message: '上级审批人未选择！',
          type: 'warning'
        });
      }else{
        this.updateStaffApprovalPermissions();
        this.$emit('btnLinkClose')
        this.$message({
          message: '上级审批人批量修改成功！',
          type: 'success'
        });
      }
    },
    search(){
      this.params.pageNum=1;
      this.getList();
    },
    async getList() {
      let res = await getAllStaff(this.params.pageNum, this.params.pageSize, this.params)
      this.tableData = res.list
      this.total = res.total
    },
    async updateStaffApprovalPermissions(){
      let res = await updateStaffApprovalPermissions({
        staffId:this.staffId,
        staffIdList:this.id,
      })
      console.log(res);
    },
    handleSizeChange(val){
      // console.log(val)
      this.params.pageSize=val;
      this.params.pageNum=1;
      this.getList();
    },
    handleCurrentChange(val){
      // console.log(val)
      this.params.pageNum=val;
      this.getList();
    },
    chooseChange(val){
      if(val){
        this.staffId=val.staffId;
      }else{
        this.staffId='';
      }
      console.log(this.staffId);
    },
    getid(){
      this.id=[];
      if(this.queryParams.length!=0){
        this.queryParams?.forEach(res=>{
          this.id.push(res.F0);
        })
      }
      console.log(this.id);
    },
  }
}
</script>
<style lang="scss" scoped>
.search{
  width: 100%;
}
.pagination-block {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4px;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
:deep(.el-table__body tr.current-row>td.el-table__cell){
  background-color:#409cff;
  color: white;
}
</style>
