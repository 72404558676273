<template>
  <div class="perPortrait">
    <div class="firstline">
      <!-- 头部人员消息 -->
      <div class="firstline-firstcolumn">
        <perPortraitPerson
          :peop-msg="peopMsg"
          :getintend="intend"
          :get-staff-id="staffId"
        />
      </div>
      <!-- 信用评分 -->
      <div class="firstline-secondcolumn">
        <div class="creditScore">
          <div class="creditScore-title">
            <p>总得分</p>
            <p class="p2">{{ level }}保安</p>
          </div>

          <div id="creditScore-echart" class="creditScore-echart" />
          <el-button plain class="button2" @click="scoreRecalculation()">
            分值计算
          </el-button>
        </div>
      </div>
      <!-- 静态、动态、违规评分 -->
      <div class="firstline-thirdcolumn">
        <scoringDetailslist :score-arr="scoreArr" :data-arr="dataArr" />
      </div>
    </div>
    <div class="secondline">
      <div class="secondline-firstcolumn">
        <div class="tagcloud-title">
          <span style="font-weight: bold; font-size: 1.2vw">个人标签</span>
        </div>
        <div class="tagcloud">
          <div
            style="width: 100%; height: 100%; display: flex; flex-wrap: wrap"
          >
            <div
              v-for="(item, index) in tagcloudMessage"
              :key="index"
              class="tagcloudSpan"
              :style="
                index % 2 == 0
                  ? 'margin-right:1%;' + item.bgColor
                  : item.bgColor
              "
            >
              <div
                style="
                  width: 60%;
                  height: 100%;
                  white-space: nowrap;
                  -webkit-transform: scale(0.7);
                "
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    font-size: 0.7rem;
                    margin-left: 0.2rem;
                    white-space: nowrap;
                    color: #999999;
                  "
                >
                  {{ item.kindName }}
                </div>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    font-size: 0.9rem;
                    margin-left: 0.2rem;
                    white-space: nowrap;
                  "
                >
                  {{ item.labelName }}
                </div>
              </div>
              <div
                style="
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img
                  :src="item.icon"
                  alt=""
                  style="height: 90%; margin-top: 25%"
                />
              </div>
            </div>
          </div>
          <!-- <a href="#" v-for="(item, index) in tagclouddata" :key="index">
                        <div class="a">
                          <div class="aimg">
                            <img :src="item.img" style="width: 1.5vw" />
                          </div>
                          <div class="aspan">
                            <span style="color: #3981e5" v-if="index % 4 == 0">{{
                              item.tip
                            }}</span>
                            <span style="color: #6fcf84" v-if="index % 4 == 1">{{
                              item.tip
                            }}</span>
                            <span style="color: #f47963" v-if="index % 4 == 2">{{
                              item.tip
                            }}</span>
                            <span style="color: #fdb33c" v-if="index % 4 == 3">{{
                              item.tip
                            }}</span>
                          </div>
                        </div>
                      </a> -->
        </div>
      </div>
      <div class="secondline-secondcolumn">
        <div class="workingAbility-title" style="margin-top: 0.5rem">
          <span style="font-weight: bold; font-size: 1.2vw">出勤统计</span>
          <div style="display: flex; align-items: center">
            <div
              style="
                width: 15px;
                height: 5px;
                background-color: #4687f7;
                border-radius: 10px;
                margin-right: 0.5rem;
              "
            />
            <span style="margin-right: 1rem" class="span0">出勤班数</span>
            <div
              style="
                width: 15px;
                height: 5px;
                background-color: #b9d1fc;
                border-radius: 10px;
                margin-right: 0.5rem;
              "
            />
            <span class="span0">请假班数</span>
          </div>
        </div>
        <div id="echarts1" class="secondcolumn-echarts" />
      </div>
      <div class="secondline-thirdcolumn">
        <div class="workingAbility-title" style="margin-top: 0.5rem">
          <span style="font-weight: bold; font-size: 1.2vw">考勤打卡</span>
          <div style="display: flex; align-items: center">
            <div
              style="
                width: 15px;
                height: 5px;
                background-color: #4687f7;
                border-radius: 10px;
                margin-right: 0.5rem;
              "
            />
            <span style="margin-right: 1rem; font-size: 0.9vw; color: #b2b2b2"
              >正常打卡</span
            >
            <div
              style="
                width: 15px;
                height: 5px;
                background-color: #fabb5d;
                border-radius: 10px;
                margin-right: 0.5rem;
              "
            />
            <span style="margin-right: 1rem; font-size: 0.9vw; color: #b2b2b2"
              >迟到早退</span
            >
            <div
              style="
                width: 15px;
                height: 5px;
                background-color: #ee726e;
                border-radius: 10px;
                margin-right: 0.5rem;
              "
            />
            <span style="margin-right: 1rem; font-size: 0.9vw; color: #b2b2b2"
              >缺卡</span
            >
          </div>
        </div>
        <div id="echarts3" class="thirdcolumn-echarts" />
      </div>
    </div>
    <div class="thirdline">
      <div class="thirdline-firstcolumn">
        <div class="workingAttitude-title">
          <span style="font-weight: bold; font-size: 1.2vw">工作态度</span>
        </div>
        <div id="workingAttitude-echart" class="workingAttitude-echart" />
      </div>
      <div class="thirdline-secondcolumn">
        <div class="workingAbility-title">
          <span style="font-weight: bold; font-size: 1.2vw">工作能力</span>
          <div>
            <div class="circle1" />
            <span style="font-size: 0.9vw; color: #b2b2b2; margin-right: 1vw"
              >基准分值</span
            >
            <div class="circle2" />
            <span style="font-size: 0.9vw; color: #b2b2b2">评估分值</span>
          </div>
        </div>
        <div id="workingAbility-echart" class="workingAbility-echart" />
      </div>
      <div class="thirdline-thirdcolumn">
        <div class="postInspectionRecord-title">
          <span style="font-weight: bold; font-size: 1.2vw">奖惩统计</span>
        </div>
        <div id="records" class="scrollDiv">
          <div
            v-for="(item, index) in rewardsPunishments"
            :key="index"
            class="recordsSpan"
            :style="item.bgColor"
          >
            <div
              style="
                display: flex;
                justify-content: center;
                height: 54%;
                width: 8%;
                margin-left: 3%;
              "
            >
              <img :src="item.icon" alt="" />
            </div>
            <div style="width: calc(89% - 4rem)">
              <div style="width: 100%; display: flex">
                <div
                  style="
                    font-size: 0.9vw;
                    margin-right: 0.8vw;
                    margin-left: 1vw;
                    width: 70%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    cursor: pointer;
                  "
                  :title="item.itemContent"
                >
                  {{ item.itemContent }}
                </div>
                <div style="font-size: 0.9vw; width: 30%">
                  {{
                    item.itemScore > 0 ? '+' + item.itemScore : item.itemScore

                  }}分
                </div>
              </div>
              <div>
                <span
                  style="
                    font-size: 0.9vw;
                    margin-right: 0.8vw;
                    margin-left: 1vw;
                  "
                  >{{ item.date }}</span
                >
                <span style="font-size: 0.9vw">{{ item.time }}</span>
              </div>
            </div>
            <div
              class="corporationLable"
              :style="
                item.kind == 'Y'
                  ? 'background-color: #518cfd'
                  : 'background-color: #ee726e'
              "
            >
              {{ item.itemName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import elementResizeDetectorMaker from 'element-resize-detector'
import perPortraitPerson from '@/components/aloneComponents/pg/duty/perPortraitPerson.vue'
import scoringDetailslist from '@/components/aloneComponents/pg/duty/scoringDetailslist.vue'
import '@/assets/js/tagcloud.js' //标签云js
import {
  getStaffScore,
  getStaffTwo,
  getCustomCount,
  getStaffLabel,
  getStaffRewards,
  calculateEvaluation
} from '@/requet/api/pg/perPortrait'
import { formatDate } from '@/utils/dateTime'
export default {
  name: 'PerPortrait',
  components: {
    perPortraitPerson,
    scoringDetailslist
  },
  props: ['params'],
  data() {
    return {
      flag: true,
      evalId: '',
      name: 0,
      show1: true,
      show2: false,
      select1: '',
      select2: false,
      staffId: '',
      year: '',
      month: '',
      getData: '',
      level: '',
      intend: '',
      dataArr: [],
      performance: [{}], //工作绩效
      attitude: [],
      attitudeNum: [],
      ability: [],
      peopMsg: '',
      monthArr1: [],
      normal: [],
      exception: [],
      offset: [],
      monthArr2: [],
      holiday: [],
      monthArr3: [],
      overtime: [],
      number0: 0,
      scoreArr: [
        {
          type: 'a1',
          icon: require('@/assets/images/perPortrait/jtpf.png'),
          text: '静态评分'
          // percentage: 0,
          // number: 202,
        },
        {
          type: 'a2',
          icon: require('@/assets/images/perPortrait/dtpf.png'),
          text: '动态评分'
          // percentage: 0,
          // number: 250,
        },
        {
          type: 'a3',
          // icon: require("@/assets/images/perPortrait/wgpf.png"),
          icon: require('@/assets/images/perPortrait/奖惩.png'),
          text: '奖惩评分',
          reward: 0,
          punish: 0
          // percentage: 0,
          // number: 0,
        }
      ],
      scoringDetailsdatas: [
        {
          img: require('@/assets/images/perPortrait/jcqk.png'),
          tip: '基础情况',
          score: 180
        },
        {
          img: require('@/assets/images/perPortrait/ywnl.png'),
          tip: '业务能力',
          score: 162
        },
        {
          img: require('@/assets/images/perPortrait/pj.png'),
          tip: '评价',
          score: 110
        },
        {
          img: require('@/assets/images/perPortrait/hyd.png'),
          tip: '活跃度',
          score: 151
        },
        {
          img: require('@/assets/images/perPortrait/lvynl.png'),
          tip: '履约能力',
          score: 138
        }
      ],
      orderRecorddatas: [
        {
          time: '2022-07-29 13:05:05',
          post: '常熟银行-门岗',
          worktime: '2022-07-30 周六'
        },
        {
          time: '2022-07-29 13:05:05',
          post: '常熟银行-门岗',
          worktime: '2022-07-30 周六'
        },
        {
          time: '2022-07-29 13:05:05',
          post: '常熟银行-门岗',
          worktime: '2022-07-30 周六'
        }
      ],
      activeName: 'first',
      // tableData: [
      //   {
      //     originalPosition: "一大队-副队长",
      //     adjustPosition: "一大队-大队长",
      //     postTransferDate: "2022-07-29",
      //     operator: "调度员张晓霞",
      //   },
      //   {
      //     originalPosition: "一大队-副队长",
      //     adjustPosition: "一大队-大队长",
      //     postTransferDate: "2022-07-29",
      //     operator: "调度员张晓霞",
      //   },
      //   {
      //     originalPosition: "一大队-副队长",
      //     adjustPosition: "一大队-大队长",
      //     postTransferDate: "2022-07-29",
      //     operator: "调度员张晓霞",
      //   },
      //   {
      //     originalPosition: "一大队-副队长",
      //     adjustPosition: "一大队-大队长",
      //     postTransferDate: "2022-07-29",
      //     operator: "调度员张晓霞",
      //   },
      // ],
      // tagclouddata: [
      //   { img: require("@/assets/images/perPortrait/l.png"), tip: "抗压力强" },
      //   { img: require("@/assets/images/perPortrait/lv.png"), tip: "执行力强" },
      //   { img: require("@/assets/images/perPortrait/h.png"), tip: "积极主动" },
      //   { img: require("@/assets/images/perPortrait/y.png"), tip: "抗压力强" },
      //   { img: require("@/assets/images/perPortrait/l.png"), tip: "热爱工作" },
      //   { img: require("@/assets/images/perPortrait/lv.png"), tip: "乐观" },
      //   { img: require("@/assets/images/perPortrait/h.png"), tip: "抗压力强" },
      // ],
      tagcloudMessage: [],
      rewardsPunishments: [],
      dbl: 0
    }
  },
  beforeUnmount() {},
  mounted() {
    this.staffId = this.params
    if (this.$route.query.staffId) {
      this.staffId = this.$route.query.staffId
    }

    console.log('查询工号:' + this.staffId)
    this.getStaffid()
    this.getStaffTwo()
    this.getCustomCount()
    this.getStaffScore()
    this.getcreditScore() //仪表盘echarts图
    this.getEchartsOne()
    this.getEchartsTwo()
    this.getworkingAttitude()
    this.getworkingAbility() //工作能力echarts图
    this.getStaffLabel()
    this.getStaffRewards()
    // this.gettagcloud();
    const dom = document.getElementById('records')
    dom.addEventListener('scroll', () => {
      const clientHeight = dom.clientHeight
      const scrollTop = dom.scrollTop
      const scrollHeight = dom.scrollHeight
      if (clientHeight + scrollTop >= scrollHeight) {
        if (this.rewardsPunishments.length <= 3) {
          this.dbl = 0
        }
        if (scrollTop > this.dbl + 1) {
          this.dbl = scrollTop
          // this.getStaffRewards()
        }
      }
    })
  },
  methods: {
    message4_filter(value) {
      let a = value[3]?.levelItem?.itemScore
      if (a == undefined) {
        let b = 0
        return b
      }
      return a
    },
    message3_filter(value) {
      let a = value[1]?.levelItem?.itemScore
      if (a == undefined) {
        let b = 0
        return b
      }
      return a
    },
    message2_filter(value) {
      let a = value[2]?.levelItem?.itemScore
      if (a == undefined) {
        let b = 0
        return b
      }
      return a
    },
    message1_filter(value) {
      let a = value[0]?.levelItem?.itemScore
      if (a == undefined) {
        let b = 0
        return b
      }
      return a
    },
    async getStaffLabel() {
      await getStaffLabel({ staffId: this.staffId }).then(res => {
        this.tagcloudMessage = res
        for (var i = 0; i < this.tagcloudMessage.length; i++) {
          if (this.tagcloudMessage[i].kind == '001') {
            this.tagcloudMessage[
              i
            ].icon = require('@/assets/images/perPortrait/gztdbq.png')
            this.tagcloudMessage[i].bgColor = 'background-color:#fff7eb'
          }
          if (this.tagcloudMessage[i].kind == '002') {
            this.tagcloudMessage[
              i
            ].icon = require('@/assets/images/perPortrait/gznlbq.png')
            this.tagcloudMessage[i].bgColor = 'background-color:#f9fbff'
          }
          if (this.tagcloudMessage[i].kind == '003') {
            this.tagcloudMessage[
              i
            ].icon = require('@/assets/images/perPortrait/gzjxbq.png')
            this.tagcloudMessage[i].bgColor = 'background-color:#f9fbff'
          }
          if (this.tagcloudMessage[i].kind == '004') {
            this.tagcloudMessage[
              i
            ].icon = require('@/assets/images/perPortrait/kqdkbq.png')
            this.tagcloudMessage[i].bgColor = 'background-color:#fef5f5'
          }
          if (this.tagcloudMessage[i].kind == '005') {
            this.tagcloudMessage[
              i
            ].icon = require('@/assets/images/perPortrait/bsjbq.png')
            this.tagcloudMessage[i].bgColor = 'background-color:#f0fcf8'
          }
          if (this.tagcloudMessage[i].kind == '006') {
            this.tagcloudMessage[
              i
            ].icon = require('@/assets/images/perPortrait/zzqdbq.png')
            this.tagcloudMessage[i].bgColor = 'background-color:#fff7eb'
          }
          if (this.tagcloudMessage[i].kind == '007') {
            this.tagcloudMessage[
              i
            ].icon = require('@/assets/images/perPortrait/jbbq.png')
            this.tagcloudMessage[i].bgColor = 'background-color:#fef5f5'
          }
          if (this.tagcloudMessage[i].kind == '008') {
            this.tagcloudMessage[
              i
            ].icon = require('@/assets/images/perPortrait/pjbq.png')
            this.tagcloudMessage[i].bgColor = 'background-color:#f9fbff'
          }
        }
      })
    },
    async getStaffRewards() {
      await getStaffRewards({ staffId: this.staffId }).then(res => {
        this.rewardsPunishments = res
        for (var i = 0; i < this.rewardsPunishments.length; i++) {
          this.rewardsPunishments[i].date = formatDate(
            this.rewardsPunishments[i].startDate,
            'YYYY-MM-DD'
          )
          this.rewardsPunishments[i].time = formatDate(
            this.rewardsPunishments[i].startDate,
            'HH:mm:ss'
          )
          if (this.rewardsPunishments[i].kind == 'Y') {
            this.rewardsPunishments[i].bgColor = 'background-color:#f9fbff'
            this.rewardsPunishments[
              i
            ].icon = require('@/assets/images/perPortrait/奖励单.png')
          }
          if (this.rewardsPunishments[i].kind == 'N') {
            this.rewardsPunishments[i].bgColor = 'background-color:#fef5f5'
            this.rewardsPunishments[
              i
            ].icon = require('@/assets/images/perPortrait/惩罚.png')
          }
          this.rewardsPunishments[i].itemScore = this.cutZero(
            this.rewardsPunishments[i].itemScore
          )
        }
        console.log(this.rewardsPunishments)
      })
    },
    cutZero(old) {
      // 拷贝一份 返回去掉零的新串
      old = old.toString()
      var newstr = old
      // 循环变量 小数部分长度
      var leng = old.length - old.indexOf('.') - 1
      // 判断是否有小数
      if (old.indexOf('.') > -1) {
        // 循环小数部分
        for (let i = leng; i > 0; i--) {
          // 存在小数
          if (newstr.indexOf('.') > -1) {
            // 如果newstr末尾有0
            if (newstr.indexOf('0') && newstr.substr(-1, 1) === '0') {
              var k = newstr.lastIndexOf('0')
              // 如果小数点后只有一个0 去掉小数点
              if (newstr.charAt(k - 1) === '.') {
                return newstr.substring(0, k - 1)
              } else {
                // 否则 去掉一个0
                newstr = newstr.substring(0, k)
              }
            } else {
              // 如果末尾有0
              return newstr
            }
          }
        }
      }
      return old
    },
    getStaffid() {
      let time = new Date()
      this.year = time.getFullYear()
      let Mon = time.getMonth() + 1
      this.month = Mon < 10 ? '0' + Mon : Mon
    },
    async getStaffTwo() {
      let res = await getStaffTwo({
        staffId: this.staffId
      })
      this.peopMsg = res
    },
    async getCustomCount() {
      let res = await getCustomCount({
        staffId: this.staffId
      })
      //考勤打卡数据
      let clock = res?.customCountList
      clock.forEach(e => {
        let a = e.month + '月'
        this.monthArr1.push(a)
        let b = e.normal
        this.normal.push(b)
        let c = e.exception
        this.exception.push(c)
        let d = e.offset
        this.offset.push(d)
      })
      this.getEchartsTwo()
      //病事假 =>//请假班数
      let holiday = res?.holidayCountList
      holiday.forEach(e => {
        let a = e.month + '月'
        let b = e.holiday //请假
        this.monthArr2.push(a)
        this.holiday.push(b)
      })
      //加班意愿 =>//出勤班数
      let overtime = res?.overtimeCountList
      overtime.forEach(e => {
        let a = e.month + '月'
        let b = e.overtime
        this.monthArr3.push(a)
        this.overtime.push(b)
      })
      this.getEchartsOne()
      // this.getEchartsThree()
    },
    async getStaffScore() {
      let res = await getStaffScore({
        staffId: this.staffId,
        year: this.year,
        month: this.month //this.month
      })
      this.getData = res?.empSocre
      this.evalId = res?.levelAndIntend?.evalId
      this.dataArr = res?.empSocre?.nodeItems
      this.level = res?.levelAndIntend?.level
      this.intend = res?.levelAndIntend?.intend
      if (this.getData?.levelItem?.itemScore >= 0) {
        this.number0 = this.getData.levelItem.itemScore
      } else {
        this.number0 = 0
      }

      this.getcreditScore()
      this.scoreArr[0][`number`] = this.dataArr[0].levelItem.itemScore
      this.scoreArr[1][`number`] = this.dataArr[1].levelItem.itemScore
      this.scoreArr[2][`number`] = this.dataArr[2].levelItem.itemScore
      this.scoreArr[2][`reward`] =
        this.dataArr[2].nodeItems[0].nodeItems[0].levelItem.itemScore
      this.scoreArr[2][`punish`] =
        this.dataArr[2].nodeItems[0].nodeItems[1].levelItem.itemScore

      this.scoreArr[0][`percentage`] = this.dataArr[0].levelItem.percent
      this.scoreArr[1][`percentage`] = this.dataArr[1].levelItem.percent
      this.scoreArr[2][`percentage`] = Math.abs(
        this.dataArr[2].levelItem.percent
      )
      //工作绩效
      let work = res?.empSocre?.nodeItems[1].nodeItems[0].nodeItems[2].nodeItems
      this.performance = work
      //工作态度
      let work2 =
        res?.empSocre?.nodeItems[1].nodeItems[0].nodeItems[0].nodeItems
      ;(this.attitude = []), (this.attitudeNum = [])
      work2.forEach(e => {
        let a = e.levelItem?.itemName
        let b = e.levelItem?.itemScore
        this.attitude.push(a)
        this.attitudeNum.push(b)
      })
      this.getworkingAttitude() //工作态度echarts图
      //工作能力
      let work3 =
        res?.empSocre?.nodeItems[1].nodeItems[0].nodeItems[1].nodeItems
      this.ability = []
      work3.forEach(e => {
        let a = e.levelItem?.itemScore
        this.ability.push(a)
      })
      this.getworkingAbility()
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    getEchartsOne() {
      var echartWatch = document.getElementById('echarts1')
      var echartBar = echarts.init(echartWatch)
      var option
      echartBar.clear()
      window.addEventListener('resize', () => {
        echartBar.resize()
      })
      option = {
        tooltip: {
          show: true,
          confine: true, // 将 tooltip 框限制在图表的区域内
          transitionDuration: 0, //[defaule:0.4] echart防止tooltip的抖动
          backgroundColor: 'rgba(255,255,255,1)',
          color: 'black',
          borderWidth: '1',
          borderColor: 'gray',
          padding: [5, 12, 5, 12],
          textStyle: {
            color: 'black'
          },
          trigger: 'axis',
          formatter: params => {
            // 获取xAxis data中的数据
            let dataStr = `<div><p style="font-size: 16px;
      font-family: OPPOSans-M;
      font-weight: 500;
      color: #5996eb;
      line-height: 20px;margin:0 5px 5px;">
    ${params[0].name}
</p></div>`
            params.forEach(item => {
              dataStr += `<div>
  <div style="margin: 0 8px;">
    <span style="font-size: 12px;
    font-family: OPPOSans-M;
    color: #7270A6;
    line-height: 14px;color:rgb(182,182,182)">${item.seriesName}:</span>
    <span style="font-size: 12px;
    font-family: Roboto-R;
    font-weight: 400;
    color: black;
    line-height: 16px;">${item.data}个</span>
  </div>
</div>`
            })
            return dataStr
          },
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 1, color: 'rgba(0,90,255,0)' },
                { offset: 0, color: 'rgba(0,90,255,0.15)' }
              ])
            }
          }
        },
        grid: {
          //与绝对定位相似，top，left，right，bottom 设定是根据上级盒子宽高来计算
          top: '0',
          left: '0',
          right: '0%',
          bottom: '17%'
        },
        xAxis: {
          type: 'category',
          data: this.monthArr2,
          axisLine: {
            lineStyle: {
              // color: "#rgb(241,243,246)",
              color: '#e5e9ef'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#a0a0a0',
            interval: 0,
            textStyle: { fontSize: '10' },
            //多出字可以省略显示成点
            formatter: function (params) {
              var index = 3 //字数为3个超出就显示成点
              var newstr = ''
              for (var i = 0; i < params.length; i += index) {
                var tmp = params.substring(i, i + index)
                newstr += tmp + ''
              }
              if (newstr.length > 3) return newstr.substring(0, 3) + '...'
              else return newstr
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#a0a0a0'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: ['rgb(241,243,246)']
            }
          }
        },
        series: [
          {
            name: '出勤班数',
            data: this.overtime,
            type: 'bar',
            barWidth: '15%',
            itemStyle: {
              color: '#3674f4',
              barBorderRadius: [4, 4, 4, 4]
            }
          },
          {
            name: '请假班数',
            type: 'bar',
            data: this.holiday,
            barWidth: '15%',
            itemStyle: {
              color: '#b9d1fc',
              barBorderRadius: [4, 4, 4, 4]
            }
          }
        ]
      }
      //解决tab切换 Echarts变形
      echartBar.setOption(option)
      var width = echartWatch.offsetWidth
      var height = echartWatch.offsetHeight / 1.1
      var resize = {
        width: width,
        height: height
      }
      echartBar.resize(resize)

      window.addEventListener('resize', () => {
        echartBar.resize()
      })
      const _this = this
      let erd = elementResizeDetectorMaker()
      erd.listenTo(echartWatch, () => {
        _this.$nextTick(() => {
          echartBar.resize()
        })
      })
    },
    getEchartsTwo() {
      var echartsTwo = document.getElementById('echarts3')
      var twoEchart = echarts.init(echartsTwo)
      var option
      ;(option = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'white',
          confine: true, // 将 tooltip 框限制在图表的区域内
          transitionDuration: 0, //[defaule:0.4] echart防止tooltip的抖动
          borderWidth: '1',
          borderColor: 'gray',
          padding: [5, 12, 5, 12],
          formatter: params => {
            // 获取xAxis data中的数据
            let dataStr = `<div><p style="font-size: 16px;
      font-family: OPPOSans-M;
      font-weight: 500;
      color: #5996eb;
      line-height: 20px;margin:0 5px 5px;">
    ${params[0].name}
</p></div>`
            params.forEach(item => {
              dataStr += `<div>
  <div style="margin: 0 8px;">
    <span style="font-size: 12px;
    font-family: OPPOSans-M;
    color: #7270A6;
    line-height: 14px;color:rgb(182,182,182)">${item.seriesName}:</span>
    <span style="font-size: 12px;
    font-family: Roboto-R;
    font-weight: 400;
    color: black;
    line-height: 16px;">${item.data}次</span>
  </div>
</div>`
            })
            return dataStr
          }
        },
        grid: {
          top: '0px',
          left: '0',
          right: '0',
          bottom: '-1px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.monthArr1,
          axisLine: {
            lineStyle: {
              color: '#e5e9ef'
            }
          },
          axisTick: {
            show: false
          },
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 1, color: 'rgba(0,90,255,0)' },
                { offset: 0, color: 'rgba(0,90,255,0.15)' }
              ])
            }
          },
          axisLabel: {
            interval: 0,
            inside: false,
            textStyle: {
              color: 'rgb(182,182,182)',
              fontSize: '10',
              fontFamily: 'OPPOSans-M'
            }
          }
        },
        yAxis: {
          type: 'value',
          show: false
        },
        series: [
          {
            name: '正常打卡',
            type: 'line',
            // stack: 'Total',
            data: this.normal,
            tooltip: {
              valueFormatter: function (value) {
                return value + '次'
              }
            },
            itemStyle: {
              normal: {
                color: '#5996eb',
                lineStyle: {
                  color: '#5996eb'
                }
              }
            }
          },
          {
            name: '迟到早退',
            type: 'line',
            // stack: 'Total',
            data: this.exception,
            tooltip: {
              valueFormatter: function (value) {
                return value + '次'
              }
            },
            itemStyle: {
              normal: {
                color: '#fabb5d',
                lineStyle: {
                  color: '#fabb5d'
                }
              }
            }
          },
          {
            name: '缺卡',
            type: 'line',
            // stack: 'Total',
            data: this.offset,
            tooltip: {
              valueFormatter: function (value) {
                return value + '次'
              }
            },
            itemStyle: {
              normal: {
                color: '#ee726e',
                lineStyle: {
                  color: '#ee726e'
                }
              }
            }
          }
        ]
      }),
        twoEchart.setOption(option)
      var width = echartsTwo.offsetWidth
      var height = echartsTwo.offsetHeight / 1.1
      var resize = {
        width: width,
        height: height
      }
      twoEchart.resize(resize)
      window.addEventListener('resize', () => {
        twoEchart.resize()
      })
      const _this = this
      let erd = elementResizeDetectorMaker()
      erd.listenTo(echartsTwo, () => {
        _this.$nextTick(() => {
          twoEchart.resize()
        })
      })
    },
    getcreditScore() {
      var chartcreditScore = document.getElementById('creditScore-echart')
      var creditScoreChart = echarts.init(chartcreditScore)
      var option
      option = {
        graphic: [
          {
            type: 'text',
            id: 'text01',
            left: '13%',
            top: '85%',
            style: {
              fill: '#b2b2b2',
              text: '0',
              font: '12px Microsoft YaHei'
            }
          },
          {
            type: 'text',
            id: 'text02',
            left: '76%',
            top: '85%',
            style: {
              fill: '#b2b2b2',
              text: '100',
              font: '12px Microsoft YaHei'
            }
          }
        ],
        series: [
          {
            type: 'gauge',
            radius: '120%',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            splitNumber: 0,
            center: ['50%', '78%'],
            itemStyle: {
              color: 'white',
              shadowColor: 'rgba(0,138,255,0.45)',
              shadowBlur: 10,
              shadowOffsetX: 2,
              shadowOffsetY: 2
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 18,
                roundCap: true,
                color: [[1, '#6fa4ff']]
              }
            },
            pointer: {
              length: '60%',
              width: 5,
              offsetCenter: [0, '5%']
            },
            splitLine: {
              show: false
            },

            // axisTick: {
            //   show: false,
            // },
            axisLabel: {
              show: false,
              distance: 0
            },
            title: {
              show: false
            },
            detail: {
              width: '60%',
              offsetCenter: [0, '28%'],
              valueAnimation: true,
              formatter: function (value) {
                return '{value|' + value.toFixed(2) + '}'
              },
              rich: {
                value: {
                  fontSize: 20,
                  fontWeight: 'bolder',
                  color: 'white'
                }
              }
            },
            data: [
              {
                value: this.number0
              }
            ]
          },
          {
            type: 'gauge',
            radius: '120%',
            startAngle: 180,
            endAngle: 180 - ((this.number0 - 0) / (100 - 0)) * 180,
            min: 0,
            max: 100,
            splitNumber: 0,
            center: ['50%', '78%'],
            axisLine: {
              lineStyle: {
                width: 18,
                roundCap: true,
                color: [[1, 'white']]
              }
            },
            pointer: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 0
            },
            title: {
              show: false
            },
            detail: {
              show: false
            }
          }
        ]
      }
      creditScoreChart.setOption(option)
      var width = chartcreditScore.offsetWidth
      var height = chartcreditScore.offsetHeight / 1.1
      var resize = {
        width: width,
        height: height
      }
      creditScoreChart.resize(resize)
      window.addEventListener('resize', () => {
        creditScoreChart.resize()
      })
      const _this = this
      let erd = elementResizeDetectorMaker()
      erd.listenTo(chartcreditScore, () => {
        _this.$nextTick(() => {
          creditScoreChart.resize()
        })
      })
      // let _this=this;
      // creditScoreChart.on('click', function (param){
      //   var timer;
      //   if(_this.flag){
      //     console.log(_this.name);
      //     _this.go();
      //   }
      //   _this.flag = false;
      //   clearTimeout(timer);
      //   timer = setTimeout(()=>{_this.flag = true}, 1000);
      // });
    },
    go() {
      console.log(11)
      this.$router.push({
        path: '/home/configurationForm/LM1411',
        // params: {
        //   menuid: "LM1411",
        // },
        query: {
          menuid: 'LM1411',
          title: '人员评分记录',
          configtype: '006',
          groupid: 'M11513',
          filtergroup: '',
          filtergroupshowsign: 'Y',
          pkval: this.evalId
        }
      })
    },
    getworkingAttitude() {
      var chartworkingAttitude = document.getElementById(
        'workingAttitude-echart'
      )
      var workingAttitudeChart = echarts.init(chartworkingAttitude)
      var option
      workingAttitudeChart.clear()
      window.addEventListener('resize', () => {
        workingAttitudeChart.resize()
      })
      const _this = this
      let erd = elementResizeDetectorMaker()
      erd.listenTo(chartworkingAttitude, () => {
        _this.$nextTick(() => {
          workingAttitudeChart.resize()
        })
      })
      option = {
        tooltip: {
          show: true,
          confine: true, // 将 tooltip 框限制在图表的区域内
          transitionDuration: 0, //[defaule:0.4] echart防止tooltip的抖动
          backgroundColor: 'rgba(255,255,255,1)',
          color: 'black',
          borderWidth: '1',
          borderColor: 'gray',
          padding: [5, 12, 5, 12],
          textStyle: {
            color: 'black'
          },
          trigger: 'axis',
          formatter: '{b}<br>{c}分',
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 1, color: 'rgba(0,90,255,0)' },
                { offset: 0, color: 'rgba(0,90,255,0.15)' }
              ])
            }
          }
        },
        grid: {
          //与绝对定位相似，top，left，right，bottom 设定是根据上级盒子宽高来计算
          top: '15%',
          left: '10%',
          right: '0%',
          bottom: '18%'
        },
        xAxis: {
          type: 'category',
          data: this.attitude,
          axisLine: {
            lineStyle: {
              color: '#e5e9ef'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#a0a0a0'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#a0a0a0'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: ['rgb(241,243,246)']
            }
          }
        },
        series: [
          {
            data: this.attitudeNum,
            type: 'bar',
            barWidth: '28%',
            itemStyle: {
              color: '#3674f4',
              barBorderRadius: [4, 4, 4, 4]
            }
          }
        ]
      }
      workingAttitudeChart.setOption(option)
      var width = chartworkingAttitude.offsetWidth
      var height = chartworkingAttitude.offsetHeight / 1.1
      var resize = {
        width: width,
        height: height
      }
      workingAttitudeChart.resize(resize)
    },
    getworkingAbility() {
      var chartworkingAbility = document.getElementById('workingAbility-echart')
      var workingAbilityChart = echarts.init(chartworkingAbility)
      var option
      workingAbilityChart.clear()
      var width = chartworkingAbility.offsetWidth
      var height = chartworkingAbility.offsetHeight / 1.1
      var resize = {
        width: width,
        height: height
      }
      workingAbilityChart.resize(resize)
      window.addEventListener('resize', () => {
        workingAbilityChart.resize()
      })
      option = {
        radar: {
          indicator: [
            { name: '计划性', max: 25 },
            { name: '协调沟通', max: 25 },
            { name: '应变能力', max: 25 },
            { name: '经验学识', max: 25 },
            { name: '岗位技能', max: 25 }
          ],
          name: {
            textStyle: {
              padding: [-15, -12],
              color: 'black'
            }
          }
        },
        series: [
          {
            type: 'radar',
            symbol: 'none',
            data: [
              {
                value: this.ability ? this.ability : [0, 0, 0, 0, 0],
                name: '评估分值',
                lineStyle: {
                  color: 'rgba(253,179,60,0.8)'
                },
                areaStyle: {
                  color: 'rgba(253,179,60,0.75)'
                }
              },
              {
                value: [20, 20, 20, 20, 20],
                name: '基准分值',
                lineStyle: {
                  color: 'rgba(54,116,244,0.8)'
                },
                areaStyle: {
                  color: 'rgba(54,116,244,0.2)'
                }
              }
            ]
          }
        ]
      }
      workingAbilityChart.setOption(option)
    },
    gettagcloud() {
      /*3D标签云*/
      tagcloud({
        selector: '.tagcloud', //元素选择器
        fontsize: 13, //基本字体大小, 单位px
        radius: 35, //滚动半径, 单位px
        mspeed: 'slow', //滚动最大速度, 取值: slow, normal(默认), fast
        ispeed: 'slow', //滚动初速度, 取值: slow, normal(默认), fast
        direction: 135, //初始滚动方向, 取值角度(顺时针360): 0对应top, 90对应left, 135对应right-bottom(默认)...
        keep: false //鼠标移出组件后是否继续随鼠标滚动, 取值: false, true(默认) 对应 减速至初速度滚动, 随鼠标滚动
      })
    },
    async scoreRecalculation() {
      calculateEvaluation({ staffId: this.staffId }).then(() => {
        this.number0 = 0
        this.getStaffScore()
        this.getcreditScore()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.perPortrait {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: rgb(243, 244, 248);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.firstline {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.firstline-firstcolumn {
  height: 100%;
  width: 25%;
  border-radius: 8px;
  background-color: white;
}
.firstline-secondcolumn {
  height: 100%;
  width: 20%;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: url('../../../../assets/images/perPortrait/bj.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.creditScore {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.creditScore-title {
  width: 80%;
  height: 15%;
  font-size: 1.2vw;
  font-weight: bold;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .p2 {
    background: -webkit-linear-gradient(
      470deg,
      rgba(248, 255, 6, 0.81) 25%,
      rgb(151 113 13)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.creditScore-echart {
  width: 90%;
  height: 85%;
}
.firstline-thirdcolumn {
  height: 100%;
  width: 53%;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.secondline {
  width: 100%;
  height: 33%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.secondline-firstcolumn {
  height: 100%;
  width: 25%;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tagcloud-title {
  width: 90%;
  height: 15%;
}
.tagcloud {
  width: 90% !important;
  height: 75% !important;
  min-width: 90% !important;
  min-height: 75% !important;
  position: relative;
}
.tagcloud a {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  padding: 5px 10px;
  color: #333;
  font-size: 0.9vw;
  border: 1px solid #e6e7e8;
  border-radius: 8px;
  background-color: #f0f2f5;
  text-decoration: none;
  white-space: nowrap;
  box-shadow: 6px 4px 8px 0 rgba(151, 142, 136, 0.34);
}
.a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.aimg {
  width: 20%;
  height: 100%;
  margin-right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aspan {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tagcloud > a:hover {
  color: #3385cf;
}
.secondline-secondcolumn {
  height: 100%;
  width: 36%;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.secondcolumn-echarts {
  width: 90%;
  height: 77%;
}
.thirdcolumn-echarts {
  width: 90%;
  height: 77%;
}
.orderRecord-title {
  width: 90%;
  height: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.orderRecord-whole {
  width: 95%;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.orderRecord-list {
  width: 100%;
  height: 30%;
  margin-bottom: 1vh;
  border-radius: 4px;
  background-color: #f9fbff;
  display: flex;
  flex-direction: row;
}
.orderRecord-list-img {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.orderRecord-list-whole {
  width: 85%;
  height: 100%;
  font-size: 0.9vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.secondline-thirdcolumn {
  height: 100%;
  width: 36%;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.thirdline {
  width: 100%;
  height: 33%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.thirdline-firstcolumn {
  height: 100%;
  width: 37%;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.workingAttitude-title {
  width: 90%;
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.workingAttitude-echart {
  width: 90%;
  height: 85%;
}
.thirdline-secondcolumn {
  height: 100%;
  width: 31%;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.workingAbility-title {
  width: 90%;
  height: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .span0 {
    font-size: 0.9vw;
    color: #b2b2b2;
    // cursor: pointer;
  }
  .span2 {
    font-size: 0.9vw;
    color: blue;
    border-bottom: 1px solid blue;
  }
}
.workingAbility-echart {
  width: 90%;
  height: 85%;
}
.thirdline-thirdcolumn {
  height: 100%;
  width: 30%;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.postInspectionRecord-title {
  width: 90%;
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.postInspectionRecord-whole {
  width: 90%;
  height: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.postInspectionRecord-whole-column {
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.postInspectionRecord-list {
  width: 100%;
  height: 46%;
  background-image: url('~@/assets/images/perPortrait/gzjx_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.postInspectionRecord-list-img {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.postInspectionRecord-list-whole {
  width: 55%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.postInspectionRecord-list1 {
  height: 95%;
  width: 45%;
  background-image: url('~@/assets/images/perPortrait/zg_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.postInspectionRecord-list2 {
  height: 95%;
  width: 45%;
  background-image: url('~@/assets/images/perPortrait/tg_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.postInspectionRecord-list-info {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.info {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.infotime {
  height: 80%;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.infoper {
  height: 80%;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.infoimg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.8vw;
}
.infobg {
  padding: 0 0.4vw;
  border-radius: 25%;
  color: #808181;
  background-color: #edf1f8;
  font-size: 0.9vw;
}
.infotext {
  height: 50%;
  width: 70%;
  font-size: 1vw;
  text-align: center;
}
.postInspectionRecord-list-btn {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle1 {
  display: inline-block;
  width: 0.5vw;
  height: 0.5vw;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #3674f4;
}
.circle2 {
  display: inline-block;
  width: 0.5vw;
  height: 0.5vw;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #f9b859;
}
.corporationLable {
  float: right;
  font-size: 0.5vw;
  margin-bottom: 6%;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 4rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.tagcloudSpan {
  width: 49%;
  height: 24%;
  margin-bottom: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recordsSpan {
  width: 100%;
  height: 32%;
  margin-top: 1%;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.scrollDiv {
  width: 90%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
:deep(.el-button) {
  padding: 3px 2px;
  margin-top: 5%;
  color: white;
  border: none;
  background-color: #609aff;
}
:deep(.el-tabs__header) {
  margin: 0px;
}
:deep(.el-tabs__item.is-active) {
  color: #3981e5;
}
:deep(.el-table th.el-table__cell > .cell) {
  text-align: center;
}
:deep(.el-table) {
  overflow: visible;
}
</style>
