<template>
  <div class="viewGradeTypePopBlock">
    <div class="types">
      <!-- 显示排班 -->
      <div v-for="item in showList" class="types-item" style="flex-wrap: wrap">
        <div v-for="temp in item.shiftList" class="types-item">
          <gradeTypeViewCard :item="temp" :is-has-mutil-days="isMutilDays" />
        </div>
      </div>
      <!-- 显示请假 -->
      <div v-for="item in holidayList" class="holiday-item">
        {{ item.holidaytypeName }}:{{ item.holidayBeginDate }}~{{
          item.holidayEndDate
        }}
      </div>
    </div>
    <div class="footer">
      <el-icon class="footer-close">
        <el-icon-circle-close />
      </el-icon>
    </div>
  </div>
</template>

<script>
import { CircleClose as ElIconCircleClose } from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../../../utils/gogocodeTransfer'
import gradeTypeViewCard from '@/components/aloneComponents/pg/duty/gradeTypeViewCard'

export default {
  name: 'ViewGradeTypePop',
  components: {
    gradeTypeViewCard,
    ElIconCircleClose
  },
  props: {
    date: {
      type: String
    },
    postation: {
      type: Array,
      required: true
    },
    list: Array,
    dateList: Array
  },
  emits: ['close'],
  data() {
    return {}
  },
  computed: {
    isMutilDays() {
      //每月一号数组里存在classesDay2，用classesDay2判断是否跨日显示日期
      //其他日期数组里无classesDay2，用classesDay判断是否跨日显示日期
      return this.list?.filter(x =>
        x.classesDay2 ? Number(x.classesDay2) > 0 : Number(x.classesDay) > 0
      )?.length > 0
        ? true
        : false
      // return false
    },
    showList() {
      //判断跨日 classesDay > 0 或 attendanceDate != date
      let mutilDay = this.list?.find(
        x =>
          Number(x.classesDay) > 0 ||
          this.$moment(x.attendanceDate).isBefore(this.date)
      )
      let arr = []
      // console.log('mutilDay-' + this.date, mutilDay, this.list)
      if (mutilDay) {
        let mutilDayIndex = this.dateList.findIndex(
          x =>
            x.date == mutilDay.attendanceDate ||
            this.$moment(mutilDay.attendanceDate).isBefore(x.date)
        ) //上月日期处理
        // console.log('mutilDayIndex', mutilDayIndex)
        for (let i = 0; i <= Number(mutilDay.classesDay); i++) {
          if (this.dateList[mutilDayIndex + i]?.shiftList) {
            arr.push(this.dateList[mutilDayIndex + i])
          }
        }
      } else {
        arr = [this.dateList.find(x => x.attendanceDate == this.date)]
      }
      // console.log(arr)
      return arr
    },
    // 请假信息
    holidayList() {
      let arr = []
      for (const item of this.showList) {
        if (item.holidays != null) {
          for (const holiday of item.holidays) {
            // 如果不存在，则加入队列（用于去重）
            if (
              !arr.find(
                i =>
                  i.holidayBeginDate == holiday.holidayBeginDate &&
                  i.holidayEndDate == holiday.holidayEndDate &&
                  i.holidaytype == holiday.holidaytype
              )
            ) {
              arr.push(holiday)
            }
          }
        }
      }
      return arr
    }
  },
  methods: {
    close() {
      console.log('close', this.postation)
      $emit(this, 'close', this.postation)
    }
  }
}
</script>

<style lang="scss" scoped>
.viewGradeTypePopBlock {
  display: flex;
  flex-wrap: wrap;
  width: 180px;
  .head {
    width: 100%;
    display: flex;
    align-items: center;
    height: 30px;
    &-title {
      width: 80%;
      font-size: 14px;
      font-weight: 550;
    }
  }

  .types {
    width: 100%;
    margin: 5px 0 5px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //justify-content: space-between;

    &-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 3px;
    }
  }

  .footer {
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-close {
      width: 100%;
      display: flex;
      justify-content: center;
      color: #bbc1ce;
      cursor: pointer;
      font-size: 14px;
    }
  }

  .holiday-item {
    font-size: 13px;
    text-align: center;
    text-overflow: ellipsis;
    background-color: #f56c6c;
    color: #ffffff;
    border-radius: 5px;
    margin-bottom: 5px;
  }
}
</style>
