import { axios, post, fetch, patch, put, doPostJson } from '@/http'

/**
 * 更新经纬度
 * @param params
 *
 */
export async function updateLocation(params) {
  return doPostJson('/Custom/Location/updateLocation', params)
}
