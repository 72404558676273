<template>
  <div class="all">
    <div class="itemall">
      <div class="left">
        <div class="one">
          <!-- 考勤统计 -->
          <div class="one-left">
            <div class="one-left-title">考勤统计</div>
            <div class="one-left-whole">
              <div class="one-left-list1">
                <div
                  class="list1"
                  style="cursor: pointer; width: 35%"
                  @click="
                    getStatisticsdata('应打卡'),
                    (Statisticstip = '应打卡统计'),
                    (Clocknums = ClockCount.all),
                    (dialogStatistics = true)
                  "
                >
                  <span class="span1">{{ ClockCount.all }}</span>
                  <span>应打卡</span>
                </div>
                <div
                  class="list1"
                  style="cursor: pointer; width: 35%"
                  @click="
                    getStatisticsdata('已打卡'),
                    (Statisticstip = '已打卡统计'),
                    (Clocknums = ClockCount.already),
                    (dialogStatistics = true)
                  "
                >
                  <span class="span1">{{ ClockCount.already }}</span>
                  <span>已打卡</span>
                </div>
              </div>
              <div class="one-left-list2">
                <div
                  class="list2"
                  style="cursor: pointer"
                  @click="
                    getStatisticsdata('迟到'),
                    (Statisticstip = '迟到统计'),
                    (Clocknums = ClockCount.late),
                    (dialogStatistics = true)
                  "
                >
                  <span class="span1">{{ ClockCount.late }}</span>
                  <span>迟到</span>
                </div>
                <div
                  class="list2"
                  style="cursor: pointer"
                  @click="
                    getStatisticsdata('早退'),
                    (Statisticstip = '早退统计'),
                    (Clocknums = ClockCount.early),
                    (dialogStatistics = true)
                  "
                >
                  <span class="span1">{{ ClockCount.early }}</span>
                  <span>早退</span>
                </div>
                <div
                  class="list2"
                  style="cursor: pointer"
                  @click="
                    getStatisticsdata('缺卡'),
                    (Statisticstip = '缺卡统计'),
                    (Clocknums = ClockCount.lack),
                    (dialogStatistics = true)
                  "
                >
                  <span class="span1">{{ ClockCount.lack }}</span>
                  <span>缺卡</span>
                </div>
                <div
                  class="list2"
                  style="cursor: pointer"
                  @click="
                    getStatisticsdata('范围外'),
                    (Statisticstip = '范围外统计'),
                    (Clocknums = ClockCount.out),
                    (dialogStatistics = true)
                  "
                >
                  <span class="span1">{{ ClockCount.out }}</span>
                  <span>范围外</span>
                </div>
                <div
                  class="list1"
                  style="cursor: pointer"
                  @click="
                    getStatisticsdata('未排班打卡'),
                    (Statisticstip = '未排班打卡统计'),
                    (Clocknums = ClockCount.extra),
                    (dialogStatistics = true)
                  "
                >
                  <span class="span1">{{ ClockCount.extra }}</span>
                  <span>未排班</span>
                </div>
                <el-dialog
                  v-model="dialogStatistics"
                  :before-close="StatisticsClose"
                  :close-on-click-modal="false"
                  :title="Statisticstip"
                  width="80%"
                >
                  <el-table
                    :data="ClockDetail"
                    border
                    height="250"
                    style="width: 100%"
                    @row-click="handleCurrentChange"
                  >
                    <el-table-column type="index" width="50" />
                    <el-table-column label="大队" prop="depName" />
                    <el-table-column label="驻点" prop="arrestName" />
                    <el-table-column label="人员姓名" prop="staffName" />
                    <el-table-column label="岗位" width="160" prop="postName" />
                    <el-table-column
                      label="班次"
                      width="160"
                      prop="classesName"
                    />
                    <el-table-column
                      label="应打卡时间"
                      width="190"
                      prop="standardClockTime"
                    />
                    <el-table-column
                      label="实际打卡时间"
                      width="190"
                      prop="clockTime"
                    />
                    <el-table-column label="打卡类型" prop="clockType" />
                    <el-table-column label="打卡结果" prop="clockResult" />
                  </el-table>
                  <div style="text-align: center; margin-top: 10px">
                    <el-pagination
                      v-model:current-page="ClockpageId"
                      :total="Clocknums"
                      background
                      layout="prev, pager, next, total, jumper"
                    />
                  </div>
                </el-dialog>
              </div>
            </div>
          </div>
          <!-- 考勤异常 -->
          <div class="one-right">
            <el-dialog
              v-model="dialoghandle"
              :before-close="handleClose"
              :close-on-click-modal="false"
              width="80%"
            >
              <span>请输入审批意见：</span>
              <el-input v-model="ClockException" :rows="3" type="textarea" />
              <template #footer>
                <span class="dialog-footer">
                  <el-button
                    @click="
                      ;(dialogin = false),
                        (dialoghandle = false),
                        (ClockExceptionbtn = false)
                    "
                    >取 消</el-button
                  >
                  <el-button
                    v-if="batching == false"
                    :disabled="
                      ClockException == '' && ClockExceptionbtn == true
                        ? true
                        : false
                    "
                    type="primary"
                    @click="clickbtn()"
                    >确 定</el-button
                  >
                  <el-button
                    v-if="batching == true"
                    :disabled="
                      ClockException == '' && ClockExceptionbtn == true
                        ? true
                        : false
                    "
                    type="primary"
                    @click="clickbtns()"
                    >确 定</el-button
                  >
                </span>
              </template>
            </el-dialog>
            <div class="one-right-title">
              <div class="one-right-title-font">
                <span>异常处理</span>
              </div>
              <div class="one-right-title-i" @click="dialogi = true">
                <el-icon><el-icon-arrow-right /></el-icon>
              </div>
              <el-dialog
                v-model="dialogi"
                :before-close="iClose"
                :close-on-click-modal="false"
                title="考勤异常查询"
                width="80%"
              >
                <el-date-picker
                  v-model="selectdate"
                  format="yyyy 年 MM 月 dd 日"
                  placeholder="选择日期"
                  style="width: 30%"
                  type="date"
                  value-format="YYYY-MM-DD"
                />
                <el-input
                  v-model="selectname"
                  placeholder="请输入人员姓名"
                  style="width: 30%; margin-left: 5%"
                />
                <el-select
                  v-model="selecttype"
                  clearable
                  collapse-tags
                  multiple
                  placeholder="异常类型"
                  style="width: 30%; margin-left: 5%"
                >
                  <el-option
                    v-for="(item, index) in ExceptionTypelist"
                    :key="index"
                    :label="item.codeName"
                    :value="item.codeName"
                  />
                </el-select>
                <div style="margin-top: 5px; margin-bottom: 5px">
                  <el-button size="small" type="button" @click="search()">
                    查询
                  </el-button>
                  <el-button
                    :disabled="tabledatas.length == 0 ? true : false"
                    size="small"
                    type="button"
                    @click="
                      ;(dialoghandle = true),
                        (batching = true),
                        (ClockException = '')
                    "
                  >
                    处理为正常
                  </el-button>
                  <el-button
                    :disabled="tabledatas.length == 0 ? true : false"
                    size="small"
                    type="button"
                    @click="
                      ;(dialoghandle = true),
                        (batching = true),
                        (ClockExceptionbtn = true),
                        (ClockException = '')
                    "
                  >
                    驳回
                  </el-button>
                </div>
                <el-table
                  :data="onelist2"
                  border
                  height="250"
                  style="width: 100%"
                  @row-dblclick="dblclicktable"
                  @selection-change="handleSelectionChange"
                >
                  <el-table-column type="selection" width="40" />
                  <el-table-column label="序号" type="index" align="center" />
                  <el-table-column label="姓名" prop="staffName" />
                  <el-table-column label="大队" prop="depName" />
                  <el-table-column label="驻点" prop="arrestName" />
                  <el-table-column label="岗位" prop="postName" />
                  <el-table-column label="班次" prop="classesName" />
                  <el-table-column label="班次日期" prop="attendanceDate" />
                  <el-table-column label="打卡时间" prop="clockTime" />
                  <el-table-column label="异常类型" prop="specialType" />
                  <el-table-column label="原因" prop="reason" />
                  <el-table-column label="操作" width="120">
                    <template #default="scope">
                      <el-button
                        size="small"
                        type="text"
                        @click="
                          getbtnvalue(
                            scope.row.clockId,
                            scope.row.createTime,
                            scope.row.schedulingId,
                            scope.row.specialId
                          ),
                          (dialoghandle = true),
                          (ClockException = '')
                        "
                      >
                        处理为正常
                      </el-button>
                      <el-button
                        v-if="scope.row.specialId != null"
                        size="small"
                        style="color: #f47451"
                        type="text"
                        @click="
                          getbtnvalue(
                            scope.row.clockId,
                            scope.row.createTime,
                            scope.row.schedulingId,
                            scope.row.specialId
                          ),
                          (dialoghandle = true),
                          (ClockExceptionbtn = true),
                          (ClockException = '')
                        "
                      >
                        驳回
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <div style="text-align: center; margin-top: 10px">
                  <el-pagination
                    v-model:current-page="ipage"
                    :total="inums"
                    background
                    layout="prev, pager, next, total, jumper"
                  />
                </div>
              </el-dialog>
            </div>
            <div class="one-right-whole">
              <div
                v-for="(item, index) in onelist"
                :key="index"
                class="one-right-list"
                @dblclick="clickonelist(item)"
              >
                <div class="one-right-list-img">
                  <img src="@/assets/images/dispacthingCenter/kq_qk.png" />
                </div>
                <div class="one-right-list-font">
                  <div class="one-right-list-font1">
                    <el-tooltip
                      :content="item.specialType"
                      class="item"
                      effect="light"
                      placement="top-start"
                    >
                      <span class="fontp1"
                        >异常类型：{{ item.specialType }}</span
                      >
                    </el-tooltip>
                    <el-tooltip
                      :content="item.reason"
                      class="item fontp2"
                      effect="light"
                      placement="top-start"
                    >
                      <span v-if="item.specialId != null"
                        >原因：{{ item.reason }}</span
                      >
                    </el-tooltip>
                    <span v-if="item.specialId == null">尚未提交审批单</span>
                  </div>
                  <div class="one-right-list-font2">
                    <span>{{ item.staffName }}</span
                    ><span>|</span><span>{{ item.clockTime }}</span>
                  </div>
                </div>
                <div
                  class="one-right-list-button1"
                  @click="
                    getbtnvalue(
                      item.clockId,
                      item.createTime,
                      item.schedulingId,
                      item.specialId
                    ),
                    (dialoghandle = true),
                    (ClockException = '')
                  "
                >
                  <el-button link type="primary"> 处理为正常 </el-button>
                </div>
                <div
                  v-if="item.specialId != null"
                  class="one-right-list-button2"
                  @click="
                    getbtnvalue(
                      item.clockId,
                      item.createTime,
                      item.schedulingId,
                      item.specialId
                    ),
                    (dialoghandle = true),
                    (ClockExceptionbtn = true),
                    (ClockException = '')
                  "
                >
                  <el-button style="color: #f47451" type="text">
                    驳回
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 岗位空缺 -->
        <div class="two">
          <div class="two-title">
            <div class="two-title-font">
              <span>岗位空缺</span>
            </div>
            <div class="two-title-ileft" @click="dialogNopost = true">
              <span>更多</span>
            </div>
            <div class="two-title-i">
              <el-icon><el-icon-arrow-right /></el-icon>
            </div>
            <el-dialog
              v-model="dialogNopost"
              :before-close="NopostClose"
              :close-on-click-modal="false"
              title="岗位空缺查询"
              width="80%"
            >
              <el-select
                v-model="Nopoststaff"
                clearable
                collapse-tags
                filterable
                multiple
                placeholder="驻点"
                style="width: 22%"
              >
                <el-option
                  v-for="(item, index) in Arrest"
                  :key="index"
                  :label="item.arrestName"
                  :value="item.arrestId"
                />
              </el-select>
              <el-select
                v-model="Nopostpost"
                clearable
                collapse-tags
                filterable
                multiple
                placeholder="岗位"
                style="width: 22%; margin-left: 4%"
              >
                <el-option
                  v-for="(item, index) in Post"
                  :key="index"
                  :label="item.postName"
                  :value="item.postId"
                />
              </el-select>
              <el-select
                v-model="Nopostclass"
                clearable
                collapse-tags
                filterable
                multiple
                placeholder="班次"
                style="width: 22%; margin-left: 4%"
              >
                <el-option
                  v-for="(item, index) in Classes"
                  :key="index"
                  :label="item.classesName"
                  :value="item.classesId"
                />
              </el-select>
              <el-date-picker
                v-model="Nopostdate"
                format="yyyy 年 MM 月 dd 日"
                placeholder="选择日期"
                style="width: 22%; margin-left: 4%"
                type="date"
                value-format="YYYY-MM-DD"
              />
              <div style="margin-top: 5px; margin-bottom: 5px">
                <el-button size="small" type="primary" @click="nopostSearch()">
                  查询
                </el-button>

                <el-button
                  size="small"
                  type="button"
                  @click="
                    addOrderPushObjtype = 'Multiple',
                    addOrderPushObjDialogShow = true
                  "
                >
                  分派工单
                </el-button>
              </div>
              <el-table
                :data="twolist2"
                border
                height="260"
                style="width: 100%"
                @selection-change="twolist2HandleSelectionChange"
              >
                <el-table-column type="selection" width="55" />
                <el-table-column label="驻点" prop="arrestName" />
                <el-table-column label="岗位" prop="postName" />
                <el-table-column label="班次" prop="classesName" />
                <el-table-column label="开始时间" prop="startDate" />
                <el-table-column label="结束时间" prop="endDate" />
                <el-table-column label="操作" width="120">
                  <template #default="scope">
                    <el-button
                      size="small"
                      type="text"
                      @click="postOrder(scope.row)"
                    >
                      派单
                    </el-button>
                    <el-button
                      size="small"
                      type="text"
                      @click="
                        ;(needStaffpage = 1),
                          (NoStaffperson = ''),
                          (needarrestName = scope.row.arrestName),
                          (needpostName = scope.row.postName),
                          (needclassName = scope.row.classesName),
                          (needRow = scope.row),
                          getStaff(
                            scope.row.endDate,
                            scope.row.startDate,
                            scope.row.schedulingId
                          )
                      "
                    >
                      指派
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div style="text-align: center; margin-top: 10px">
                <el-pagination
                  v-model:current-page="Nopostpage"
                  :total="Nopostnums"
                  background
                  layout="prev, pager, next, total, jumper"
                />
              </div>
            </el-dialog>
          </div>
          <div class="two-whole">
            <div
              v-for="(item, index) in twolist"
              :key="index"
              class="two-whole-list"
              @click="
                ;(needStaffpage = 1),
                  (NoStaffperson = ''),
                  (needarrestName = item.arrestName),
                  (needpostName = item.postName),
                  (needclassName = item.classesName),
                  (needRow = item),
                  getStaff(item.endDate, item.startDate, item.schedulingId)
              "
            >
              <div class="two-whole-listrow">
                <div class="two-whole-list-font">
                  <span class="two-whole-span1"
                    >{{ item.arrestName }}-{{ item.postName }}</span
                  >
                  <span class="two-whole-span2"
                    >{{ item.classesName }}({{
                    nopostdates[index].starttime











                    }}-{{ nopostdates[index].endtime }})</span
                  >
                </div>
                <div class="two-whole-list-img">
                  <img src="@/assets/images/dispacthingCenter/gwkq_icon.png" />
                </div>
              </div>
            </div>
            <el-dialog
              v-model:value="dialogNoStaff"
              :before-close="NoStaffClose"
              :close-on-click-modal="false"
              title="空缺岗位排班"
              width="80%"
            >
              <div style="display: flex; flex-direction: column">
                <div style="display: inline-block; margin: 1% 0">
                  <!-- <el-button
                                size="small"
                                :type="needKind === '001' ? 'primary' : 'button'"
                                @click="staffsearch('001')"
                              >
                                空闲
                              </el-button> -->
                  <el-button
                    size="small"
                    :type="needKind === '002' ? 'primary' : 'button'"
                    @click="staffsearch('002')"
                  >
                    全部
                  </el-button>
                  <el-button
                    size="small"
                    :type="needKind === '003' ? 'primary' : 'button'"
                    @click="staffsearch('003')"
                  >
                    同审批人
                  </el-button>
                  <el-button
                    size="small"
                    :type="needKind === '004' ? 'primary' : 'button'"
                    @click="staffsearch('004')"
                  >
                    保安部
                  </el-button>
                  <el-button
                    size="small"
                    :type="needKind === '006' ? 'primary' : 'button'"
                    @click="staffsearch('006')"
                  >
                    本大队
                  </el-button>
                  <el-button
                    size="small"
                    :type="needKind === '007' ? 'primary' : 'button'"
                    @click="staffsearch('007')"
                  >
                    本驻点
                  </el-button>
                </div>
                <div style="margin-bottom: 1%">
                  <el-input
                    v-model="NoStaffperson"
                    placeholder="请输入人员姓名"
                    style="width: 15%"
                  />
                  <el-input
                    v-model="noStaffDepName"
                    placeholder="请输入大队名称"
                    style="width: 15%; margin-left: 2%"
                  />
                  <el-input
                    v-model="noStaffArrestName"
                    placeholder="请输入驻点名称"
                    style="width: 15%; margin-left: 2%"
                  />
                  <el-checkbox
                    v-model="kongXian"
                    style="width: 3%; margin-left: 2%"
                    @change="staffsearch(needKind)"
                  >
                    空闲
                  </el-checkbox>
                  <el-checkbox
                    v-model="sendExpatriate"
                    style="margin-left: 2%"
                    @change="staffsearch(needKind)"
                  >
                    外派人员
                  </el-checkbox>
                  <el-checkbox
                    v-model="sendFireNo"
                    @change="staffsearch(needKind)"
                  >
                    消控证
                  </el-checkbox>
                  <el-checkbox
                    v-model="service"
                    @change="staffsearch(needKind)"
                  >
                    服兵役
                  </el-checkbox>
                  <el-checkbox
                    v-model="experience"
                    @change="staffsearch(needKind)"
                  >
                    同岗经历
                  </el-checkbox>
                  <br />
                  <el-select
                    v-model="sexValue"
                    placeholder="性别"
                    size="mini"
                    style="width: 10%; margin-top: 10px"
                    @change="staffsearch(needKind)"
                  >
                    <el-option
                      v-for="item in sexARrr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      size="mini"
                    />
                  </el-select>
                  <el-select
                    v-model="degree"
                    placeholder="学历"
                    size="mini"
                    style="width: 15%; margin-left: 2%; margin-top: 10px"
                    @change="staffsearch(needKind)"
                  >
                    <el-option
                      v-for="item in degreeArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      size="mini"
                    />
                  </el-select>
                  <el-input
                    v-model="height"
                    type="number"
                    size="mini"
                    placeholder="身高下限"
                    style="width: 20%; margin-left: 2%; margin-top: 10px"
                    min="120"
                    max="200"
                  >
                    <template #prepend> 身高/cm </template>
                  </el-input>

                  <el-input
                    v-model="ageMin"
                    type="number"
                    size="mini"
                    placeholder="最小年龄"
                    style="width: 17%; margin-left: 2%; margin-top: 10px"
                    min="0"
                    max="120"
                  >
                    <template #prepend> 年龄 </template>
                  </el-input>
                  <span style="margin-top: 10px">至</span>
                  <el-input
                    v-model="ageMax"
                    type="number"
                    size="mini"
                    placeholder="最大年龄"
                    style="width: 10%"
                    min="0"
                    max="120"
                  />

                  <el-button
                    size="small"
                    :type="needKind === '005' ? 'primary' : 'button'"
                    style="margin-left: 2%"
                    @click="staffsearch('005')"
                  >
                    查询
                  </el-button>
                </div>
              </div>
              <!--              <br />-->
              <div style="margin: 10px 0 10px 0">
                <span style="margin-left: 0%">驻点：{{ needarrestName }}</span>
                <span style="margin-left: 2%">岗位：{{ needpostName }}</span>
                <span style="margin-left: 2%">班次：{{ needclassName }}</span>
                <span style="margin-left: 1%"
                  >开始时间：{{ needRow.startDate }}</span
                >
                <span style="margin-left: 1%"
                  >结束时间：{{ needRow.endDate }}</span
                >
              </div>
              <el-table
                :data="needStaffdatas"
                border
                height="160"
                style="width: 100%; margin-top: 5px"
              >
                <el-table-column type="index" width="50" />
                <el-table-column label="部门" prop="depName" />
                <el-table-column label="驻点" prop="arrestName" />
                <el-table-column label="工号" prop="oldStaffId" />
                <el-table-column label="姓名" prop="staffName" />
                <el-table-column label="联系方式" prop="relationTele" />
                <el-table-column label="性别" prop="sex" />
                <el-table-column label="年龄" prop="age" />
                <el-table-column label="操作" width="100">
                  <template #default="scope">
                    <el-button
                      size="small"
                      type="text"
                      @click="open(scope.row.staffId)"
                    >
                      安排
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div style="text-align: center; margin-top: 10px">
                <el-pagination
                  v-model:current-page="needStaffpage"
                  :total="needStaffdatanums"
                  background
                  layout="prev, pager, next, total, jumper"
                />
              </div>
            </el-dialog>
          </div>
        </div>
        <div class="three">
          <!-- OA审批 -->
          <div class="three-left">
            <div class="three-left-title">
              <div class="three-left-title-top">
                <span>OA审批</span>
                <div @click="toOa()">
                  <span
                    style="
                      color: #adaeb0;
                      font-size: 1vw;
                      font-weight: 100;
                      cursor: pointer;
                    "
                    >更多</span
                  >
                  <el-icon><el-icon-arrow-right /></el-icon>
                </div>
              </div>
              <div class="three-left-title-choose">
                <div class="spanc">
                  <span
                    :class="spanctype == 'holiday' ? 'spancchecked' : ''"
                    @click="spanctype = 'holiday'"
                  >
                    请假({{ holidayNum }})
                  </span>
                </div>
                <div class="spanc">
                  <span
                    :class="spanctype == 'travel' ? 'spancchecked' : ''"
                    @click="spanctype = 'travel'"
                  >
                    出差({{ travelNum }})
                  </span>
                </div>
                <div class="spanc">
                  <span
                    :class="spanctype == 'outtime' ? 'spancchecked' : ''"
                    @click="spanctype = 'outtime'"
                  >
                    加班({{ overtimeNum }})
                  </span>
                </div>
              </div>
            </div>
            <div class="three-left-whole">
              <!-- 请假 -->
              <template v-for="(item, index) in holidayList">
                <div
                  v-if="spanctype == 'holiday'"
                  class="three-left-whole-list"
                >
                  <div class="three-left-whole-list-img">
                    <img src="@/assets/images/dispacthingCenter/oa.png" />
                  </div>

                  <div class="three-left-whole-list-font">
                    <div class="three-left-whole-list-font1">
                      <span class="font1-margin">{{ item.staffName }}</span>
                      <span v-if="item.days != null"
                        >请假{{ item.days }}天</span
                      >
                      <span v-if="item.days == null">请假0天</span>
                    </div>
                    <div class="three-left-whole-list-font2">
                      {{ item.billDate }}
                    </div>
                  </div>

                  <div
                    class="three-left-whole-list-btn"
                    @click="goto('请假', item.billId)"
                  >
                    处理
                  </div>

                  <div class="three-left-whole-list-i">
                    <el-icon><el-icon-arrow-right /></el-icon>
                  </div>
                </div>
              </template>

              <!-- 出差 -->
              <template v-for="(item, index) in travelList">
                <div v-if="spanctype == 'travel'" class="three-left-whole-list">
                  <div class="three-left-whole-list-img">
                    <img src="@/assets/images/dispacthingCenter/oa.png" />
                  </div>

                  <div class="three-left-whole-list-font">
                    <div class="three-left-whole-list-font1">
                      <span class="font1-margin">{{ item.staffName }}</span>
                      <span v-if="item.days != null"
                        >出差{{ item.days }}天</span
                      >
                      <span v-if="item.days == null">出差0天</span>
                    </div>
                    <div class="three-left-whole-list-font2">
                      {{ item.billDate }}
                    </div>
                  </div>

                  <div
                    class="three-left-whole-list-btn"
                    @click="goto('出差', item.billId)"
                  >
                    处理
                  </div>

                  <div class="three-left-whole-list-i">
                    <el-icon><el-icon-arrow-right /></el-icon>
                  </div>
                </div>
              </template>

              <!-- 加班 -->
              <template v-for="(item, index) in overtimeList">
                <div
                  v-if="spanctype == 'outtime'"
                  class="three-left-whole-list"
                >
                  <div class="three-left-whole-list-img">
                    <img src="@/assets/images/dispacthingCenter/oa.png" />
                  </div>

                  <div class="three-left-whole-list-font">
                    <div class="three-left-whole-list-font1">
                      <span class="font1-margin">{{ item.staffName }}</span>
                      <span v-if="item.days != null"
                        >加班{{ item.days }}天</span
                      >
                      <span v-if="item.days == null">加班0天</span>
                    </div>
                    <div class="three-left-whole-list-font2">
                      {{ item.billDate }}
                    </div>
                  </div>

                  <div
                    class="three-left-whole-list-btn"
                    @click="goto('加班', item.billId)"
                  >
                    处理
                  </div>

                  <div class="three-left-whole-list-i">
                    <el-icon><el-icon-arrow-right /></el-icon>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <addOrderPushObjDialog
            v-if="addOrderPushObjDialogShow"
            v-model:visible="addOrderPushObjDialogShow"
            :type="addOrderPushObjtype"
            :val="addOrderPushObjval"
            :data="twolist2HandleSelectionData"
            :batch-order-info="batchOrderInfo"
            @confirm="addOrderConfirm"
            @confirmAll="addOrderConfirmAll"
          />
          <!-- 工单管理 -->
          <div class="three-right">
            <div class="three-right-top">
              <div class="three-right-top-left">临服管理</div>
              <div class="three-right-top-right" @click="openDialogAddorder">
                <div class="three-right-top-right-img">
                  <img src="@/assets/images/dispacthingCenter/gd_xz.png" />
                </div>
                <span>新增工单</span>
              </div>
              <el-dialog
                v-model:value="dialogAddorder"
                :before-close="AddorderClose"
                :close-on-click-modal="false"
                :title="Addtitle"
                width="80%"
              >
                <!-- 弹窗1 -->
                <div v-if="Adddialogtype == '1'">
                  <el-form
                    ref="form"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                    "
                  >
                    <el-form-item style="width: 65%; text-align: center">
                      <span style="margin-right: 5%">工单类型:</span>
                      <el-radio-group v-model="Addtype">
                        <el-radio
                          v-for="(item, index) in Addtypelist"
                          :key="index"
                          :label="item"
                        />
                      </el-radio-group>
                    </el-form-item>
                  </el-form>
                  <!-- 选择001 -->
                  <el-form
                    v-show="Addtype == Addtypelist[1]"
                    ref="forminput"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-around;
                    "
                  >
                    <el-form-item style="width: 45%; text-align: center">
                      <span style="margin-right: 2%">请输入地址:</span>
                      <el-input
                        id="suggestId"
                        v-model="Addaddress"
                        style="width: 60%; height: auto"
                        model-value="百度"
                      />
                    </el-form-item>
                    <el-form-item style="width: 40%; text-align: center">
                      <span style="margin-right: 2%">请输入开始时间</span>
                      <el-date-picker
                        v-model="Addstarttime1"
                        end-placeholder="结束日期"
                        range-separator="至"
                        start-placeholder="开始日期"
                        style="width: 70%; height: auto"
                        type="datetimerange"
                        value-format="YYYY-MM-DD HH:mm:ss"
                      />
                      <!--                      <el-date-picker v-model="Addstarttime1" placeholder="选择日期" style="width:60%;height: auto;"-->
                      <!--                                      type="datetime"-->
                      <!--                                      value-format="YYYY-MM-DD-hh-mm-ss"></el-date-picker>-->
                    </el-form-item>
                    <el-form-item
                      style="
                        width: 15%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                      "
                    >
                      <el-button type="primary" @click="workOrderDialogNext">
                        下一步
                      </el-button>
                    </el-form-item>
                  </el-form>
                  <div
                    v-show="Addtype == Addtypelist[1]"
                    id="container"
                    style="width: 100%; height: 250px; margin-bottom: 30px"
                  />

                  <!-- 选择002 -->
                  <el-form
                    v-show="Addtype == Addtypelist[0]"
                    ref="forminput"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      margin-top: 2%;
                    "
                  >
                    <el-form-item style="width: 330px; text-align: center">
                      <span style="margin-right: 2%">请输入开始时间:</span>
                      <el-date-picker
                        v-model="Addstarttime2"
                        placeholder="选择日期"
                        style="width: 60%; height: auto"
                        type="date"
                        value-format="YYYY-MM-DD"
                      />
                      <!--                      <el-date-picker v-model="Addstarttime2" end-placeholder="结束日期" range-separator="至"-->
                      <!--                                      start-placeholder="开始日期" style="width:70%;height: auto;" type="datetimerange"-->
                      <!--                                      value-format="YYYY-MM-DD-hh-mm"></el-date-picker>-->
                    </el-form-item>
                    <el-form-item
                      style="
                        width: 20%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                      "
                    >
                      <el-button type="primary" @click="workOrderDialogNext">
                        下一步
                      </el-button>
                    </el-form-item>
                  </el-form>
                  <div
                    v-show="Addtype == Addtypelist[0]"
                    style="
                      width: 100%;
                      height: 250px;
                      display: flex;
                      flex-direction: row;
                      margin-bottom: 10px;
                    "
                  >
                    <div
                      style="
                        width: 30%;
                        height: 100%;
                        border: 1px solid #ebeef5;
                        overflow-y: auto;
                      "
                    >
                      <treemenu2
                        :data="dirData2"
                        :props="dirProps"
                        @getactClickdata="getactClicktreemenudata"
                      />
                    </div>
                    <div
                      style="
                        width: 70%;
                        height: 100%;
                        border: 1px solid #ebeef5;
                        border-left: none;
                        border-bottom: none;
                      "
                    >
                      <el-table
                        height="250"
                        :data="classData"
                        highlight-current-row
                        style="width: 100%; height: 100%; overflow-y: auto"
                        @current-change="wkOrderDialogCurrentChange"
                      >
                        <el-table-column label="岗位名称" prop="postName" />
                        <el-table-column label="班次名称" prop="classesName" />
                        <el-table-column label="开始时间" prop="startTime" />
                        <el-table-column label="结束时间" prop="endTime" />
                      </el-table>
                    </div>
                  </div>
                </div>
                <!-- 弹窗2 -->
                <div v-if="Adddialogtype == '2'">
                  <div
                    style="
                      width: 100%;
                      height: 360px;
                      margin-top: 10px;
                      display: flex;
                      flex-wrap: wrap;
                    "
                  >
                    <div v-if="btnSheetAgain == true" style="height: 40px">
                      <span style="margin-right: 2%">请输入截单时间:</span>
                      <el-date-picker
                        v-model="SheetAgainAddstarttime"
                        placeholder="选择日期"
                        style="width: 60%; height: auto"
                        type="date"
                        value-format="YYYY-MM-DD"
                      />
                    </div>
                    <div
                      style="
                        width: 100%;
                        display: flex;
                        height: 320px;
                        margin-top: -20px;
                      "
                    >
                      <!--                      <div-->
                      <!--                        style="-->
                      <!--                          width: 30%;-->
                      <!--                          height: 100%;-->
                      <!--                          border: 1px solid #ebeef5;-->
                      <!--                          overflow-y: auto;-->
                      <!--                        "-->
                      <!--                      >-->
                      <!--                        <treemenu2-->
                      <!--                          :data="dirData2"-->
                      <!--                          :props="dirProps"-->
                      <!--                          @getactClickdata="getactClickdata"-->
                      <!--                          @reset="getactClickdataReset"-->
                      <!--                          :resetAble="true"-->
                      <!--                        ></treemenu2>-->
                      <!--                      </div>-->
                      <div
                        style="
                          width: 100%;
                          height: 100%;
                          border: 1px solid #ebeef5;
                          border-left: none;
                          border-bottom: none;
                        "
                      >
                        <div
                          style="
                            width: 100%;
                            height: 9%;
                            margin-top: 1%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-around;
                            align-items: center;
                          "
                        >
                          <div style="width: 70%">
                            <el-button
                              size="small"
                              :type="needKind2 === '002' ? 'primary' : 'button'"
                              @click="staffsearch2('002')"
                            >
                              全部
                            </el-button>
                            <el-button
                              size="small"
                              :type="needKind2 === '003' ? 'primary' : 'button'"
                              @click="staffsearch2('003')"
                            >
                              同审批人
                            </el-button>
                            <el-button
                              size="small"
                              :type="needKind2 === '004' ? 'primary' : 'button'"
                              @click="
                                staffsearch2(needKind2 === '004' ? '' : '004')
                              "
                            >
                              保安部
                            </el-button>
                            <el-button
                              size="small"
                              :type="needKind2 === '006' ? 'primary' : 'button'"
                              @click="staffsearch2('006')"
                            >
                              本大队
                            </el-button>
                            <el-button
                              size="small"
                              :type="needKind2 === '007' ? 'primary' : 'button'"
                              @click="staffsearch2('007')"
                            >
                              本驻点
                            </el-button>
                          </div>
                          <el-input
                            v-model="starname"
                            placeholder="请选入工号或姓名："
                            style="display: inline-block; width: 20%"
                          />
                          <el-input
                            v-model="staraddress"
                            placeholder="请选入距离(米)："
                            style="display: inline-block; width: 20%"
                          />
                          <!-- <cascader :options="dirData2" style="width:20%" @checkedId="getcheckedId"></cascader> -->
                          <div style="display: inline-block; width: 50%">
                            <el-checkbox-group
                              v-model="starvalue"
                              style="margin-left: 2%"
                            >
                              <el-checkbox
                                v-for="star in staroptions"
                                :key="star"
                                :label="star"
                              >
                                {{ star }}
                              </el-checkbox>
                            </el-checkbox-group>
                          </div>
                        </div>
                        <!--                          <el-button @click="more()">更多筛选条件</el-button>-->
                        <!--                        v-if="openIs"-->
                        <div style="margin-top: 2%">
                          <el-checkbox
                            v-model="kongXian"
                            style="width: 3%; margin-left: 2%"
                            @change="staffsearch2(needKind2)"
                          >
                            空闲
                          </el-checkbox>
                          <el-checkbox
                            v-model="sendExpatriate"
                            style="margin-left: 2%"
                            @change="staffsearch2(needKind2)"
                          >
                            外派人员
                          </el-checkbox>
                          <el-checkbox
                            v-model="sendFireNo"
                            @change="staffsearch2(needKind2)"
                          >
                            消控证
                          </el-checkbox>
                          <el-checkbox
                            v-model="service"
                            @change="staffsearch2(needKind2)"
                          >
                            服兵役
                          </el-checkbox>

                          <el-select
                            v-model="sexValue"
                            placeholder="性别"
                            size="mini"
                            style="width: 15%; margin-left: 2%"
                            @change="staffsearch2(needKind2)"
                          >
                            <el-option
                              v-for="item in sexARrr"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                              size="mini"
                            />
                          </el-select>
                          <el-select
                            v-model="degree"
                            placeholder="学历"
                            size="mini"
                            style="width: 25%; margin-left: 2%"
                            @change="staffsearch2(needKind2)"
                          >
                            <el-option
                              v-for="item in degreeArr"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                              size="mini"
                            />
                          </el-select>
                          <el-input
                            v-model="height"
                            type="number"
                            size="mini"
                            placeholder="身高下限"
                            style="
                              width: 20%;
                              margin-left: 2%;
                              margin-top: 10px;
                            "
                            min="120"
                            max="200"
                          >
                            <template #prepend> 身高 </template>
                          </el-input>

                          <el-input
                            v-model="ageMin"
                            type="number"
                            size="mini"
                            placeholder="年龄"
                            style="
                              width: 20%;
                              margin-left: 2%;
                              margin-top: 10px;
                            "
                            min="0"
                            max="120"
                          >
                            <template #prepend> 年龄 </template>
                          </el-input>
                          <span style="margin: 10px 4px 0 4px">至</span>
                          <el-input
                            v-model="ageMax"
                            type="number"
                            size="mini"
                            placeholder="年龄"
                            style="width: 13%; margin-top: 10px"
                            min="0"
                            max="120"
                          />
                          <el-button
                            type="primary"
                            style="margin-left: 2%"
                            @click="searchpeople()"
                          >
                            查询
                          </el-button>
                        </div>
                        <el-table
                          ref="multipleTable"
                          :data="dirData"
                          height="180"
                          style="width: 100%; margin-top: 10px"
                          @selection-change="wkOrderPeopleSelect"
                          @sort-change="sortChange"
                        >
                          <el-table-column type="selection" width="55" />
                          <el-table-column label="工号" prop="oldStaffId" />
                          <el-table-column label="姓名" prop="staffName" />
                          <el-table-column
                            label="联系方式"
                            prop="relationTele"
                          />
                          <el-table-column
                            label="星级"
                            prop="levelName"
                            show-overflow-tooltip
                            sortable="custom"
                          />
                          <el-table-column
                            label="距离"
                            prop="distance"
                            show-overflow-tooltip
                            sortable="custom"
                          />
                        </el-table>
                      </div>
                    </div>
                    <div
                      style="
                        width: 100%;
                        height: 30px;
                        margin-top: 10px;
                        display: flex;
                        justify-content: flex-end;
                      "
                    >
                      <el-pagination
                        v-model:current-page="workerOrderPage"
                        v-model:page-size="workerOrderpagesize"
                        :page-sizes="[100, 200, 300, 400]"
                        :total="workerOrdernums"
                        background
                        layout="prev, pager, next, total, jumper,sizes"
                        style="margin-right: 20px"
                      />
                      <el-button @click="workOrderDialogPre">
                        上一步
                      </el-button>
                      <el-button type="primary" @click="workOrderDialogSubmit">
                        确认
                      </el-button>
                      <el-button type="primary" @click="workOrderPushAllSubmit">
                        全部推送
                      </el-button>
                    </div>
                  </div>
                </div>
              </el-dialog>
            </div>
            <div class="three-right-whole">
              <div
                class="three-right-whole-left"
                @click="dialogWorkorder = true"
              >
                <span class="nums">{{ threelist2finishtotal }}</span>
                <span>历史工单</span>
                <img
                  class="three-right-whole-left-img"
                  src="@/assets/images/dispacthingCenter/gd_jt.png"
                />
                <!-- <div class="img-in">
                                                <span>8</span>
                                                <span>待派工单</span>
                                                <img src="../../assets/images/dispacthingCenter/gd_jt.png">
                                            </div> -->
              </div>
              <div class="three-right-whole-right">
                <div
                  v-for="(item, index) in threelist2"
                  :key="index"
                  class="three-right-whole-right-list"
                >
                  <div class="three-right-whole-right-list-font1">
                    <div
                      class="three-right-whole-right-list-span1"
                      @click="goform(item.billId)"
                    >
                      <span>{{ item.billName }}</span>
                      <span class="type">{{ item.billStatus }}</span>
                    </div>
                    <div class="three-right-whole-right-list-span2">
                      <span
                        v-if="item.billStatus == '待分配'"
                        class="end"
                        style="
                          color: #46d0a7;
                          width: 60px;
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                        @click="sendSheetAgain(item.billId)"
                        >再次分配</span
                      >
                      <span
                        v-if="item.billStatus == '抢单中'"
                        class="end"
                        style="
                          color: #46d0a7;
                          width: 60px;
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                        @click="updateSheetToStop(item.billId)"
                        >立即结束</span
                      >
                      <span
                        v-if="item.billStatus == '结束'"
                        class="end"
                        style="
                          color: #46d0a7;
                          width: 60px;
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                        @click="sheetWork(item.billId)"
                        >生效</span
                      >
                      <!-- <span>|</span>
                                                            <span style="color:#f47451" @click="sheetAbandon(item.billId)">废弃</span> -->
                      <div class="i">
                        <el-icon><el-icon-arrow-right /></el-icon>
                      </div>
                    </div>
                  </div>
                  <div
                    class="three-right-whole-right-list-font2"
                    @click="goform(item.billId)"
                  >
                    <span>派单：{{ item.startDate }}</span>
                    <span>截单：{{ item.endDate }}</span>
                  </div>
                </div>
              </div>
              <el-dialog
                v-model:value="dialogWorkorder"
                :before-close="WorkorderClose"
                :close-on-click-modal="false"
                title="工单列表"
                width="80%"
              >
                <el-table
                  :data="threelist2finish"
                  border
                  height="300"
                  style="width: 100%"
                >
                  <el-table-column label="操作" width="120">
                    <template #default="scope">
                      <el-button
                        size="small"
                        type="text"
                        @click="
                          addworderorder(
                            scope.row.billName,
                            scope.row.startDate,
                            scope.row.endDate,
                            scope.row.finishTime
                          )
                        "
                      >
                        复制
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column label="工单名称 " prop="billName" />
                  <el-table-column label="工单状态" prop="billStatus" />
                  <el-table-column label="开始日期" prop="startDate" />
                  <el-table-column label="结束日期" prop="endDate" />
                  <el-table-column label="截单时间" prop="finishTime" />
                  <el-table-column label="创建人" prop="creator" />
                </el-table>
                <div style="text-align: center; margin-top: 10px">
                  <el-pagination
                    v-model:current-page="historyorderpages"
                    :total="threelist2finishtotal"
                    background
                    layout="prev, pager, next, total, jumper"
                  />
                </div>
              </el-dialog>
            </div>
          </div>
        </div>
        <!-- 查岗 -->
        <div class="four">
          <div class="four-left">
            <div class="four-left-top">查岗</div>
            <el-dialog
              v-model:value="dialogelcheckpost"
              :before-close="elcheckpostClose"
              :close-on-click-modal="false"
              :title="elcheckposttip"
              width="80%"
            >
              <el-table
                :data="elcheckpostdata"
                border
                height="300"
                style="width: 100%"
              >
                <el-table-column label="驻点 " prop="arrestName" />
                <el-table-column label="岗位" prop="postName" />
                <el-table-column label="班次" prop="classesName" />
                <el-table-column label="开始日期" prop="startDate" />
                <el-table-column label="结束日期" prop="endDate" />
                <el-table-column label="查岗对象" prop="staffName" />
              </el-table>
              <div style="text-align: center; margin-top: 10px">
                <el-pagination
                  v-model:current-page="elcheckpostpageId"
                  :total="elcheckpostnums"
                  background
                  layout="prev, pager, next, total, jumper"
                />
              </div>
            </el-dialog>
            <div class="four-left-whole">
              <div
                v-for="(item, index) in fourleftlist"
                :key="index"
                class="four-left-whole-list"
                @click="
                  getelcheckpostdata(item.type), (dialogelcheckpost = true)
                  ;(elcheckposttip = item.type),
                   (elcheckpostpageId = 1),
                   (elcheckpostnums = item.number)
                "
              >
                <div class="four-left-whole-list-img">
                  <img :src="fourimg[index]" />
                </div>
                <div class="four-left-whole-list-span">
                  <span class="four-left-span1">{{ item.number }}</span>
                  <span style="white-space: nowrap">{{ item.type }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="four-right">
            <div class="four-right-top" />
            <div class="four-right-whole">
              <div
                v-for="(item, index) in fourrightlist"
                :key="index"
                class="four-right-whole-list"
              >
                <div class="four-right-whole-list-img">
                  <img :src="fourimg1[index]" />
                </div>
                <div class="four-right-whole-list-span">
                  <span
                    class="four-right-span1"
                    @click="checktoPost(item.btn)"
                    >{{ item.btn }}</span
                  >
                </div>
                <el-dialog
                  v-model:value="dialogcheckPost"
                  :before-close="checkPostClose"
                  :close-on-click-modal="false"
                  title="人工查岗"
                  width="80%"
                >
                  <div style="margin-bottom: 30px; margin-top: 10px">
                    <span>请选择查询人员：</span>
                    <el-cascader
                      v-model="checkPostvalue"
                      :clearable="true"
                      :options="options"
                      :props="props2"
                      :show-all-levels="false"
                      collapse-tags
                      filterable
                      placeholder="搜索："
                      @change="checkPostvalueChange"
                    />
                    <el-button style="margin-left: 30px" @click="checkPost()">
                      查岗
                    </el-button>
                  </div>
                </el-dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- 消息列表 -->
        <div class="right-top">
          <div class="right-top-title">
            <div class="right-top-title-font">
              <span>消息列表</span>
              <el-button
                class="elButton"
                type="primary"
                :icon="ElIconRefresh"
                @click="refresh"
              >
                点击刷新
              </el-button>
              <el-button class="elButton" type="primary" @click="allRead">
                全部已读
              </el-button>
            </div>
          </div>
          <div class="right-top-whole" style="overflow: hidden">
            <dispatchMessage
              ref="child"
              :data="rightdata"
              :db="db"
              @getMessageRefresh="getMessageRefresh"
              @nopost="getKey"
              @tabindex="tabindex"
              @scrolldown="scrolldown"
            >
              >
            </dispatchMessage>
            <p v-if="listParams.loading">Loading...</p>
            <p v-if="listParams.finished">No More</p>
          </div>
        </div>
      </div>
    </div>
    <batchOrderInfoDialog
      v-if="batchOrderInfoDialogShow"
      :type="batchType"
      v-model:visible="batchOrderInfoDialogShow"
      :batch-order-info="batchOrderInfo"
      :data="twolist2HandleSelectionData"
      :current-class="currentClass"
      :distance-row="distanceRow"
      :act-click="actClick"
      :addaddress="Addaddress"
      :addstarttime1="Addstarttime1"
      :addstarttime2="Addstarttime2"
      :lat1="lat1"
      :lon1="lon1"
    />
    <batchAllOrderInfoDialog
      v-if="batchAllOrderInfoDialogShow"
      v-model:visible="batchAllOrderInfoDialogShow"
      :type="batchType"
      :params="batchAllParams"
      :data="twolist2HandleSelectionData"
      :current-class="currentClass"
      :act-click="actClick"
      :addaddress="Addaddress"
      :addstarttime1="Addstarttime1"
      :addstarttime2="Addstarttime2"
      :lat1="lat1"
      :lon1="lon1"
    />
  </div>
</template>

<script>
import {
  ArrowRight as ElIconArrowRight,
  Refresh as ElIconRefresh,
} from '@element-plus/icons-vue'
import { BMPGL } from '@/assets/js/bmpgl.js'
import treemenu from '@/views/configurationForm/treeMenu'
import treemenu2 from '@/views/configurationForm/treeMenu2'
import {
  checkPost,
  getAllPost,
  getApproval,
  getArrest,
  getCheckPostNumber,
  getClasses,
  getClassesByArrest,
  getClockAll,
  getClockCount,
  getClockDetail,
  getClockEarly,
  getClockException,
  getClockLack,
  getClockLate,
  getClockNormal,
  getExceptionType,
  getMessage,
  getNeedStaff,
  getOnPost,
  getOutPost,
  getPost,
  getPostNoStaff,
  getSatffWithTree,
  getPostStaff,
  getSheet,
  getSheetFinish,
  getSheetType,
  getStaff,
  handleNormal,
  noApproval,
  sendSheetAgain,
  sheetAbandon,
  sheetWork,
  updateScheduling,
  updateSheetToStop,
  updateSheetToWork,
  updateMessageAllRead,
  getClockOut,
  getClockExtra,
} from '@/requet/api/ba/dispatchingCenter'
import dispatchMessage from '@/components/aloneComponents/pg/home/dispatchMessage'
import addOrderPushObjDialog from '@/components/aloneComponents/pg/duty/dispatchCenter/addOrderPushObjDialog'
import batchOrderInfoDialog from '@/components/aloneComponents/pg/duty/dispatchCenter/batchOrderInfoDialog'
import { diffDatesec, formatDate } from '@/utils/dateTime'
import cascader from '@/components/aloneComponents/pg/duty/dispatchCenter/cascader'
import batchAllOrderInfoDialog from '@/components/aloneComponents/pg/duty/dispatchCenter/batchAllOrderInfoDialog'
import { shallowRef } from "vue";

export default {
  name: 'DispatchingCenter',
  components: {
    treemenu,
    treemenu2,
    cascader,
    dispatchMessage,
    addOrderPushObjDialog,
    batchOrderInfoDialog,
    batchAllOrderInfoDialog,
    ElIconArrowRight,
  },
  props: ['params'],
  data() {
    return {
      // 01 缺岗 02新增工单
      batchType: '01',
      keyId: '',
      //查岗弹窗标题
      Statisticstip: '',
      //查岗弹窗内容
      elcheckpostdata: [],
      //查岗弹窗分页页码
      elcheckpostpageId: 1,
      //查岗弹窗分页limit
      elcheckpostpageLimit: 10,
      //查岗弹窗列表总数
      elcheckpostnums: 0,
      //查岗弹窗开关
      dialogelcheckpost: false,
      //查岗弹窗名称
      elcheckposttip: '',
      //判断是否为批量处理
      batching: false,
      //考勤异常信息
      onelist: [],
      //弹窗内 考勤异常信息
      onelist2: [],
      //岗位空缺信息
      twolist: [],
      //弹窗内 岗位空缺信息
      twolist2: [],
      //岗位空缺信息 裁剪的时间数据
      nopostdates: [],
      threelist: [],
      //工单列表 待处理工单列表数据
      threelist2: [],
      //工单列表 历史工单列表页数
      historyorderpages: 1,
      //工单列表 历史工单数据
      threelist2finish: [],
      threelist2finishtotal: 0,
      fourimg: [require('@/assets/images/dispacthingCenter/zgw.png'),
        require('@/assets/images/dispacthingCenter/zg.png'),
        require('@/assets/images/dispacthingCenter/tg.png'),],
      fourimg1: [require('@/assets/images/dispacthingCenter/zd.png'),
        require('@/assets/images/dispacthingCenter/bc.png'),],
      fourleftlist: [{ number: '543', type: '总岗位人数' },
        { number: '530', type: '在岗人数' },
        { number: '13', type: '脱岗人数' },],
      fourrightlist: [{ btn: '人工查岗' }, { btn: '查岗记录' }],
      //人工查岗开关
      dialogcheckPost: false,
      //人工查岗 弹窗选择器内部内容
      options: [],
      //人工查岗 弹窗选择器 所选内容
      checkPostvalue: [],
      checkPostid: [],
      rightdata: [],
      rightbottom: [],
      starttime: '',
      endtime: '',
      holidayList: [],
      travelList: [],
      overtimeList: [],
      holidayNum: 0,
      overtimeNum: 0,
      travelNum: 0,
      spanctype: 'holiday',
      //考勤统计数值
      ClockCount: [],
      //考勤统计 异常数据
      ClockDetail: [],
      //考勤统计弹窗开关
      dialogStatistics: false,
      //驳回和处理为正常弹窗开关
      dialoghandle: false,
      //判断是否点击驳回
      ClockExceptionbtn: false,
      //驳回和处理为正常弹窗中 文本内容
      ClockException: '',
      //考勤异常 >按钮弹窗开关
      dialogi: false,
      //考勤异常类型
      ExceptionTypelist: [],
      //考勤异常 >按钮弹窗 表格选中数据
      tabledatas: [],
      //考勤异常 >按钮弹窗 驳回和处理为正常 批量处理按钮
      btndatas: [],
      //考勤异常 >按钮弹窗 查询条件-日期
      selectdate: '',
      //考勤异常 >按钮弹窗 查询条件-人员姓名
      selectname: '',
      //考勤异常 >按钮弹窗 查询条件-异常类型
      selecttype: [],
      //考勤异常 >按钮弹窗 列表信息总数
      inums: 0,
      //考勤异常 >按钮弹窗 列表页数
      ipage: 1,
      //空缺岗位排班开关
      dialogNoStaff: false,
      //空缺岗位排班 查询条件-人员姓名
      NoStaffperson: '',
      noStaffArrestName: null,
      noStaffDepName: null,
      needNoStaffDepId: false,
      needNoStaffArrestId: false,
      //获取排班不冲突人员信息
      needStaffdatas: [],
      //获取排班不冲突人员信息总数
      needStaffdatanums: 0,
      //获取排班不冲突人员信息列表页数
      needStaffpage: 1,
      //空缺岗位结束时间
      needendDate: '',
      //空缺岗位开始时间
      needstartDate: '',
      //全部
      needKind: '002',
      //空缺岗位id
      needschedulingId: '',
      //空缺岗位驻点
      needarrestName: '',
      //空缺岗位岗位
      needpostName: '',
      //空缺岗位班次
      needclassName: '',
      needRow: {},
      //排班不冲突人员id
      needstaffId: '',
      //驻点
      Arrest: [],
      //岗位
      Post: [],
      //班次
      Classes: [],
      //空缺岗位 更多 开关
      dialogNopost: false,
      //空缺岗位 更多 条件查询-驻点
      Nopoststaff: [],
      //空缺岗位 更多 条件查询-岗位
      Nopostpost: [],
      //空缺岗位 更多 条件查询-班次
      Nopostclass: [],
      //空缺岗位 更多 条件查询-日期
      Nopostdate: '',
      //空缺岗位 更多 数据量
      Nopostnums: 0,
      //空缺岗位 更多 列表页数
      Nopostpage: 1,
      billid: '',
      dialogWorkorder: false,
      btnclockId: '',
      btndate: '',
      btnschedulingId: '',
      btnspecialId: '',
      //新增工单 弹窗1
      dialogAddorder: false,
      //新增工单 弹窗1和2切换控制字段
      Adddialogtype: '1',
      //新增工单title
      Addtitle: '新增工单-选择工单类型及地点',
      //选择新增类型
      Addtype: '人员收集',
      //新增类型数组
      Addtypelist: [],
      //经度
      lon: 120.778499,
      lon1: null,
      //纬度,
      lat: 31.601076,
      lat1: null,
      mapAddress: '',
      // 百度的地图密钥
      ak: '1XjLLEhZhQNUzd93EjU5nOGQ',
      map: null,
      //新增工单地址
      Addaddress: '',
      //新增工单 001 时间
      Addstarttime1: '',
      Addstarttimedata: 0,
      //新增工单 002 时间
      Addstarttime2: '',
      essArrestId: null,
      treemenutitle: '岗位选取',
      moduleid: 'TREE220001',
      props2: {
        multiple: true,
        label: 'label',
        children: 'children',
        isLeaf: 'leaf',
        icon: 'icon',
      },
      props: {
        label: 'name',
        children: 'children',
        isLeaf: 'leaf',
        icon: 'icon',
      },
      dirData: [],
      dirData2: [],
      dirProps: {
        label: 'label',
        children: 'children',
        value: 'value',
      },
      order: 0,
      distanceList: [],
      starname: '',
      staraddress: '',
      starvalue: [],
      staroptions: [
'一星', '二星', '三星', '四星', '五星'
],
      workerOrderPage: 1,
      workerOrdernums: 0,
      workerOrderpagesize: 100,
      distanceRow: {},
      //筛选关联Id
      faId: '',
      //主表单模块Id
      mainform: '',
      //显示到岗位
      allshow: 'true',
      classData: [],
      currentClass: null,
      //树形菜单选中驻点返回值
      actClick: '',
      name: '001',
      pointid: '',
      personid: '',
      ClockpageId: 1,
      Clocknums: 0,
      ClockpageLimit: 10,
      NeedStaffstartDate: '',
      NeedStaffendDate: '',
      obj: {
        date: '',
        day: 0,
        endTime: '',
        lat: '',
        lon: '',
        startTime: '',
      },
      btnSheetAgain: false,
      SheetAgainAddstarttime: '',
      SheetAgainbillId: '',
      //消息列表当前页数
      pageNumber: 1,
      listArr: [],
      count: 20,
      listParams: {
        pageNum: 1,
        pageSize: 10,
        loading: false,
        error: false,
        finished: false,
      },
      //消息列表返回当前位置
      tabindexs: 0,
      //消息列表接口传的类型参数
      kind: null,
      db: 0,
      sendFireNo: false,
      sexARrr: [{
          value: '全部',
          label: '性别',
        },
        {
          value: '男',
          label: '男',
        },
        {
          value: '女',
          label: '女',
        },],
      sexValue: '全部',
      degree: '006',
      degreeArr: [{
          value: '006',
          label: '初中以上',
        },
        {
          value: '005',
          label: '高中/职高/中专以上',
        },
        {
          value: '002',
          label: '大专以上',
        },
        {
          value: '001',
          label: '本科以上',
        },],
      height: '',
      ageMin: '',
      ageMax: '',
      openIs: true,
      addOrderPushObjDialogShow: false,
      batchOrderInfoDialogShow: false,
      batchAllOrderInfoDialogShow: false,
      batchOrderInfo: {},
      batchAllParams: null,
      //空闲
      kongXian: false,
      //同审批人
      sendschedulingId: false,
      //保安部
      sendsecurityStaff: false,
      //外派人员
      sendExpatriate: false,
      //服兵役
      service: false,
      //  同岗经历
      experience: false,
      needKind2: '002',
      sendsecurityStaff2: false,
      addOrderPushObjtype: '',
      addOrderPushObjval: {},
      twolist2HandleSelectionData: [],
      depId2: '',
      classId: '',
      arrestId2: '',
      ElIconRefresh: shallowRef(ElIconRefresh),
    }
  },
  computed: {
    disabled() {
      console.log(this.listParams.loading || this.listParams.finished)
      return this.listParams.loading || this.listParams.finished
    },
  },
  watch: {
    tabindexs(newval) {
      if (newval == 0) {
        this.kind = null
      } else if (newval == 1) {
        this.kind = '001'
      } else if (newval == 2) {
        this.kind = '002'
      } else if (newval == 3) {
        this.kind = '003'
      } else if (newval == 4) {
        this.kind = '004'
      }
      this.pageNumber = 1
      this.db = 0
      // document.getElementById(this.rightdata[this.tabindexs].title).scrollTop=0;
      this.getMessage()
    },
    async workerOrderPage(newval) {
      if (this.btnSheetAgain == false) {
        if (this.Addtype == '直接排班') {
          let res = await getNeedStaff(
            this.workerOrderPage,
            this.workerOrderpagesize,
            {
              arrestId: this.needNoStaffArrestId ? this.arrestId2 : null,
              depId: this.needNoStaffDepId ? this.depId2 : null,
              approval: this.sendschedulingId ? this.classId : null,
              kind: this.needKind2,
              fireNo: this.sendFireNo ? 'Y' : null,
              expatriate: this.sendExpatriate ? '002' : null,
              sex: this.sexValue ? this.sexValue : null,
              smallAge: this.ageMin ? this.ageMin : null,
              bigAge: this.ageMax ? this.ageMax : null,
              height: this.height ? this.height : null,
              education: this.degree ? this.degree : null,
              service: this.service ? '001' : null,
              securityStaff: this.sendsecurityStaff ? '001' : null,
              date: this.Addstarttime2,
              day: this.currentClass.classesDay,
              endTime: this.currentClass.endTime,
              lat: this.currentClass.latitude,
              lon: this.currentClass.longitude,
              startTime: this.currentClass.startTime,
            }
          )
          this.NeedStaffstartDate = res.startDate
          this.NeedStaffendDate = res.endDate
          this.dirData = res.pageInfo.list
          this.workerOrdernums = res.pageInfo.total
          console.log(this.dirData)
        } else {
          let dateTime = this.Addstarttime1[0].split(' ', 2)
          let res = await getNeedStaff(
            this.workerOrderPage,
            this.workerOrderpagesize,
            {
              kind: this.needKind2,
              fireNo: this.sendFireNo ? 'Y' : null,
              expatriate: this.sendExpatriate ? '002' : null,
              sex: this.sexValue ? this.sexValue : null,
              smallAge: this.ageMin ? this.ageMin : null,
              bigAge: this.ageMax ? this.ageMax : null,
              height: this.height ? this.height : null,
              education: this.degree ? this.degree : null,
              service: this.service ? '001' : null,
              securityStaff: this.sendsecurityStaff ? '001' : null,
              arrestId: this.essArrestId,
              startDate: this.Addstarttime1[0],
              endDate: this.Addstarttime1[1],
              date: dateTime[0],
              day: this.Addstarttimedata,
              endTime: '00:00:00',
              lat: this.lat1,
              lon: this.lon1,
              startTime: '00:00:00',
            }
          )
          this.NeedStaffstartDate = res.startDate
          this.NeedStaffendDate = res.endDate
          this.dirData = res.pageInfo.list
          this.workerOrdernums = res.pageInfo.total
          console.log(this.dirData)
        }
      } else {
        let res = await getNeedStaff(
          this.workerOrderPage,
          this.workerOrderpagesize,
          {
            date: this.obj.date,
            day: this.obj.day,
            endTime: this.obj.endTime,
            lat: this.obj.lat,
            lon: this.obj.lon,
            startTime: this.obj.startTime,
          }
        )
        this.NeedStaffstartDate = res.startDate
        this.NeedStaffendDate = res.endDate
        this.dirData = res.pageInfo.list
        this.workerOrdernums = res.pageInfo.total
        console.log(this.dirData)
      }
    },
    needStaffpage(newval) {
      this.getStaff(this.needendDate, this.needstartDate, '')
    },
    ipage(newval) {
      this.search()
    },
    Nopoststaff(newval) {
      this.getPost()
      this.Nopostpost = []
      this.Nopostclass = []
    },
    Nopostpost(newval) {
      this.getClasses()
      this.Nopostclass = []
    },
    Nopostpage(newval) {
      this.nopostSearch()
    },
    historyorderpages(newval) {
      this.getSheetFinish()
    },
    Addtype(newval) {
      if (this.Addtype == '人员收集') {
        this.map?.destroy()
        this.getPosition(this.lon, this.lat)
      } else if (this.Addtype == '直接排班') {
        this.map?.destroy()
        this.getInitTree()
      }
    },
    ClockpageId(newval) {
      if (this.Statisticstip == '应打卡统计') {
        let res = getClockAll(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          this.ClockDetail = res.list
        })
      } else if (this.Statisticstip == '已打卡统计') {
        let res = getClockNormal(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          console.log(res)
          this.ClockDetail = res.list
        })
      } else if (this.Statisticstip == '迟到统计') {
        let res = getClockLate(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          this.ClockDetail = res.list
        })
      } else if (this.Statisticstip == '缺卡统计') {
        let res = getClockLack(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          this.ClockDetail = res.list
        })
      } else if (this.Statisticstip == '早退统计') {
        let res = getClockEarly(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          this.ClockDetail = res.list
        })
      } else if (this.Statisticstip == '范围外统计') {
        let res = getClockOut(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          this.ClockDetail = res.list
        })
      } else if (this.Statisticstip == '未排班打卡统计') {
        let res = getClockExtra(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          this.ClockDetail = res.list
        })
      }
    },
    elcheckpostpageId(newval) {
      this.getelcheckpostdata(this.elcheckposttip)
    },
    starvalue(val) {
      this.searchpeople()
    },
  },
  mounted() {
    console.log('params:', this.params)

    let postNoStaffPo = {}
    if (this.params) {
      //内部跳转
      this.dialogNopost = true
      postNoStaffPo.schedulingIdList = this.params.split(',')
    }

    // this.getDate();
    this.getClockCount()
    this.getPostNoStaff(1, 10, postNoStaffPo)
    this.getApproval()
    this.getSheet()
    this.getMessage()
    this.getCheckPostNumber()
    this.getClockException(1, 10)
    this.getExceptionType()
    this.getArrest()
    // this.getClockDetail();
    this.getSatffWithTree()
    this.getPostStaff()
    this.getSheetFinish()
    this.getSheetType()
  },
  // beforeDestroy() {
  //   this.StatisticsClose()
  // },
  // destroyed() {
  //   this.StatisticsClose()
  // },
  methods: {
    addOrderConfirm(val) {
      console.log('123', val)
      this.batchOrderInfo = val
      this.batchType = '01'
      this.batchOrderInfoDialogShow = true
    },
    addOrderConfirmAll(val) {
      this.batchAllParams = val
      this.batchType = '01'
      this.batchAllOrderInfoDialogShow = true
    },
    more() {
      this.openIs = !this.openIs
    },
    getMessageRefresh(val) {
      if (val == true) {
        this.refresh()
      }
    },
    tabindex(val) {
      this.tabindexs = val
    },
    scrolldown(val) {
      if (val == true) {
        this.pageNumber++
        this.getMessageagain()
      }
    },
    //查询全部消息
    async getMessage() {
      this.rightdata = [
        { title: '全部', msgList: [] },
        {
          urgency: '001',
          icon: require('@/assets/images/dispacthingCenter/j.png'),
          title: '紧急',
          msgList: [],
        },
        {
          urgency: '002',
          icon: require('@/assets/images/dispacthingCenter/g.png'),
          title: '高危',
          msgList: [],
        },
        {
          urgency: '003',
          icon: require('@/assets/images/dispacthingCenter/z.png'),
          title: '中危',
          msgList: [],
        },
        {
          urgency: '004',
          icon: require('@/assets/images/dispacthingCenter/d.png'),
          title: '低危',
          msgList: [],
        },
      ]
      if (this.tabindexs == 0) {
        let res = await getMessage(this.pageNumber, 20, {
          staffId: sessionStorage.getItem('myId'),
        })
        if (res) {
          this.rightdata[this.tabindexs].msgList = res.list
        } else {
          this.rightdata[this.tabindexs].msgList = []
        }
      } else if (this.tabindexs != 0) {
        let res = await getMessage(this.pageNumber, 20, {
          staffId: sessionStorage.getItem('myId'),
          kind: this.kind,
        })
        if (res) {
          this.rightdata[this.tabindexs].msgList = res.list
        } else {
          this.rightdata[this.tabindexs].msgList = []
        }
      }
      // let msgData = [
      //   { title: "全部", msgList:[]},
      //   {
      //     urgency: "001",
      //     icon: require("@/assets/images/dispacthingCenter/j.png"),
      //     title: "紧急",
      //     msgList: [],
      //   },
      //   {
      //     urgency: "002",
      //     icon: require("@/assets/images/dispacthingCenter/g.png"),
      //     title: "高危",
      //     msgList: [],
      //   },
      //   {
      //     urgency: "003",
      //     icon: require("@/assets/images/dispacthingCenter/z.png"),
      //     title: "中危",
      //     msgList: [],
      //   },
      //   {
      //     urgency: "004",
      //     icon: require("@/assets/images/dispacthingCenter/d.png"),
      //     title: "低危",
      //     msgList: [],
      //   },
      // ];
      // if(this.pageNumber==1){
      //   msgData[0].msgList=res.list ;
      // }else{
      //   msgData[0].msgList.push(res.list) ;
      // }
      // console.log(res.list)
      // this.listArr.push(res.list)
      // msgData[0].msgList = this.listArr
      // for (const item of res.list) {
      //   msgData.forEach((res) => {
      //     if (res.urgency == item.urgency) {
      //       res.msgList.push(item);
      //     }
      //   });
      // }
      // this.rightdata = msgData;
      // console.log(this.rightdata)
    },
    async getMessageagain() {
      if (this.tabindexs == 0) {
        let res = await getMessage(this.pageNumber, 20, {
          staffId: sessionStorage.getItem('myId'),
        })
        console.log(res)
        if (this.pageNumber == 1) {
          this.rightdata[this.tabindexs].msgList = []
          if (res.list != []) {
            res.list.forEach((item) => {
              this.rightdata[this.tabindexs].msgList.push(item)
            })
          }
        } else {
          if (res.list != []) {
            res.list.forEach((item) => {
              this.rightdata[this.tabindexs].msgList.push(item)
            })
          }
        }
      } else {
        let res = await getMessage(this.pageNumber, 20, {
          staffId: sessionStorage.getItem('myId'),
          kind: this.kind,
        })
        console.log(res)
        if (this.pageNumber == 1) {
          this.rightdata[this.tabindexs].msgList = []
          if (res.list != []) {
            res.list.forEach((item) => {
              this.rightdata[this.tabindexs].msgList.push(item)
            })
          }
        } else {
          if (res.list != []) {
            res.list.forEach((item) => {
              this.rightdata[this.tabindexs].msgList.push(item)
            })
          }
        }
      }
      // console.log(this.pageNumber);
    },
    // load(){
    //     // this.pageNumber = this.pageNumber+1
    //     // this.getMessage()
    //     // this.getMessage()
    //     //   this.count += 20
    //     this.listParams.loading = true;
    //   if (this.listParams.finished == false) {
    //     this.listParams.pageNum++;
    //     this.getMessage();
    //   }
    // },
    async updateSheetToStop(billId) {
      let res = await updateSheetToStop({ billId: billId })
      this.getSheet()
    },
    async getKey(value) {
      this.keyId = value
      let res = await getPostNoStaff(1, 10, { schedulingIdList: [value] })
      console.log(res.list[0])
      this.twolist2 = []
      this.twolist2.push(res.list[0])
      this.dialogNopost = true
    },
    async sendSheetAgain(billId) {
      this.Addaddress = ''
      this.Addstarttime1 = ''
      this.Addstarttime2 = ''
      this.SheetAgainbillId = billId
      let m = await sendSheetAgain({ id: billId })
      console.log(m)
      if (m.date != null) {
        m.date = m.date.slice(0, m.date.indexOf(' '))
      }
      this.obj.date = m.date
      this.obj.day = m.day
      this.obj.endTime = m.endTime
      this.obj.lat = m.lat
      this.obj.lon = m.lon
      this.obj.startTime = m.startTime
      let res = await getNeedStaff(
        this.workerOrderPage,
        this.workerOrderpagesize,
        this.obj
      )
      this.NeedStaffstartDate = res.startDate
      this.NeedStaffendDate = res.endDate
      this.dirData = res.pageInfo.list
      this.workerOrdernums = res.pageInfo.total
      console.log('dirData', this.dirData, res)
      let k = getSatffWithTree()
      k.then((res) => {
        this.dirData2 = res
        this.dirData2.forEach((e) => {
          if (e.children != null) {
            e.children.forEach((res) => {
              res.children = null
            })
          }
        })
        console.log(res)
      })
      this.Adddialogtype = '2'
      this.Addtitle = '新增工单-选择推送对象'
      this.getSheet()
      this.dialogAddorder = true
      this.btnSheetAgain = true

      // this.getSheet();
    },
    //查询岗位空缺信息
    async getPostNoStaff(pageId, pageLimit, po) {
      let a = getPostNoStaff(pageId, pageLimit, po)
      a.then((res) => {
        this.twolist = res.list
        console.log(this.twolist)
        for (let i = 0; i < this.twolist.length; i++) {
          if (this.twolist[i].startDate != null) {
            this.starttime = this.twolist[i].startDate.split(' ')[1]
          } else {
            this.starttime = ''
          }
          if (this.twolist[i].startDate != null) {
            this.endtime = this.twolist[i].endDate.split(' ')[1]
          } else {
            this.endtime = ''
          }
          this.nopostdates.push({
            starttime: this.starttime,
            endtime: this.endtime,
          })
        }
        this.twolist2 = res.list
        this.Nopostnums = res.total
        // console.log(res);
      })
    },
    async getPostNoStaff2(pageId, pageLimit, po) {
      let a = getPostNoStaff(pageId, pageLimit, po)
      a.then((res) => {
        this.twolist2 = res.list
        this.Nopostnums = res.total
      })
    },
    //获取所有驻点信息
    async getArrest() {
      let res = getArrest()
      res.then((res) => {
        this.Arrest = res
        // console.log(res);
      })
    },
    //按照所选驻点编号获取相应的岗位信息
    async getPost() {
      let res = getPost({
        arrestId: [],
        arrestIdList: this.Nopoststaff,
        classesId: [],
        date: '',
        postId: [],
      })
      res.then((res) => {
        this.Post = res
        // console.log(res);
      })
    },
    //按照所选岗位编号获取相应的班次信息
    async getClasses() {
      let res = getClasses({
        postId: this.Nopostpost[0],
      })
      res.then((res) => {
        this.Classes = res
        // console.log(res);
      })
    },
    //岗位空缺 条件搜索
    nopostSearch() {
      this.getPostNoStaff2(this.Nopostpage, 10, {
        arrestId: this.Nopoststaff,
        classesId: this.Nopostclass,
        date: this.Nopostdate,
        postId: this.Nopostpost,
      })
      // console.log(this.Nopoststaff,this.Nopostpost,this.Nopostclass,this.Nopostdate)
    },
    //考勤统计弹窗
    StatisticsClose(done) {
      this.dialogStatistics = false
      done()
    },
    handleCurrentChange(val) {
      console.log('handleCurrentChange', val)
      // /SM0610?menuid=SM0610&title=打卡管理&configtype=006&groupid=M11464&filtergroup=&filtergroupshowsign=Y
      // /home/configurationForm/SM0610?menuid=SM0610&title=打卡管理&configtype=006&groupid=M11464&filtergroup=&filtergroupshowsign=Y
      this.dialogStatistics = false
      console.log('123ads')
      sessionStorage.setItem('pgSysSign', '保安考勤统计')
      this.$router.push({
        // name: "configurationForm",
        path: '/home/configurationForm/SM0610',
        params: {
          wfid: 'SM0610',
        },
        query: {
          moduleid: 'M11464',
          wfid: 'SM0587',
          configtype: '006',
          menuid: 'SM0610',
          title: '打卡管理',
          groupid: 'M11464',
          filtergroupshowsign: 'Y',
          staffName: val.staffName,
        },
      })
    },
    //查询审批信息
    async getApproval() {
      let a = getApproval()
      a.then((res) => {
        this.holidayList = res.holidayList
        this.travelList = res.travelList
        this.overtimeList = res.overtimeList
        this.holidayNum = res.holidayNum
        this.overtimeNum = res.overtimeNum
        this.travelNum = res.travelNum
        console.log(res)
      })
    },
    //查询工单信息
    async getSheet() {
      let a = getSheet()
      a.then((res) => {
        this.threelist2 = res.list
        console.log(res)
        console.log(this.threelist2)
      })
    },
    //获取完成的工单信息
    async getSheetFinish() {
      let res = getSheetFinish(this.historyorderpages, 10)
      res.then((res) => {
        this.threelist2finishtotal = res.total
        this.threelist2finish = res.list
        console.log(res)
        for (let i = 0; i < this.threelist2finish.length; i++) {
          if (this.threelist2finish[i].startDate != null) {
            this.threelist2finish[i].startDate =
              this.threelist2finish[i].startDate.split(' ')[0]
          }
          if (this.threelist2finish[i].endDate != null) {
            this.threelist2finish[i].endDate =
              this.threelist2finish[i].endDate.split(' ')[0]
          }
          // console.log(this.threelist2finish[i].startDate, this.threelist2finish[i].endDate);
        }
      })
    },
    //获取考勤异常信息
    async getClockException(pageId, pageLimit) {
      let a = getClockException(1, 10, {})
      a.then((res) => {
        this.onelist = res.list
        console.log(this.onelist)
      })
      let b = getClockException(pageId, pageLimit, {})
      b.then((res) => {
        this.onelist2 = res.list
        this.inums = res.total
        console.log(this.onelist2)
      })
    },
    //考勤异常 处理为正常和驳回 按钮弹窗
    ClockExceptionClose(done) {
      done()
    },
    getbtnvalue(clockId, date, schedulingId, specialId) {
      this.btnclockId = clockId
      this.btndate = date
      this.btnschedulingId = schedulingId
      this.btnspecialId = specialId
    },
    //处理为正常和驳回 功能实现
    async clickbtn() {
      if (this.ClockExceptionbtn == false) {
        this.dialoghandle = false
        let res = handleNormal([{
            clockId: this.btnclockId,
            date: this.btndate,
            remark: this.ClockException,
            schedulingId: this.btnschedulingId,
            specialId: this.btnspecialId,
          },])
        res.then((res) => {
          console.log(res)
          if (res == null) {
            this.getClockException(this.ipage, 10)
          }
        })
      } else {
        this.dialoghandle = false
        let res = noApproval([{
            id: this.btnspecialId,
            remark: this.ClockException,
          },])
        res.then((res) => {
          console.log(res)
          if (res == null) {
            this.getClockException(this.ipage, 10)
          }
        })
      }
      this.ClockExceptionbtn = false
    },
    //处理为正常和驳回 功能实现 批量处理
    async clickbtns() {
      ;(this.btndatas = []), console.log(this.tabledatas)
      if (this.ClockExceptionbtn == false) {
        this.dialoghandle = false
        for (let i = 0; i < this.tabledatas.length; i++) {
          this.btndatas.push({
            clockId: this.tabledatas[i].clockId,
            date: this.tabledatas[i].createTime,
            remark: this.ClockException,
            schedulingId: this.tabledatas[i].schedulingId,
            specialId: this.tabledatas[i].specialId,
          })
        }
        let res = handleNormal(this.btndatas)
        res.then((res) => {
          console.log(res)
          if (res == null) {
            this.getClockException(this.ipage, 10)
          }
        })
      } else {
        this.dialoghandle = false
        for (let i = 0; i < this.tabledatas.length; i++) {
          this.btndatas.push({
            id: this.tabledatas[i].specialId,
            remark: this.ClockException,
          })
        }
        let res = noApproval(this.btndatas)
        res.then((res) => {
          console.log(res)
          if (res == null) {
            this.getClockException(this.ipage, 10)
          }
        })
      }
      this.batching = false
      this.ClockExceptionbtn = false
    },

    //考勤异常外部 双击跳转
    clickonelist(item) {
      this.dblclicktable(item)
    },
    //考勤异常>标签弹窗 table双击跳转
    dblclicktable(val) {
      console.log(val)
      this.dialogi = false
      this.$router.push({
        name: 'addProcess',
        params: {
          wfid: '1538760440136466432',
        },
        query: {
          moduleid: 'M11480',
          wfid: '1538760440136466432',
          frombillid: '15',
          wfRange: '1',
          menuid: 'LM1384',
          title: '考勤异常',
          configtype: '003',
          filtergroup: '',
          groupid: '',
          filtergroupshowsign: 'Y',
          billid: val.specialId,
        },
      })
    },
    //考勤异常>标签弹窗 table选中的数据
    handleSelectionChange(val) {
      this.tabledatas = val
      console.log(this.tabledatas)
      // console.log(val);
    },
    twolist2HandleSelectionChange(val) {
      console.log(val)
      this.twolist2HandleSelectionData = val
    },
    //获取考勤异常类型
    async getExceptionType() {
      let res = getExceptionType()
      res.then((res) => {
        this.ExceptionTypelist = res
        console.log(res)
      })
    },
    //考勤异常>标签弹窗
    iClose(done) {
      this.ipage = 1
      ;(this.selectdate = ''),
        (this.selectname = ''),
        (this.selecttype = []),
        done()
      this.getClockException(1, 10)
    },
    handleClose(done) {
      this.ClockExceptionbtn = false
      done()
    },
    search() {
      console.log(this.selectdate, this.selectname, this.selecttype)
      let res = getClockException(this.ipage, 10, {
        date: this.selectdate,
        staffName: this.selectname,
        exception: this.selecttype,
      })
      res.then((res) => {
        this.inums = res.total
        this.onelist2 = res.list
        console.log(res)
      })
    },
    //岗位空缺排班
    NoStaffClose(done) {
      this.needKind = '002'
      this.needNoStaffDepId = false
      this.needNoStaffArrestId = false
      ;(this.sendFireNo = false),
        (this.sexValue = '全部'),
        (this.degree = '006'),
        (this.height = ''),
        (this.ageMin = ''),
        (this.ageMax = ''),
        (this.arrestId2 = ''),
        (this.depId2 = ''),
        (this.classId = ''),
        // this.openIs=false,
        (this.kongXian = false), //空闲
        (this.sendschedulingId = false), //同审批人
        (this.sendsecurityStaff = false), //保安部
        (this.sendExpatriate = false), //外派人员
        (this.service = false), //服兵役
        (this.experience = false), //  同岗经历
        done()
    },
    async updateScheduling() {
      let res = updateScheduling({
        schedulingId: this.needschedulingId,
        staffId: this.needstaffId,
      })
      res.then((res) => {
        console.log(res)
      })
    },
    //岗位空缺排班弹窗 安排按钮
    open(staffId) {
      this.$confirm('是否安排此人?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '安排成功!',
          })
          this.needstaffId = staffId
          this.dialogNoStaff = false
          this.needKind = '001'
          this.needNoStaffDepId = true
          this.needNoStaffArrestId = true
          this.updateScheduling()
          this.getPostNoStaff(1, 10, {})
        })
        .catch(() => {})
    },
    //岗位空缺查询
    NopostClose(done) {
      this.Nopoststaff = []
      this.Nopostpost = []
      this.Nopostclass = []
      this.Nopostdate = ''
      this.Nopostpage = 1
      this.getPostNoStaff(this.Nopostpage, 10, {})
      done()
    },
    postOrder(row) {
      let secs = diffDatesec(row.startDate, new Date())
      console.log('row', row, secs)
      this.twolist2HandleSelectionData = [row]
      this.addOrderPushObjval = row
      this.addOrderPushObjtype = 'Single'
      this.addOrderPushObjDialogShow = true
    },
    //获取排班不冲突人员
    async getStaff(endDate, startDate, schedulingId) {
      if (endDate != null) {
        this.needendDate = endDate
      }
      if (startDate != null) {
        this.needstartDate = startDate
      }
      if (schedulingId != '') {
        this.needschedulingId = schedulingId
      }
      if (endDate == null && startDate == null) {
        this.needStaffdatas = []
        this.needStaffdatanums = 0
      } else {
        getStaff(this.needStaffpage, 10, {
          startDate: this.needstartDate,
          staffName: this.NoStaffperson,
          endDate: this.needendDate,
          kind: this.kongXian ? '001' : this.needKind2,
          depName: this.noStaffDepName,
          arrestName: this.noStaffArrestName,
          arrestId: this.needNoStaffArrestId ? this.needRow.arrestId : null,
          depId: this.needNoStaffDepId ? this.needRow.depId : null,
          approval: this.sendschedulingId ? this.needschedulingId : null,
          securityStaff: this.sendsecurityStaff ? '001' : null,
          expatriate: this.sendExpatriate ? '002' : null,
          fireNo: this.sendFireNo ? 'Y' : null,
          sex: this.sexValue ? this.sexValue : null,
          smallAge: this.ageMin ? this.ageMin : null,
          bigAge: this.ageMax ? this.ageMax : null,
          height: this.height ? this.height : null,
          education: this.degree ? this.degree : null,
          service: this.service ? '001' : null,
          experience: this.experience ? this.needschedulingId : null,
        }).then((res) => {
          this.needStaffdatas = res.list
          this.needStaffdatanums = res.total
          // console.log(res.list);
        })
      }

      this.dialogNoStaff = true
    },
    //查询 排班不冲突人员
    staffsearch(needKind) {
      this.needKind = needKind
      //保安部
      if (needKind == '004') {
        this.sendsecurityStaff = true
      } else {
        this.sendsecurityStaff = false
      }
      //同审批人
      if (needKind == '003') {
        this.sendschedulingId = true
      } else {
        this.sendschedulingId = false
      }
      //本大队
      if (needKind == '006') {
        this.needNoStaffDepId = true
      } else {
        this.needNoStaffDepId = false
      }
      //本驻点
      if (needKind == '007') {
        this.needNoStaffArrestId = true
      } else {
        this.needNoStaffArrestId = false
      }
      this.getStaff(this.needendDate, this.needstartDate, '')
    },
    staffsearch2(needKind) {
      this.needKind2 = needKind
      //保安部
      if (needKind == '004') {
        this.sendsecurityStaff = true
      } else {
        this.sendsecurityStaff = false
      }
      //同审批人
      if (needKind == '003') {
        this.sendschedulingId = true
      } else {
        this.sendschedulingId = false
      }
      //本大队
      if (needKind == '006') {
        this.needNoStaffDepId = true
      } else {
        this.needNoStaffDepId = false
      }
      //本驻点
      if (needKind == '007') {
        this.needNoStaffArrestId = true
      } else {
        this.needNoStaffArrestId = false
      }
      this.workOrderDialogNext(this.needendDate, this.needstartDate, '')
    },

    //oA审批 处理按钮 跳转
    goto(type, billid) {
      this.billid = billid
      if (type == '请假') {
        this.$router.push({
          name: 'addProcess',
          params: {
            wfid: '1538809084525019136',
          },
          query: {
            moduleid: 'M11468',
            wfid: '1538809084525019136',
            frombillid: '12',
            wfRange: '1',
            menuid: 'LM1365',
            title: '请假申请',
            configtype: '003',
            groupid: 'M11468',
            filtergroup: '',
            filtergroupshowsign: 'Y',
            billid: billid,
            ismaptotabLinkroute: 'Y',
          },
        })
      }
      if (type == '出差') {
        this.$router.push({
          name: 'addProcess',
          params: {
            wfid: '1536580718707212288',
          },
          query: {
            moduleid: 'M11470',
            wfid: '1536580718707212288',
            frombillid: '14',
            wfRange: '1',
            menuid: 'LM1367',
            title: '出差申请',
            configtype: '003',
            groupid: 'M11470',
            filtergroup: '',
            filtergroupshowsign: 'Y',
            billid: billid,
            ismaptotabLinkroute: 'Y',
          },
        })
      }
      if (type == '加班') {
        this.$router.push({
          name: 'addProcess',
          params: {
            wfid: '1536580689032511488',
          },
          query: {
            moduleid: 'M11469',
            wfid: '1536580689032511488',
            frombillid: '13',
            wfRange: '1',
            menuid: 'LM1368',
            title: '加班处理',
            configtype: '003',
            groupid: 'M11469',
            filtergroup: '',
            filtergroupshowsign: 'Y',
            billid: billid,
            ismaptotabLinkroute: 'Y',
          },
        })
      }
    },
    //oA审批 更多按钮 跳转
    toOa() {
      this.$router.push({
        name: 'remainProcess',
        query: {
          menuid: 'LM1312',
          title: '待办工作',
          configtype: '003',
          groupid: '',
          filtergroup: '',
          filtergroupshowsign: 'Y',
        },
      })
    },
    //工单管理弹窗
    WorkorderClose(done) {
      done()
    },
    //工单模块跳转 新增工单页面
    addworderorder(billName, startDate, endDate, finishTime) {
      this.$router.push({
        name: 'configurationForm',
        params: {
          menuid: 'LM1398',
        },
        query: {
          menuid: 'LM1398',
          title: '工单派发',
          configtype: '006',
          groupid: 'M11495',
          filtergroup: '',
          filtergroupshowsign: 'Y',
          totitle: '新增工单-复制',
          billName: billName,
          startDate: startDate,
          endDate: endDate,
          finishTime: finishTime,
        },
      })
    },
    //工单管理 列表点击跳转
    goform(billId) {
      this.$router.push({
        name: 'addProcess',
        params: {
          wfid: '1556459272211136512',
        },
        query: {
          moduleid: 'M11495',
          wfid: '1556459272211136512',
          frombillid: '18',
          billId: billId,
          wfRange: '1',
          menuid: 'LM1399',
          title: '工单审批',
          configtype: '003',
          groupid: 'M11495',
          filtergroup: '',
          filtergroupshowsign: 'Y',
          totitle: '工单审批',
          name: this.name++,
        },
      })
    },
    //人工查岗弹窗
    checkPostClose(done) {
      ;(this.checkPostvalue = []), done()
    },
    async getPostStaff() {
      let res = getPostStaff()
      res.then((res) => {
        this.options = res
      })
    },
    async getSatffWithTree() {
      let res = getSatffWithTree()
      res.then((res) => {
        // this.options = res;
        console.log(res)
      })
    },
    //人工查岗弹窗 选择器选择数据变动
    checkPostvalueChange(value) {
      console.log(value)
      for (let i = 0; i < value.length; i++) {
        this.checkPostid.push(value[i][2])
      }
      // console.log(this.checkPostid);
    },
    //查岗跳转
    checktoPost(btn) {
      if (btn == '人工查岗') {
        this.dialogcheckPost = true
      } else if (btn == '查岗记录') {
        this.$router.push({
          name: 'configurationForm',
          params: {
            menuid: 'LM1397',
          },
          query: {
            menuid: 'LM1397',
            title: '查岗记录',
            configtype: '006',
            groupid: 'M11494',
            filtergroup: '',
            filtergroupshowsign: 'Y',
            ismaptotabLinkroute: 'Y',
            totitle: '脱岗处理',
          },
        })
      }
    },
    //查岗弹窗关闭
    elcheckpostClose(done) {
      done()
    },
    //获取打卡统计信息
    async getClockCount() {
      let res = getClockCount()
      res.then((res) => {
        this.ClockCount = res
        console.log(res)
      })
    },
    //获取打卡异常详细信息
    async getClockDetail() {
      let res = getClockDetail()
      res.then((res) => {
        this.ClockDetail = res
        console.log(res)
      })
    },
    //工单生效
    async sheetWork(billId) {
      this.$confirm('是否生效该工单?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          let res = sheetWork({ billId: billId })
          this.$message({
            type: 'success',
            message: '安排成功!',
          })
          res.then((res) => {
            console.log(res)
            if (res == 'success') {
              this.getSheet()
              this.getSheetFinish()
            }
          })
          // this.getSheet();
        })
        .catch(() => {})
    },
    //工单废弃
    async sheetAbandon(billId) {
      let res = sheetAbandon({ billId: billId })
      this.getSheet()
      res.then((res) => {
        console.log(res)
      })
    },
    //查岗数量统计
    async getCheckPostNumber() {
      let res = getCheckPostNumber()
      res.then((res) => {
        console.log(res)
        this.fourleftlist[0].number = res.all
        this.fourleftlist[1].number = res.on
        this.fourleftlist[2].number = res.out
      })
    },
    //人工查岗
    async checkPost() {
      let res = checkPost({ staffIdList: this.checkPostid })
      res.then((res) => {
        // console.log(res);
      })
      this.dialogcheckPost = false
    },
    //查岗获取相关数据
    getelcheckpostdata(type) {
      if (type == '总岗位人数') {
        let res = getAllPost(this.elcheckpostpageId, this.elcheckpostpageLimit)
        res.then((res) => {
          this.elcheckpostdata = res.list
        })
      } else if (type == '在岗人数') {
        let res = getOnPost(this.elcheckpostpageId, this.elcheckpostpageLimit)
        res.then((res) => {
          this.elcheckpostdata = res.list
        })
      } else if (type == '脱岗人数') {
        let res = getOutPost(this.elcheckpostpageId, this.elcheckpostpageLimit)
        res.then((res) => {
          this.elcheckpostdata = res.list
        })
      }
      console.log('查岗', this.elcheckpostdata)
    },
    //
    async getStatisticsdata(type) {
      this.ClockpageId = 1
      this.ClockpageLimit = 10
      if (type == '应打卡') {
        let res = getClockAll(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          this.ClockDetail = res.list
        })
      } else if (type == '已打卡') {
        let res = getClockNormal(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          console.log(res)
          this.ClockDetail = res.list
        })
      } else if (type == '迟到') {
        let res = getClockLate(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          this.ClockDetail = res.list
        })
      } else if (type == '缺卡') {
        let res = getClockLack(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          this.ClockDetail = res.list
        })
      } else if (type == '早退') {
        let res = getClockEarly(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          this.ClockDetail = res.list
        })
      } else if (type == '范围外') {
        let res = getClockOut(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          this.ClockDetail = res.list
        })
      } else if (type == '未排班打卡') {
        let res = getClockExtra(this.ClockpageId, this.ClockpageLimit)
        res.then((res) => {
          this.ClockDetail = res.list
        })
      }
      // console.log(this.ClockDetail);
    },

    //新增工单弹窗开关
    AddorderClose(done) {
      ;(this.needKind2 = '002'),
        (this.sendsecurityStaff = false), //保安部
        (this.needNoStaffDepId = false)
      this.needNoStaffArrestId = false
      ;(this.sendFireNo = false),
        (this.sexValue = '全部'),
        (this.degree = '006'),
        (this.height = ''),
        (this.ageMin = ''),
        (this.ageMax = ''),
        (this.arrestId2 = ''),
        (this.depId2 = ''),
        (this.classId = ''),
        (this.starname = ''),
        (this.staraddress = '')
      // this.openIs=false,
      ;(this.kongXian = false), //空闲
        (this.sendschedulingId = false), //同审批人
        (this.sendExpatriate = false), //外派人员
        (this.service = false), //服兵役
        (this.experience = false), //  同岗经历
        done()
      this.map?.destroy()
      this.Addtype = '直接排班'
      this.Adddialogtype = '1'
      this.Addtitle = '新增工单-选择工单类型及地点'
      this.btnSheetAgain = false
      this.getSheet()
    },
    async openDialogAddorder() {
      let res = await getSatffWithTree()
      this.dirData2 = res
      this.dirData2.forEach((e) => {
        if (e.children != null) {
          e.children.forEach((res) => {
            res.children = null
          })
        }
      })
      this.dialogAddorder = true
      this.Addaddress = ''
      this.Addstarttime1 = ''
      this.Addstarttime2 = ''
      // this.map?.destroy();
      // this.getPosition(this.lon, this.lat);
    },
    //地图显示
    getPosition(a, b) {
      // 传入密钥获取地图回调。
      let _this = this
      BMPGL(this.ak).then((BMapGL) => {
        // 创建地图实例
        _this.map = new BMapGL.Map('container')
        // 创建点坐标 axios => res 获取的初始化定位坐标
        let point = new BMapGL.Point(a, b)
        // 初始化地图，设置中心点坐标和地图级别
        _this.map.centerAndZoom(point, 19)
        //开启鼠标滚轮缩放
        _this.map.enableScrollWheelZoom(true)
        var marker = new BMapGL.Marker(point)
        _this.map.addOverlay(marker)

        var ac = new BMapGL.Autocomplete({
          //建立一个自动完成的对象
          input: 'suggestId',
          location: _this.map,
        })
        ac.addEventListener('onhighlight', function (e) {
          //鼠标放在下拉列表上的事件
          var str = ''
          var _value = e.fromitem.value
          var value = ''
          if (e.fromitem.index > -1) {
            value =
              _value.province +
              _value.city +
              _value.district +
              _value.street +
              _value.business
          }
          str =
            'FromItem<br />index = ' +
            e.fromitem.index +
            '<br />value = ' +
            value

          value = ''
          if (e.toitem.index > -1) {
            _value = e.toitem.value
            value =
              _value.province +
              _value.city +
              _value.district +
              _value.street +
              _value.business
          }
          str +=
            '<br />ToItem<br />index = ' +
            e.toitem.index +
            '<br />value = ' +
            value
          // document.getElementById("searchResultPanel").innerHTML = str;
        })
        var myValue
        ac.addEventListener('onconfirm', function (e) {
          //鼠标点击下拉列表后的事件
          // console.log(e);
          var _value = e.item.value
          myValue =
            _value.province +
            _value.city +
            _value.district +
            _value.street +
            _value.business
          // document.getElementById("searchResultPanel").innerHTML ="onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
          setPlace()
        })

        function setPlace() {
          _this.map.clearOverlays() //清除地图上所有覆盖物
          function myFun() {
            var pp = local.getResults().getPoi(0).point //获取第一个智能搜索的结果
            _this.map.centerAndZoom(pp, 18)
            _this.map.addOverlay(new BMapGL.Marker(pp)) //添加标注
          }

          var local = new BMapGL.LocalSearch(_this.map, {
            //智能搜索
            onSearchComplete: myFun,
          })
          // console.log(myValue);

          //创建地址解析器实例
          var myGeo = new BMapGL.Geocoder()
          //获取选中地址的经纬度
          myGeo.getPoint(myValue, (point) => {
            console.log('point', point, myValue)
            _this.lon1 = point.lng.toFixed(6)
            _this.lat1 = point.lat.toFixed(6)
            _this.mapAddress = myValue
            _this.Addaddress = myValue
          })
          local.search(myValue)
        }
      })
    },
    //初始化树形结构
    getInitTree() {
      let param = {
        moduleid: this.moduleid,
      }
      this.$postJson('/ConfigureReports/Tree/getTree', param).then((res) => {
        if (res.data) {
          this.mainform = res.data.mainform //主表单模块Id
          this.faId = res.data.subformlinkfield //筛选关联Id
          console.log(res.data)
          this.menuList = res.data.treeList
        } else {
          this.$notify.error({
            title: res.errMsg,
            message: res.errMsgDetail,
            duration: 0,
          })
        }
      })
    },
    //根据驻点id获取班次信息
    async getClassesByArrest(id) {
      let res = getClassesByArrest([id])
      res.then((res) => {
        console.log(res)
        this.classData = res
      })
    },
    //获取工单类型
    async getSheetType() {
      let res = getSheetType()
      res.then((res) => {
        console.log(res)
        this.Addtypelist = res
        this.Addtype = this.Addtypelist[0]
      })
    },
    async workOrderDialogNext() {
      if (this.Addtype == '直接排班') {
        if (!this.Addstarttime2) {
          this.$message.warning('请选择日期')
          return
        }
        if (!this.currentClass) {
          this.$message.warning('请选择班次')
          return
        }
        //todo 批量排班数据

        let res = await getNeedStaff(
          this.workerOrderPage,
          this.workerOrderpagesize,
          {
            arrestId: this.needNoStaffArrestId ? this.arrestId2 : null,
            depId: this.needNoStaffDepId ? this.depId2 : null,
            approval: this.sendschedulingId ? this.classId : null,
            kind: this.kongXian ? '001' : this.needKind2,
            fireNo: this.sendFireNo ? 'Y' : null,
            expatriate: this.sendExpatriate ? '002' : null,
            sex: this.sexValue ? this.sexValue : null,
            smallAge: this.ageMin ? this.ageMin : null,
            bigAge: this.ageMax ? this.ageMax : null,
            height: this.height ? this.height : null,
            education: this.degree ? this.degree : null,
            service: this.service ? '001' : null,
            securityStaff: this.sendsecurityStaff ? '001' : null,
            // arrestId: this.essArrestId,
            date: this.Addstarttime2,
            day: this.currentClass.classesDay,
            endTime: this.currentClass.endTime,
            lat: this.currentClass.latitude,
            lon: this.currentClass.longitude,
            startTime: this.currentClass.startTime,
          }
        )
        if (res === null) {
          this.NeedStaffstartDate = ''
          this.NeedStaffendDate = ''
          this.dirData = []
          this.workerOrdernums = 0
          return
        }
        this.NeedStaffstartDate = res.startDate
        this.NeedStaffendDate = res.endDate
        this.dirData = res.pageInfo.list
        // this.dirData?.forEach(x => {
        //   x?.depList?.forEach(d => {
        //     d.depName = d.distanceType
        //   })
        // })
        this.workerOrdernums = res.pageInfo.total
        // console.log("dirData", this.dirData, res);
        let k = getSatffWithTree()
        k.then((res) => {
          this.dirData2 = res
          this.dirData2.forEach((e) => {
            if (e.children != null) {
              e.children.forEach((res) => {
                res.children = null
              })
            }
          })
          console.log(res)
        })
        this.Adddialogtype = '2'
        this.Addtitle = '新增工单-选择推送对象'
      }
      if (this.Addtype === '人员收集') {
        console.log('asdf', this.Addtype, this.Addstarttime1, '')
        if (!this.Addstarttime1) {
          this.$message.warning('请选择时间')
          return
        }
        this.Addstarttimedata =
          (Date.parse(this.Addstarttime1[1]) -
            Date.parse(this.Addstarttime1[0])) /
          1000 /
          60 /
          60 /
          24
        let dateTime = this.Addstarttime1[0].split(' ', 2)
        console.log(dateTime)
        let res = await getNeedStaff(
          this.workerOrderPage,
          this.workerOrderpagesize,
          {
            arrestId: this.needNoStaffArrestId ? this.arrestId2 : null,
            depId: this.needNoStaffDepId ? this.depId2 : null,
            approval: this.sendschedulingId ? this.classId : null,
            startDate: this.Addstarttime1[0],
            endDate: this.Addstarttime1[1],
            kind: this.kongXian ? '001' : this.needKind2,
            fireNo: this.sendFireNo ? 'Y' : null,
            expatriate: this.sendExpatriate ? '002' : null,
            sex: this.sexValue ? this.sexValue : null,
            smallAge: this.ageMin ? this.ageMin : null,
            bigAge: this.ageMax ? this.ageMax : null,
            height: this.height ? this.height : null,
            education: this.degree ? this.degree : null,
            service: this.service ? '001' : null,
            securityStaff: this.sendsecurityStaff ? '001' : null,
            date: dateTime[0],
            day: this.Addstarttimedata,
            endTime: '00:00:00',
            lat: this.lat1,
            lon: this.lon1,
            startTime: '00:00:00',
          }
        )
        if (res === null) {
          this.NeedStaffstartDate = ''
          this.NeedStaffendDate = ''
          this.dirData = []
          this.workerOrdernums = 0
          return
        }
        this.NeedStaffstartDate = res.startDate
        this.NeedStaffendDate = res.endDate
        this.dirData = res.pageInfo.list

        this.workerOrdernums = res.pageInfo.total
        console.log(this.dirData)
        // this.dirData?.forEach(x => {
        //   x?.depList?.forEach(d => {
        //     d.depName = d.distanceType
        //   })
        // })
        console.log('dirData', this.dirData, res)
        let k = getSatffWithTree()
        k.then((res) => {
          this.dirData2 = res
          this.dirData2.forEach((e) => {
            if (e.children != null) {
              e.children.forEach((res) => {
                res.children = null
              })
            }
          })
          console.log(res)
        })
        this.Adddialogtype = '2'
        this.Addtitle = '新增工单-选择推送对象'
        this.map?.destroy()
        // this.$router.push({
        //   name: 'addProcess',
        //   params: {
        //     wfid: '1556459272211136512'
        //   },
        //   query: {
        //     moduleid:'M11495',
        //     wfid: '1556459272211136512',
        //     frombillid:'18',
        //     wfRange:'1',
        //     menuid:'LM1399',
        //     title: '工单审批',
        //     configtype: '003',
        //     groupid: 'M11495',
        //     filtergroup: '',
        //     filtergroupshowsign: 'Y',
        //     totitle: '新增工单审批',
        //     name:'001',
        //     billName: null,
        //     startDate: this.Addstarttime1[0],
        //     endDate: this.Addstarttime1[1],
        //     finishTime: null,

        //     lat: this.lat,
        //     lon: this.lon,
        //     mapAddress: this.mapAddress
        //   }
        // })
      }
    },
    async sortChange(column) {
      console.log(column)
      if (column.order == 'ascending') {
        this.order = 0
      } else {
        this.order = 1
      }
      if (this.btnSheetAgain == false) {
        if (this.Addtype === '直接排班') {
          if (column.column.label == '星级') {
            let res = await getNeedStaff(
              this.workerOrderPage,
              this.workerOrderpagesize,
              {
                starOrder: this.order,
                date: this.Addstarttime2,
                day: this.currentClass.classesDay,
                endTime: this.currentClass.endTime,
                lat: this.currentClass.latitude,
                lon: this.currentClass.longitude,
                startTime: this.currentClass.startTime,
              }
            )
            if (res != null) {
              this.NeedStaffstartDate = res.startDate
              this.NeedStaffendDate = res.endDate
              this.dirData = res.pageInfo.list
              this.workerOrdernums = res.pageInfo.total
              console.log(this.dirData)
            } else if (res == null) {
              this.NeedStaffstartDate = ''
              this.NeedStaffendDate = ''
              this.dirData = []
              this.workerOrdernums = 0
            }
          } else if (column.column.label == '距离') {
            let res = await getNeedStaff(
              this.workerOrderPage,
              this.workerOrderpagesize,
              {
                order: this.order,
                date: this.Addstarttime2,
                day: this.currentClass.classesDay,
                endTime: this.currentClass.endTime,
                lat: this.currentClass.latitude,
                lon: this.currentClass.longitude,
                startTime: this.currentClass.startTime,
              }
            )
            if (res != null) {
              this.NeedStaffstartDate = res.startDate
              this.NeedStaffendDate = res.endDate
              this.dirData = res.pageInfo.list
              this.workerOrdernums = res.pageInfo.total
              console.log(this.dirData)
            } else if (res == null) {
              this.NeedStaffstartDate = ''
              this.NeedStaffendDate = ''
              this.dirData = []
              this.workerOrdernums = 0
            }
          }
        } else {
          if (column.column.label == '星级') {
            let dateTime = this.Addstarttime1[0].split(' ', 2)
            let res = await getNeedStaff(
              this.workerOrderPage,
              this.workerOrderpagesize,
              {
                startDate: this.Addstarttime1[0],
                endDate: this.Addstarttime1[1],
                starOrder: this.order,
                date: dateTime[0],
                day: this.Addstarttimedata,
                endTime: '00:00:00',
                lat: this.lat1,
                lon: this.lon1,
                startTime: '00:00:00',
              }
            )
            if (res != null) {
              this.NeedStaffstartDate = res.startDate
              this.NeedStaffendDate = res.endDate
              this.dirData = res.pageInfo.list
              this.workerOrdernums = res.pageInfo.total
              console.log(this.dirData)
            } else if (res == null) {
              this.NeedStaffstartDate = ''
              this.NeedStaffendDate = ''
              this.dirData = []
              this.workerOrdernums = 0
            }
          } else if (column.column.label == '距离') {
            let dateTime = this.Addstarttime1[0].split(' ', 2)
            let res = await getNeedStaff(
              this.workerOrderPage,
              this.workerOrderpagesize,
              {
                startDate: this.Addstarttime1[0],
                endDate: this.Addstarttime1[1],
                order: this.order,
                date: dateTime[0],
                day: this.Addstarttimedata,
                endTime: '00:00:00',
                lat: this.lat1,
                lon: this.lon1,
                startTime: '00:00:00',
              }
            )
            if (res != null) {
              this.NeedStaffstartDate = res.startDate
              this.NeedStaffendDate = res.endDate
              this.dirData = res.pageInfo.list
              this.workerOrdernums = res.pageInfo.total
              console.log(this.dirData)
            } else if (res == null) {
              this.NeedStaffstartDate = ''
              this.NeedStaffendDate = ''
              this.dirData = []
              this.workerOrdernums = 0
            }
          }
        }
      } else {
        let res = await getNeedStaff(
          this.workerOrderPage,
          this.workerOrderpagesize,
          {
            starOrder: this.order,
            date: this.obj.date,
            day: this.obj.day,
            endTime: this.obj.endTime,
            lat: this.obj.lat,
            lon: this.obj.lon,
            startTime: this.obj.startTime,
          }
        )
        if (res != null) {
          this.NeedStaffstartDate = res.startDate
          this.NeedStaffendDate = res.endDate
          this.dirData = res.pageInfo.list
          this.workerOrdernums = res.pageInfo.total
          console.log(this.dirData)
        } else if (res == null) {
          this.NeedStaffstartDate = ''
          this.NeedStaffendDate = ''
          this.dirData = []
          this.workerOrdernums = 0
        }
      }
    },
    getcheckedId(id) {
      this.essArrestId = id
      this.staffsearch2(this.needKind)
    },
    async searchpeople() {
      if (this.btnSheetAgain == false) {
        if (this.Addtype === '直接排班') {
          let res = await getNeedStaff(
            this.workerOrderPage,
            this.workerOrderpagesize,
            {
              arrestId: this.needNoStaffArrestId ? this.arrestId2 : null,
              depId: this.needNoStaffDepId ? this.depId2 : null,
              approval: this.sendschedulingId ? this.classId : null,
              kind: this.kongXian ? '001' : this.needKind2,
              fireNo: this.sendFireNo ? 'Y' : null,
              expatriate: this.sendExpatriate ? '002' : null,
              sex: this.sexValue ? this.sexValue : null,
              smallAge: this.ageMin ? this.ageMin : null,
              bigAge: this.ageMax ? this.ageMax : null,
              height: this.height ? this.height : null,
              education: this.degree ? this.degree : null,
              service: this.service ? '001' : null,
              securityStaff: this.sendsecurityStaff ? '001' : null,
              // arrestId: this.essArrestId,
              staff: this.starname,
              distance: this.staraddress,
              starList: this.starvalue,
              date: this.Addstarttime2,
              day: this.currentClass.classesDay,
              endTime: this.currentClass.endTime,
              lat: this.currentClass.latitude,
              lon: this.currentClass.longitude,
              startTime: this.currentClass.startTime,
            }
          )
          if (res != null) {
            this.NeedStaffstartDate = res.startDate
            this.NeedStaffendDate = res.endDate
            this.dirData = res.pageInfo.list
            this.workerOrdernums = res.pageInfo.total
            console.log(this.dirData)
          } else if (res == null) {
            this.NeedStaffstartDate = ''
            this.NeedStaffendDate = ''
            this.dirData = []
            this.workerOrdernums = 0
          }
        } else {
          let dateTime = this.Addstarttime1[0].split(' ', 2)
          let res = await getNeedStaff(
            this.workerOrderPage,
            this.workerOrderpagesize,
            {
              arrestId: this.needNoStaffArrestId ? this.arrestId2 : null,
              depId: this.needNoStaffDepId ? this.depId2 : null,
              approval: this.sendschedulingId ? this.classId : null,
              startDate: this.Addstarttime1[0],
              endDate: this.Addstarttime1[1],
              kind: this.kongXian ? '001' : this.needKind2,
              fireNo: this.sendFireNo ? 'Y' : null,
              expatriate: this.sendExpatriate ? '002' : null,
              sex: this.sexValue ? this.sexValue : null,
              smallAge: this.ageMin ? this.ageMin : null,
              bigAge: this.ageMax ? this.ageMax : null,
              height: this.height ? this.height : null,
              education: this.degree ? this.degree : null,
              service: this.service ? '001' : null,
              securityStaff: this.sendsecurityStaff ? '001' : null,
              staff: this.starname,
              distance: this.staraddress,
              starList: this.starvalue,
              date: dateTime[0],
              day: this.Addstarttimedata,
              endTime: '00:00:00',
              lat: this.lat1,
              lon: this.lon1,
              startTime: '00:00:00',
            }
          )
          if (res != null) {
            this.NeedStaffstartDate = res.startDate
            this.NeedStaffendDate = res.endDate
            this.dirData = res.pageInfo.list
            this.workerOrdernums = res.pageInfo.total
            console.log(this.dirData)
          } else if (res == null) {
            this.NeedStaffstartDate = ''
            this.NeedStaffendDate = ''
            this.dirData = []
            this.workerOrdernums = 0
          }
        }
      } else {
        let res = await getNeedStaff(
          this.workerOrderPage,
          this.workerOrderpagesize,
          {
            staff: this.starname,
            distance: this.staraddress,
            starList: this.starvalue,
            date: this.obj.date,
            day: this.obj.day,
            endTime: this.obj.endTime,
            lat: this.obj.lat,
            lon: this.obj.lon,
            startTime: this.obj.startTime,
          }
        )
        if (res != null) {
          this.NeedStaffstartDate = res.startDate
          this.NeedStaffendDate = res.endDate
          this.dirData = res.pageInfo.list
          this.workerOrdernums = res.pageInfo.total
          console.log(this.dirData)
        } else if (res == null) {
          this.NeedStaffstartDate = ''
          this.NeedStaffendDate = ''
          this.dirData = []
          this.workerOrdernums = 0
        }
      }
    },
    getactClickdataReset() {
      this.getactClickdata({
        value: null,
      })
    },
    async getactClickdata(data) {
      console.log('handleNodeClick', data.isSameClick)
      // let arrId = data.isSameClick ? null : data.value
      let arrId = data.value
      this.workerOrderPage = 1
      if (this.btnSheetAgain == false) {
        if (this.Addtype === '直接排班') {
          let res = await getNeedStaff(
            this.workerOrderPage,
            this.workerOrderpagesize,
            {
              arrestId: arrId,
              date: this.Addstarttime2,
              day: this.currentClass.classesDay,
              endTime: this.currentClass.endTime,
              lat: this.currentClass.latitude,
              lon: this.currentClass.longitude,
              startTime: this.currentClass.startTime,
            }
          )
          if (res != null) {
            this.NeedStaffstartDate = res.startDate
            this.NeedStaffendDate = res.endDate
            this.dirData = res.pageInfo.list
            this.workerOrdernums = res.pageInfo.total
            console.log(this.dirData)
          } else if (res == null) {
            this.NeedStaffstartDate = ''
            this.NeedStaffendDate = ''
            this.dirData = []
            this.workerOrdernums = 0
          }
        } else {
          let dateTime = this.Addstarttime1[0].split(' ', 2)
          let res = await getNeedStaff(
            this.workerOrderPage,
            this.workerOrderpagesize,
            {
              arrestId: arrId,
              date: dateTime[0],
              day: this.Addstarttimedata,
              endTime: '00:00:00',
              lat: this.lat1,
              lon: this.lon1,
              startTime: '00:00:00',
            }
          )
          if (res != null) {
            this.NeedStaffstartDate = res.startDate
            this.NeedStaffendDate = res.endDate
            this.dirData = res.pageInfo.list
            this.workerOrdernums = res.pageInfo.total
            console.log(this.dirData)
          } else if (res == null) {
            this.NeedStaffstartDate = ''
            this.NeedStaffendDate = ''
            this.dirData = []
            this.workerOrdernums = 0
          }
        }
      } else {
        let res = await getNeedStaff(
          this.workerOrderPage,
          this.workerOrderpagesize,
          {
            arrestId: arrId,
            date: this.obj.date,
            day: this.obj.day,
            endTime: this.obj.endTime,
            lat: this.obj.lat,
            lon: this.obj.lon,
            startTime: this.obj.startTime,
          }
        )
        if (res != null) {
          this.NeedStaffstartDate = res.startDate
          this.NeedStaffendDate = res.endDate
          this.dirData = res.pageInfo.list
          this.workerOrdernums = res.pageInfo.total
          console.log(this.dirData)
        } else if (res == null) {
          this.NeedStaffstartDate = ''
          this.NeedStaffendDate = ''
          this.dirData = []
          this.workerOrdernums = 0
        }
      }

      // this.distanceList = data.distanceList
    },
    getactClicktreemenudata(data) {
      console.log('click:', data)
      this.depId2 = data.depId
      this.arrestId2 = data.value
      if (data.children == null) {
        this.pointid = data.value
      }
      this.actClick = data.value
      this.getClassesByArrest(this.actClick)
    },
    workOrderDialogSubmit() {
      console.log('aaa', this.currentClass)

      if (this.btnSheetAgain == false) {
        if (this.personid == '') {
          this.$message.warning('请选择人员！')
          return
        }
        this.map?.destroy()

        // this.dialogAddorder = false;
        let tempAddtype = this.Addtype
        // this.Addtype = "人员收集";
        // let tempAdddialogtype = this.Adddialogtype
        // this.Adddialogtype = "1";
        // let tempAddtitle = this.Addtitle
        // this.Addtitle = "新增工单-选择工单类型及地点";
        // let tempCurrentClass = this.currentClass;
        // this.currentClass = null;
        // let tempPersonid = this.personid;
        // this.personid = "";

        //todo 回退****************************
        this.batchType = tempAddtype === '人员收集' ? '03' : '02'
        this.batchOrderInfoDialogShow = true
        //todo ********************************
        // if (tempAddtype == "人员收集") {
        //   this.$router.push({
        //     name: "addProcess",
        //     params: {
        //       wfid: "1556459272211136512",
        //     },
        //     query: {
        //       moduleid: "M11495",
        //       wfid: "1556459272211136512",
        //       frombillid: "18",
        //       wfRange: "1",
        //       menuid: "LM1399",
        //       title: "工单审批",
        //       configtype: "003",
        //       groupid: "M11495",
        //       filtergroup: "",
        //       filtergroupshowsign: "Y",
        //       totitle: "新增工单审批",
        //       name: "001",
        //       billName: null,
        //       startDate: this.NeedStaffstartDate,
        //       endDate: this.NeedStaffendDate,
        //       finishTime: null,
        //       person: tempPersonid,
        //       lat: this.lat1,
        //       lon: this.lon1,
        //       mapAddress: this.mapAddress,
        //     },
        //   });
        // } else {
        //   console.log('aaa1', tempCurrentClass)
        //   this.$router.push({
        //     name: "addProcess",
        //     params: {
        //       wfid: "1556459272211136512",
        //     },
        //     query: {
        //       moduleid: "M11495",
        //       wfid: "1556459272211136512",
        //       frombillid: "18",
        //       wfRange: "1",
        //       menuid: "LM1399",
        //       title: "工单审批",
        //       configtype: "003",
        //       groupid: "M11495",
        //       filtergroup: "",
        //       filtergroupshowsign: "Y",
        //       totitle: "新增工单审批",
        //       name: "001",
        //       billName: null,
        //       finishTime: null,
        //       postname: tempCurrentClass.postId,
        //       pointname: this.pointid,
        //       classesName: tempCurrentClass.classesName,
        //       classesid: tempCurrentClass.classesId,
        //       startDate: this.NeedStaffstartDate,
        //       endDate: this.NeedStaffendDate,
        //       person: tempPersonid,
        //       // endDate:this.currentClass.endTime+'',
        //       // "lat": this.currentClass.latitude,
        //       // "lon": this.currentClass.longitude,
        //     },
        //   });
        // }
      } else {
        console.log(
          this.SheetAgainAddstarttime,
          this.personid,
          this.SheetAgainbillId
        )
        this.updateSheetToWork()
      }
    },
    workOrderPushAllSubmit() {
      let tempParams = {}
      if (this.Addtype === '直接排班') {
        tempParams = {
          arrestId: this.needNoStaffArrestId ? this.arrestId2 : null,
          depId: this.needNoStaffDepId ? this.depId2 : null,
          approval: this.sendschedulingId ? this.classId : null,
          kind: this.kongXian ? '001' : this.needKind2,
          fireNo: this.sendFireNo ? 'Y' : null,
          expatriate: this.sendExpatriate ? '002' : null,
          sex: this.sexValue ? this.sexValue : null,
          smallAge: this.ageMin ? this.ageMin : null,
          bigAge: this.ageMax ? this.ageMax : null,
          height: this.height ? this.height : null,
          education: this.degree ? this.degree : null,
          service: this.service ? '001' : null,
          securityStaff: this.sendsecurityStaff ? '001' : null,
          staff: this.starname,
          distance: this.staraddress,
          starList: this.starvalue,
          date: this.Addstarttime2,
          day: this.currentClass.classesDay,
          endTime: this.currentClass.endTime,
          lat: this.currentClass.latitude,
          lon: this.currentClass.longitude,
          startTime: this.currentClass.startTime,
        }
      } else {
        let dateTime = this.Addstarttime1[0].split(' ', 2)
        tempParams = {
          arrestId: this.needNoStaffArrestId ? this.arrestId2 : null,
          depId: this.needNoStaffDepId ? this.depId2 : null,
          approval: this.sendschedulingId ? this.classId : null,
          startDate: this.Addstarttime1[0],
          endDate: this.Addstarttime1[1],
          kind: this.kongXian ? '001' : this.needKind2,
          fireNo: this.sendFireNo ? 'Y' : null,
          expatriate: this.sendExpatriate ? '002' : null,
          sex: this.sexValue ? this.sexValue : null,
          smallAge: this.ageMin ? this.ageMin : null,
          bigAge: this.ageMax ? this.ageMax : null,
          height: this.height ? this.height : null,
          education: this.degree ? this.degree : null,
          service: this.service ? '001' : null,
          securityStaff: this.sendsecurityStaff ? '001' : null,
          staff: this.starname,
          distance: this.staraddress,
          starList: this.starvalue,
          date: dateTime[0],
          day: this.Addstarttimedata,
          endTime: '00:00:00',
          lat: this.lat1,
          lon: this.lon1,
          startTime: '00:00:00',
        }
      }

      this.batchAllParams = tempParams
      this.batchType = this.Addtype === '人员收集' ? '03' : '02'
      this.batchAllOrderInfoDialogShow = true
    },
    async updateSheetToWork() {
      if (this.personid == '') {
        this.$message.warning('请选择人员！')
        return
      } else {
        if (this.SheetAgainAddstarttime == '') {
          this.$message.warning('请选择截单时间！')
          return
        } else {
          let res = updateSheetToWork({
            billId: this.SheetAgainbillId,
            receiveIds: this.personid,
            time: this.SheetAgainAddstarttime,
          })
          this.getSheet()
          this.personid = ''
          this.SheetAgainAddstarttime = ''
          this.dialogAddorder = false
          this.Adddialogtype = 1
          this.btnSheetAgain = false
        }
      }
    },
    workOrderDialogPre() {
      this.Addaddress = ''
      this.Addstarttime1 = ''
      this.Addstarttime2 = ''
      this.Adddialogtype = '1'
      this.Addtitle = '新增工单-选择工单类型及地点'
      this.map?.destroy()
      this.getPosition(this.lon, this.lat)
    },
    wkOrderDialogCurrentChange(val) {
      this.currentClass = val
      console.log('aaa', this.currentClass)
      this.classId = val.classesId
    },
    wkOrderPeopleSelect(row) {
      console.log('row:', row)
      this.distanceRow = row
      let personid = ''
      this.distanceRow.forEach((item) => {
        if (item.oldStaffId) {
          if (personid == '') {
            personid = personid + item.oldStaffId
          } else {
            personid = personid + ',' + item.oldStaffId
          }
        }
      })
      this.personid = personid
      console.log(this.personid)
    },
    async refresh() {
      this.pageNumber = 1
      this.db = 0
      // document.getElementById(this.rightdata[this.tabindexs].title).scrollTop=0;
      this.getMessage() //刷新接口数据
      // this.$refs.child.scroll()
    },
    async updateMessageAllRead() {
      let params = {
        staffId: this.needstaffId,
      }
      this.$doFetch('/Custom/message/updateMessageAllRead', params).then(
        (res) => {
          if (res.success) {
            console.log(res)
          }
        }
      )
    },
    // 已读全部信息
    allRead() {
      this.updateMessageAllRead()
      this.getMessage()
    },
  },
}
</script>

<style lang="scss" scoped>
.all {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(243, 244, 248);
  // background-color:black;
}

.itemall {
  width: 99%;
  height: 99%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left {
  width: 70.6%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

//左侧第一行部分
.one {
  width: 100%;
  height: 31%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.one-left {
  width: 36%;
  height: 100%;
  border-radius: 4px;
  background-image: url('~@/assets/images/dispacthingCenter/kq_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.one-left-title {
  font-size: 1.2vw;
  font-weight: bold;
  color: white;
  width: 85%;
  height: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.one-left-whole {
  width: 80%;
  height: 78%;
  display: flex;
  flex-direction: column;
}

.one-left-list1 {
  width: 100%;
  height: 50%;
  // color: white;
  // font-size: 0.9vw;
  display: flex;
  flex-direction: row;
}
.list1 {
  width: 20%;
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 0.5vw;
  // cursor: pointer;
}

.span0 {
  font-size: 1.5vw;
}
.span1 {
  font-size: 1.2vw;
  //text-align: center;
}

.one-left-list2 {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
}

.list2 {
  width: 25%;
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 0.5vw;
  // cursor: pointer;
}

.one-right {
  width: 61.6%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.one-right-title {
  width: 94%;
  height: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.one-right-title-font {
  width: 95%;
  height: 100%;
  font-size: 1.2vw;
  font-weight: bold;
  color: #333333;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.one-right-title-i {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.one-right-whole {
  width: 94%;
  height: 70%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.one-right-list {
  width: 100%;
  height: 30%;
  border-radius: 4px;
  margin-bottom: 1vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  background-color: rgb(242, 246, 255);
  display: flex;
  flex-direction: row;
}

.one-right-list-img {
  height: 100%;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.one-right-list-img > img {
  width: 50%;
}

.one-right-list-font {
  height: 100%;
  width: 55%;
  display: flex;
  flex-direction: column;
}

.one-right-list-font1 {
  height: 50%;
  width: 100%;
  font-size: 0.9vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.fontp1 {
  width: 50%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fontp2 {
  width: 50%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.one-right-list-font2 {
  height: 50%;
  width: 100%;
  font-size: 0.9vw;
  color: #adaeb0;
  display: flex;
  flex-direction: row;
}

.one-right-list-button1 {
  height: 100%;
  width: 20%;
  font-size: 0.9vw;
  color: #4880ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.one-right-list-button2 {
  height: 100%;
  width: 15%;
  font-size: 0.9vw;
  color: #f47451;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

//左侧第二行部分
.two {
  width: 100%;
  height: 19%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.two-title {
  width: 94%;
  height: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.two-title-font {
  width: 92%;
  font-size: 1.2vw;
  color: #333333;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.two-title-ileft {
  width: 5%;
  height: 100%;
  font-size: 1vw;
  color: #adaeb0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.two-title-i {
  width: 3%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.two-whole {
  width: 94%;
  height: 70%;
  white-space: nowrap;
  overflow: auto;
}

.two-whole-list {
  width: 25%;
  height: 92%;
  border: 1px solid #e6e6e6;
  cursor: pointer;
  margin-right: 1vw;
  border-radius: 4px;
  background-image: url('~@/assets/images/dispacthingCenter/gwkq_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
}

.two-whole-listrow {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.two-whole-list-font {
  width: 75%;
  height: 50%;
  font-size: 0.9vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.two-whole-span1 {
  width: 90%;
  height: 50%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.two-whole-span2 {
  width: 90%;
  height: 50%;
  color: #adaeb0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.two-whole-list-img {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.two-whole-list-img > img {
  width: 2vw;
}

.three {
  width: 100%;
  height: 31%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.three-left {
  width: 36%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.three-left-title {
  width: 85%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.three-left-title-top {
  width: 100%;
  height: 50%;
  font-size: 1.2vw;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.three-left-title-choose {
  width: 100%;
  height: 50%;
  font-size: 0.9vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.spanc {
  height: 100%;
  width: 35%;
  cursor: pointer;
}

.spancchecked {
  color: #4880ff;
  text-decoration: underline;
}

.three-left-whole {
  width: 98%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.three-left-whole-list {
  width: 95%;
  height: 33%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #e6e6e6;
  display: flex;
  flex-direction: row;
}

.three-left-whole-list-img {
  width: 18%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.three-left-whole-list-img > img {
  width: 2vw;
}

.three-left-whole-list-font {
  width: 65%;
  display: flex;
  flex-direction: column;
}

.three-left-whole-list-font1 {
  width: 100%;
  height: 50%;
  font-size: 0.9vw;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.three-left-whole-list-font1 > span {
  height: 80%;
}

.font1-margin {
  margin-right: 10%;
}

.three-left-whole-list-font2 {
  width: 100%;
  height: 50%;
  color: #adaeb0;
  font-size: 0.9vw;
}

.three-left-whole-list-btn {
  width: 12%;
  height: 100%;
  font-size: 0.9vw;
  color: #4880ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.three-left-whole-list-i {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.three-right {
  width: 61.6%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.three-right-top {
  width: 90%;
  height: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.three-right-top-left {
  width: 50%;
  height: 100%;
  font-size: 1.2vw;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.three-right-top-right {
  width: 50%;
  height: 100%;
  font-size: 1vw;
  color: #4880ff;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.three-right-top-right-img {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3%;
}

.three-right-top-right-img > img {
  width: 1.2vw;
}

.three-right-whole {
  width: 95%;
  height: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.three-right-whole-left {
  width: 13%;
  height: 95%;
  font-size: 0.9vw;
  color: #4880ff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('~@/assets/images/dispacthingCenter/gd_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.nums {
  font-size: 1.6vw;
}

.three-right-whole-left-img {
  margin-top: 1vh;
  width: 1.8vw;
  border-radius: 4px;
  overflow: hidden;
}

.three-right-whole-right {
  width: 85%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
}

.three-right-whole-right-list {
  width: 97%;
  height: 40px;
  padding-bottom: 0.5vh;
  padding-top: 0.5vh;
  margin-bottom: 1.2vh;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.three-right-whole-right-list-font1 {
  width: 100%;
  height: 50%;
  font-size: 0.9vw;
  padding-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.three-right-whole-right-list-span1 {
  width: 70%;
  height: 100%;
}

.type {
  margin-left: 2%;
  font-size: 0.8vw;
  background-color: #4880ff;
  color: white;
  padding: 1px 3px;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
}

.three-right-whole-right-list-span2 {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.end {
  width: 30%;
  height: 100%;
  font-size: 0.9vw;
}

.i {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.three-right-whole-right-list-font2 {
  width: 100%;
  height: 50%;
  padding-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.three-right-whole-right-list-font2 > span {
  width: 49%;
  font-size: 0.9vw;
  color: #adaeb0;
}

.four {
  width: 100%;
  height: 13%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.four-left {
  width: 58.8%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  position: relative;
}

.four-left-top {
  font-size: 1.2vw;
  font-weight: bold;
  position: absolute;
  top: 5%;
  left: 5%;
}

.four-left-whole {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.four-left-whole-list {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.four-left-whole-list-img {
  width: 40%;
  height: 90%;
  padding-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.four-left-whole-list-img > img {
  width: 2.5vw;
}

.four-left-whole-list-span {
  width: 40%;
  height: 90%;
  padding-top: 10%;
  font-size: 0.9vw;
  color: #999999;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.four-left-span1 {
  font-size: 1.2vw;
  color: black;
  font-weight: bold;
}

.four-right {
  width: 38.8%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  position: relative;
}

.four-right-top {
  font-size: 1.2vw;
  font-weight: bold;
  position: absolute;
  top: 5%;
  left: 5%;
}

.four-right-whole {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.four-right-whole-list {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.four-right-whole-list-img {
  width: 40%;
  height: 90%;
  padding-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.four-right-whole-list-img > img {
  width: 2.5vw;
}

.four-right-whole-list-span {
  width: 40%;
  height: 90%;
  padding-top: 10%;
  font-size: 0.9vw;
  color: #999999;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}

.four-right-span1 {
  font-size: 1vw;
  color: black;
  font-weight: bold;
}

.right {
  width: 28%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-top {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.right-top-title {
  width: 100%;
  height: 10%;
  background-image: url('~@/assets/images/dispacthingCenter/title_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 1.2vw;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-top-title-font {
  width: 90%;
  height: 90%;
  font-size: 1.2vw;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.right-top-whole {
  width: 100%;
  height: 90%;
  overflow-y: auto;
}

.right-top-whole-list {
  width: 100%;
  height: 10vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.right-top-whole-list-font {
  width: 100%;
  height: 100%;
  font-size: 0.9vw;
}

.right-top-whole-list-fonts {
  width: 75%;
  height: 100%;
  font-size: 0.9vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.right-top-whole-list-btn {
  width: 20%;
  height: 100%;
  font-size: 0.9vw;
}

.spancolor {
  color: #adaeb0;
}

.btni {
  padding-top: 2%;
}

.right-bottom {
  width: 100%;
  height: 60%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.right-bottom-top {
  width: 100%;
  height: 15%;
  background-image: url('~@/assets/images/dispacthingCenter/title_bg.png');
  background-repeat: no-repeat;
  background-size: 100%, 100%;
  font-size: 1.2vw;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-bottom-title-font {
  width: 90%;
  height: 90%;
  font-size: 1.2vw;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.right-bottom-bottom {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
}

.right-bottom-bottom-calendar {
  width: 100%;
  height: 40%;
  position: relative;
}

#button {
  cursor: pointer;
  position: absolute;
  top: 6%;
  right: 5%;
}

.right-bottom-bottom-whole {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.right-bottom-bottom-whole-list {
  width: 90%;
  height: 22%;
  border-radius: 4px;
  background-color: #fef8ee;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right-bottom-bottom-whole-list2 {
  width: 90%;
  height: 22%;
  border-radius: 4px;
  background-color: #f2f6ff;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right-bottom-bottom-whole-img {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-bottom-bottom-whole-img > img {
  width: 2vw;
}

.right-bottom-bottom-whole-font {
  width: 60%;
  height: 100%;
  font-size: 0.9vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-bottom-bottom-whole-font-one {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right-bottom-bottom-whole-font-img {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-bottom-bottom-whole-font-img > img {
  width: 1.2vw;
  margin-left: 2%;
}

.right-bottom-bottom-whole-font-two {
  width: 100%;
  height: 40%;
  color: #adaeb0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right-bottom-bottom-whole-btn {
  width: 30%;
  font-size: 0.9vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

//年份那一行样式修改
:deep(.el-calendar__header) {
  padding: 5px 20px;

  .el-calendar__title {
    font-weight: bold;
  }
}

:deep(.el-calendar__body) {
  // 修改主题部分
  padding: 0;
  margin: 0 auto;
  width: 90%;
}

:deep(.el-calendar__header) {
  border: none;

  .el-calendar__button-group {
    // 隐藏原生按钮
    display: none;
  }
}

//下方日历样式修改
:deep(.el-calendar-table) {
  .el-calendar-day {
    // 每天小块样式设置
    padding: 0;
    background-color: white;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      // 所有日期颜色
      color: black;
      z-index: 1;
    }
  }
}

:deep(.el-calendar-table tr:first-child td){
  border: none;
}

:deep(.el-calendar-table tr td:first-child) {
  border: none;
}

:deep(.el-calendar-table td) {
  border: none;
}

//星期那一行样式修改
:deep(.el-calendar-table thead th) {
  font-size: 1vw;
  padding: 5px 0;
}

.is-selected {
  color: white !important;
  font-size: 0.9vw;
  padding: 4px;
  background-color: #4880ff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-selected-1 {
  font-size: 0.9vw;
  padding: 4px;
  border-radius: 4px;
  background-color: #f2f6ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-selected-2 {
  font-size: 0.9vw;
  padding: 4px;
  border-radius: 4px;
  background-color: #fef0f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-selected-3 {
  font-size: 0.9vw;
  padding: 4px;
  border-radius: 4px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spancolor-zc {
  color: #f2f6ff;
}

.spancolor-kq {
  color: #fef0f0;
}

:deep(.el-dialog__body) {
  max-height: 90vh !important;
  padding: 0px 20px 10px 20px;
}

:deep(.el-table) {
  .el-table__fixed {
    height: auto !important;
    bottom: 12px !important;
  }

  .el-table__fixed-right {
    height: auto !important;
    bottom: 12px !important;
  }
}

:deep(.el-table__body-wrapper) {
  z-index: 2;
}

:deep(.el-select .el-tag:first-child) {
  display: inline-block;
  width: 65px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.el-select .el-tag) {
  display: inline-block;
  width: 30px;
}

.el-cascader {
  width: 70%;
}

:deep(.without-outline:focus) {
  outline: none;
}

:deep(.el-table--border::after),
:deep(.el-table--group::after),
:deep(.el-table::before) {
  background-color: #ebeef5;
}

:deep(.el-menu-vertical-demo:not(.el-menu--collapse)) {
  width: 100%;
}

:deep(.el-submenu .el-menu-item) {
  padding: 0 20px !important;
}

:deep(.el-menu-item.is-active) {
  color: black;
  background-color: white;
}

:deep(.el-menu-vertical-demo) {
  overflow-y: auto;
}

:deep(.el-table__body tr.current-row > td.el-table__cell) {
  background-color: #409eff;
  color: white;
}

:deep(.el-table--enable-row-hover .el-table__body tr:hover > td) {
  background-color: #d0e8ff;
  color: black;
}
:deep(.el-button--mini) {
  padding: 6px 6px;
}
</style>
