import * as Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import router from './router/index.js'
import { encode64 } from './utils/encryption'
//import baseURL from '../constans.js'
import store from './store'
import {
    ElMessage as Message,
    ElNotification as Notification
} from 'element-plus'
//import { Notify } from 'vant';
// import router from './router'
//设置axios，配置请求拦截器、响应拦截器
// axios.interceptors.request.use(function (config) {
//     // 当调用带鉴权的接口时，添加token头部标志
//     // if(localStorage.getItem('mytoken')){
//     //     //在请求头部中加入token
//     //     config.headers.Authorization = localStorage.getItem('mytoken')
//     // }
//     return config;
//   }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   });

//   //响应拦截器
//   axios.interceptors.response.use(function (response) {
//     // Do something with response data
//     return response;
//   }, function (error) {
//       //当token过期时，获取错误信息，并清除本地存储的过期token
//     // if(error.response.status == 401){
//     //     localStorage.removeItem('mytoken')
//     //     router.push('/login')
//     // }
//     return Promise.reject(error);
//   });

axios.defaults.timeout = 3000000 //响应时间
axios.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded;charset=UTF-8' //配置请求头
//axios.defaults.baseURL = process.env.NODE_ENV=='development'?'api':baseURL; //配置接口地址
//console.log('baseURL',axios.defaults.baseURL)

/**
 * 报错提示
 * @param res
 */
function showError(res) {
    Notification.error({
        dangerouslyUseHTMLString: true,
        title: res.data.errMsg == res.data.errMsgDetail ? '' : res.data.errMsg,
        message: `<p>${res.data.errMsgDetail}</p><p style="color:gray;font-size:12px;margin-top:6px;">追踪号:${res.headers['trace-id']}</p>`
    })
}

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params
            })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

/**
 * 封装get方法,自动处理报错
 * @param url
 * @param data
 * @returns {Promise}
 */
export function doFetch(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params
            })
            .then(response => {
                if (response.data.success === false) {
                    // 如果执行失败则报错
                    showError(response)
                    // reject
                    reject(response)
                } else {
                    resolve(response.data.data)
                }
            })
            .catch(err => {
                reject(err)
            })
    })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            response => {
                resolve(response.data)
            },
            err => {
                reject(err)
            }
        )
    })
}

export function postJson(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: {
                    post: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    }
                }
            })
            .then(
                response => {
                    resolve(response.data)
                },
                err => {
                    reject(err)
                }
            )
    })
}

/**
 * 封装post请求,自动处理报错
 * @param url
 * @param data
 * @returns {Promise}
 */
export function doPost(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            response => {
                if (response.data.success === false) {
                    // 如果执行失败则报错
                    showError(response)
                    // reject
                    reject(response)
                } else {
                    resolve(response.data.data)
                }
            },
            err => {
                reject(err)
            }
        )
    })
}

export function doPostJson(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: {
                    post: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    }
                }
            })
            .then(
                response => {
                    if (response.data.success === false) {
                        // 如果执行失败则报错
                        showError(response)
                        // reject
                        reject(response)
                    } else {
                        resolve(response.data.data)
                    }
                },
                err => {
                    reject(err)
                }
            )
    })
}

//上传
export function postBlob(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: {
                    post: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            })
            .then(
                response => {
                    resolve(response.data)
                },
                err => {
                    reject(err)
                }
            )
    })
}

//下载
export function post_blob(url) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, {
                responseType: 'blob'
            })
            .then(response => {
                console.log('response', response)
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

//下载
export function postBlobByJSON(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {
                headers: {
                    post: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    }
                },
                responseType: 'blob'
            })
            .then(response => {
                console.log('response', response)
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            response => {
                resolve(response.data)
            },
            err => {
                reject(err)
            }
        )
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            response => {
                resolve(response.data)
            },
            err => {
                reject(err)
            }
        )
    })
}

export function doPut(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            response => {
                if (response.data.success === false) {
                    // 如果执行失败则报错
                    showError(response)
                    // reject
                    reject(response)
                } else {
                    resolve(response.data.data)
                }
            },
            err => {
                reject(err)
            }
        )
    })
}

export function puts(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios
            .put(url, data, {
                headers: {
                    'Content-Type': 'application/octet-stream'
                }
            })
            .then(
                response => {
                    resolve(response)
                },
                err => {
                    reject(err)
                }
            )
    })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function remove(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.delete(url, data).then(
            response => {
                resolve(response.data)
            },
            err => {
                reject(err)
            }
        )
    })
}

export function fetch_blob(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params,
                responseType: 'blob',
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded; application/octet-stream'
                }
            })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

const CancelToken = axios.CancelToken

//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
    config => {
        config.cancelToken = new CancelToken(cancel => {
            store.state['axiosPromiseCancel'].push({
                url: config.url,
                cancel: cancel
            })
        })
        //在发送请求之前做某件事
        if (config.method === 'post') {
            const headers = config.headers
            // console.log('xxx',headers)
            if (
                headers != null &&
                headers.post['Content-Type'] ===
                'application/x-www-form-urlencoded;charset=UTF-8'
            ) {
                config.data = qs.stringify(config.data)
                // 请求体加密
                if (
                    window.$vueApp.config.globalProperties.isEncryption == 'Y' &&
                    !config.url.startsWith('http')
                ) {
                    config.headers['encryption-enabled'] = 'Y'
                    config.data = encode64(config.data)
                }
            }
            if (
                headers != null &&
                headers.post['Content-Type'] === 'application/json;charset=UTF-8'
            ) {
                config.data = JSON.stringify(config.data)
                // 请求体加密
                if (
                    window.$vueApp.config.globalProperties.isEncryption == 'Y' &&
                    !config.url.startsWith('http')
                ) {
                    config.headers['encryption-enabled'] = 'Y'
                    config.data = encode64(config.data)
                }
            }
        }
        // 当调用带鉴权的接口时，添加token头部标志
        if (sessionStorage.getItem('mytoken')) {
            //在请求头部中加入token
            if (
                config.url !=
                'https://restapi.amap.com/v3/assistant/coordinate/convert?parameters'
            ) {
                config.headers.AuthToken = sessionStorage.getItem('mytoken')
            }
        }
        return config
    },
    error => {
        console.log('错误的传参')
        return Promise.reject(error)
    }
)

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
    res => {
        //对响应数据做些事
        if (!res.data.success) {
            if (
                res.data.errCode == '026' ||
                res.data.errCode == '002' ||
                res.data.errCode == '029' ||
                res.data.errCode == '020'
            ) {
                //  Notify({ type: 'danger', message: res.data.errMsg });
                console.log('网络异常222:', res.data.errCode)
                sessionStorage.removeItem('mytoken')
                // this.$router.push('/home')
                router.push({
                    path: '/login'
                })
            } else {
                return Promise.resolve(res)
            }
        }
        return res
    },
    error => {
        store.state['cffloading'] = false
        if (axios.isCancel(error)) {
            // 为了终结promise链 就是实际请求 不会走到.catch(rej=>{});这样就不会触发错误提示之类了。
            return new Promise(() => { })
        } else {
            // console.log('网络异常222:' , error)
            //当token过期时，获取错误信息，并清除本地存储的过期token
            if (error.response.status == 401) {
                sessionStorage.removeItem('mytoken')
                console.log('token过期')
                window.$vueApp.config.globalProperties.$router.push({
                    path: '/login'
                })
            }
            // 404处理
            if (error.response.status == 404) {
                Message.error({
                    showClose: true,
                    message: `404：找不到请求路径${error.config.url}`
                })
            }
            if (error.response.status == 500) {
                Message.error({
                    showClose: true,
                    message: '服务器错误！'
                })
            }
            return Promise.reject(error)
        }
    }
)
export default axios
