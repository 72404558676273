<template>
  <div class="dutyTypeCardBlock">
    <div class="block-left">
      <div :style="'background-color:' + item.color" />
    </div>

    <div class="block-right">{{ item.name }}({{ item.time }})</div>
  </div>
</template>

<script>
export default {
  name: 'DutyTypeCard',
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {}
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
@import 'css/duty';
.dutyTypeCardBlock {
  display: flex;
  height: 24px;
  min-width: 100px;
  border: #e8eaee 1px solid;
  border-radius: 5px;
  .block-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;

    div {
      height: 13px;
      width: 13px;
      background-color: $dayBg;
      border-radius: 3px;
    }
  }

  .block-right {
    width: calc(100% - 35px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    color: #464d66;
    padding: 0 6px 0 3px;
  }
}
</style>
