<template>
    <div class="splitBill">
        <div class="splitBillTable">
            <div class="table-one">
                <div style="width: 100%;height: 10%;display: flex;flex-direction: row;">
                    <div style="width:45%;margin-left: 5%;">请假人：<span>{{holidayInfo.staffName?holidayInfo.staffName:''}}</span></div>
                    <div style="width:45%;margin-left: 5%;">请假类型：<span>{{ holidayInfo.holidayType?holidayInfo.holidayType:'' }}</span></div>
                </div>
                <el-table :data="table1" height="90%" @selection-change="handleSelectionChange1" ref="multipleTable1">
                    <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column label="系数" prop="coefficient"  width="45"></el-table-column>
                    <el-table-column label="大队" prop="depName"  width="60" show-overflow-tooltip></el-table-column>
                    <el-table-column label="驻点" prop="arrestName" show-overflow-tooltip></el-table-column>
                    <el-table-column label="岗位" prop="postName" show-overflow-tooltip></el-table-column>
                    <el-table-column label="班次" prop="classesName" show-overflow-tooltip></el-table-column>
                    <el-table-column label="出勤日期" prop="attendanceDate" show-overflow-tooltip></el-table-column>
                </el-table>
            </div>
            <div class="btn">
                <el-button type="primary" :icon="ElIconArrowRight" @click="toRight"></el-button>
                <el-button type="primary" :icon="ElIconArrowLeft" style="margin-left:0px" @click="toLeft"></el-button>
                <el-dialog title="系数调整"
                           :visible.sync="coefficientDialogShow"
                           append-to-body
                           :destroy-on-close="true"
                           :close-on-click-modal="false"
                           :show-close="false">
                    <div size="medium" style="width:100%;display: flex;justify-content:center;align-items:center;margin-bottom: 20px;">
                        <el-input-number v-model="num" :step="0.01" step-strictly :precision="2" :max="max" :min="0.01"></el-input-number>
                    </div>
                    <div style="position: absolute;right: 10px;bottom:10px">
                        <el-button @click="coefficientDialogShow=false">取消</el-button>
                        <el-button type="primary" @click="toConfirm">确认</el-button>
                    </div>
                </el-dialog>
            </div>
            <div class="table-two">
                <div style="width: 100%;height: 10%;display: flex;flex-direction: row;">
                    <div style="width:45%;margin-left: 5%;">请假人：<span>{{holidayInfo.staffName?holidayInfo.staffName:''}}</span></div>
                    <div style="width:45%;margin-left: 5%;">请假类型：
                        <el-select v-model="value" placeholder="请选择" style="width:50%">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <el-table :data="table2" height="90%" @selection-change="handleSelectionChange2" ref="multipleTable2">
                    <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column label="系数" prop="coefficient"  width="45"></el-table-column>
                    <el-table-column label="大队" prop="depName"  width="60" show-overflow-tooltip></el-table-column>
                    <el-table-column label="驻点" prop="arrestName" show-overflow-tooltip></el-table-column>
                    <el-table-column label="岗位" prop="postName" show-overflow-tooltip></el-table-column>
                    <el-table-column label="班次" prop="classesName" show-overflow-tooltip></el-table-column>
                    <el-table-column label="出勤日期" prop="attendanceDate" show-overflow-tooltip></el-table-column>
                </el-table>
            </div>
        </div>
        <div class="footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="confirm">确认</el-button>
        </div>
    </div>
</template>
<script>
    import {getSplitBill,getHolidayType,confirmSplitBill} from "@/requet/api/ba/scheduleApi";
    import {
        ArrowRight as ElIconArrowRight,
        ArrowLeft as ElIconArrowLeft,
    } from '@element-plus/icons-vue'
    import {shallowRef} from "vue";

    export default {
        name:'splitBill',
        props: ['queryParams'],
        data(){
            return{
                table1:[],
                table2:[],
                value:"",
                options:[
                    {value: '选项1',label: '黄金糕'},
                    {value: '选项2',label: '双皮奶'},
                    {value: '选项3',label: '蚵仔煎'},
                    {value: '选项4',label: '龙须面'},
                    {value: '选项5',label: '北京烤鸭'}
                ],
                choose1:[],
                choose2:[],
                holidayInfo:{},
                coefficientDialogShow:false,
                num:1,
                checked:false,//负数校验是否通过
                type:'',
                max:1,
                ElIconArrowRight: shallowRef(ElIconArrowRight),
                ElIconArrowLeft: shallowRef(ElIconArrowLeft),
            }
        },
        watch:{
            queryParams(val){
                this.clear();
                this.getSplitBill();
                this.getHolidayType();
            }
        },
        mounted(){
            console.log('queryParams', this.queryParams)
            this.clear();
            this.getSplitBill();
            this.getHolidayType();
        },
        methods:{
            close() {
                this.$emit('btnLinkClose')
            },
            confirm(){
                if(this.table2.length==0){
                    this.$message({
                        message: '未进行单据拆分！',
                        type: 'warning'
                    });
                    return
                }
                if(this.value==''){
                    this.$message({
                        message: '未选择请假类型！',
                        type: 'warning'
                    });
                    return
                }
                this.confirmSplitBill();
                this.$emit('btnLinkClose')

            },
            async getSplitBill(){
                let res=await getSplitBill({
                    billid:this.queryParams.billid
                })
                console.log(res);
                this.holidayInfo=res.holidayInfo;
                this.table1=res.list;
            },
            async getHolidayType(){
                let res=await getHolidayType()
                this.options=[];
                console.log(res)
                if(res.length){
                    res.forEach(options=>{
                        let obj={
                            value:options.id,
                            label:options.name,
                        }
                        this.options.push(obj)
                    })
                }
            },
            async confirmSplitBill(){

                var arr=[];
                this.table1.forEach(table1=>{
                    if(table1.coefficient!=0){
                        arr.push(table1)
                    }
                })
                let obj={
                    newHolidayType:this.value,
                    newList:this.table2,
                    oldBillId:this.queryParams.billid,
                    oldList:arr,
                }
                let res=await confirmSplitBill(obj);
            },
            handleSelectionChange1(val){
                console.log(val);
                this.choose1=JSON.parse(JSON.stringify(val));
            },
            handleSelectionChange2(val){
                console.log(val);
                this.choose2=JSON.parse(JSON.stringify(val));
            },
            toRight(){
                if(this.choose1.length){
                    var maxnum=this.choose1[0].coefficient;
                    this.choose1.forEach(res=>{
                        if(res.coefficient<maxnum){
                            maxnum=res.coefficient
                        }
                    })
                    this.max=maxnum;
                    this.num=this.max;
                    this.type='toRight';
                    this.coefficientDialogShow=true;
                }else{
                    this.max=1;
                    this.num=this.max;
                    this.$message({
                        message: '左侧关联排班未选择！',
                        type: 'warning'
                    });
                }
            },
            toLeft(){
                if(this.choose2.length){
                    var maxnum=this.choose2[0].coefficient;
                    this.choose2.forEach(res=>{
                        if(res.coefficient<maxnum){
                            maxnum=res.coefficient
                        }
                    })
                    this.max=maxnum;
                    this.num=this.max;
                    this.type='toLeft';
                    this.coefficientDialogShow=true;
                }else{
                    this.max=1;
                    this.num=this.max;
                    this.$message({
                        message: '右侧关联排班未选择！',
                        type: 'warning'
                    });
                }
            },
            toConfirm(){
                this.coefficientDialogShow=false;
                if(this.type=='toRight'){
                    //计算左侧列表数据
                    for(let i=0;i<this.table1.length;i++){
                        if(this.choose1.filter(tableChoose=>{return tableChoose.series==this.table1[i].series}).length!=0){
                            this.table1[i].coefficient=Number((this.table1[i].coefficient-this.num).toFixed(2));
                            if(this.table1[i].coefficient==0){
                                this.table1.splice(i,1)
                                i--;
                            }
                        }
                    }
                    //计算右侧列表数据
                    var arr=JSON.parse(JSON.stringify(this.choose1));
                    arr.forEach(res=>{
                        if(this.table2.length==0||this.table2.filter(k =>{return k.series==res.series}).length==0){
                            res.coefficient=this.num;
                            this.table2.push(res)
                        }else if(this.table2.filter(k =>{return k.series==res.series}).length!=0){
                            this.table2.forEach(table2Change=>{
                                if(table2Change.series==res.series){
                                    table2Change.coefficient=Number((table2Change.coefficient+this.num).toFixed(2));
                                }
                            })
                        }
                    })

                    this.$refs.multipleTable1.clearSelection()
                }else if(this.type=='toLeft'){
                    //计算左侧列表数据
                    var arr2=JSON.parse(JSON.stringify(this.choose2));
                    arr2.forEach(table=>{
                        if(this.table1.filter(res=>{return res.series==table.series}).length){
                            for(let k=0;k<this.table1.length;k++){
                                if(this.table1[k].series==table.series){
                                    this.table1[k].coefficient=Number((this.table1[k].coefficient+this.num).toFixed(2));
                                }
                            }
                        }else{
                            table.coefficient=this.num;
                            this.table1.push(table)
                        }
                    })

                    //计算右侧列表数据
                    for(let i=0;i<this.table2.length;i++){
                        if(this.choose2.filter(res=>{return res.series==this.table2[i].series}).length){
                            this.table2[i].coefficient=Number((this.table2[i].coefficient-this.num).toFixed(2));
                            if(this.table2[i].coefficient==0){
                                this.table2.splice(i,1)
                                i--;
                            }
                        }
                    }

                    this.$refs.multipleTable2.clearSelection()
                }
            },
            clear(){
                this.table2=[];
                this.choose1=[];
                this.choose2=[];
                this.value='';
            },
            //旧列表数据负数校验
            check(){
                this.checked=true;
                this.table1.forEach(res=>{
                    console.log(res)
                    res.coefficient=Number(res.coefficient)
                    if(Number(res.coefficient)<0){
                        this.checked=false;
                    }
                })
            },
        },
    }
</script>
<style lang="scss" scoped>
  .splitBill{
    width: 100%;
    height: 100%;

    .splitBillTable{
      width: 100%;
      height: 90%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .table-one{
        width: 45%;
        height: 100%;
      }
      .btn{
        width: 10%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
      }
      .table-two{
        width: 45%;
        height: 100%;
      }
    }
    .footer {
      //   width: 100%;
      //   height: 20%;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
</style>