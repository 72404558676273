<template>
  <div class="editGradeTypePopBlock">
    <div class="head">
      <div class="head-title">更改班次</div>
      <el-icon class="head-close">
        <el-icon-circle-close />
      </el-icon>
    </div>
    <template>
      <div class="types">
        <div v-for="item in list" class="types-item">
          <gradeTypeOptCard :item="item" />
        </div>
      </div>
    </template>

    <div class="foot">
      <div class="confirm-btn" @click="confirm">确定</div>
    </div>
  </div>
</template>

<script>
import { CircleClose as ElIconCircleClose } from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../../../utils/gogocodeTransfer'
import dutyTypeCard from '@/components/aloneComponents/pg/duty/dutyTypeCard'
import gradeTypeOptCard from '@/components/aloneComponents/pg/duty/gradeTypeOptCard'

export default {
  name: 'EditGradeTypePop',
  components: {
    dutyTypeCard,
    gradeTypeOptCard,
    ElIconCircleClose
  },
  props: {
    date: {
      type: String
    },
    postation: {
      type: Array,
      required: true
    },
    postClasses: Array, //岗位下所有班次
    shiftList: Array,
    dateList: Array,
    mode: String
  },
  emits: ['confirm', 'close'],
  data() {
    return {
      list: [],
      dates: [],
      classesDialogVisble: false
    }
  },
  computed: {},
  watch: {
    postClasses: {
      handler(val, oldVal) {
        // console.log('handler:', this.mode)
        if (this.mode === 'edit' && this.list.length == 0 && val) {
          this.init()
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // console.log('edit pop')
  },
  methods: {
    async init() {
      console.log('edit init')
      let arr = []
      for (const item of this.postClasses) {
        arr.push({
          ...item,
          checked:
            this.shiftList &&
            this.shiftList.find(x => x.classesId === item.classesId)
              ? true
              : false
        })
      }
      // console.log('list:', this.list)
      this.list = arr
    },
    confirm() {
      console.log('this.list', this.list)
      $emit(
        this,
        'confirm',
        this.postation,
        this.list.filter(x => x.checked)
      )
    },
    mutilSelect(data) {
      // console.log('mutilSelect', data)
      this.dates[data.postation[0]].shiftList[data.postation[1]].checked =
        data.checked
      this.dates = JSON.parse(JSON.stringify(this.dates))
      console.log(this.dates)
    },
    close() {
      $emit(this, 'close', this.postation)
    },
    openClasses() {
      this.classesDialogVisble = true
    },
    delClasses() {}
  }
}
</script>

<style lang="scss" scoped>
.editGradeTypePopBlock {
  display: flex;
  flex-wrap: wrap;
  width: 180px;
  .head {
    width: 100%;
    display: flex;
    align-items: center;
    height: 30px;

    &-title {
      width: 80%;
      font-size: 14px;
      //font-weight: 550;
    }

    &-close {
      width: 20%;
      display: flex;
      justify-content: flex-end;
      color: #bbc1ce;
      cursor: pointer;
      font-size: 14px;
    }
  }

  .types {
    width: 100%;
    margin: 5px 0 5px 0;
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;

    &-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
    }
  }

  .date-block {
    display: flex;
    width: 100%;
    margin-bottom: 10px;

    &-left {
      width: 45%;
      display: flex;
      flex-direction: column;

      &-title {
        font-size: 14px;
        color: #b6bcc9;
      }

      &-date {
        width: 75px;
        height: 30px;
        line-height: 30px;
        margin: 5px 5px 0px 5px;
        text-align: center;
        background-color: #eff1f3;
        border-radius: 4px;
        font-size: 13px;
        cursor: pointer;
        text-overflow: ellipsis;
      }
    }

    &-right {
      width: 55%;
      display: flex;
      flex-direction: column;

      &-title {
        font-size: 14px;
        color: #b6bcc9;
        margin-right: 10px;
      }

      i:nth-of-type(1) {
        margin-right: 10px;
        color: #5892e8;
        font-size: 14px;
      }

      i:nth-of-type(2) {
        margin-right: 10px;
        color: #f38b8b;
        font-size: 14px;
      }

      &-duty {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        &-card {
          margin-top: 5px;
        }
      }
    }
  }

  .foot {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .confirm-btn {
      width: 100%;
      max-width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #367ce3;
      color: white;
      height: 35px;
      border-radius: 5px;
      font-size: 13px;
    }
  }
}
</style>
