import { doFetch, doPostJson } from '@/http'

/**
 * 换班
 * @param params
 *
 */
export function doDispatch(params) {
  return doPostJson('/Custom/staff/doDispatch', params)
}

/**
 * 获取可以换班的人
 * @param params
 *
 */
export function getDispatchInfo(params) {
  return doPostJson('/Custom/staff/getDispatchInfo', params)
}

/**
 * 查询员工基本信息（按员工编号）
 * @param params
 *
 */
export function getStaff(params) {
  return doPostJson('/Custom/staff/getStaff', params)
}

/**
 * 查询员工排班日历信息（按员工编号，年，月）
 * @param params
 *
 */
export function getStaffCalendar(params) {
  return doPostJson('/Custom/staff/getStaffCalendar', params)
}

/**
 * 查询排班信息（按人员）
 * @param params
 *
 */
export function getStaffAll(pageId, pageLimit, params) {
  return doPostJson(
    `/Custom/schedule/retrieve/employee/${pageId}/${pageLimit}`,
    params
  )
}

/**
 * 物理删除排班信息
 * @param params
 *
 */
export function deleteStaff(params) {
  return doPostJson('/Custom/schedule/delete', params)
}

/**
 * 查询员工日历数量统计信息（按员工编号，年，月）
 * @param params
 *
 */
export function getStaffCalendarCount(params) {
  return doPostJson('/Custom/staff/getStaffCalendarCount', params)
}
/**
 * 展开员工排班信息（按员工编号，年，月）
 * @param params
 *
 */
export function getSchedulingClockInfo(params) {
  return doPostJson('/Custom/staff/getSchedulingClockInfo', params)
}
