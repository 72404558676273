//这个模块建错了，没有按control建
import { axios, post, fetch, patch, put, postJson } from '../../http'
export default {
  delSysBillDt(params) {
    return post('/ConfigureReports/formconfig/delSysBillDt', params)
  },
  getColumnsByTableName(params) {
    return post('/ConfigureReports/formconfig/getColumnsByTableName', params)
  },
  getSelectList(params) {
    return post('/ConfigureReports/ParsingReport/getSelectList', params)
  },
  //配置打印模块复制现有配置
  copyPrintConfig(params) {
    return post('/ConfigureReports/PrintConfig/copyPrintConfig', params)
  },
  //配置查询表单主表
  querySysBillHd(params) {
    return fetch('/ConfigureReports/formconfig/querySysBillHd', params)
  },
  //通过菜单id获取分类
  getAllConfigClassByMenuid(params) {
    return post(
      '/ConfigureReports/formconfig/getAllConfigClassByMenuid',
      params
    )
  },
  //打印记录log
  setPrintLog(params) {
    return post('/ConfigureReports/wfprint/setPrintLog', params)
  },
  //获取配置表单按钮
  selectButtonList(params) {
    return post('/ConfigureReports/formconfig/selectButtonList', params)
  },
  //新增配置表单主表
  initializeFormInfo(params) {
    return fetch('/ConfigureReports/formconfig/initializeFormInfo', params)
  },
  //获取动态数据库列表
  getDynamicDBs() {
    return post('/ConfigureReports/formconfig/getDynamicDBs')
  },
  //获取数据库表列表
  getTables() {
    return post('/ConfigureReports/formconfig/getTables')
  },
  //获取数据库表列表
  getTablesClom(params) {
    return post('/ConfigureReports/formconfig/getTablesClom', params)
  },
  //获取表单类型
  getSysBillHdSingleType(params) {
    return fetch('/ConfigureReports/formconfig/getSysBillHdSingleType', params)
  },
  //获取报表详细
  getSysQueryHdDetail(params) {
    return fetch('/ConfigureReports/Reports/getSysQueryHdDetail', params)
  },
  //表单复制
  copyForm(params) {
    return post('/ConfigureReports/formconfig/copyForm', params)
  },
  //新增或修改帮助
  addOrUpdateHelp(params) {
    return postJson('/ConfigureReports/formconfig/addOrUpdateHelp', params)
  },
  //新增或修改查询过滤增强
  addOrUpdateFilter(params) {
    return postJson('/ConfigureReports/formconfig/addOrUpdateFilter', params)
  },
  //查询过滤增强配置列表
  getFilterList(params) {
    return postJson('/ConfigureReports/formconfig/getFilterList', params)
  },
  //删除过滤增强配置列表
  deleteFilter(params) {
    return postJson('/ConfigureReports/formconfig/deleteFilter', params)
  },
}
