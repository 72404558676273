import { saveAs } from 'file-saver'
import { ElMessage as Message } from 'element-plus'

/**
 * 下载文件Blob
 * @param res
 * @param value
 * @returns {Promise}
 */
export function downloadFileBlob(res, value, fileName) {
  let flag = true
  let blob = new Blob([res], { type: 'application/vnd.ms-excel' })
  // 判断是否是文档
  if (value === 'docx') {
    blob = new Blob([res], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    })
  }
  var reader = new FileReader()
  let promise = new Promise(resolve => {
    reader.readAsText(blob)
    // 判断返回的是“文件流”还是需要抛出的错误信息
    reader.onloadend = e => {
      let obj = e.target.result
      if (obj.indexOf('message') > -1) {
        Message.error(JSON.parse(obj).message)
        flag = false
      } else {
        let url = window.URL.createObjectURL(blob)
        // 文件名
        // let fileName = decodeURI(res.rawData.headers.filename)
        saveAs(url, fileName)
        flag = true
      }
      resolve(flag)
    }
  })
  return promise
}

export function base64toFile(dataStr, filename = 'file') {
  let arr = dataStr.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let suffix = mime.split('/')[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime
  })
}
