import { axios, post, fetch, patch, put, doPostJson } from '@/http'
// import {Notify} from "vant";

// function checkResult(res) {
//     res.then(r => {
//         if (r.errCode != null && r.errCode.length > 0) {
//             Notify(r.errMsg + ':' + r.errMsgDetail ? r.errMsgDetail : '')
//         }
//     })
//     return res;
// }
/**
 * 获取人员画像接口
 * @param params
 *
 */
export async function getStaffScore(params) {
  return doPostJson('/Custom/staff/getStaffScore', params)
}
export async function getStaffLabel(params) {
  return doPostJson('/Custom/staff/getStaffLabel', params)
}
export async function getStaffRewards(params) {
  return doPostJson('/Custom/staff/getStaffRewards', params)
}
export async function getStaffTwo(params) {
  return doPostJson('/Custom/staff/getStaffTwo', params)
} //获取员工基本信息
export async function getCustomCount(params) {
  return doPostJson('/Custom/staff/getCustomCount', params)
} //获取员工出勤统计
export async function saveAndApproval(params) {
  return doPostJson('/Custom/sheet/saveAndApproval', params)
}
export async function sendSheetByCondition(params) {
  return doPostJson('/Custom/sheet/sendSheetByCondition', params)
}
export async function calculateEvaluation(params) {
  return doPostJson('/Custom/evaluation/calculateEvaluation', params)
}
