import {
  post,
  fetch,
  put,
  remove,
  postJson,
  postBlob,
  post_blob,
} from '../../http'
export default {
  //获取消息列表
  notice(params) {
    return fetch('/rextec/api/notice/123', params)
  },
  // 运单查询分页接口
  waybill(params) {
    return fetch('/rextec/api/waybill', params)
  },
  // 运单确认接口
  waybillUpdate(id, data) {
    return put('/rextec/api/waybill/' + id, data)
  },
  // 运单详情/明细接口
  waybillDetail(id) {
    return fetch('/rextec/api/waybill/' + id, {})
  },
  // 删除运单接口
  waybillDelete(id) {
    return remove('/rextec/api/waybill/' + id, {})
  },
  // 运单管理/待计划列表接口
  waybillOrder(data) {
    return fetch('/rextec/api/waybill/order', data)
  },
  /**
   * 运单管理/待计划(,选择生成运单)
   * @param {*} data
   * ids(自动生成运单时，前端传过来的多个订单号),autoLevel(自动生成运单时，省市区合并的级别(1:省,2:市,3:区,4:选择生成运单))
   * @returns
   */
  autoWaybill(data) {
    return put('/rextec/api/waybill/autoWaybill', data)
  },
  // 自动生成运单
  animationWaybill(data) {
    return put('/rextec/api/waybill/animationWaybill', data)
  },

  /**
   * 确认运单(运输合同、计费规则、税率)
   * @param {*} data {trancompanyid(承运商id)}
   * @returns id(合同id),showDate(运输合同名称),billId(计费规则id),ruleDescription(计费规则名称),taxPoint(税率)
   */
  contractbill(data) {
    return postJson('/rextec/api/waybill/contract', data)
  },
  /**
   * 计算运费接口
   * @param {*} data {contractId(合同id) ,billId(计费规则id),waybillId(运单id)}
   * @returns chargeCarriage(运费)
   */
  chargeCarriage(data) {
    return postJson('/rextec/api/waybill/chargeCarriage', data)
  },
  // 新增报货记录及明细 post
  declareGoods(data) {
    return postJson('/rextec/api/declareGoods', data)
  },
  // 查询待派车的报货记录
  waitVehicle(data) {
    return fetch('/rextec/api/declareGoods/waitVehicle', data)
  },
  // 生成提货派车记录
  pickupVehicleUpdate(data) {
    return postJson('/rextec/api/pickupVehicle', data)
  },
  // 删除提货派车记录
  pickupVehicleDelete(id) {
    return remove('/rextec/api/pickupVehicle/' + id, {})
  },
  // 查询提货派车记录
  pickupVehicle(data) {
    return fetch('/rextec/api/pickupVehicle', data)
  },
  // 未到场提货交接记录查询 预警(warn='Y')
  waitArrive(data) {
    return fetch('/rextec/api/pickupVehicle/waitArrive', data)
  },
  // 待装载提货交接记录查询
  waitLoad(data) {
    return fetch('/rextec/api/pickupVehicle/waitLoad', data)
  },
  // 待发车提货交接记录查询
  waitDepart(data) {
    return fetch('/rextec/api/pickupVehicle/waitDepart', data)
  },
  // 已发车提货交接记录查询
  alreadyDepart(data) {
    return fetch('/rextec/api/pickupVehicle/alreadyDepart', data)
  },
  // 确认到场
  pickupVehicleSign(id, data) {
    return put('/rextec/api/pickupVehicle/sign/' + id, data)
  },
  // 开始装载
  pickupVehicleLoadStart(id, data) {
    return put('/rextec/api/pickupVehicle/loadStart/' + id, data)
  },
  // 完成装载
  pickupVehicleLoadOver(id, data) {
    return put('/rextec/api/pickupVehicle/loadOver/' + id, data)
  },
  // 确认发车
  pickupVehicleDepart(id, data) {
    return put('/rextec/api/pickupVehicle/depart/' + id, data)
  },
  // 确认到场
  pickupVehicleArrive(id, data) {
    return put('/rextec/api/pickupVehicle/arrive/' + id, data)
  },
  // 派车详情
  pickupVehicledetail(id) {
    return fetch('/rextec/api/pickupVehicle/detail?pickupVehicleId=' + id, {})
  },
  // 装载详情
  pickupVehicleDepartdetail(id) {
    return fetch('/rextec/api/pickupVehicle/box?id=' + id, {})
  },
  // 添加装载明细时获取货物信息
  pickupVehicleproduct(id) {
    return fetch('/rextec/api/pickupVehicle/product?id=' + id, {})
  },
  // 导入配货波次接口
  uploadAllocate(data) {
    return postBlob('/rextec/api/waybill/uploadAllocate', data)
  },
  //下载模板
  downloadAllocation() {
    return post_blob('/rextec/api/waybill/downloadAllocation')
  },
  // 配货报货(待报货)接口
  allocatioin(data) {
    return fetch('/rextec/api/waybill/allocatioin', data)
  },
  // 配货报货(待配货)接口
  assembly(data) {
    return fetch('/rextec/api/waybill/assembly', data)
  },
  // 配货报货(待派车)接口
  sendCar(data) {
    return fetch('/rextec/api/waybill/sendCar', data)
  },
  // 配货报货-报货弹窗详情
  allocationWaveDetail(allocationWaveId) {
    return fetch(
      '/rextec/api/allocationWave/detail?allocationWaveId=' + allocationWaveId,
      {}
    )
  },
}
