<template>
  <el-config-provider :locale="locale">
  <div id="app">
    <!-- <div id="nav">
                  <router-link to="/">Home</router-link> |
                  <router-link to="/about">About</router-link>
                </div> -->
    <router-view />
  </div>
</el-config-provider>
</template>

<script>
  import { ElConfigProvider } from 'element-plus'
  import zhCn from 'element-plus/lib/locale/lang/zh-cn'
  import en from 'element-plus/lib/locale/lang/en'

export default {
  name: 'App',
  components:{
    ElConfigProvider
  },

  provide() {
    return {}
  },
  data() {
    return {
      locale: '' 
    }
  },
  mounted() {
    this.locale=localStorage.getItem('language') ?localStorage.getItem('language') =='CN' ? zhCn : localStorage.getItem('language') =='EN' ? en : zhCn : zhCn
    //判断是否是绿色主题 是就引入commonGreen.css
    console.log(this.isFollowTheme, 'this.isFollowTheme');
    if(localStorage.getItem('themeName')){
      if(JSON.parse(localStorage.getItem('themeName')).name === 'white-green'){
        //创建link标签默认引入 绿色css 主题样式文件
        let link = document.createElement("link");
        link.type = "text/css";
        link.id = "theme";
        link.rel = "stylesheet";
        link.href = "css/greenTheme/commonGreen.css";
        document.getElementsByTagName("head")[0].appendChild(link);

        if(this.isFollowTheme==='Y'){
          //创建link标签默认引入 绿色BTNcss 主题样式文件
          let linkbtngreen = document.createElement("link");
          linkbtngreen.type = "text/css";
          linkbtngreen.id = "theme";
          linkbtngreen.rel = "stylesheet";
          linkbtngreen.href = "css/greenTheme/btnGreen.css";
          document.getElementsByTagName("head")[0].appendChild(linkbtngreen);
        }
      }else if(JSON.parse(localStorage.getItem('themeName')).name === 'white-blue'){
        if(this.isFollowTheme==='Y'){
          //创建link标签默认引入 蓝色BTNcss 主题样式文件
          let linkbtnblue = document.createElement("link");
          linkbtnblue.type = "text/css";
          linkbtnblue.id = "theme";
          linkbtnblue.rel = "stylesheet";
          linkbtnblue.href = "css/blueTheme/btnBlue.css";
          document.getElementsByTagName("head")[0].appendChild(linkbtnblue);
        }
      }
    }
  },
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem('store'))
        )
      )
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      this.$store.state.axiosPromiseCancel = []
      this.$store.state.cffloading = false
      //this.$store.state.tagList=[]
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
      // sessionStorage.setItem("store2", 222);
    })
  },
  watch: {
    "$i18n.locale": function (newVal)  {
      this.locale = newVal == 'CN' ? zhCn : en 
    }
  },
  methods: {},
}
</script>

<style lang="scss">
@import 'assets/css/font.css';
@import 'assets/css/flexstyle.css';
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  height: 100%;
  width: 100%;
}
.el-table--border th.gutter:last-of-type {
  display: block !important;
  width: 17px !important;
}
.el-popover {
  min-width: 80px !important;
}
.el-cascader-panel {
  height: 210px;
}
.el-scrollbar__wrap {
  overflow-x: hidden !important;
}
.tangram-suggestion-main {
  z-index: 999999;
  max-height: 150px;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.tangram-suggestion table {
  width: 100%;
  margin-top: 10px !important;
}
.el-button {
  //transform: scale(0.9)!important;
  //transform-origin: 0 0 !important;
}
.el-input {
  //transform: scale(0.9)!important;
  //transform-origin: 0 0 !important;
}
</style>
