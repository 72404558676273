import { doFetch, doPostJson, post_blob, postBlobByJSON } from '@/http'
/**
 * 确认拆分单据
 * @param params
 * @constructor
 */
export function confirmSplitBill(params) {
  return doPostJson('/Custom/approval/confirmSplitBill',params)
}

/**
 * 获取请假单类型
 * @returns {Promise | Promise<unknown>}
 */
export function getHolidayType() {
  return doFetch('/Custom/approval/getHolidayType')
}

/**
 * 获取拆分数据
 * @param params
 * @constructor
 */
export function getSplitBill(params) {
  return doPostJson('/Custom/approval/getSplitBill?billid='+params.billid,params)
}

/**
 * 批量更新驻点下班次打卡距离
 * @param params
 * @constructor
 */
export function updateClassesClockDistance(params) {
  return doPostJson('/Custom/classes/updateClassesClockDistance',params)
}

/**
 * 批量更新员工状态
 * @param params
 * @constructor
 */
export function batchUpdateStaffStatus(params) {
  return doPostJson('/Custom/staff/batchUpdateStaffStatus', params)
}

/**
 * 获取员工状态
 * @returns {Promise | Promise<unknown>}
 */
export function getStaffStatus() {
  return doFetch('/Custom/staff/getStaffStatus')
}

/**
 * 批量更新班次类型
 * @param params
 * @constructor
 */
export function updateClassesType(params) {
  return doPostJson('/Custom/classes/updateClassesType', params)
}

/**
 *
 * 获取驻点树形图
 * @param params
 * @constructor
 */
export function getArrestTree(params) {
  return doPostJson('/Custom/arrest/getArrestTree', params)
}

export function updateStaffArrest(params) {
  return doPostJson('/Custom/staff/updateStaffArrest', params)
}
/**
 *
 * 查询排班信息（按岗位）
 * @param params
 * @constructor
 */
export function retrievePost(params) {
  return doPostJson(
    `/Custom/schedule/retrieve/post/${params.pageNo}/${params.pageSize}`,
    params
  )
}

export function getScheduleList(params) {
  return doPostJson(`/Custom/schedule/getScheduleList/1/5`, params)
}

/**
 * 查询所有班次类别
 * @returns {Promise | Promise<unknown>}
 */
export function getClassesType() {
  return doFetch('/Custom/classesType/getClassesType')
}

/**
 *
 * 查询班次（按岗位编号）
 * @returns {Promise}
 */
export function getClasses(params) {
  return doPostJson('/Custom/classes/getClasses', params)
}

/**
 *
 * 查询班次（按排班编号集合）
 * @param params
 * @returns {Promise}
 */
export function getClassesBySchedulingId(params) {
  return doFetch('/Custom/classes/getClassesBySchedulingId', params)
}

/**
 * 员工接口
 * @param params
 * @returns {Promise}
 */
export function getStaff(params) {
  return doFetch('/Custom/staff/getStaff', params)
}

/**
 * 批量修改上级审批人
 * @param params
 * @returns {Promise}
 */
export function updateStaffApprovalPermissions(params) {
  return doPostJson(`/Custom/staff/updateStaffApprovalPermissions`, params)
}

/**
 * 查询所有员工编号和姓名
 * @param params
 * @returns {Promise}
 */
export function getAllStaff(pageNo, pageSize, params) {
  return doPostJson(`/Custom/staff/getAllStaff/${pageNo}/${pageSize}`, params)
}
/**
 * 查新增/修改排班信息
 * @param params
 * @returns {Promise}
 */
export function scheduleUpsert(params, startMonth, endMonth) {
  return doPostJson(`/Custom/schedule/upsert/${startMonth}/${endMonth}`, params)
}
export function scheduleUpsert1(params, startMonth, endMonth) {
  return doPostJson(
    `/Custom/schedule/upsert1/${startMonth}/${endMonth}`,
    params
  )
}
export function scheduleUpsert2(params, startMonth, endMonth) {
  return doPostJson(
    `/Custom/schedule/upsert2/${startMonth}/${endMonth}`,
    params
  )
}

/**
 * 查询岗位（按驻点编号集合）
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function getPost(params) {
  return doPostJson(`/Custom/post/getPost`, params)
}

/**
 * 导出排班信息
 * @param params
 * @returns {Promise}
 */
export function download(params) {
  return postBlobByJSON(`/Custom/schedule/download`, params)
}

/**
 * 获取导入排班信息的条数
 * @param params
 * @returns {Promise}
 */
export function getUploadCount(year, month, params) {
  return doPostJson(`/Custom/schedule/getUploadCount/${year}/${month}`, params)
}

/**
 * 导入排班信息
 * @param params
 * @returns {Promise}
 */
export function upload(year, month, params) {
  return doPostJson(`/Custom/schedule/upload/${year}/${month}`, params)
}
export function deleteDuty(params) {
  return doPostJson(`/Custom/schedule/delete`, params)
}
export function deleteAllDuty(params) {
  return doPostJson(`/Custom/schedule/deleteAll`, params)
}

/**
 * 查询驻点下所有班次
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function getClassesByArrest(params) {
  return doPostJson(`/Custom/classes/getClassesByArrest`, params)
}

/**
 * getArrest
 * @returns {Promise | Promise<unknown>}
 */
export function getArrest() {
  return doFetch(`/Custom/arrest/getArrest`)
}
export function getShiftColor() {
  return doPostJson(`/Custom/schedule/getShiftColor`)
}
export function getClassesByStaff(params) {
  return doPostJson(`/Custom/classes/getClassesByStaff`, params)
}
