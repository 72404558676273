<template>
  <el-dialog
    v-bind="$attrs"
    title="选择人员"
    width="740px"
    :append-to-body="true"
    @close="_close"
  >
    <div class="dialogBlock">
      <div class="head">
        <el-input
          v-model.trim="params.staffName"
          style="width: 120px; margin-right: 15px"
          placeholder="请输入姓名"
        />

        <el-button type="primary" @click="search"> 查询 </el-button>
        <el-button @click="reset"> 清空 </el-button>
      </div>
      <div class="table">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column prop="oldStaffId" label="编号" />
          <el-table-column prop="depName" label="大队" show-overflow-tooltip />
          <el-table-column
            prop="staffName"
            label="姓名"
            show-overflow-tooltip
          />
        </el-table>
      </div>
      <div class="pagination-block">
        <el-pagination
          :current-page="params.pageNum"
          :page-sizes="[10, 20, 40, 100]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      <div class="footer">
        <el-button type="danger" @click="_close"> 取消 </el-button>
        <el-button type="primary" @click="confirm"> 确定 </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../../../utils/gogocodeTransfer'
import { getAllStaff } from '@/requet/api/ba/scheduleApi'

export default {
  name: 'ArrangePeopleSelectDialog',
  components: {},
  props: [],
  emits: ['confirm', 'update:visible'],
  data() {
    return {
      params: {
        staffId: null,
        staffName: null,
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      tableData: [],
      multipleSelection: []
    }
  },
  watch: {},
  mounted() {
    this.getList()
  },
  methods: {
    search() {
      this.params.pageNum = 1
      this.getList()
    },
    async getList() {
      let res = await getAllStaff(
        this.params.pageNum,
        this.params.pageSize,
        this.params
      )
      this.tableData = res.list
      this.total = res.total
    },
    reset() {
      this.tableData = []
      this.params.staffName = null
      this.params.pageNum = 1
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleSizeChange(val) {
      this.params.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.params.pageNum = val
      this.getList()
    },
    confirm() {
      console.log(this.multipleSelection)
      $emit(this, 'confirm', this.multipleSelection)
      $emit(this, 'update:visible', false)
    },
    _close() {
      $emit(this, 'update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../css/duty';
.input_width_ .el-input {
  width: 220px;
}
.dialogBlock {
  display: flex;
  flex-wrap: wrap;
  .head {
  }

  .table {
    width: 100%;
  }

  .pagination-block {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 4px;
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
</style>
