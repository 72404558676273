<template>
  <el-dialog
    v-bind="$attrs"
    title="更改班次"
    width="480px"
    :append-to-body="true"
    @close="_close"
  >
    <div class="dialogBlock" style="margin-top: -20px">
      <div v-for="holiday in holidayList" class="holiday-item">
        {{ holiday.holidaytypeName }}:{{ holiday.holidayBeginDate }}~{{
          holiday.holidayEndDate
        }}
      </div>
      <div v-for="(item, index) of localDates" class="date-block">
        <div class="date-block-left">
          <div class="date-block-left-title">日期</div>
          <div class="date-block-left-date">
            {{ item.date }}
          </div>
        </div>
        <div class="date-block-right">
          <div style="display: flex; align-items: center">
            <div class="date-block-right-title">添加班次</div>
            <el-icon><el-icon-circle-plus-outline /></el-icon>
            <i class="iconfont iconshanchudefuben" @click="delClasses(index)" />
          </div>
          <div class="date-block-right-duty">
            <div v-for="(temp, index2) in item.shiftList" class="types-item">
              <!-- v-if 处理上月跨日数据-->
              <gradeTypeOptCard
                v-if="item.date == temp.attendanceDate || !temp.attendanceDate"
                class="date-block-right-duty-card"
                :item="temp"
                :postation="[index, index2]"
                :show-ckeck-icon="false"
                @mutilSelect="mutilSelect"
              />
            </div>
          </div>
        </div>
      </div>

      <shiftSelectDialog
        v-if="classesDialogVisble"
        v-model:visible="classesDialogVisble"
        :post-classes="postClasses"
        @confirm="shiftConfirm"
      />
      <div class="footer">
        <el-button type="danger" @click="_close"> 取消 </el-button>
        <el-button type="primary" @click="confirm"> 确定 </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { CirclePlus as ElIconCirclePlusOutline, } from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../../../../utils/gogocodeTransfer'
import gradeTypeOptCard from '@/components/aloneComponents/pg/duty/gradeTypeOptCard'
import shiftSelectDialog from '@/components/aloneComponents/pg/duty/dialog/shiftSelectDialog'

export default {
  name: 'MutilDayEditDialog',
  components: {
    gradeTypeOptCard,
    shiftSelectDialog,
    ElIconCirclePlusOutline
  },
  props: {
    dates: Array,
    postClasses: Array
  },
  emits: ['confirm', 'update:visible'],
  data() {
    return {
      classesDialogVisble: false,
      openIndex: null,
      localDates: []
    }
  },
  computed: {
    // 请假信息
    holidayList() {
      let arr = []
      for (const item of this.localDates) {
        if (item.holidays != null) {
          for (const holiday of item.holidays) {
            // 如果不存在，则加入队列（用于去重）
            if (
              !arr.find(
                i =>
                  i.holidayBeginDate == holiday.holidayBeginDate &&
                  i.holidayEndDate == holiday.holidayEndDate &&
                  i.holidaytype == holiday.holidaytype
              )
            ) {
              arr.push(holiday)
            }
          }
        }
      }
      return arr
    }
  },
  watch: {},
  mounted() {
    this.localDates = this.dates
  },
  methods: {
    openClasses(index) {
      this.openIndex = index
      this.classesDialogVisble = true
    },
    delClasses(index) {
      this.localDates[index].shiftList = this.localDates[
        index
      ].shiftList.filter(x => !x.checked)
    },
    mutilSelect(data) {
      this.localDates[data.postation[0]].shiftList[data.postation[1]].checked =
        data.checked
      this.localDates = JSON.parse(JSON.stringify(this.localDates))
    },
    shiftConfirm(shifts) {
      console.log('shifts2', shifts)
      for (const item of shifts) {
        let findObj = this.localDates[this.openIndex].shiftList.find(
          x => x.classesId === item.classesId
        )
        if (findObj == null || findObj == undefined) {
          item.checked = false
          this.localDates[this.openIndex].shiftList.push(item)
          this.localDates[this.openIndex].postId = item.postId
        }
      }
      console.log('this.localDates', this.localDates)
    },
    confirm() {
      console.log('mm confirm', this.localDates)
      // let arr = []
      for (const [index, item] of this.localDates.entries()) {
        if (item.shiftList.length == 0) {
          let temp = {
            date: item.date,
            dayNo: item.dayNo,
            openPop: false,
            shiftList: [],
            show: false,
            staffId: item.staffId,
            postId: item.postId
          }
          this.localDates[index] = temp
        } else {
          this.localDates[index].postId = item.postId
        }
      }
      $emit(this, 'confirm', {
        list: this.localDates
      })
      this._close()
    },
    _close() {
      $emit(this, 'update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../css/duty';
.input_width_ .el-input {
  width: 320px;
}
.dialogBlock {
  display: flex;
  flex-wrap: wrap;
  .date-block {
    display: flex;
    width: 100%;
    margin-bottom: 15px;

    &-left {
      width: 45%;
      display: flex;
      flex-direction: column;

      &-title {
        font-size: 14px;
        color: #b6bcc9;
      }

      &-date {
        width: 75px;
        height: 30px;
        line-height: 30px;
        margin: 5px 5px 0px 5px;
        text-align: center;
        background-color: #eff1f3;
        border-radius: 4px;
        font-size: 13px;
        cursor: pointer;
        text-overflow: ellipsis;
      }
    }

    &-right {
      width: 55%;
      display: flex;
      flex-direction: column;

      &-title {
        font-size: 14px;
        color: #b6bcc9;
        margin-right: 10px;
      }

      i:nth-of-type(1) {
        margin-right: 10px;
        color: #5892e8;
        font-size: 14px;
      }

      i:nth-of-type(2) {
        margin-right: 10px;
        color: #f38b8b;
        font-size: 14px;
      }

      &-duty {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        &-card {
          margin-top: 5px;
          width: 180px;
        }
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .holiday-item {
    width: 100%;
    font-size: 13px;
    text-align: center;
    text-overflow: ellipsis;
    background-color: #f56c6c;
    color: #ffffff;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}
</style>
