<template>
  <div style="width: 100%; height: 100%">
    <div ref="floorContainer" class="container" />
    <div v-if="label2Show" id="label2" class="label2">
      <dialogHg
        v-if="conType == 'container'"
        :faclist="faclist"
        con-type="container"
        @hgClose="hgClose"
      />
      <dialogHg
        v-else-if="conType == 'carport'"
        :faclist="faclist"
        con-type="carport"
        @hgClose="hgClose"
        @changeCar="changeCar"
      />
    </div>
    <div v-if="labelnameShow" id="label" class="label">
      {{ selectObject.facilityName }}
    </div>
    <div class="btn">
      <el-tree
        :data="areas"
        :props="defaultProps"
        @node-click="handleNodeClick"
      />
    </div>
    <div class="explain">
      <el-button
        type="warning"
        :icon="ElIconInfo"
        circle
        style="font-size: 26px"
        @click="openInfo"
      />
    </div>
  </div>
</template>

<script>
import { InfoFilled as ElIconInfo } from '@element-plus/icons-vue'
import $ from 'jquery'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js'
import dialogHg from '@/components/threeD/dialogHg'
import { markRaw, shallowRef } from 'vue'
export default {
  name: 'Floor',
  components: { dialogHg },
  props: ['queryParams'],
  data() {
    return {
      dom: null,
      camera: null,
      scene: null,
      renderer: null,
      controls: null,
      selectObject: null,
      areas: [],
      areasx: [],
      areasid: [],
      defaultProps: {
        children: 'children',
        label: 'storeyName'
      },
      label2Show: false,
      labelnameShow: false,
      faclist: [],
      divWidth: 0,
      divHeight: 0,
      bfMaterial: null,
      currentFloor: 'SM000011',
      glColor: { r: 255, g: 0, b: 0 },
      currentProperty: 'PR000108',
      locationlist: [],
      HLCabinet: [],
      currentEntrance: 'SI22040085',
      entrancelist: [],
      MJCabinet: [],
      currentDevice: 'SB000022',
      devalertlist: [],
      SBCabinet: [],
      gltfLoad: null,
      conType: 'container',
      mouseX: 0,
      mouseY: 0,
      ElIconInfo: shallowRef(ElIconInfo)
    }
  },
  mounted() {
    console.log('floor:', this.queryParams)

    setTimeout(()=>{
      //初始化loader
      this.gltfLoad = new GLTFLoader()
      this.gltfLoad.setMeshoptDecoder(MeshoptDecoder)

      this.dom = this.$refs.floorContainer
      console.log('dom', this.dom)
      this.init() //初始化

      let property = this.queryParams?.property
          ? this.queryParams.property
          : localStorage.getItem('property')
      let entrance = this.queryParams?.entrance
          ? this.queryParams.entrance
          : localStorage.getItem('entrance')
      let device = this.queryParams?.device
          ? this.queryParams.device
          : localStorage.getItem('device')
      console.log(entrance)
      if (property) {
        console.log('财物')
        this.getLocation(property)
        localStorage.removeItem('property')
      } else if (entrance) {
        console.log('门禁')
        this.getEntrance(entrance)
        localStorage.removeItem('entrance')
      } else if (device) {
        console.log('设备')
        this.getdevAlert(device)
        localStorage.removeItem('device')
      } else {
        this.getModel(this.currentFloor)
      }
      this.getAreas() //模型区域列表
      this.animate() //动画
      this.divWidth = this.dom.offsetWidth
      this.divHeight = this.dom.offsetHeight
      this.dom.addEventListener('dblclick', this.onMouseDblclick, false)
      this.dom.addEventListener('click', this.onMouseclick, false)
      this.dom.addEventListener('mousemove', this.onMouseMove, false)
    },380)
  },
  methods: {
    //打开操作说明
    openInfo() {
      this.$alert(
        '<div>1.按住鼠标左键可整体旋转模型方向</div><div>2.按住鼠标右键，可整体拖拽模型位置</div><div>3.鼠标滚轮，可整体缩放模型</div><div>4.鼠标左键单击模型，可展示该模型信息</div>',
        '操作说明',
        {
          dangerouslyUseHTMLString: true
        }
      )
    },
    //点击跳转
    clickJump(position, lookAt) {
      // this.flyTo(new THREE.Vector3(-0.68,1,0),new THREE.Vector3(-0.68,0.21,-0.21))

      this.flyTo(
        new THREE.Vector3(position[0], position[1], position[2]),
        new THREE.Vector3(lookAt[0], lookAt[1], lookAt[2])
      )
      // this.flyTo(new THREE.Vector3(-1.3,1,0),new THREE.Vector3(-1.3,0.21,-0.6))
    },
    flyTo(position, lookAt) {
      let num = 30
      if (!this.camera || !this.controls) {
        return
      }
      const t = this.controls.target.clone()
      const p = this.camera.position.clone()

      let px = (position.x - p.x) / num
      let py = (position.y - p.y) / num
      let pz = (position.z - p.z) / num
      let lx = (lookAt.x - t.x) / num
      let ly = (lookAt.y - t.y) / num
      let lz = (lookAt.z - t.z) / num
      let pos = JSON.parse(JSON.stringify(p))
      let look = JSON.parse(JSON.stringify(t))
      this.time = setInterval(() => {
        pos.x = pos.x + px
        pos.y = pos.y + py
        pos.z = pos.z + pz
        look.x = look.x + lx
        look.y = look.y + ly
        look.z = look.z + lz
        if (pos.x.toFixed(2) == position.x.toFixed(2)) clearInterval(this.time)
        this.moveLookAt(pos, look)
      })
    },
    //移动照相机位置
    moveLookAt(position, lookAt) {
      this.camera.lookAt(lookAt)
      console.log(position)
      this.camera.position.set(position.x, position.y, position.z)
      this.controls.target.set(lookAt.x, lookAt.y, lookAt.z)
      this.controls.update()
    },
    hgClose() {
      this.label2Show = false
    },
    handleNodeClick(data) {
      // console.log(data)
      this.currentFloor = data.modelid
      if (data.children == null) {
        this.createMoreChange(data.modelid)
      }
      this.hgClose()
      // console.log(data);
    },
    //设备模型列表获取模型
    getModel(areaid) {
      //KQ-001
      console.log(this.HLCabinet)
      let param = {
        modelid: areaid
      }
      this.$doFetch('/Custom/Sto3D/models', param).then(res => {
        // console.log(res);
        if (res.facilityModels != null) {
          let list = res.facilityModels
          // if
          let datalist = res
          // this.$delete(datalist, 'facilityModels')
          delete datalist.facilityModels
          //console.log(datalist)
          //console.log(list)
          list.unshift(datalist)
          // console.log(list);
          // let arr = []
          // arr.push(datalist)
          // arr.concat()
          // let carjson={};
          for (let r = 0; r < list.length; r++) {
            list[r].xrotation = (list[r].xrotation / 180) * Math.PI
            list[r].yrotation =
              list[r].yrotation == 0 ? 0 : Math.PI / list[r].yrotation
          }
          // for(let l=0;l<list.length; l++){
          //   if(list[l].facilityid){
          //   if(list[l].facilityid=="SB001004"){
          //     // let aa=list[l];
          //     // carjson=aa;
          //     // carjson.facilityid="";
          //     list[l].path="/image/reduce/car/car.glb";
          //     // list.push(carjson)
          //   }
          //   }
          // }
          // console.log(list)
          for (let i = 0; i < list.length; i++) {
            this.createGltf(list[i], i, list.length - 1, areaid)
          }
        } else {
          let datalist = []
          datalist.push(res)
          // this.$delete(datalist,'facilityModels')
          console.log(datalist)
          for (let i = 0; i < 1; i++) {
            this.createGltf(datalist[i], '', '', areaid)
          }
          console.log('--------------')
        }
      })
    },
    //获取模型区域列表
    getAreas() {
      this.$doFetch('/Custom/Sto3D/areas').then(res => {
        console.log(res)
        this.areas = res
        this.areas.map(item => {
          this.areasx.push(item.children)
          let obj = {
            modelid: item.modelid,
            storey: item.storey,
            storeyName: item.storeyName,
            areaid: item.areaid,
            storageid: item.storageid
          }
          this.areasid.push(obj)
        })
        for (let i = 0; i < this.areasx.length; i++) {
          for (let x = 0; x < this.areasx[i].length; x++) {
            this.areasid.push(this.areasx[i][x])
          }
        }
        //console.log(this.areasid)
      })
    },
    //根据财物ID定位库柜
    getLocation(propertyid) {
      let param = {
        propertyid: propertyid
      }
      this.$doFetch('/Custom/Sto3D/location', param).then(res => {
        this.locationlist = res
        console.log(this.locationlist)
        for (let i = 0; i < this.locationlist.length; i++) {
          if (this.locationlist[i].floorModelId == this.currentFloor) {
            let obj = this.locationlist[i].facilityModelIds
            for (let l = 0; l < obj.length; l++) {
              this.HLCabinet.push(obj[l].modelid)
            }
          }
        }
        let that = this
        that.getModel(this.currentFloor)
      })
    },
    //门禁授权联动
    getEntrance(billid) {
      let param = {
        billid: billid
      }
      this.$doFetch('/Custom/Sto3D/entrance', param).then(res => {
        this.entrancelist = res
        for (let i = 0; i < this.entrancelist.length; i++) {
          if (this.entrancelist[i].floorModelId == this.currentFloor) {
            let obj = this.entrancelist[i].facilityModelIds
            for (let l = 0; l < obj.length; l++) {
              this.MJCabinet.push(obj[l].modelid)
            }
          }
        }
        let that = this
        that.getModel(this.currentFloor)
      })
    },
    //设备报警接口
    getdevAlert(facilityid) {
      let param = {
        facilityid: facilityid
      }
      this.$doFetch('/Custom/Sto3D/deviceAlert', param).then(res => {
        this.devalertlist = res
        for (let i = 0; i < this.devalertlist.length; i++) {
          if (this.devalertlist[i].floorModelId == this.currentFloor) {
            let obj = this.devalertlist[i].facilityModelIds
            for (let l = 0; l < obj.length; l++) {
              this.SBCabinet.push(obj[l].modelid)
            }
          }
        }
        let that = this
        that.getModel(this.currentFloor)
      })
      console.log('devalertlist', this.devalertlist)
    },
    clearScene() {
      // 从scene中删除模型并释放内存
      if (this.scene.children.length > 0) {
        for (let p = 0; p < this.scene.children.length; p++) {
          var currObj = this.scene.children[p]
          // 判断类型
          // console.log(currObj);
          if (currObj.type == 'Group') {
            var children = currObj.children
            for (let i = 0; i < children.length; i++) {
              this.deleteGroup(children[i])
            }
          }
        }
      }
    },
    deleteGroup() {
      var allChildren = this.scene.children
      for (var i = allChildren.length - 1; i >= 0; i--) {
        if (allChildren[i] instanceof THREE.Group) {
          this.scene.remove(allChildren[i])
        }
      }
    },
    createMoreChange(modelid) {
      this.clearScene()
      this.getModel(modelid)
      // console.log(modelid);
      // let list = [
      //   {
      //     name: "villa",
      //     modelId: "villa",
      //     scale: [0.2, 0.2, 0.2],
      //     x: 0,
      //     y: 0,
      //     z: 0,
      //     rotateX: 0,
      //     rotateY: 0,
      //   },
      // ];
      // for (let i = 0; i < list.length; i++) {
      //   this.createGltf(list[i]);
      // }
    },
    changeCar(modelid, type) {
      if (type == 'stop') {
        //停车
      } else if (type == 'move') {
        //移车
      }
      this.hgClose()
      this.clearScene()
      this.getModel(modelid)
    },
    //初始化
    init: function () {
      let that = this
      let container = this.dom
      /**
       * 创建渲染器对象
       */
      this.renderer = new THREE.WebGLRenderer({ antialias: true })
      this.renderer.setSize(container.clientWidth, container.clientHeight)
      this.renderer.setClearColor(0xb9d3ff, 1) //设置背景颜色
      this.renderer.shadowMap.enabled = true //是否只显示阴影
      this.renderer.shadowMapEnabled = true //是否只显示阴影
      // this.renderer.outputEncoding = THREE.sRGBEncoding;//消除色差
      container.appendChild(this.renderer.domElement)
      //  创建场景对象Scene
      this.scene = markRaw(new THREE.Scene())
      /**
       * 相机设置
       */
      this.camera = new THREE.PerspectiveCamera(
        70,
        container.clientWidth / container.clientHeight,
        0.01,
        1000
      )

      // this.scene.position.set(0,0,0);
      // this.camera.lookAt(new THREE.Vector3(0, 0, 0));
      //创建一个环境灯光
      let ambientLight = new THREE.AmbientLight(0x444444, 0.2)
      this.scene.add(ambientLight)
      //创建一个平行光
      let light = new THREE.DirectionalLight(0xffffff, 0.8)
      // 位置
      light.position.set(100, 10, 50)
      // 方向光指向对象网格模型mesh，可以不设置，默认的位置是0,0,0
      //  light.target = mesh;
      this.scene.add(light)
      //点光源
      // var point = new THREE.PointLight(0xffffff, 0.2);
      // point.position.set(400, 200, 300); //点光源位置
      // // 通过add方法插入场景中，不插入的话，渲染的时候不会获取光源的信息进行光照计算
      // this.scene.add(point); //点光源添加到场景中
      // //聚光源
      // let spotLight = new THREE.SpotLight(0xffffff, 0.2);
      // this.scene.add(spotLight); //聚光源添加到场景中
      light.castShadow = true
      //把视角放入环境

      //  this.camera.position.x = 0.0960720817107734;
      //  this.camera.position.y =0.6029217739688802;
      //  this.camera.position.z = 0.58965422967441464;
      //     document.addEventListener( 'mousemove', this.onDocumentMouseMove, false );
      //     this.canvas1 = document.createElement('canvas');
      // this.context1 = this.canvas1.getContext('2d');
      // this.context1.font = "Bold 20px Arial";
      // this.context1.fillStyle = "rgba(0,0,0,0.95)";
      //   this.context1.fillText('Hello, world!', 0, 20);

      // // canvas contents will be used for a texture
      // this.texture1 = new THREE.Texture(this.canvas1)
      // this.texture1.needsUpdate = true;

      // ////////////////////////////////////////

      // var spriteMaterial = new THREE.SpriteMaterial( { map: this.texture1, useScreenCoordinates: true} );

      // this.sprite1 = new THREE.Sprite( spriteMaterial );
      // this.sprite1.scale.set(200,100,1.0);
      // this.sprite1.position.set( 50, 50, 0 );
      // this.scene.add( this.sprite1 );

      this.scene.add(this.camera)
      //加入事件监听器，窗口自定义
      window.onresize = function () {
        // 重置渲染器输出画布canvas尺寸
        that.renderer.setSize(that.divWidth, that.divHeight)
        // 全屏情况下：设置观察范围长宽比aspect为窗口宽高比
        that.camera.aspect = that.divWidth / that.divHeight
        // 渲染器执行render方法的时候会读取相机对象的投影矩阵属性projectionMatrix
        // 但是不会每渲染一帧，就通过相机的属性计算投影矩阵(节约计算资源)
        // 如果相机的一些属性发生了变化，需要执行updateProjectionMatrix ()方法更新相机的投影矩阵
        that.camera.updateProjectionMatrix()
      }

      //创建控件对象
      this.controls = new OrbitControls(this.camera, this.renderer.domElement) //创建控件对象
      var lookAt = { x: 0, y: 0.2, z: 0 }
      var position = {
        x: 0.0960720817107734,
        y: 0.6029217739688802,
        z: 0.58965422967441464
      }
      this.camera.lookAt(lookAt)
      this.camera.position.set(position.x, position.y, position.z)
      this.controls.target.set(lookAt.x, lookAt.y, lookAt.z)
      // this.controls.addEventListener('change', this.animate)
      //初始化角度rotation
      //  this.camera.rotation.x = -0.9280136287485594;
      //  this.camera.rotation.y =0.11710849639534557;
      //  this.camera.rotation.z =0.15477357365773922;
      this.camera.up.y = 1
      console.log(this.camera)
      this.controls.update()
      console.log(this.controls)
    },
    createGltf(res, index, length, areaid) {
      let that = this
      //加载gltf模型
      let gltf1 = this.gltfLoad
      // console.log("/_MINIO" + res.path);
      gltf1.load(
        that.ImgbaseURL + res.path,
        function (gltf) {
          const model = gltf.scene
          if (that.currentFloor == areaid) {
            model.traverse(function (child) {
              if (child.isMesh) {
                //可根据名字寻找模型（name是可以重名的，返回第一个）
                child.name = res.modelid
                child.facilityid = res.facilityid
                child.facilityname = res.name
                child.modelid = res.modelid
                child.modelstyle = res.modelstyle
                child.material.emissive = child.material.color
                child.material.emissiveMap = child.material.map
                child.material.transparent = true //材质允许透明 如果有玻璃材质时开启
                child.material.opacity = 1 //材质默认透明度
                child.clipShadows = true
                child.wireframe = true
                child.castShadow = true
                if (that.HLCabinet.length > 0) {
                  that.HLCabinet.forEach(e => {
                    let mxid = e
                    if (child.modelid == mxid) {
                      child.material.opacity = 0.5 //材质默认透明度
                      child.material.color = that.glColor //材质默认透明度
                      child.material.emissive = that.glColor
                    }
                  })
                }
                if (that.MJCabinet.length > 0) {
                  that.MJCabinet.forEach(e => {
                    let mxid = e
                    if (child.modelid == mxid) {
                      child.material.opacity = 0.5 //材质默认透明度
                      child.material.color = that.glColor //材质默认透明度
                      child.material.emissive = that.glColor
                    }
                  })
                }
              }
              if (that.SBCabinet.length > 0) {
                that.SBCabinet.forEach(e => {
                  let mxid = e
                  if (child.modelid == mxid) {
                    child.material.opacity = 0.5 //材质默认透明度
                    child.material.color = that.glColor //材质默认透明度
                    child.material.emissive = that.glColor
                  }
                })
              }
            })
            //设置整体场景的比例
            model.scale.set(res.xscale, res.yscale, res.zscale)
            model.translateX(res.xaxis)
            model.translateY(res.yaxis)
            model.translateZ(res.zaxis)
            model.rotateX(res.xrotation)
            model.rotateY(res.yrotation)
            that.scene.add(model)

            // // 循环当前模型
            // model.traverse((child) => {
            //   // 查看模型的类型是否为MEsh 只有是mesh网格模型的才有位置属性这些
            //   if (child.type == "Mesh") {
            //     //绘制边框线
            //     const lineGeom = new THREE.EdgesGeometry(child.geometry);
            //     const lineMaterial = new THREE.LineBasicMaterial({
            //       color: 0x018bf5,
            //       linewidth: 10,
            //       linecap: "round",
            //       linejoin: "round",
            //     });
            //     const line = new THREE.LineSegments(lineGeom, lineMaterial);
            //     line.scale.copy(model.scale);
            //     line.rotation.copy(model.rotation);
            //     line.position.copy(model.position);
            //     //that.scene.add(line);
            //   }
            // });
            if (index == length) {
              let tz = {}
              // console.log(that.entrancelist)
              if (that.MJCabinet.length > 0) {
                if (
                  that.entrancelist.length > 0 &&
                  that.entrancelist[0].facilityModelIds.length > 0
                ) {
                  tz = that.entrancelist[0].facilityModelIds[0]
                }
              }
            }
            // console.log(5555555555)
            // console.log(that.entrancelist)
            if (index == length) {
              let tz = {}
              // console.log(that.entrancelist)
              if (that.MJCabinet.length > 0) {
                if (
                  that.entrancelist.length > 0 &&
                  that.entrancelist[0].facilityModelIds.length > 0
                ) {
                  tz = that.entrancelist[0].facilityModelIds[0]
                }
              }
              if (that.HLCabinet.length > 0) {
                if (
                  that.locationlist.length > 0 &&
                  that.locationlist[0].facilityModelIds.length > 0
                ) {
                  tz = that.locationlist[0].facilityModelIds[0]
                }
              }
              if (that.SBCabinet.length > 0) {
                if (
                  that.devalertlist.length > 0 &&
                  that.devalertlist[0].facilityModelIds.length > 0
                ) {
                  tz = that.devalertlist[0].facilityModelIds[0]
                }
              }
              let position = [tz.xaxis, 1, 0]
              let lookAt = [tz.xaxis, tz.yaxis, tz.zaxis]
              if (JSON.stringify(tz) !== '{}') {
                that.clickJump(position, lookAt)
              }
            }
          }
        },
        xhr => {
          // called while loading is progressing
          //   console.log( xhr.loaded / xhr.total * 100 );
          //   if(xhr.loaded / xhr.total==100){
          //   if(this.entrancelist.length>0&&this.entrancelist[0].facilityModelIds.length>0){
          //   let tz=this.entrancelist[0].facilityModelIds;
          //    let position=[tz.xaxis,1,-1];
          //    let lookAt=[tz.xaxis,tz.yaxis,tz.zaxis];
          //    this.clickJump(position, lookAt)
          // }
          //   }
        }
      )
    },
    //测试 模型加载 gltf转成glb
    createGltf1(res, index, length, areaid) {
      let that = this
      //加载gltf模型
      let gltf1 = new GLTFLoader()
      gltf1.load('static/img/gltf/' + res.modelId + '.gltf', function (gltf) {
        const model = gltf.scene
        model.traverse(function (child) {
          if (child.isMesh) {
            //可根据名字寻找模型（name是可以重名的，返回第一个）
            child.name = res.name
            child.material.emissive = child.material.color
            child.material.emissiveMap = child.material.map
            child.material.transparent = true //材质允许透明 如果有玻璃材质时开启
            child.material.opacity = 1 //材质默认透明度
            child.clipShadows = true
            child.wireframe = true
            child.castShadow = true
          }
        })
        //设置整体场景的比例
        model.scale.set(res.scale[0], res.scale[1], res.scale[2])
        model.translateX(res.x)
        model.translateY(res.y)
        model.translateZ(res.z)
        model.rotateX(res.rotateX)
        model.rotateY(res.rotateY)
        that.scene.add(model)

        // 循环当前模型
        model.traverse(child => {
          // 查看模型的类型是否为MEsh 只有是mesh网格模型的才有位置属性这些
          if (child.type == 'Mesh') {
            //绘制边框线
            const lineGeom = new THREE.EdgesGeometry(child.geometry)
            const lineMaterial = new THREE.LineBasicMaterial({
              color: 0x018bf5,
              linewidth: 10,
              linecap: 'round',
              linejoin: 'round'
            })
            const line = new THREE.LineSegments(lineGeom, lineMaterial)
            line.scale.copy(model.scale)
            line.rotation.copy(model.rotation)
            line.position.copy(model.position)
            //that.scene.add(line);
          }
        })
      })
    },
    createMore() {
      let list = [
        // {
        //     name:"house",
        //     scale:[0.005,0.005,0.005],
        //     x:-1.5,
        //     y:0,
        //     z:1.07
        // },
        {
          name: 'platform',
          modelId: 'platform',
          scale: [0.18, 0.18, 0.18],
          x: 0.25,
          y: 0.05,
          z: -0.3,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'platform',
          modelId: 'platform',
          scale: [0.18, 0.18, 0.18],
          x: 0.25,
          y: 0.05,
          z: -0.2,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'platform',
          modelId: 'platform',
          scale: [0.18, 0.18, 0.18],
          x: 0.25,
          y: 0.05,
          z: -0.1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'detector',
          modelId: 'detector',
          scale: [0.18, 0.18, 0.18],
          x: 1,
          y: 0.05,
          z: -0.73,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'device1',
          modelId: 'device1',
          scale: [0.2, 0.2, 0.2],
          x: 1.22,
          y: 0.05,
          z: -0.95,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'desk',
          modelId: 'desk',
          scale: [0.2, 0.2, 0.2],
          x: 0.45,
          y: 0.05,
          z: -0.15,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet1',
          modelId: 'cabinet1',
          scale: [0.16, 0.16, 0.16],
          x: 1.62,
          y: 0.05,
          z: -0.5,
          rotateX: 0,
          rotateY: Math.PI / 2
        },
        {
          name: 'cabinet2',
          modelId: 'cabinet2',
          scale: [0.14, 0.14, 0.14],
          x: 0.65,
          y: 0.05,
          z: -0.45,
          rotateX: 0,
          rotateY: (3 * Math.PI) / 2
        },
        {
          name: 'cabinet2',
          modelId: 'cabinet2',
          scale: [0.14, 0.14, 0.14],
          x: 0.7,
          y: 0.05,
          z: -0.3,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'cabinet2',
          modelId: 'cabinet2',
          scale: [0.14, 0.14, 0.14],
          x: 0.8,
          y: 0.05,
          z: -0.3,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'cabinet2',
          modelId: 'cabinet2',
          scale: [0.14, 0.14, 0.14],
          x: 0.9,
          y: 0.05,
          z: -0.3,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'cabinet3',
          modelId: 'cabinet3',
          scale: [0.16, 0.18, 0.18],
          x: 2.09,
          y: 0.05,
          z: -0.565,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'cabinet3',
          modelId: 'cabinet3',
          scale: [0.16, 0.18, 0.18],
          x: 2.07,
          y: 0.05,
          z: -0.1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet3',
          modelId: 'cabinet3',
          scale: [0.16, 0.18, 0.18],
          x: 2.23,
          y: 0.05,
          z: -0.565,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'cabinet3',
          modelId: 'cabinet3',
          scale: [0.16, 0.18, 0.18],
          x: 2.21,
          y: 0.05,
          z: -0.1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet3',
          modelId: 'cabinet3',
          scale: [0.16, 0.18, 0.18],
          x: 2.37,
          y: 0.05,
          z: -0.565,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'cabinet3',
          modelId: 'cabinet3',
          scale: [0.16, 0.18, 0.18],
          x: 2.35,
          y: 0.05,
          z: -0.1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet3',
          modelId: 'cabinet3',
          scale: [0.16, 0.18, 0.18],
          x: 2.51,
          y: 0.05,
          z: -0.565,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'cabinet3',
          modelId: 'cabinet3',
          scale: [0.16, 0.18, 0.18],
          x: 2.49,
          y: 0.05,
          z: -0.1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet3',
          modelId: 'cabinet3',
          scale: [0.16, 0.18, 0.18],
          x: 2.65,
          y: 0.05,
          z: -0.565,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'cabinet3',
          modelId: 'cabinet3',
          scale: [0.16, 0.18, 0.18],
          x: 2.63,
          y: 0.05,
          z: -0.1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet2',
          modelId: 'cabinet2',
          scale: [0.16, 0.18, 0.18],
          x: 2.02,
          y: 0.05,
          z: -0.9,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet2',
          modelId: 'cabinet2',
          scale: [0.16, 0.18, 0.18],
          x: 2.06,
          y: 0.05,
          z: -0.9,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet2',
          modelId: 'cabinet2',
          scale: [0.16, 0.18, 0.18],
          x: 2.14,
          y: 0.05,
          z: -0.9,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet2',
          modelId: 'cabinet2',
          scale: [0.16, 0.18, 0.18],
          x: 2.18,
          y: 0.05,
          z: -0.9,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet2',
          modelId: 'cabinet2',
          scale: [0.16, 0.18, 0.18],
          x: 2.26,
          y: 0.05,
          z: -0.9,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet2',
          modelId: 'cabinet2',
          scale: [0.16, 0.18, 0.18],
          x: 2.3,
          y: 0.05,
          z: -0.9,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet2',
          modelId: 'cabinet2',
          scale: [0.16, 0.18, 0.18],
          x: 2.38,
          y: 0.05,
          z: -0.9,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet2',
          modelId: 'cabinet2',
          scale: [0.16, 0.18, 0.18],
          x: 2.42,
          y: 0.05,
          z: -0.9,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet1',
          modelId: 'cabinet1',
          scale: [0.14, 0.18, 0.18],
          x: 2.52,
          y: 0.05,
          z: -1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet1',
          modelId: 'cabinet1',
          scale: [0.14, 0.18, 0.18],
          x: 2.56,
          y: 0.05,
          z: -1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet1',
          modelId: 'cabinet1',
          scale: [0.14, 0.18, 0.18],
          x: 2.64,
          y: 0.05,
          z: -1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet1',
          modelId: 'cabinet1',
          scale: [0.14, 0.18, 0.18],
          x: 2.68,
          y: 0.05,
          z: -1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet1',
          modelId: 'cabinet1',
          scale: [0.14, 0.18, 0.18],
          x: 2.76,
          y: 0.05,
          z: -1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet1',
          modelId: 'cabinet1',
          scale: [0.14, 0.18, 0.18],
          x: 2.8,
          y: 0.05,
          z: -1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet1',
          modelId: 'cabinet1',
          scale: [0.14, 0.18, 0.18],
          x: 2.88,
          y: 0.05,
          z: -1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet1',
          modelId: 'cabinet1',
          scale: [0.14, 0.18, 0.18],
          x: 2.92,
          y: 0.05,
          z: -1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet1',
          modelId: 'cabinet1',
          scale: [0.14, 0.18, 0.18],
          x: 3.1,
          y: 0.05,
          z: -1.25,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'cabinet6',
          modelId: 'cabinet6',
          scale: [0.18, 0.18, 0.18],
          x: 1.7,
          y: 0.05,
          z: -0.3,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'cabinet6',
          modelId: 'cabinet6',
          scale: [0.18, 0.18, 0.18],
          x: 1.7,
          y: 0.05,
          z: -0.068,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'meetingDesk',
          modelId: 'meetingDesk',
          scale: [0.14, 0.18, 0.18],
          x: 0.75,
          y: 0.05,
          z: -1,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet6',
          modelId: 'cabinet6',
          scale: [0.18, 0.18, 0.18],
          x: 1.8,
          y: 0.05,
          z: -0.3,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'cabinet6',
          modelId: 'cabinet6',
          scale: [0.18, 0.18, 0.18],
          x: 1.8,
          y: 0.05,
          z: -0.068,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'cabinet6',
          modelId: 'cabinet6',
          scale: [0.18, 0.18, 0.18],
          x: 1.9,
          y: 0.05,
          z: -0.3,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'cabinet6',
          modelId: 'cabinet6',
          scale: [0.18, 0.18, 0.18],
          x: 1.9,
          y: 0.05,
          z: -0.068,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'greyCabinet2',
          modelId: 'greyCabinet2',
          scale: [0.18, 0.18, 0.18],
          x: 1.55,
          y: 0.05,
          z: -0.283,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'greyCabinet2',
          modelId: 'greyCabinet2',
          scale: [0.18, 0.18, 0.18],
          x: 1.55,
          y: 0.05,
          z: -0.108,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'greyCabinet2',
          modelId: 'greyCabinet2',
          scale: [0.18, 0.18, 0.18],
          x: 1.4,
          y: 0.05,
          z: -0.283,
          rotateX: 0,
          rotateY: Math.PI
        },
        {
          name: 'greyCabinet2',
          modelId: 'greyCabinet2',
          scale: [0.18, 0.18, 0.18],
          x: 1.4,
          y: 0.05,
          z: -0.108,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'chairs1',
          modelId: 'chairs1',
          scale: [0.14, 0.18, 0.18],
          x: 0.7,
          y: 0.05,
          z: -0.55,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'chairs2',
          modelId: 'chairs2',
          scale: [0.14, 0.18, 0.18],
          x: 0.7,
          y: 0.05,
          z: -0.7,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'computer',
          modelId: 'computer',
          scale: [0.14, 0.18, 0.18],
          x: 0.7,
          y: 0.1,
          z: -0.625,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'computer',
          modelId: 'computer',
          scale: [0.14, 0.18, 0.18],
          x: 0.75,
          y: 0.1,
          z: -0.625,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'computer',
          modelId: 'computer',
          scale: [0.14, 0.18, 0.18],
          x: 0.8,
          y: 0.1,
          z: -0.625,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'computer',
          modelId: 'computer',
          scale: [0.14, 0.18, 0.18],
          x: 0.84,
          y: 0.1,
          z: -0.625,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'strongBOx',
          modelId: 'strongBOx',
          scale: [0.14, 0.18, 0.18],
          x: 1,
          y: 0.05,
          z: -0.45,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'strongBOx',
          modelId: 'strongBOx',
          scale: [0.14, 0.18, 0.18],
          x: 1.04,
          y: 0.05,
          z: -0.45,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'strongBOx',
          modelId: 'strongBOx',
          scale: [0.14, 0.18, 0.18],
          x: 0.96,
          y: 0.05,
          z: -0.45,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'strongBOx',
          modelId: 'strongBOx',
          scale: [0.14, 0.18, 0.18],
          x: 0.92,
          y: 0.05,
          z: -0.45,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'fireHydrant',
          modelId: 'fireHydrant',
          scale: [0.18, 0.18, 0.18],
          x: 0.55,
          y: 0.05,
          z: -0.84,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'fireHydrant',
          modelId: 'fireHydrant',
          scale: [0.18, 0.18, 0.18],
          x: 1.25,
          y: 0.05,
          z: -0.84,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'fireHydrant',
          modelId: 'fireHydrant',
          scale: [0.18, 0.18, 0.18],
          x: 1.75,
          y: 0.05,
          z: -0.84,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'fireHydrant',
          modelId: 'fireHydrant',
          scale: [0.18, 0.18, 0.18],
          x: 2.35,
          y: 0.05,
          z: -0.84,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'plant',
          modelId: 'plant',
          scale: [0.18, 0.18, 0.18],
          x: 0.5,
          y: 0.05,
          z: -0.83,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'plant',
          modelId: 'plant',
          scale: [0.18, 0.18, 0.18],
          x: 2.9,
          y: 0.05,
          z: -0.6,
          rotateX: 0,
          rotateY: 0
        },
        {
          name: 'door',
          modelId: 'door',
          scale: [0.18, 0.18, 0.18],
          x: 0.38,
          y: 0.07,
          z: -0.35,
          rotateX: 0,
          rotateY: Math.PI / 2
        },
        {
          name: 'floor',
          modelId: 'floor',
          scale: [0.2, 0.2, 0.2],
          x: 0,
          y: -0.035,
          z: 0,
          rotateX: 0,
          rotateY: 0
        }
      ]

      for (let i = 0; i < list.length; i++) {
        this.createGltf1(list[i])
      }
    },
    onMouseMove(event) {
      // console.log(this.scene)
      //再给新的选中模型变色前 先把上一个模型复原
      if (this.selectObject) {
        this.selectObject.material.opacity = 1
      }
      // 获取 raycaster 和所有模型相交的数组，其中的元素按照距离排序，越近的越靠前
      var intersects = this.getIntersects(event)
      // console.log(intersects)
      // 获取选中最近的 Mesh 对象
      if (
        intersects.length != 0 &&
        intersects[0].object instanceof THREE.Mesh
      ) {
        this.selectObject = intersects[0].object
        if (this.selectObject.facilityid != null) {
          this.changeMaterial(this.selectObject)
          this.renderDiv(this.selectObject, intersects[0].point)
          this.labelnameShow = true
          this.conType = 'container'
          // let param = {
          //   //facilityid: "SB000011",
          //   facilityid: this.selectObject.facilityid,
          // };
          // this.faclist=[];
          // this.$doFetch("/Custom/Sto3D/facility", param).then((res) => {
          //   this.faclist = res;
          //   let faclist = res;
          //   console.log(faclist);
          //   if(faclist.facilityname=="RFID检测门"){
          //     this.labelnameShow = false;
          //   }
          //   if(faclist.styleid=="FS000022"){
          //     this.conType="carport";
          //   }
          //   console.log(this.conType)
          //    var vector4 = new THREE.Vector4(150, 60, 50);
          //   $("#labelname").css({
          //     left: vector.x * halfWidth + halfWidth,
          //     top: vector4.y,
          //   });
          // });
        } else {
          console.log('facilityid为空，是摆设！')
        }
      } else {
        this.labelnameShow = false
        // console.log('未选中 Mesh!')
      }
    },
    // 鼠标双击触发的方法
    onMouseDblclick(event) {
      // 获取 raycaster 和所有模型相交的数组，其中的元素按照距离排序，越近的越靠前
      var intersects = this.getIntersects(event)

      // 获取选中最近的 Mesh 对象
      if (
        intersects.length != 0 &&
        intersects[0].object instanceof THREE.Mesh
      ) {
        this.selectObject = intersects[0].object
        // this.changeMaterial(this.selectObject);
        this.renderDiv(this.selectObject)
      } else {
        console.log('未选中 Mesh!')
      }
    },
    //鼠标单击触发的方法
    onMouseclick(event) {
      console.log(this.scene)
      //再给新的选中模型变色前 先把上一个模型复原
      if (this.selectObject) {
        this.selectObject.material.opacity = 1
      }
      // 获取 raycaster 和所有模型相交的数组，其中的元素按照距离排序，越近的越靠前
      var intersects = this.getIntersects(event)
      console.log(intersects)
      // 获取选中最近的 Mesh 对象
      if (
        intersects.length != 0 &&
        intersects[0].object instanceof THREE.Mesh
      ) {
        this.selectObject = intersects[0].object
        if (this.selectObject.facilityid != null) {
          this.changeMaterial(this.selectObject)
          // this.renderDiv(this.selectObject);
          this.label2Show = true
          this.conType = 'container'
          let param = {
            //facilityid: "SB000011",
            facilityid: this.selectObject.facilityid
          }
          this.faclist = []
          this.$doFetch('/Custom/Sto3D/facility', param).then(res => {
            this.faclist = res
            let faclist = res
            console.log(faclist)
            if (faclist.facilityname == 'RFID检测门') {
              this.label2Show = false
            }
            if (faclist.styleid == 'FS000022') {
              this.conType = 'carport'
            }
            console.log(this.conType)
            var vector4 = new THREE.Vector4(150, 60, 50)
            $('#label2').css({
              // left: "30%",
              top: vector4.y
            })
            // $("#label2").text([
            //   "设备编号：" +
            //     faclist.accountcompanyid +
            //     "\n" +
            //     "楼层区域ID：" +
            //     faclist.areaid +
            //     "\n" +
            //     "设备ID：" +
            //     faclist.facilityid +
            //     "\n" +
            //     "设备名称：" +
            //     faclist.facilityname +
            //     "\n" +
            //     "设备描述：" +
            //     faclist.remark +
            //     "\n" +
            //     "储物柜区域ID：" +
            //     faclist.locker.areaid +
            //     "\n" +
            //     "储物柜ID：" +
            //     faclist.lockerid +
            //     "\n" +
            //     "储物柜名称：" +
            //     faclist.locker.lockername +
            //     "\n" +
            //     // "取消标志：" + faclist.locker.cancelsign+ '\n' +
            //     "储物柜状态：" +
            //     faclist.locker.statusName +
            //     "\n" +
            //     "工作人员编号：" +
            //     faclist.locker.upstaff +
            //     "\n" +
            //     // "更新时间：" + faclist.locker.uptime+ '\n' +
            //     "设备类型编号：" +
            //     faclist.styleid +
            //     "\n" +
            //     "设备类型名称：" +
            //     faclist.style.stylename +
            //     "\n" +
            //     "设备类型描述：" +
            //     faclist.style.remark +
            //     "\n",
            // ]);
          })
        } else {
          console.log('facilityid为空，是摆设！')
        }
      } else {
        this.label2Show = false
        console.log('未选中 Mesh!')
      }
    },
    // 获取与射线相交的对象数组
    getIntersects(event) {
      // console.log(this.camera)

      event.preventDefault()
      // console.log("event.clientX:" + event.clientX);
      // console.log("event.clientY:" + event.clientY);

      // 声明 raycaster 和 mouse 变量
      var raycaster = new THREE.Raycaster()
      var mouse = new THREE.Vector2()

      // 通过鼠标点击位置,计算出 raycaster 所需点的位置,以屏幕为中心点,范围 -1 到 1
      this.mouseX = event.offsetX
      this.mouseY = event.offsetY
      mouse.x = (event.offsetX / this.divWidth) * 2 - 1
      mouse.y = -(event.offsetY / this.divHeight) * 2 + 1

      //通过鼠标点击的位置(二维坐标)和当前相机的矩阵计算出射线位置
      raycaster.setFromCamera(mouse, this.camera)
      // console.log(this.camera);

      // 获取与射线相交的对象数组，其中的元素按照距离排序，越近的越靠前
      var intersects = raycaster.intersectObjects(this.scene.children)

      //返回选中的对象
      return intersects
    },
    // 改变对象材质属性
    changeMaterial(object) {
      object.material.opacity = 0.5
    },
    // 更新div的位置

    renderDiv(object, point) {
      // console.log(object);
      // // 获取窗口的一半高度和宽度
      // var halfWidth = this.divWidth / 2;
      // var halfHeight = this.divHeight / 2;

      // // 逆转相机求出二维坐标
      // var vector = object.position.clone().project(this.camera);
      //  var mouse = new THREE.Vector2();

      // 通过鼠标点击位置,计算出 raycaster 所需点的位置,以屏幕为中心点,范围 -1 到 1
      // let mouseX = event.offsetX-$("#container")[0].offset().left;
      // let mouseY = event.offsetY-$("#container")[0].offset().top;
      // mouse.x = (event.offsetX / this.divWidth) * 2 - 1;
      // mouse.y = -(event.offsetY / this.divHeight) * 2 + 1;

      // 修改 div 的位置
      $('#label').css({
        left: this.mouseX,
        top: this.mouseY
        // left: point.x * halfWidth + halfWidth,
        // top: point.y * halfHeight + halfHeight,
        // left: point.x * halfWidth + halfWidth,
        // top: -point.y * halfHeight + halfHeight - object.position.y,
      })
      // 显示模型信息
      $('#label').text(object.facilityname)
    },
    // 动画
    animate: function () {
      // if (this.selectObject != undefined && this.selectObject != null) {
      //       this.renderDiv(this.selectObject);
      // }
      requestAnimationFrame(this.animate)
      this.controls.update()
      this.renderer.render(this.scene, this.camera)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.el-tree) {
  width: 130px;
  margin-left: 10px;
  margin-top: 10px;
  // border-radius: 10px;
  //background-color: rgba(255, 255, 255, 1);
  background: none;
}
.container {
  position: unset;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // padding-top: 6%;
  background-color: rgb(185, 211, 255);
}
#label {
  position: absolute;
  padding: 10px;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.8);
  line-height: 1;
  border-radius: 5px;
  z-index: 10;
}
#labelname {
  position: absolute;
  padding: 10px;
  background: rgba(255, 255, 255, 0.6);
  line-height: 1;
  border-radius: 5px;
}
#label2 {
  width: 55%;
  // height: 450px;
  margin-left: 150px;
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  line-height: 1;
  padding: 10px;
  white-space: pre-line;
  line-height: 16px;
  font-size: 12px;
  background-image: url('../../assets/images/police/traffic_lights.png');
  background-size: 100% 100%;
  z-index: 12;
}

.btn {
  position: relative;
  width: 10%;
  z-index: 1;
  top: -95%;
  left: 15px;
}
.explain {
  position: absolute;
  // width: 10%;
  z-index: 1;
  bottom: 3%;
  right: 20px;
}
</style>
