<template>
  <el-popover v-model="open" placement="bottom" width="250" trigger="click">
    <template>
      <div class="month-card">
        <div class="month-card-head">
          <div class="month-card-head-pre">
            <el-icon><el-icon-d-arrow-left /></el-icon>
          </div>
          <div class="month-card-head-year">
            {{ yearFormat_filter(dutyDate) }}
          </div>
          <div class="month-card-head-next">
            <el-icon><el-icon-d-arrow-right /></el-icon>
          </div>
        </div>
        <div class="month-card-content">
          <!--          :style="dutyDate.split('-')[1] === item.val ? 'color: #409eff' : ''"-->
          <div
            v-for="item of months"
            :class="
              item.val == dutyDay
                ? 'month-card-content-item checked'
                : 'month-card-content-item'
            "
            @click="select(item.val)"
          >
            {{ item.lab }}
          </div>
        </div>
      </div>
    </template>
    <template #reference>
      <div>
        <slot />
      </div>
    </template>
  </el-popover>
</template>

<script>
import {
  DArrowLeft as ElIconDArrowLeft,
  DArrowRight as ElIconDArrowRight
} from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../../../utils/gogocodeTransfer'
import { formatDate, plusMonth, str2Date } from '@/utils/dateTime'

export default {
  name: 'MonthSelectPop',
  components: {
    ElIconDArrowLeft,
    ElIconDArrowRight
  },
  props: {
    dutyDate: {}
  },
  emits: ['preMonth', 'nextMonth', 'update:dutyDate', 'change'],
  data() {
    return {
      open: false,
      months: [
        { lab: '一月', val: '01' },
        { lab: '二月', val: '02' },
        { lab: '三月', val: '03' },
        { lab: '四月', val: '04' },
        { lab: '五月', val: '05' },
        { lab: '六月', val: '06' },
        { lab: '七月', val: '07' },
        { lab: '八月', val: '08' },
        { lab: '九月', val: '09' },
        { lab: '十月', val: '10' },
        { lab: '十一月', val: '11' },
        { lab: '十二月', val: '12' }
      ]
    }
  },
  computed: {
    dutyDay() {
      return formatDate(this.dutyDate, 'MM')
    }
  },
  methods: {
    yearFormat_filter(val) {
      return formatDate(val, 'YYYY')
    },
    preYear(months) {
      $emit(this, 'preMonth', -12)
    },
    nextYear() {
      $emit(this, 'nextMonth', 12)
    },
    select(month) {
      let dateTime = formatDate(this.dutyDate, 'YYYY') + '-' + month + '01'
      $emit(this, 'update:dutyDate', str2Date(dateTime))
      $emit(this, 'change')
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'css/duty';
.month-card {
  width: 100%;
  height: 190px;
  &-head {
    display: flex;
    height: 40px;
    align-items: center;

    &-pre {
      width: 10%;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }

    &-pre:hover {
      color: #409eff;
    }

    &-year {
      width: 80%;
      display: flex;
      justify-content: center;
      font-size: 16px;
    }

    &-next {
      width: 10%;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }

    &-next:hover {
      color: #409eff;
    }
  }

  &-content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &-item {
      width: 25%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 13px;
    }

    &-item:hover {
      color: #409eff;
    }
    .checked {
      color: #409eff;
    }
  }
}
</style>
