<template>
  <div class="batchUpdateStaffDialog">
    <div style="display: flex">
      <el-select
        v-model="arrestId"
        class="grade-content-select"
        filterable
        placeholder="请选择大队"
        @change="postChange"
      >
        <el-option
          v-for="item in arrestArr"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select
        v-model="postId"
        class="grade-content-select"
        filterable
        placeholder="请选择驻点"
      >
        <el-option
          v-for="item in arrestArr[index]?.children
            ? arrestArr[index].children
            : []"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <template>
        <div class="block">
          <el-date-picker
            v-model="value1"
            :clearable="false"
            value-format="YYYY-MM-DD"
            placeholder="选择日期"
            type="date"
          />
        </div>
      </template>
    </div>

    <div class="footer">
      <el-button @click="close"> 取消 </el-button>
      <el-button type="primary" @click="confirm"> 确认 </el-button>
    </div>
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../../utils/gogocodeTransfer'
import { formatDate } from '@/utils/dateTime'
import { getArrestTree, updateStaffArrest } from '@/requet/api/ba/scheduleApi'

export default {
  name: 'BatchUpdateStaffDialog',
  props: ['queryParams'],
  emits: ['btnLinkClose'],
  data() {
    return {
      value1: formatDate(new Date(), 'YYYY-MM-DD'),
      arrestId: '',
      postId: '',
      arrestArr: [],
      postArr: [],
      depId: '',
      dataIndex: '',
      index: '',
      staffId: []
    }
  },
  watch: {
    dataIndex(val) {
      if (val !== null) {
        this.getArrestTree()
      }
    },
    queryParams: {
      deep: true,

      handler(val) {
        console.log('queryParams0', val)
        let arr = []
        this.queryParams.forEach(e => {
          arr.push(e.F0)
        })
        this.staffId = arr
      },

      immediate: true
    }
  },
  created() {
    this.getArrestTree('create')
  },
  activated() {
    console.log('queryParams2', this.queryParams)
  },
  mounted() {
    this.staffId = []
    console.log('queryParams', this.queryParams)
    let arr = []
    this.queryParams.forEach(e => {
      arr.push(e.F0)
    })
    this.staffId = arr
    console.log(this.staffId)
  },
  methods: {
    async confirm() {
      if (this.postId && this.value1) {
        await updateStaffArrest({
          staffids: this.staffId,
          updateArrestid: this.postId,
          beginDate: this.value1 + ' 00:00:00'
        })
        this.$message.success('操作成功！')
        this.close()
      } else {
        this.$message.warning('请选择大队和驻点！')
      }
    },
    close() {
      $emit(this, 'btnLinkClose')
    },
    async getArrestTree(type) {
      let date = new Date()
      let m = date.getMonth() + 1
      let nowMonth = m < 10 ? '0' + m : m + ''
      let nowYear = date.getFullYear()
      let res = await getArrestTree({ month: nowMonth, year: nowYear })
      let dataArr = res.children
      this.arrestArr = dataArr
      if (type != 'create') {
        this.arrestId = this.arrestId ? this.arrestId : dataArr[0].value
        for (var i = 0; i < this.arrestArr.length; i++) {
          // console.log(this.arrestId);
          // console.log(this.arrestArr[i].value)
          if (this.arrestId == this.arrestArr[i].value) {
            this.index = i
            this.postId = this.arrestArr[i].children[0].value

            console.log(this.arrestArr[i])
          }
        }
      }
    },
    async updateStaffArrest() {
      let res = await updateStaffArrest({
        staffids: this.staffId,
        updateArrestid: this.postId,
        beginDate: this.value1
      })
      // console.log(res)
    },
    postChange(item) {
      console.log('change', item, this.arrestId)
      this.dataIndex = item
    }
  }
}
</script>

<style lang="scss" scoped>
.batchUpdateStaffDialog {
  width: 100%;
  height: 100%;
  position: relative;
  .grade-content-select {
    margin-right: 15px;
  }

  .footer {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
</style>
