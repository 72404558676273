import { post, fetch, patch, put, postJson, fetch_blob } from '../../http'
export default {
  //新增字典
  addDic(params) {
    return postJson('/ConfigureReports/dic/addDic', params)
  },
  //获取字典
  getDicList(params) {
    return postJson('/ConfigureReports/dic/getDicList', params)
  },
  //更新字典
  updateDic(params) {
    return postJson('/ConfigureReports/dic/updateDic', params)
  },
}
