<template>
  <div>
    <div class="ei_icon flexrowbetween">
      <span style="font-size: 14px">{{ title }}</span>
      <div v-if="title != '岗位选取'" />
    </div>
    <div class="input">
      <el-input
        v-model="input"
        class="input-with-select"
        placeholder="请输入内容"
        @keydown.enter="search"
      >
        <template #append>
          <el-button :icon="ElIconSearch" @click="search()" />
          <el-button
            v-if="resetAble"
            style="margin-left: 15px"
            :icon="ElIconRefreshLeft"
            @click="reset()"
          />
        </template>
      </el-input>
    </div>
    <div class="ei_tree tree-container">
      <el-tree
        ref="tree"
        v-loading="treeload"
        :data="locData"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        :props="props"
        node-key="id"
        @node-click="handleNodeClick"
      >
        <template #default="{ node }">
          <span class="custom-tree-node">
            <span class="wordStyle">
              <el-icon v-if="node.level==1"><el-icon-place /></el-icon>
              <el-icon v-if="node.level==2"><el-icon-location /></el-icon>
              <el-icon v-if="node.level==3"
                ><el-icon-office-building
              /></el-icon>
              {{ node.label }}
            </span>
          </span>
        </template>
      </el-tree>
    </div>
  </div>
</template>

<script>
import {
  Place as ElIconPlace,
  Location as ElIconLocation,
  OfficeBuilding as ElIconOfficeBuilding,
  Search as ElIconSearch,
  RefreshLeft as ElIconRefreshLeft,
} from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
import { mapMutations } from 'vuex'
import {shallowRef} from "vue";

export default {
  name: 'Treemenu2',
  components: {
    ElIconPlace,
    ElIconLocation,
    ElIconOfficeBuilding,
  },
  props: {
    props: Object,
    title: String,
    data: Array,
    resetAble: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: '',
      // props: {
      //     label: "name",
      //     children: "children",
      //     isLeaf: "leaf",
      //     icon: "icon"
      // },
      mainform: '',
      // faId:'',
      item: {},
      //树形加载loading
      treeload: false,
      //树形展开收缩标记
      updown: false,
      actClick: {},
      typelist: [],
      currentNodeKey: null,
      locData: [],
      ElIconSearch: shallowRef(ElIconSearch),
      ElIconRefreshLeft: shallowRef(ElIconRefreshLeft),
    }
  },
  watch: {
    moduleid(newnew, oldold) {
      console.log('moduleid' + this.moduleid)
      this.getInitTree()
    },
    data: {
      handler(val) {
        console.log('locData', val)
        this.locData = val
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // this.getInitTree();
  },
  methods: {
    ...mapMutations(['setEmployInfo']),
    search() {
      this.$refs.tree.filter(this.input)
    },
    reset() {
      this.treeload = true
      this.locData = JSON.parse(JSON.stringify(this.locData))
      $emit(this, 'reset')
      this.treeload = false
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    handleNodeClick(data) {
      if (data.children == null) {
        console.log(
          'handleNodeClick',
          data,
          this.currentNodeKey,
          data.value,
          this.currentNodeKey == data.value
        )
        let flag = this.currentNodeKey == data.value
        if (this.currentNodeKey == data.value) {
          this.currentNodeKey = null
          // this.$refs.tree.setCheckedKeys([])
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys([])
          })
          this.filterNode()
        } else {
          this.currentNodeKey = data.value
        }
        $emit(this, 'getactClickdata', {
          ...data,
          isSameClick: flag,
        })
      }
      // console.log('currNode', this.currentNodeKeys)
    },
  },
  emits: ['getactClickdata', 'reset'],
}
</script>

<style lang="scss" scoped>
.input {
  width: 90%;
  margin-left: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
}
.ei_icon {
  padding: 2% 4%;
  box-sizing: border-box;
  i {
    margin-right: 10px;
  }
}
.el-input-group {
  width: 100%;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.ei_tree {
  width: 100%;
  height: 80%;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 0 auto;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4px;
  padding-right: 8px;
}
.wordStyle {
  font-size: 1.4px;
}
.tree-container :deep .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.tree-container :deep .el-icon-caret-right:before {
  content: '\e791';
  font-size: 1.4px;
}
.tree-container :deep .el-tree-node__expand-icon {
  margin-left: 2%;
  padding: 0px;
}
.tree-container :deep .el-tree-node__expand-icon.is-leaf {
  margin-left: 0px;
}
.tree-container :deep .el-tree-node {
  position: relative;
  padding-left: 6%;
}
.tree-container :deep .el-tree-node__children {
  padding-left: 4%;
}
.tree-container :deep .el-tree-node:after {
  border-top: 1px solid #e6e6e6;
  height: 2vh;
  top: 2vh;
  width: 7%;
}
@media screen and (min-width: 0px) {
  .tree-container :deep .el-tree-node__children {
    padding-left: 3%;
  }
  .tree-container :deep .el-tree-node {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep .el-tree-node:after {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 10%;
  }
}
@media screen and (min-width: 230px) {
  .tree-container :deep .el-tree-node__children {
    padding-left: 2%;
  }
  .tree-container :deep .el-tree-node {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep .el-tree-node:after {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 8.5%;
  }
}
@media screen and (min-width: 350px) {
  .tree-container :deep .el-tree-node__children {
    padding-left: 1.5%;
  }
  .tree-container :deep .el-tree-node {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep .el-tree-node:after {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 5%;
  }
}
@media screen and (min-width: 500px) {
  .tree-container :deep .el-tree-node__children {
    padding-left: 1%;
  }
  .tree-container :deep .el-tree-node {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep .el-tree-node:after {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 4%;
  }
}
@media screen and (min-width: 700px) {
  .tree-container :deep .el-tree-node__children {
    padding-left: 0.6%;
  }
  .tree-container :deep .el-tree-node {
    position: relative;
    padding-left: 8%;
  }
  .tree-container :deep .el-tree-node:after {
    border-top: 1px solid #e6e6e6;
    height: 2vh;
    top: 2vh;
    width: 8%;
  }
}
.tree-container :deep .el-tree > .el-tree-node:before {
  border-left: none;
}
.tree-container :deep .el-tree > .el-tree-node:after {
  border-top: none;
}
.tree-container :deep .el-tree > .el-tree-node:before {
  border-left: none;
}
.tree-container :deep .el-tree > .el-tree-node:after {
  border-top: none;
}
.tree-container :deep .el-tree-node:before {
  content: '';
  left: 2%;
  position: absolute;
  right: auto;
  border-width: 1px;
}
.tree-container :deep .el-tree-node:after {
  content: '';
  left: 2%;
  position: absolute;
  right: auto;
  border-width: 1px;
}
.tree-container :deep .el-tree-node:before {
  border-left: 1px solid #e6e6e6;
  bottom: 0px;
  height: 100%;
  top: -1vh;
  width: 1px;
}
.el-tree-node :last-child:before {
  height: 5vh;
}
.tree-container {
  margin: 0px;
}
.tree-container :deep .el-tree .el-tree-node {
  position: relative;
}
:deep .el-tree-node__content {
  background-color: rgba(0, 0, 0, 0);
  margin: 0px 0px;
  padding: 0px 0px;
}
.tree-container :deep .el-tree-node .el-tree-node__content {
  height: 4vh;
  padding-left: 0 !important;
}
.tree-container :deep .el-tree-node .el-tree-node__content::before {
  border-left: 1px solid #e6e6e6;
  height: 100%;
  top: 0;
  width: 1px;
  margin-left: 1px;
  margin-top: 0px;
  z-index: 8;
}
.tree-container
  :deep
  .el-tree-node
  .el-tree-node__children
  .el-tree-node__content::before {
  border-left: 0px solid #e6e6e6;
  height: 100%;
  top: 0;
  width: 1px;
  margin-left: 1px;
  margin-top: 0px;
  z-index: 8;
}
.tree-container :deep .el-tree-node .el-tree-node__content::after {
  border-top: 1px solid #e6e6e6;
  height: 1px;
  top: 2vh;
  width: 1.5%;
  margin-left: 1px;
  z-index: 8;
}
.tree-container
  :deep
  .el-tree-node
  .el-tree-node__children
  .el-tree-node__content::after {
  border-top: 0px solid #e6e6e6;
}
.tree-container .el-tree-node .el-tree-node__content::before,
.tree-container .el-tree-node .el-tree-node__content::after {
  content: '';
  position: absolute;
  right: auto;
}
</style>
