import * as Vuex from 'vuex'
import {provide} from "vue";

const store = Vuex.createStore({
  state: {
    axiosPromiseCancel: [],
    user: {
      userName: '',
      // password: ""
    },
    loginInfo: {},
    tagList: [],
    userInfo: {},
    keepAlive: [],
    cacheData: [],
    withdrawalData: {},
    transfersInData: {},
    transfersOutData: {},
    receiveData: {},
    storeIds: '',
    storeInventoryPlanData: {},
    storeInventoryListData: {},
    storeAdjustmentListData: {},
    bgPageId: '',
    ckState: {
      billCk: 'N',
      importCk: 'N',
      checkCk: 'N',
      auditCk: 'N',
    },
    cffloading: false,
    flowStatus: '001',
    copydata: {},
    employInfo: {},
    copyid: '',
    activeMenu: '',
    menuList: false,
    menuListBadge: false,
    newEditorShow1: true,
    isLoadingComplete: false,
    isCollapse: false,
    compareShow: true,
    tabClick: false,
    refresh: false,
    tableValue: [],
    ldClick: false,
    showData: {},
    fatherShow: false,
    changeHistoryDialogShow: {},
    extData: [],
    trafficShowSelectDataClick: [],
    refreshWarningTips: true,
  },
  mutations: {
    changeWarningTips(state, value) {
      state.refreshWarningTips = value
    },
    changeFatherShow(state, value) {
      state.fatherShow = value
    },
    changechangeHistoryDialogShow(state, value) {
      state.changeHistoryDialogShow = value
    },
    gettrafficShowSelectDataClick(state, value) {
      state.trafficShowSelectDataClick = value
    },
    getShowData(state, value) {
      state.showData = value
    },
    tableValueChanged(state, value) {
      state.tableValue = value
    },
    changeTabClick: (state, tabClick) => {
      state.tabClick = tabClick
    },
    changeCompareShow: (state, compareShow) => {
      state.compareShow = compareShow
    },
    initEditorShow1: (state, newEditorShow1) => {
      state.newEditorShow1 = newEditorShow1
    },
    setactiveMenu: (state, activeMenu) => {
      state.activeMenu = activeMenu
    },
    setstoreIds: (state, storeIds) => {
      state.storeIds = storeIds
    },
    settagList: (state, tagList) => {
      state.tagList = tagList
    },
    getMenulist(state, menuList) {
      state.menuList = menuList
      console.log(state.menuList)
    },
    getMenuListBadge(state, menuListBadge) {
      state.menuListBadge = menuListBadge
    },
    TagClose: (state, i) => {
      state.tagList.splice(i, 1)
      //  if (state.tagList.length > i) {
      //    this.$router.push({ path: this.tagList[i].path });
      //  } else {
      //    this.$router.push({ path: this.tagList[i - 1].path });
      //  }
    },
    addTag: (state, item) => {
      // console.log('addTag',item.path)
      let i = state.tagList.findIndex((e) => e.path === item.path)
      if (i < 0) {
        let { matched, ...prarm } = item
        // console.log(matched)
        state.tagList.push(prarm)
      } else {
        state.tagList[i].fullPath = item.fullPath
        state.tagList[i].query = item.query
      }
    },
    setcacheData: (state, that) => {
      // console.log("that", that, that.$data, state.tagList)
      let m = state.tagList.findIndex((e) => e.name === that.$route.name)
      let i = state.cacheData.findIndex((e) => e.name === that.$route.name)
      // console.log("im", i, m)
      if (m > -1) {
        if (i < 0) {
          let obj = {
            name: that.$route.name,
            data: that.$data,
          }
          state.cacheData.push(obj)
        } else {
          state.cacheData[i].data = that.$data
        }
      }
    },
    removeCacheData: (state, name) => {
      let i = state.cacheData.findIndex((e) => e.name === name)
      if (i > -1) {
        state.cacheData.splice(i, 1)
      }
    },
    setKeepAlive: (state, keepAlive) => {
      state.keepAlive = keepAlive
    },
    pushKeepAlive: (state, name) => {
      //  console.log('pushKeepAlive', name)
      let i = state.keepAlive.findIndex((e) => e === name)
      console.log('pushKeepAlive', state.keepAlive)
      if (i < 0) {
        state.keepAlive.push(name)
      }
      let index = state.keepAlive.indexOf('perPortrait')
      if (index > -1) {
        state.keepAlive.splice(index, 1)
      }

      // console.log('pushKeepAlive', state.keepAlive)
    },
    removeKeepAlive: (state, name) => {
      let i = state.keepAlive.findIndex((e) => e === name)
      if (i > -1) {
        state.keepAlive.splice(i, 1)
      }
    },
    clearAxiosPromiseCancel(state) {
      state.axiosPromiseCancel = []
    },
    userPromiseCancel(state, loginUser) {
      //console.log(loginUser)
      state.user.userName = loginUser.userId
      // state.user.password = loginUser.password
      state.user.userChinaName = loginUser.name
      state.user.staffId = loginUser.id
    },
    removePendingPromiseCancel(state, i) {
      // console.log('removePendingPromiseCancel', i)
      state.axiosPromiseCancel.splice(i, 1)
      // console.log('state', state.axiosPromiseCancel)
    },
    userInfoPromiseCancel(state, User) {
      state.userInfo = User
    },
    setwithdrawalData(state, tableData) {
      state.withdrawalData = tableData
    },
    setTranInData(state, Data) {
      state.transfersInData = Data
    },
    setTranOutData(state, Data) {
      state.transfersOutData = Data
    },
    setreceiveData(state, Data) {
      state.receiveData = Data
    },
    setstoreInventoryPlanData(state, Data) {
      state.storeInventoryPlanData = Data
    },
    setstoreInventoryListData(state, Data) {
      state.storeInventoryListData = Data
    },
    setstoreAdjustmentListData(state, Data) {
      state.storeAdjustmentListData = Data
    },
    setBgPageId(state, Data) {
      state.bgPageId = Data
    },

    setloginInfo(state, Data) {
      state.loginInfo = Data
    },
    setckState(state, Data) {
      if (Data.tbName == 'SYS_BILL_HD') {
        // debugger
        // Vue.set(state.ckState,'billCk',Data.sign)
        state.ckState.billCk = Data.sign
      } else if (Data.tbName == 'SYS_IMPORT_HD') {
        state.ckState.importCk = Data.sign
      } else if (Data.tbName == 'SYS_BILL_CHECK') {
        state.ckState.checkCk = Data.sign
      } else if (Data.tbName == 'SYS_BILL_AUDIT') {
        state.ckState.auditCk = Data.sign
      }
    },
    setCffloading(state, Data) {
      state.cffloading = Data
    },
    SET_FLOWSTATUS(state, status) {
      state.flowStatus = status
    },
    setCopydata(state, Data) {
      state.copydata = Data
    },
    setEmployInfo(state, Data) {
      state.employInfo = Data
    },
    setCopyid(state, Data) {
      state.copyid = Data
    },
  },
  getters: {
    keepAlive: (state) => state.keepAlive,
    cacheData: (state) => state.cacheData,
    tagList: (state) => state.tagList,
    storeIds: (state) => state.storeIds,
    ckState: (state) => state.ckState,
    activeMenu: (state) => state.activeMenu,
    // checkCk:state => state.checkCk,
    // importCk:state => state.importCk,
    // billCk:state => state.billCk,
    cffloading: (state) => state.cffloading,
    flowStatus: (state) => state.flowStatus,
    copydata: (state) => state.copydata,
    copyid: (state) => state.copyid,
    employInfo: (state) => state.employInfo,
  },
  actions: {
    getNewShowData(context, value) {
      context.commit('getShowData', value)
    },
    getNewtrafficShowSelectDataClick(context, value) {
      context.commit('gettrafficShowSelectDataClick', value)
    },
    changeTableValue(context, value) {
      context.commit('tableValueChanged', value)
    },
    clearAxios(context) {
      context.commit('clearAxiosPromiseCancel')
    },
    setuser(context, loginUser) {
      // console.log(loginUser)
      context.commit('userPromiseCancel', loginUser)
    },
    removePending(context, i) {
      context.commit('removePendingPromiseCancel', i)
    },
    setUserInfo(context, User) {
      context.commit('userInfoPromiseCancel', User)
    },
    setFlowStatus(context, status) {
      context.commit('SET_FLOWSTATUS', status)
    },
    getMenuList(context, menuList) {
      context.commit('getMenulist', menuList)
    },
    initEditorShow(context, editorShow) {
      context.commit('initEditorShow1', editorShow)
    },
  },
  modules: {},
})

// provide('store', store)
export default store
