import moment from 'moment'

const _moment = moment
/**
 * 格式化
 * @param date
 * @param format "YYYY-MM-DD"
 * @returns {*}
 */
export function formatDate(date, format = 'YYYY-MM-DD') {
  return _moment(date).format(format)
}

/**
 * 获取当月天数
 * @param date
 * @returns {*}
 */
export function daysInMonth(date) {
  return _moment(date, 'YYYY-MM').daysInMonth()
}

/**
 * 加减月份
 * @param date
 * @param months 正数加 负数减
 * @returns {moment.Moment}
 */
export function plusMonth(date, months) {
  return _moment(date).add(months, 'M')
}

/**
 * 加减天数
 * @param date
 * @param days 正数加 负数减
 * @returns {moment.Moment}
 */
export function plusDays(date, days) {
  return _moment(date).add(days, 'days')
}

/**
 * string转date
 * @param str
 * @param format
 * @returns {*|moment.Moment}
 */
export function str2Date(str, format = 'YYYY-MM-DD') {
  return _moment(str, format)
}

/**
 * 获取指定日期第一天
 * @param date
 * @param unitOfTime
 */
export function dateStartOf(date, unitOfTime = 'month') {
  return _moment(date).startOf(unitOfTime)
}
/**
 * 获取指定日期最后一天
 * @param date
 * @param unitOfTime
 */
export function dateEndOf(date, unitOfTime = 'month') {
  return _moment(date).endOf(unitOfTime)
}

/**
 * 日期相减
 * @param dateA
 * @param dateB
 * @param unitOfTime
 * @returns {number}
 */
export function diffDate(dateA, dateB, unitOfTime = 'day') {
  return _moment(dateA).diff(_moment(dateB), unitOfTime)
}
export function diffDatesec(dateA, dateB, unitOfTime = 'seconds') {
  return _moment(dateA).diff(_moment(dateB), unitOfTime)
}
