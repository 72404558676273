<template>
  <div>
    <div class="batchUpdateClockDistance">
      <div class="choose">
        <el-input-number size="medium" v-model="distance" :precision="0" :min="1"></el-input-number>
        <span style="margin-left:20px">米</span>
      </div>
      <div class="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="confirm">确认</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {updateClassesClockDistance} from "@/requet/api/ba/scheduleApi";
export default {
  name:'batchUpdateClockDistance',
  props: ['queryParams'],
  data(){
    return{
      arrestIdList:[],
      distance:0,
    }
  },
  watch:{
    queryParams:{
      handler(newval){
        console.log('queryParams',newval)
        this.getarrestIdList();
      }
    }
  },
  mounted(){
    console.log('queryParams', this.queryParams)
    this.getarrestIdList();
  },
  methods:{
    getarrestIdList(){
      this.arrestIdList=[];
      if(this.queryParams.length!=0){
        this.queryParams?.forEach(res=>{
          this.arrestIdList.push(res.F0);
        })
      }
      console.log(this.arrestIdList);
    },
    close(){
      this.$emit('btnLinkClose')
    },
    async confirm(){
      let res=await updateClassesClockDistance({
        arrestIdList:this.arrestIdList,
        distance:this.distance,
      })
      this.$emit('btnLinkClose')
    },
  }
}
</script>
<style lang="scss" scoped>
.batchUpdateClockDistance{
  width: 100%;
  height: 100%;
  position: relative;

  .choose{
    width: 100%;

  }

  .footer{
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
</style>