<template>
  <div class="main">
    <div class="flexrow topdetail">
      <el-form
        ref="form"
        class="flexrow flexrowc"
        :model="sampleData"
        label-width="100px"
        size="mini"
      >
        <el-form-item label="样衣编号：">
          <el-input v-model="sampleData.series" :disabled="true" />
        </el-form-item>
        <el-form-item label="工厂款号：">
          <el-input v-model="sampleData.factstyle" :disabled="true" />
        </el-form-item>
        <el-form-item label="入库款号：">
          <el-input v-model="sampleData.whstyle" :disabled="true" />
        </el-form-item>
        <el-form-item label="工厂：">
          <el-input v-model="sampleData.factory" :disabled="true" />
        </el-form-item>
        <el-form-item v-if="showbotton" label="">
          <el-button
            v-if="checkMatSign == 'N'"
            type="primary"
            @click="hlconfirm"
          >
            核料确认
          </el-button>
          <el-button v-if="checkMatSign == 'Y'" type="success">
            核料已确认
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="flexrow sampleTop">
      <div class="yydemo">
        <div class="flexrow yystyle">
          <div class="yypic">
            <div class="flexcc">样衣图片</div>
            <div class="yypicdiv flexcolumnbetween">
              <img
                style=""
                :src="goodsPicnew"
                :large="goodsPicnew"
                preview="1"
                preview-text=""
              />
              <div class="yytime flexrowcenter">
                进仓日期：
                <u>{{ sampleData.inDate }}</u>
              </div>
            </div>
          </div>
          <div class="yycolor">
            <div class="flexcc">颜色</div>
            <div v-for="(item, i) in colorList" :key="i" class="flexcc2">
              {{ item.color }}
              {{ item.id }}
            </div>
          </div>
        </div>
      </div>
      <div class="yytable">
        <div class="yykhtable">
          <div class="flexrow">
            <div class="tline">尺码配比</div>
            <div class="tline">
              {{ sizeLine.size1 }}
            </div>
            <div class="tline">
              {{ sizeLine.size2 }}
            </div>
            <div class="tline">
              {{ sizeLine.size3 }}
            </div>
            <div class="tline">
              {{ sizeLine.size4 }}
            </div>
            <div class="tline">
              {{ sizeLine.size5 }}
            </div>
            <div class="tline">
              {{ sizeLine.size6 }}
            </div>
            <div class="tline">
              {{ sizeLine.size7 }}
            </div>
            <div class="tline">
              {{ sizeLine.size8 }}
            </div>
            <div class="tline">小计</div>

            <!-- <div class="ttitle">
                        <div v-for="(item, i) in toptitle" :key="i">{{ item.title }}</div>
                      </div>
                      <div class="ttable">
                        <div class="tcontent flexrow">
                          <div class="twidth" v-for="(item, i) in topTabledata" :key="i">
                            <div class="tline">{{ item.size }}</div>
                            <div class="tline">{{ item.value1 }}</div>
                            <div class="tline">{{ item.value2 }}</div>
                            <div class="tline">{{ item.value3 }}</div>
                            <div class="tline">{{ item.value4 }}</div>
                            <div class="tline">{{ item.value5 }}</div>
                            <div class="tline">{{ item.value6 }}</div>
                          </div>
                        </div>
                      </div> -->
          </div>
          <div v-for="(item, i) in matSize" :key="i" class="flexrow">
            <div v-if="item.title == 'B_PP_QTY_rate'" class="tline">
              {{ titletop[0] }}
            </div>
            <div v-if="item.title == 'B_PP_QTY'" class="tline">
              {{ titletop[1] }}
            </div>
            <div v-else-if="item.title == 'C_IN_QTY'" class="tline">
              {{ titletop[2] }}
            </div>
            <div v-else-if="item.title == 'C_IN_QTY_rate'" class="tline">
              {{ titletop[3] }}
            </div>
            <div class="tline">
              {{ item.size1 }}
            </div>
            <div class="tline">
              {{ item.size2 }}
            </div>
            <div class="tline">
              {{ item.size3 }}
            </div>
            <div class="tline">
              {{ item.size4 }}
            </div>
            <div class="tline">
              {{ item.size5 }}
            </div>
            <div class="tline">
              {{ item.size6 }}
            </div>
            <div class="tline">
              {{ item.size7 }}
            </div>
            <div class="tline">
              {{ item.size8 }}
            </div>
            <div class="tline">
              {{ item.total }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sampleTable">
      <ElTable
        class="boxshadow"
        :table-height="tableHeight"
        :datalist="tableDatafs"
        :table-titles="tableTitlesfs"
        :currentpage="currentpagefs"
        :page-count="pageCountfs"
        cellclassname="fontweight"
        @nextpage="changepagefs"
      />
    </div>
  </div>
</template>

<script>
import ElTable from '@/components/Table/elTablenopagination2'
export default {
  name: 'SampleDetail',
  components: {
    ElTable
  },
  props: {
    pkval: String
  },
  data() {
    return {
      defaultImg:
        'this.src="' + this.ImgbaseURL + '/bigScreenCfg/img/clo.png' + '"',
      goodsPicnew1:
        'this.src="' + require('../../../public/img/goods/2.png') + '"',
      goodsPicnew: '',
      sampleData: {
        series: 'M161',
        factstyle: 'WG20102802N',
        whstyle: 'WT1010161',
        pic: '',
        inDate: '2021-7-19',
        color: '藏青人字纹Q1',
        factory: 'WG'
      },
      toptitle: [
        {
          title: '尺码配比'
        },
        {
          title: '计划配比'
        },
        {
          title: '计划数量'
        },
        {
          title: '实际数量'
        },
        {
          title: '实际交货'
        },
        {
          title: '计划数量'
        },
        {
          title: '实际数量'
        }
      ],
      topTabledata: [
        {
          size: '48#',
          value1: '',
          value2: '9',
          value3: '9',
          value4: '',
          value5: '',
          value6: ''
        },
        {
          size: '50#',
          value1: '',
          value2: '24',
          value3: '24',
          value4: '',
          value5: '',
          value6: ''
        },
        {
          size: '52#',
          value1: '',
          value2: '26',
          value3: '27',
          value4: '',
          value5: '',
          value6: ''
        },
        {
          size: '54#',
          value1: '',
          value2: '14',
          value3: '14',
          value4: '',
          value5: '',
          value6: ''
        },
        {
          size: '56#',
          value1: '',
          value2: '7',
          value3: '7',
          value4: '',
          value5: '',
          value6: ''
        },
        {
          size: '',
          value1: '',
          value2: '',
          value3: '',
          value4: '',
          value5: '',
          value6: ''
        },
        {
          size: '小计',
          value1: '',
          value2: '80',
          value3: '81',
          value4: '',
          value5: '',
          value6: ''
        }
      ],
      topSum: [],
      tableHeight: window.innerHeight - 390,
      // tableDatafs: [],
      tableDatafs: [
        {
          value1: '1',
          value2: '面料 2134001',
          value3: '12100%山羊36',
          value4: '150cm有效',
          value5: '160.5',
          value6: '81',
          value7: '1.98',
          value8: '420',
          value9: '832.22',
          value10: '1.99',
          value11: '',
          value12: '',
          value13: ''
        },
        {
          value1: '2',
          value2: '面料 1673676',
          value3: '68支 100%纳米棉',
          value4: '200cm有效',
          value5: '158.5',
          value6: '102',
          value7: '2.20',
          value8: '864',
          value9: '1065.80',
          value10: '6.5',
          value11: '',
          value12: '',
          value13: ''
        },
        {
          value1: '3',
          value2: '面料 0315001',
          value3: '1/54nm 53%棉 47%醋酸纤维',
          value4: '100cm有效',
          value5: '108.2',
          value6: '34',
          value7: '2.56',
          value8: '566',
          value9: '579.00',
          value10: '1.78',
          value11: '',
          value12: '',
          value13: ''
        },
        {
          value1: '4',
          value2: '面料 4021001',
          value3: '2/32nm 强捻棉',
          value4: '240cm有效',
          value5: '245.9',
          value6: '65',
          value7: '1.82',
          value8: '892',
          value9: '1093.00',
          value10: '2.11',
          value11: '',
          value12: '',
          value13: ''
        },
        {
          value1: '',
          value2: '合计',
          value3: '',
          value4: '',
          value5: '',
          value6: '',
          value7: '',
          value8: '',
          value9: '3,570.02',
          value10: '',
          value11: '企划成本：',
          value12: '',
          value13: ''
        }
      ],
      currentpagefs: 1,
      pageCountfs: 0,
      pageSizefs: 20,
      tableTitlesfs: [
        {
          fixed: false,
          prop: 'ROW_NUMBER',
          align: 'center',
          label: '序号',
          width: 5
        },
        {
          fixed: false,
          prop: 'MATERIAL_NAME',
          align: 'center',
          label: '面料名称',
          width: 19
        },
        {
          fixed: false,
          prop: 'ELEMENT',
          align: 'center',
          label: '成分',
          width: 11
        },
        {
          fixed: false,
          prop: 'MODEL',
          align: 'center',
          label: '规格',
          width: 11
        },
        {
          fixed: false,
          prop: 'OUT_QTY',
          align: 'center',
          label: '送货米数',
          width: 11
        },
        {
          fixed: false,
          prop: 'IN_QTY',
          align: 'center',
          label: '实际到货数',
          width: 11
        },
        {
          fixed: false,
          prop: 'F_UNIT_QTY',
          align: 'center',
          label: '实际用料',
          width: 11
        },
        {
          fixed: false,
          prop: 'PRICE',
          align: 'center',
          label: '单价',
          width: 9
        },
        {
          fixed: false,
          prop: 'F_PRICE',
          align: 'center',
          label: '核价',
          width: 9
        },
        {
          fixed: false,
          prop: 'UNIT_QTY',
          align: 'center',
          label: '预估耗料',
          width: 9
        },
        {
          fixed: false,
          prop: 'value11',
          align: 'center',
          label: '--',
          width: 9
        },
        {
          fixed: false,
          prop: 'value12',
          align: 'center',
          label: '备料颜色',
          width: 9
        },
        {
          fixed: false,
          prop: 'value13',
          align: 'center',
          label: '预计数',
          width: 9
        }
      ],
      sizeLine: {},
      matSize: [],
      titletop: ['计划配比', '计划数量', '实际数量', '实际交货'],
      colorList: [],
      showbotton: false,
      checkMatSign: 'N'
    }
  },
  watch: {},
  created() {},
  mounted() {
    console.log(this.pkval)
    this.getData()
  },
  methods: {
    changepagefs(currentpage) {
      this.currentpagefs = currentpage
      this.tableData = []
      this.tableDatafs = []
    },
    search() {
      console.log(this.sampleData.series)
    },
    getData() {
      let param = {
        billId: this.pkval
      }
      this.$postJson('/Custom/scm/checkMaterial', param).then(res => {
        if (res.success) {
          this.showbotton = res.data.showButton
          // this.showbotton=true;
          console.log(res.data.matList.matSizeList)
          if (res.data.matList != []) {
            let sampleDetail = res.data.matList[0]
            this.checkMatSign = sampleDetail.checkMatSign
            this.goodsPicnew =
              this.ImgPath +
              '/SCM/Product/' +
              sampleDetail.product_no +
              '/' +
              sampleDetail.product_no +
              '.jpg'
            this.sampleData = {
              series: sampleDetail.materialId,
              factstyle: '',
              whstyle: sampleDetail.product_code,
              pic: '',
              inDate: sampleDetail.inDate,
              color: '藏青人字纹Q1',
              factory: sampleDetail.shortName
            }
            if (sampleDetail.matSizeList.length != 0) {
              this.sizeLine = sampleDetail.matSizeList[0]
              this.matSize = []
              let rate1 = {}
              let rate2 = {}
              for (let p = 1; p < sampleDetail.matSizeList.length; p++) {
                let nbmatSize = sampleDetail.matSizeList[p]
                if (p % 2 == 1) {
                  let color = {
                    color: sampleDetail.matSizeList[p].color_name,
                    id: sampleDetail.matSizeList[p].color_no
                  }
                  this.colorList.push(color)
                  if (nbmatSize.total == 0) {
                    let fm = 1
                    rate1 = {
                      size1:
                        nbmatSize.size1 == null
                          ? null
                          : ((nbmatSize.size1 / fm) * 100).toFixed(0) + '%',
                      size2:
                        nbmatSize.size2 == null
                          ? null
                          : ((nbmatSize.size2 / fm) * 100).toFixed(0) + '%',
                      size3:
                        nbmatSize.size3 == null
                          ? null
                          : ((nbmatSize.size3 / fm) * 100).toFixed(0) + '%',
                      size4:
                        nbmatSize.size4 == null
                          ? null
                          : ((nbmatSize.size4 / fm) * 100).toFixed(0) + '%',
                      size5:
                        nbmatSize.size5 == null
                          ? null
                          : ((nbmatSize.size5 / fm) * 100).toFixed(0) + '%',
                      size6:
                        nbmatSize.size6 == null
                          ? null
                          : ((nbmatSize.size6 / fm) * 100).toFixed(0) + '%',
                      size7:
                        nbmatSize.size7 == null
                          ? null
                          : ((nbmatSize.size7 / fm) * 100).toFixed(0) + '%',
                      size8:
                        nbmatSize.size8 == null
                          ? null
                          : ((nbmatSize.size8 / fm) * 100).toFixed(0) + '%',
                      title: 'B_PP_QTY_rate'
                    }
                  } else {
                    rate1 = {
                      size1:
                        nbmatSize.size1 == null
                          ? null
                          : ((nbmatSize.size1 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size2:
                        nbmatSize.size2 == null
                          ? null
                          : ((nbmatSize.size2 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size3:
                        nbmatSize.size3 == null
                          ? null
                          : ((nbmatSize.size3 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size4:
                        nbmatSize.size4 == null
                          ? null
                          : ((nbmatSize.size4 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size5:
                        nbmatSize.size5 == null
                          ? null
                          : ((nbmatSize.size5 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size6:
                        nbmatSize.size6 == null
                          ? null
                          : ((nbmatSize.size6 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size7:
                        nbmatSize.size7 == null
                          ? null
                          : ((nbmatSize.size7 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size8:
                        nbmatSize.size8 == null
                          ? null
                          : ((nbmatSize.size8 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      title: 'B_PP_QTY_rate'
                    }
                  }
                  this.matSize.push(rate1)
                }
                this.matSize.push(sampleDetail.matSizeList[p])
                if (p % 2 != 1) {
                  if (nbmatSize.total == 0) {
                    let fm = 1
                    rate2 = {
                      size1:
                        nbmatSize.size1 == null
                          ? null
                          : ((nbmatSize.size1 / fm) * 100).toFixed(0) + '%',
                      size2:
                        nbmatSize.size2 == null
                          ? null
                          : ((nbmatSize.size2 / fm) * 100).toFixed(0) + '%',
                      size3:
                        nbmatSize.size3 == null
                          ? null
                          : ((nbmatSize.size3 / fm) * 100).toFixed(0) + '%',
                      size4:
                        nbmatSize.size4 == null
                          ? null
                          : ((nbmatSize.size4 / fm) * 100).toFixed(0) + '%',
                      size5:
                        nbmatSize.size5 == null
                          ? null
                          : ((nbmatSize.size5 / fm) * 100).toFixed(0) + '%',
                      size6:
                        nbmatSize.size6 == null
                          ? null
                          : ((nbmatSize.size6 / fm) * 100).toFixed(0) + '%',
                      size7:
                        nbmatSize.size7 == null
                          ? null
                          : ((nbmatSize.size7 / fm) * 100).toFixed(0) + '%',
                      size8:
                        nbmatSize.size8 == null
                          ? null
                          : ((nbmatSize.size8 / fm) * 100).toFixed(0) + '%',
                      title: 'B_PP_QTY_rate'
                    }
                  } else {
                    rate2 = {
                      size1:
                        nbmatSize.size1 == null
                          ? null
                          : ((nbmatSize.size1 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size2:
                        nbmatSize.size2 == null
                          ? null
                          : ((nbmatSize.size2 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size3:
                        nbmatSize.size3 == null
                          ? null
                          : ((nbmatSize.size3 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size4:
                        nbmatSize.size4 == null
                          ? null
                          : ((nbmatSize.size4 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size5:
                        nbmatSize.size5 == null
                          ? null
                          : ((nbmatSize.size5 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size6:
                        nbmatSize.size6 == null
                          ? null
                          : ((nbmatSize.size6 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size7:
                        nbmatSize.size7 == null
                          ? null
                          : ((nbmatSize.size7 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      size8:
                        nbmatSize.size8 == null
                          ? null
                          : ((nbmatSize.size8 / nbmatSize.total) * 100).toFixed(
                              0
                            ) + '%',
                      title: 'C_IN_QTY_rate'
                    }
                  }
                  this.matSize.push(rate2)
                }
              }
            }
          }
          this.tableDatafs = res.data.bomList
          let fpricr_sum = 0
          for (let i = 0; i < this.tableDatafs.length; i++) {
            fpricr_sum += Number(this.tableDatafs[i].F_PRICE)
          }
          let sumtable = {
            value1: '',
            MATERIAL_NAME: '合计',
            ELEMENT: '',
            MODEL: '',
            OUT_QTY: '',
            IN_QTY: '',
            F_UNIT_QTY: '',
            PRICE: '',
            F_PRICE: fpricr_sum.toFixed(3),
            UNIT_QTY: '',
            value11: '企划成本：',
            value12: '',
            value13: ''
          }
          this.tableDatafs.push(sumtable)
        } else {
          this.$notify.error({
            title: res.errMsg,
            dangerouslyUseHTMLString: true,
            message:
              '<div style="width:250px;word-wrap:break-word;">' +
              res.errMsgDetail +
              '</div>'
          })
        }
      })
    },
    hlconfirm() {
      let param = {
        billId: this.pkval
      }
      this.$postJson('/Custom/scm/confirmMaterial', param).then(res => {
        if (res.success) {
          this.checkMatSign = 'Y'
          console.log(res.success)
        } else {
          this.$notify.error({
            title: res.errMsg,
            dangerouslyUseHTMLString: true,
            message:
              '<div style="width:250px;word-wrap:break-word;">' +
              res.errMsgDetail +
              '</div>'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.flexrowc {
  display: flex;
  justify-content: space-between;
}
.flexcc {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 20px;
  border-bottom: 0.5px solid rgb(126, 126, 126, 0.2);
}
.flexcc2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 83px;
  border-bottom: 0.5px solid rgb(126, 126, 126, 0.2);
}
.main {
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-size: 14px;
  .topdetail {
    width: 95%;
    margin-left: 2.5%;
    height: 5%;
    padding-top: 8px;
  }
  .sampleTop {
    width: 95%;
    margin-left: 2.5%;
    height: 20%;
    border: 0.5px solid rgb(126, 126, 126, 0.2);
    .yydemo {
      width: 30%;
      // height: 100%;
      border-right: 0.5px solid rgb(126, 126, 126, 0.2);
      .yyseries {
        width: 100%;
        height: 10%;
      }
      .yystyle {
        width: 100%;
        height: 100%;
        .yypic {
          width: 70%;
          height: 100%;
          .yypicdiv {
            width: 100%;
            height: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 35%;
            }
          }
        }
        .yycolor {
          width: 30%;
          height: 100%;
          border-left: 0.5px solid rgb(126, 126, 126, 0.2);
        }
      }
      .yytime {
        width: 100%;
        height: 10%;
        font-size: 12px;
        margin-top: 5px;
        padding: 10px 0;
      }
    }
    .yytable {
      width: 80%;
      height: 100%;
      // border-top: 0.5px solid rgb(126, 126, 126, 0.2);
      .yykhtable {
        width: 100%;
        height: 90%;

        .tline {
          width: 100%;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 0.5px solid rgb(126, 126, 126, 0.2);
          border-right: 0.5px solid rgb(126, 126, 126, 0.2);
        }
        .ttitle {
          width: 10%;
          height: 100%;
          font-size: 12px;
          line-height: 20px;
          //padding-left: 5%;
          div {
            height: 20px;
            text-align: center;
            border-bottom: 0.5px solid rgb(126, 126, 126, 0.2);
            border-right: 0.5px solid rgb(126, 126, 126, 0.2);
            border-left: 0.5px solid rgb(126, 126, 126, 0.2);
          }
        }
        .ttable2 {
          width: 100%;
          height: 100%;
        }
        .ttable {
          width: 90%;
          height: 100%;
          font-size: 12px;
          .tcontent {
            width: 100%;
            height: 100%;
            .twidth {
              width: 20%;
              width: 100%;
              line-height: 20px;
            }
          }
        }
        .tcontent {
          width: 100%;
          height: 100%;
          .tline {
            width: 100%;
            height: 15%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 0.5px solid rgb(126, 126, 126, 0.2);
            border-right: 0.5px solid rgb(126, 126, 126, 0.2);
          }
        }
      }
    }
  }
  .sampleTable {
    width: 95%;
    margin: 15px 0 0 2.5%;
    height: 70%;
  }
}
</style>
