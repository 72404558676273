<template>
  <div
    :style="
      'background-color:' +
      item.shiftCatColor +
      ';border: solid 1px' +
      item.shiftCatColor
    "
    class="gradeTypeViewCardBlock"
    @click="jumpClass"
  >
    <div class="name">
      {{ item.classesName }}
      {{ `${isHasMutilDays ? item.attendanceDate + ' ' : ' '}` }}
      <!--      ${item.startTime}-${item.endTime}-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'GradeTypeViewCard',
  components: {},
  props: {
    item: {
      type: Object
    },
    isHasMutilDays: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: []
    }
  },
  methods: {
    change() {
      this.item.checked = !this.item.checked
      // this.$emit('change')
    },
    jumpClass() {
      console.log('classId', this.item.classesId)
      // /home/configurationForm/SM0587?menuid=SM0587&title=班次管理&configtype=006&groupid=M11460&filtergroup=&filtergroupshowsign=Y
      // if(this.item.classesType === 'SCAT00000007'){
      //   return
      // } else {
      //
      // }
      this.$router.push({
        path: '/home/configurationForm/SM0587',
        params: {
          wfid: 'SM0587'
        },
        query: {
          moduleid: 'M11495',
          wfid: 'SM0587',
          configtype: '006',
          frombillid: '18',
          menuid: 'SM0587',
          title: '班次管理',
          groupid: 'M11460',
          filtergroupshowsign: 'Y',
          pkval: this.item?.belongTo ? this.item.belongTo : this.item.classesId
          // classesId: 'BC220900008331',
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'css/duty';

.gradeTypeViewCardBlock {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
  //height: 30px;
  background-color: $noonNightBg;
  border-radius: 5px;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  border: $noonNightBg solid 1px;
  padding: 3px;

  .name {
    text-align: center;
    text-overflow: ellipsis;
  }
}

.checked {
  color: #367ce3;
  border: #367ce3 solid 1px;
}

.checked-icon {
  color: #367ce3;
  position: absolute;
  right: -1px;
  top: -1px;
  font-size: 10px;
}
</style>
