<template>
  <div style="width: 100%; height: 100%">
    <div ref="frameContainer" class="container" />
    <!-- <div id="label"></div> -->
    <div v-if="label2Show" id="label2" class="label2">
      <dialogHg
        :faclist="faclist"
        :storage-obj="storageObj"
        :con-type="conType"
        type="select"
        @hgClose="hgClose"
        @closeLinkTc="closeLinkTc"
      />
    </div>
    <div class="btn">
      <!-- <el-button type="primary" @click="clickJump([-0.68,1,0],[-0.68,0.21,-0.21])">跳转示例</el-button> -->
      <!-- <el-button type="primary" @click="createMoreChange">删除几何体</el-button> -->
      <!-- <div class="">
                    <div
                      v-for="(item, index) in areasid"
                      :key="index"
                      @click="createMoreChange(item.modelid)"
                    > -->
      <!-- <el-button type="primary" @click="createMoreChange(item.storageid)">{{item.storeyName}}</el-button> -->
      <!-- 楼层：{{ item.storeyName }}
                    </div>
                  </div> -->
      <el-tree
        :data="areas"
        :props="defaultProps"
        @node-click="handleNodeClick"
      />
    </div>
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
import $ from 'jquery'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js'
import dialogHg from '@/components/threeD/dialogHg'
import { markRaw } from "vue";

export default {
  name: 'FloorIframe',
  components: { dialogHg },
  props: ['queryParams'],
  emits: ['btnLinkClose'],
  data() {
    return {
      dom: null,
      camera: null,
      scene: null,
      renderer: null,
      controls: null,
      selectObject: null,
      areas: [],
      areasx: [],
      areasid: [],
      defaultProps: {
        children: 'children',
        label: 'storeyName'
      },
      label2Show: false,
      faclist: [],
      divWidth: 0,
      divHeight: 0,
      bfMaterial: null,
      currentFloor: 'SM000011',
      glColor: { r: 255, g: 0, b: 0 },
      currentProperty: 'PR000108',
      locationlist: [],
      HLCabinet: [],
      currentEntrance: 'SI22040085',
      entrancelist: [],
      MJCabinet: [],
      currentDevice: 'SB000022',
      devalertlist: [],
      SBCabinet: [],
      storageObj: {
        storageid: '', //当前仓库id
        storeyName: '' //当前仓库name
      },
      gltfLoad: null,
      conType: 'container'
    }
  },
  mounted() {
    console.log('floorframe aa')
    setTimeout(() => {
      console.log('floorframe:', this.queryParams)
      //初始化loader
      this.gltfLoad = new GLTFLoader()
      this.gltfLoad.setMeshoptDecoder(MeshoptDecoder)

      this.dom = this.$refs.frameContainer

      this.init() //初始化

      this.getAreas() //模型区域列表

      let property = this.queryParams?.property
          ? this.queryParams.property
          : localStorage.getItem('property')
      let entrance = this.queryParams?.entrance
          ? this.queryParams.entrance
          : localStorage.getItem('entrance')
      let device = this.queryParams?.device
          ? this.queryParams.device
          : localStorage.getItem('device')
      console.log(entrance)
      if (property) {
        console.log('财物')
        this.getLocation(property)
        localStorage.removeItem('property')
      } else if (entrance) {
        console.log('门禁')
        this.getEntrance(entrance)
        localStorage.removeItem('entrance')
      } else if (device) {
        console.log('设备')
        this.getdevAlert(device)
        localStorage.removeItem('device')
      } else {
        this.getModel(this.currentFloor)
      }
      this.animate() //动画
      this.divWidth = this.dom.offsetWidth
      this.divHeight = this.dom.offsetHeight

      this.dom.addEventListener('dblclick', this.onMouseDblclick, false)
      this.dom.addEventListener('click', this.onMouseclick, false)
    }, 300)

  },
  methods: {
    closeLinkTc(obj) {
      console.log('aa:', obj)
      $emit(this, 'btnLinkClose', obj)
    },
    //点击跳转
    clickJump(position, lookAt) {
      console.log('*********************')

      // this.flyTo(new THREE.Vector3(-0.68,1,0),new THREE.Vector3(-0.68,0.21,-0.21))

      this.flyTo(
        new THREE.Vector3(position[0], position[1], position[2]),
        new THREE.Vector3(lookAt[0], lookAt[1], lookAt[2])
      )
      // this.flyTo(new THREE.Vector3(-1.3,1,0),new THREE.Vector3(-1.3,0.21,-0.6))
    },
    flyTo(position, lookAt) {
      let num = 30
      if (!this.camera || !this.controls) {
        return
      }
      const t = this.controls.target.clone()
      const p = this.camera.position.clone()

      let px = (position.x - p.x) / num
      let py = (position.y - p.y) / num
      let pz = (position.z - p.z) / num
      let lx = (lookAt.x - t.x) / num
      let ly = (lookAt.y - t.y) / num
      let lz = (lookAt.z - t.z) / num
      let pos = JSON.parse(JSON.stringify(p))
      let look = JSON.parse(JSON.stringify(t))
      this.time = setInterval(() => {
        pos.x = pos.x + px
        pos.y = pos.y + py
        pos.z = pos.z + pz
        look.x = look.x + lx
        look.y = look.y + ly
        look.z = look.z + lz
        if (pos.x.toFixed(2) == position.x.toFixed(2)) clearInterval(this.time)
        this.moveLookAt(pos, look)
      })
    },
    //移动照相机位置
    moveLookAt(position, lookAt) {
      this.camera.lookAt(lookAt)
      console.log(position)
      this.camera.position.set(position.x, position.y, position.z)
      this.controls.target.set(lookAt.x, lookAt.y, lookAt.z)
      this.controls.update()
    },
    hgClose() {
      this.label2Show = false
    },
    handleNodeClick(data) {
      console.log(data)
      this.currentFloor = data.modelid
      if (data.children == null) {
        this.createMoreChange(data.modelid)
      }
      // console.log(data);
    },
    //设备模型列表获取模型
    getModel(areaid) {
      //KQ-001
      console.log(this.HLCabinet)
      let param = {
        modelid: areaid
      }
      console.log(areaid)
      this.$doFetch('/Custom/Sto3D/models', param).then(res => {
        this.storageObj.storageid = res.storageid
        this.storageObj.storeyName = this.areas.find(
          e => e.storageid === this.storageObj.storageid
        ).storeyName
        // console.log(res);
        if (res.facilityModels != null) {
          // let list = res.facilityModels;
          // let datalist = res;
          let list = []
          for (let f = 0; f < res.facilityModels.length; f++) {
            if (res.facilityModels[f].modeltype == '001') {
              list.push(res.facilityModels[f])
            }
          }
          // let list = res.facilityModels;
          // let datalist = res;
          // datalist.facilityModels=list;
          // this.$delete(datalist, "facilityModels");

          let datalist = res
          delete datalist.facilityModels
          list.unshift(datalist)
          // this.createPic(datalist,areaid);//添加平面图片
          // console.log(list)
          //   list.push(datalist);
          // console.log(list);

          for (let r = 0; r < list.length; r++) {
            list[r].xrotation = (list[r].xrotation / 180) * Math.PI
            list[r].yrotation =
              list[r].yrotation == 0 ? 0 : Math.PI / list[r].yrotation
          }
          for (let i = 0; i < list.length; i++) {
            this.createGltf(list[i], i, list.length - 1, areaid)
          }
        } else {
          let datalist = []
          datalist.push(res)
          // this.$delete(datalist,'facilityModels')
          // console.log(datalist[0]);
          for (let i = 0; i < 1; i++) {
            this.createGltf(datalist[i])
          }
          console.log('--------------')
        }
      })
    },
    //获取模型区域列表
    getAreas() {
      this.$doFetch('/Custom/Sto3D/areas').then(res => {
        console.log(res)
        this.areas = res
        this.areas.map(item => {
          this.areasx.push(item.children)
          let obj = {
            modelid: item.modelid,
            storey: item.storey,
            storeyName: item.storeyName,
            areaid: item.areaid,
            storageid: item.storageid
          }
          this.areasid.push(obj)
        })
        for (let i = 0; i < this.areasx.length; i++) {
          for (let x = 0; x < this.areasx[i].length; x++) {
            this.areasid.push(this.areasx[i][x])
          }
        }
        //console.log(this.areasid)
      })
    },
    //根据财物ID定位库柜
    getLocation(propertyid) {
      let param = {
        propertyid: propertyid
      }
      this.$doFetch('/Custom/Sto3D/location', param).then(res => {
        this.locationlist = res
        // console.log(this.locationlist)
        for (let i = 0; i < this.locationlist.length; i++) {
          if (this.locationlist[i].floorModelId == this.currentFloor) {
            let obj = this.locationlist[i].facilityModelIds
            for (let l = 0; l < obj.length; l++) {
              this.HLCabinet.push(obj[l].modelid)
            }
          }
        }
        let that = this
        that.getModel(this.currentFloor)
      })
    },
    //门禁授权联动
    getEntrance(billid) {
      let param = {
        billid: billid
      }
      this.$doFetch('/Custom/Sto3D/entrance', param).then(res => {
        this.entrancelist = res
        for (let i = 0; i < this.entrancelist.length; i++) {
          if (this.entrancelist[i].floorModelId == this.currentFloor) {
            let obj = this.entrancelist[i].facilityModelIds
            for (let l = 0; l < obj.length; l++) {
              this.MJCabinet.push(obj[l].modelid)
            }
          }
        }
        let that = this
        that.getModel(this.currentFloor)
      })
    },
    //设备报警接口
    getdevAlert(facilityid) {
      let param = {
        facilityid: facilityid
      }
      this.$doFetch('/Custom/Sto3D/deviceAlert', param).then(res => {
        this.devalertlist = res
        for (let i = 0; i < this.devalertlist.length; i++) {
          if (this.devalertlist[i].floorModelId == this.currentFloor) {
            let obj = this.devalertlist[i].facilityModelIds
            for (let l = 0; l < obj.length; l++) {
              this.SBCabinet.push(obj[l].modelid)
            }
          }
        }
        let that = this
        that.getModel(this.currentFloor)
      })
      console.log('devalertlist', this.devalertlist)
    },
    clearScene() {
      // 从scene中删除模型并释放内存
      if (this.scene.children.length > 0) {
        for (let p = 0; p < this.scene.children.length; p++) {
          var currObj = this.scene.children[p]
          // 判断类型
          // console.log(currObj);
          if (currObj.type == 'Group') {
            var children = currObj.children
            for (let i = 0; i < children.length; i++) {
              this.deleteGroup(children[i])
            }
          }
          // else {
          //   this.deleteGroup(currObj);
          // }
          // this.scene.remove(currObj);
        }
      }
    },
    deleteGroup() {
      var allChildren = this.scene.children
      for (var i = allChildren.length - 1; i >= 0; i--) {
        if (allChildren[i] instanceof THREE.Group) {
          this.scene.remove(allChildren[i])
        }
      }

      //   // console.log(group);
      //   if (!group) return;
      //   // 删除掉所有的模型组内的mesh
      //   group.traverse(function (item) {
      //     // console.log(item);
      //     if (item instanceof THREE.Mesh) {
      //       // console.log(1)
      //       item.geometry.dispose(); // 删除几何体
      //       item.material.dispose(); // 删除材质
      //     } else if (item instanceof THREE.Group) {
      //       // for(let i = 0; i< item.children.length; i++){
      //       //     if (item.children[i] instanceof THREE.Group){
      //       //         let item1=item.children[i];
      //       //         for(let i = 0; i< item1.children.length; i++){
      //       //     if (item1.children[i] instanceof THREE.Mesh){
      //       //         // let item1=item.children[i];
      //       //     item1.children[i].geometry.dispose(); // 删除几何体
      //       // item1.children[i].material.dispose(); // 删除材质
      //       //     }
      //       // }
      //       //     }
      //       // }
      //       // console.log(2)
      //     }
      //   });
    },
    createMoreChange(modelid) {
      this.clearScene()
      this.getModel(modelid)
      // console.log(modelid);
      // let list = [
      //   {
      //     name: "villa",
      //     modelId: "villa",
      //     scale: [0.2, 0.2, 0.2],
      //     x: 0,
      //     y: 0,
      //     z: 0,
      //     rotateX: 0,
      //     rotateY: 0,
      //   },
      // ];
      // for (let i = 0; i < list.length; i++) {
      //   this.createGltf(list[i]);
      // }
    },
    //初始化
    init: function () {
      let that = this
      let container = this.dom
      /**
       * 创建渲染器对象
       */
      this.renderer = new THREE.WebGLRenderer({ antialias: true })
      this.renderer.setSize(container.clientWidth, container.clientHeight)
      this.renderer.setClearColor(0xb9d3ff, 1) //设置背景颜色
      this.renderer.shadowMap.enabled = true //是否只显示阴影
      this.renderer.shadowMapEnabled = true //是否只显示阴影
      container.appendChild(this.renderer.domElement)
      //  创建场景对象Scene
      this.scene = markRaw(new THREE.Scene())
      /**
       * 相机设置
       */
      this.camera = new THREE.PerspectiveCamera(
        70,
        container.clientWidth / container.clientHeight,
        0.01,
        10
      )
      this.camera.lookAt(new THREE.Vector3(0, 0, 0))
      //创建一个环境灯光
      let ambientLight = new THREE.AmbientLight(0x444444, 0.2)
      this.scene.add(ambientLight)
      //创建一个平行光
      let light = new THREE.DirectionalLight(0xffffff, 0.8)
      // 位置
      light.position.set(100, 10, 50)
      // 方向光指向对象网格模型mesh，可以不设置，默认的位置是0,0,0
      //  light.target = mesh;
      this.scene.add(light)
      //点光源
      // var point = new THREE.PointLight(0xffffff, 0.2);
      // point.position.set(400, 200, 300); //点光源位置
      // // 通过add方法插入场景中，不插入的话，渲染的时候不会获取光源的信息进行光照计算
      // this.scene.add(point); //点光源添加到场景中
      // //聚光源
      // let spotLight = new THREE.SpotLight(0xffffff, 0.2);
      // this.scene.add(spotLight); //聚光源添加到场景中
      light.castShadow = true
      //把视角放入环境
      this.scene.add(this.camera)
      //加入事件监听器，窗口自定义
      window.onresize = function () {
        // 重置渲染器输出画布canvas尺寸
        that.renderer.setSize(that.divWidth, that.divHeight)
        // 全屏情况下：设置观察范围长宽比aspect为窗口宽高比
        that.camera.aspect = that.divWidth / that.divHeight
        // 渲染器执行render方法的时候会读取相机对象的投影矩阵属性projectionMatrix
        // 但是不会每渲染一帧，就通过相机的属性计算投影矩阵(节约计算资源)
        // 如果相机的一些属性发生了变化，需要执行updateProjectionMatrix ()方法更新相机的投影矩阵
        that.camera.updateProjectionMatrix()
      }

      //创建控件对象
      this.controls = new OrbitControls(this.camera, this.renderer.domElement) //创建控件对象
      // this.controls.addEventListener('change', this.animate)

      this.camera.position.z = 0.8
      // this.camera.position.x = 0;
      this.camera.position.y = 0.8
      this.controls.update()
    },
    //加载平面贴图
    createPic(list, areaid) {
      let that = this
      let pic = 'static/img/onefloor.jpg'
      let translateX = -0.24
      let translateY = 0.2
      let translateZ = -0.25
      let geoPointx = 2.55
      let geoPointy = 1.2
      // 贴图模型
      if (areaid == 'SM000015') {
        pic = 'static/img/parkfloor.jpg'
        translateX = -0.2
        translateY = -0.3
        translateZ = -0.58
        geoPointx = 3
        geoPointy = 1.5
      }
      let geometry1 = new THREE.PlaneGeometry(geoPointx, geoPointy)
      // TextureLoader创建一个纹理加载器对象，可以加载图片作为几何体纹理
      let textureLoader = new THREE.TextureLoader()
      // 执行load方法，加载纹理贴图成功后，返回一个纹理对象Texture
      textureLoader.load(pic, function (texture) {
        that.scene.remove(that.mesh1)
        let material1 = new THREE.MeshBasicMaterial({
          // color: 0x0000ff,
          // 设置颜色纹理贴图：Texture对象作为材质map属性的属性值
          map: texture, //设置颜色贴图属性值
          side: THREE.DoubleSide
        }) //材质对象Material
        that.mesh1 = new THREE.Mesh(geometry1, material1) //网格模型对象Mesh
        that.mesh1.translateY(translateY) //该模型沿Y轴正方向平移1
        that.mesh1.translateX(translateX) //该模型沿Y轴正方向平移1
        that.mesh1.translateZ(translateZ) //该模型沿Y轴正方向平移1
        that.mesh1.rotateX(-Math.PI / 2)
        that.scene.add(that.mesh1) //网格模型添加到场景中
        //纹理贴图加载成功后，调用渲染函数执行渲染操作
        // render();
      })
    },
    createGltf(res, index, length, areaid) {
      let that = this
      //加载gltf模型
      let gltf1 = this.gltfLoad
      // console.log("/_MINIO" + res.path);
      gltf1.load(
        that.ImgbaseURL + res.path,
        function (gltf) {
          const model = gltf.scene
          if (that.currentFloor == areaid) {
            model.traverse(function (child) {
              if (child.isMesh) {
                //可根据名字寻找模型（name是可以重名的，返回第一个）
                child.name = res.modelid
                child.facilityid = res.facilityid
                child.modelid = res.modelid
                child.modelstyle = res.modelstyle
                child.material.emissive = child.material.color
                child.material.emissiveMap = child.material.map
                child.material.transparent = true //材质允许透明 如果有玻璃材质时开启
                child.material.opacity = 1 //材质默认透明度
                child.clipShadows = true
                child.wireframe = true
                child.castShadow = true
                if (that.HLCabinet.length > 0) {
                  that.HLCabinet.forEach(e => {
                    let mxid = e
                    if (child.modelid == mxid) {
                      child.material.opacity = 0.5 //材质默认透明度
                      child.material.color = that.glColor //材质默认透明度
                      child.material.emissive = that.glColor
                    }
                  })
                }
                if (that.MJCabinet.length > 0) {
                  that.MJCabinet.forEach(e => {
                    let mxid = e
                    if (child.modelid == mxid) {
                      child.material.opacity = 0.5 //材质默认透明度
                      child.material.color = that.glColor //材质默认透明度
                      child.material.emissive = that.glColor
                    }
                  })
                }
              }
              if (that.SBCabinet.length > 0) {
                that.SBCabinet.forEach(e => {
                  let mxid = e
                  if (child.modelid == mxid) {
                    child.material.opacity = 0.5 //材质默认透明度
                    child.material.color = that.glColor //材质默认透明度
                    child.material.emissive = that.glColor
                  }
                })
              }
            })
            //设置整体场景的比例
            model.scale.set(res.xscale, res.yscale, res.zscale)
            model.translateX(res.xaxis)
            model.translateY(res.yaxis)
            model.translateZ(res.zaxis)
            model.rotateX(res.xrotation)
            model.rotateY(res.yrotation)
            that.scene.add(model)

            // // 循环当前模型
            // model.traverse((child) => {
            //   // 查看模型的类型是否为MEsh 只有是mesh网格模型的才有位置属性这些
            //   if (child.type == "Mesh") {
            //     //绘制边框线
            //     const lineGeom = new THREE.EdgesGeometry(child.geometry);
            //     const lineMaterial = new THREE.LineBasicMaterial({
            //       color: 0x018bf5,
            //       linewidth: 10,
            //       linecap: "round",
            //       linejoin: "round",
            //     });
            //     const line = new THREE.LineSegments(lineGeom, lineMaterial);
            //     line.scale.copy(model.scale);
            //     line.rotation.copy(model.rotation);
            //     line.position.copy(model.position);
            //     //that.scene.add(line);
            //   }
            // });
            if (index == length) {
              let tz = {}
              // console.log(that.entrancelist)
              if (that.MJCabinet.length > 0) {
                if (
                  that.entrancelist.length > 0 &&
                  that.entrancelist[0].facilityModelIds.length > 0
                ) {
                  tz = that.entrancelist[0].facilityModelIds[0]
                }
              }
            }
            // console.log(5555555555)
            // console.log(that.entrancelist)
            if (index == length) {
              let tz = {}
              // console.log(that.entrancelist)
              if (that.MJCabinet.length > 0) {
                if (
                  that.entrancelist.length > 0 &&
                  that.entrancelist[0].facilityModelIds.length > 0
                ) {
                  tz = that.entrancelist[0].facilityModelIds[0]
                }
              }
              if (that.HLCabinet.length > 0) {
                if (
                  that.locationlist.length > 0 &&
                  that.locationlist[0].facilityModelIds.length > 0
                ) {
                  tz = that.locationlist[0].facilityModelIds[0]
                }
              }
              if (that.SBCabinet.length > 0) {
                if (
                  that.devalertlist.length > 0 &&
                  that.devalertlist[0].facilityModelIds.length > 0
                ) {
                  tz = that.devalertlist[0].facilityModelIds[0]
                }
              }
              let position = [tz.xaxis, 1, 0]
              let lookAt = [tz.xaxis, tz.yaxis, tz.zaxis]
              if (JSON.stringify(tz) !== '{}') {
                that.clickJump(position, lookAt)
              }
            }
          }
        },
        xhr => {
          // called while loading is progressing
          //   console.log( xhr.loaded / xhr.total * 100 );
          //   if(xhr.loaded / xhr.total==100){
          //   if(this.entrancelist.length>0&&this.entrancelist[0].facilityModelIds.length>0){
          //   let tz=this.entrancelist[0].facilityModelIds;
          //    let position=[tz.xaxis,1,-1];
          //    let lookAt=[tz.xaxis,tz.yaxis,tz.zaxis];
          //    this.clickJump(position, lookAt)
          // }
          //   }
        }
      )
    },
    createMore() {
      let list = [
        {
          path: '/image/modeltype/house.gltf',
          xaxis: -1.5,
          yaxis: -0.03,
          zaxis: 0,
          xscale: 0.05,
          yscale: 0.05,
          zscale: 0.05,
          xrotation: 1,
          yrotation: 0
        }
      ]
      for (let i = 0; i < list.length; i++) {
        this.createGltf(list[i])
      }
    },
    // 鼠标双击触发的方法
    onMouseDblclick(event) {
      // 获取 raycaster 和所有模型相交的数组，其中的元素按照距离排序，越近的越靠前
      var intersects = this.getIntersects(event)

      // 获取选中最近的 Mesh 对象
      if (
        intersects.length != 0 &&
        intersects[0].object instanceof THREE.Mesh
      ) {
        this.selectObject = intersects[0].object
        // this.changeMaterial(this.selectObject);
        this.renderDiv(this.selectObject)
      } else {
        alert('未选中 Mesh!')
      }
    },
    //鼠标单击触发的方法
    onMouseclick(event) {
      //再给新的选中模型变色前 先把上一个模型复原
      if (this.selectObject) {
        this.selectObject.material.opacity = 1
      }
      // 获取 raycaster 和所有模型相交的数组，其中的元素按照距离排序，越近的越靠前
      var intersects = this.getIntersects(event)

      // 获取选中最近的 Mesh 对象
      if (
        intersects.length != 0 &&
        intersects[0].object instanceof THREE.Mesh
      ) {
        this.selectObject = intersects[0].object
        if (this.selectObject.facilityid != null) {
          this.changeMaterial(this.selectObject)
          this.renderDiv(this.selectObject)
          this.label2Show = true
          let param = {
            //facilityid: "SB000011",
            facilityid: this.selectObject.facilityid
          }
          this.$doFetch('/Custom/Sto3D/facility', param).then(res => {
            this.faclist = res
            let faclist = res
            // console.log('faclist',faclist);
            var vector4 = new THREE.Vector4(150, 60, 50)
            // $("#label2").css({
            //   // left: "30%",
            //   top: vector4.y,
            // });
          })
        } else {
          console.log('facilityid为空，是摆设！')
        }
      } else {
        this.label2Show = false
        console.log('未选中 Mesh!')
      }
    },
    // 获取与射线相交的对象数组
    getIntersects(event) {
      event.preventDefault()
      // console.log("event.clientX:" + event.clientX);
      // console.log("event.clientY:" + event.clientY);

      // 声明 raycaster 和 mouse 变量
      var raycaster = new THREE.Raycaster()
      var mouse = new THREE.Vector2()

      // 通过鼠标点击位置,计算出 raycaster 所需点的位置,以屏幕为中心点,范围 -1 到 1
      // let mouseX = event.offsetX-$("#container")[0].offset().left;
      // let mouseY = event.offsetY-$("#container")[0].offset().top;
      mouse.x = (event.offsetX / this.divWidth) * 2 - 1
      mouse.y = -(event.offsetY / this.divHeight) * 2 + 1

      //通过鼠标点击的位置(二维坐标)和当前相机的矩阵计算出射线位置
      raycaster.setFromCamera(mouse, this.camera)
      console.log(this.camera)

      // 获取与射线相交的对象数组，其中的元素按照距离排序，越近的越靠前
      var intersects = raycaster.intersectObjects(this.scene.children)

      //返回选中的对象
      return intersects
    },
    // 改变对象材质属性
    changeMaterial(object) {
      object.material.opacity = 0.5
      // let material = new THREE.MeshLambertMaterial({
      //   // color: 0xffff00,
      //   transparent: object.material.transparent ? false : true,
      //   opacity: 0.8,
      // });
      // object.material = material;
    },
    // 更新div的位置

    renderDiv(object) {
      console.log(object)
      // 获取窗口的一半高度和宽度
      var halfWidth = this.divWidth / 2
      var halfHeight = this.divHeight / 2

      // 逆转相机求出二维坐标
      var vector = object.position.clone().project(this.camera)

      // 修改 div 的位置
      $('#label').css({
        left: vector.x * halfWidth + halfWidth,
        top: -vector.y * halfHeight + halfHeight - object.position.y
      })
      // 显示模型信息
      $('#label').text('name:' + object.name)
    },
    // 动画
    animate: function () {
      // if (this.selectObject != undefined && this.selectObject != null) {
      //       this.renderDiv(this.selectObject);
      // }
      requestAnimationFrame(this.animate)
      this.controls.update()
      this.renderer.render(this.scene, this.camera)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.el-tree) {
  width: 100px;
  margin-left: 10px;
  margin-top: 10px;
  // border-radius: 10px;
  //background-color: rgba(255, 255, 255, 1);
  background: none;
}
.container {
  position: unset;
  width: 100%;
  height: 100%;
}

#label {
  position: absolute;
  padding: 10px;
  background: rgba(255, 255, 255, 0.6);
  line-height: 1;
  border-radius: 5px;
}

#label2 {
  width: 55%;
  height: 520px;
  margin-left: 150px;
  position: absolute;
  top: 15%;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  line-height: 1;
  padding: 10px;
  white-space: pre-line;
  line-height: 16px;
  font-size: 12px;
  background-image: url('../../assets/images/police/traffic_lights.png');
  background-size: 100% 100%;
  z-index: 2;
}

.btn {
  position: relative;
  width: 10%;
  z-index: 1;
  top: -95%;
  left: 15px;
}
</style>
