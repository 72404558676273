const button = {
  '新增': 'newly Increased',
}



const languageMap = {
    ...button,
}

module.exports = {
    enLanguageMap: languageMap
}
