import { fetch, postJson, post } from '../../http'
export default {
  getPoleInfo(params) {
    return fetch('/Custom/tc/map/getPoleInfo', params)
  },
  getAccessoryInfo(params) {
    return fetch('/Custom/tc/map/getAccessoryInfo', params)
  },
  getMoudleByDeviceId(params) {
    return fetch('/Custom/tc/map/getMoudleByDeviceId', params)
  },
}
