<template>
  <el-dialog
    v-bind="$attrs"
    title="选择班次"
    width="240px"
    top="10px"
    :append-to-body="true"
    @close="_close"
  >
    <div class="shiftSelectDialog">
      <div class="content">
        <template v-for="(item, index) of localPostClasses">
          <gradeTypeOptCard
            class="item"
            :item="item"
            :index="index"
            @mutilSelect="mutilSelect"
          />
        </template>
      </div>

      <div class="footer">
        <el-button type="danger" @click="_close"> 取消 </el-button>
        <el-button type="primary" @click="confirm"> 确定 </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { $on, $off, $once, $emit } from '../../../../../utils/gogocodeTransfer'
import gradeTypeOptCard from '@/components/aloneComponents/pg/duty/gradeTypeOptCard'

export default {
  name: 'ShiftSelectDialog',
  components: {
    gradeTypeOptCard
  },
  props: {
    postClasses: Array
  },
  emits: ['confirm', 'update:visible'],
  data() {
    return {
      localPostClasses: []
    }
  },
  watch: {},
  mounted() {
    this.postClasses.forEach(x => {
      this.localPostClasses.push({
        ...x,
        checked: false
      })
    })
  },
  methods: {
    mutilSelect(data) {
      console.log('mutilSelect1', this.localPostClasses, data)
      this.localPostClasses[data.index].checked = data.checked
      this.localPostClasses = JSON.parse(JSON.stringify(this.localPostClasses))
      console.log('mutilSelect', this.localPostClasses, data)
    },
    confirm() {
      console.log(
        'shiftConf',
        this.localPostClasses.filter(x => x.checked)
      )
      $emit(
        this,
        'confirm',
        this.localPostClasses.filter(x => x.checked)
      )
      $emit(this, 'update:visible', false)
    },
    _close() {
      $emit(this, 'update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../css/duty';
.input_width_ .el-input {
  width: 220px;
}
.shiftSelectDialog {
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
  .content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-height: 300px;
    overflow-y: scroll;
    .item {
      margin-bottom: 5px;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>
